import React, { FC, useState } from 'react'
import omit from 'lodash.omit'
import { searchFields, useTableColumns, FieldTypes } from './data'
import {
  useUpadminFinancePayToHzBankMutation,
  useUpadminFinancePageQuerySettlementsQuery
} from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { Modal, message } from 'antd'
import { useSearchStore } from '../../hooks/pageSearch'

const tabKey = 'payFailed'
const PayFailedTable: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[pageKey] }
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const date = table.searchParams?.date
  const [isModalShow, updateModalShow] = useState(false)
  const [curRegionId, setCurRegionId] = useState(searchParams?.regionId)
  const [curCompanyId, setCurCompanyId] = useState(searchParams?.companyId)
  const [curMeetingInfo, setCurMeetingInfo] = useState(
    {} as { [key: string]: any }
  )
  const [isModalLoading, setModalLoading] = useState(false)

  const queryVariable = {
    ...omit(table.searchParams, ['date', 'regionId']),
    // 省区为 '' 时，删除省区字段
    ...(!curRegionId ? {} : { region: curRegionId }),

    meetingStartTimestamp:
      (date?.[0] && JSON.stringify(date[0].startOf('day').unix() * 1000)) ||
      undefined,
    meetingEndTimestamp:
      (date?.[1] && JSON.stringify(date[1].endOf('day').unix() * 1000)) ||
      undefined
  }

  const [{ data, error, fetching }, refetch] =
    useUpadminFinancePageQuerySettlementsQuery({
      variables: {
        pager: omit(table.pager, 'total'),
        query: {
          settlementStatus: 2,
          ...queryVariable
        }
      }
    })

  const [, rePayToHzBank] = useUpadminFinancePayToHzBankMutation()

  const onModalOpen = (v: any, meeting: any) => {
    updateModalShow(true)
    console.log(meeting)
    setCurMeetingInfo(meeting)
  }

  const onConfirmClick = async () => {
    setModalLoading(true)
    const result = await rePayToHzBank({
      meetingId: curMeetingInfo?.attenders?.meetingId,
      attenderId: curMeetingInfo?.attenders?.attenderId
    })

    if (!result.data?.result?.success) {
      setModalLoading(false)
      message.error(result.data?.result?.message || '打款失败')
      return
    }
    if (result.data?.result?.success) {
      setTimeout(() => {
        message.success('打款成功')
        setModalLoading(false)
        updateModalShow(false)
        refetch()
      }, 3000)
    }
  }

  const tableColumns = useTableColumns({
    onCancel: refetch,
    type: 'payFailed',
    openConfirmModal: onModalOpen
  })

  // 省区选项改变，手动触发状态更改
  const onRegionChange = (value: string) => {
    setCurRegionId(value)
  }
  // 企业选择改变，省区置空，待重新选择
  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
    setCurRegionId('')
  }

  const onTableReset = () => {
    setCurCompanyId('')
    setCurRegionId('')
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        tabKey={tabKey}
        searchProps={{
          fields: searchFields(
            // production,
            curCompanyId,
            onRegionChange,
            onCompanyChange
          )
        }}
        bordered
        loading={fetching}
        pager={data?.settlements?.pager}
        dataSource={
          data?.settlements?.items?.filter(
            (item) => item?.attenders?.meetingId
          ) || []
        }
        size='middle'
        rowKey='id'
        columns={tableColumns}
        onReset={onTableReset}
      />

      <Modal
        confirmLoading={isModalLoading}
        maskClosable={false}
        destroyOnClose
        title={'重新打款'}
        visible={isModalShow}
        onCancel={() => updateModalShow(false)}
        centered
        width={380}
        onOk={() => onConfirmClick()}
      >
        确认重新打款？
      </Modal>
    </main>
  )
}

export default PayFailedTable
