import React, { FC, useState, useRef } from 'react'
import { Upload, Button, message, Row } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import useAxios from 'axios-hooks'
import { UploadProps } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { UploadRequestOption } from 'rc-upload/lib/interface'

import OSS from 'ali-oss'
import { useUserStore } from '../hooks/user'

export interface UploadButtonProps {
  buttonText?: string
  bucket: string
  dir?: string
  onChange?: (fileList: UploadFile<any>[]) => void
  onUploaded?: (url: string) => void | Promise<void>
  accept?: string
  noMessage?: boolean
}

interface STSServerResponse {
  SecurityToken: string
  AccessKeyId: string
  AccessKeySecret: string
  Expiration: string
}

const useOssClient = (bucket: string) => {
  const { token } = useUserStore()
  const [credentials, setCredentials] = useState<STSServerResponse | null>(null)
  const [, fetchSTS] = useAxios<STSServerResponse>(
    {
      url: process.env.STS_SERVICE,
      headers: {
        token
      }
    },
    { manual: true }
  )

  const ossClient = useRef<OSS | null>(null)

  const createOSSClient = (creds: STSServerResponse) => {
    const client = new OSS({
      region: 'oss-cn-hangzhou',
      bucket,
      accessKeyId: creds.AccessKeyId,
      accessKeySecret: creds.AccessKeySecret,
      stsToken: creds.SecurityToken,
      timeout: 10 * 60 * 1000
    })
    ossClient.current = client
    return client
  }

  return async () => {
    const expired = credentials?.Expiration
      ? new Date().getTime() - new Date(credentials.Expiration).getTime() >= 0
      : true

    if (expired) {
      // const { data } = await fetchSTS()
      const result = await fetchSTS()
      const data = result?.data || result
      setCredentials(data)
      return createOSSClient(data)
    }
    return ossClient.current
  }
}

const UploadButton: FC<UploadButtonProps> = ({
  buttonText,
  dir,
  onChange,
  onUploaded,
  bucket,
  accept,
  noMessage
}) => {
  const [running, setRunning] = useState<boolean>(false)
  const getOssClient = useOssClient(bucket)

  const onFileChange: UploadProps['onChange'] = ({ fileList }) => {
    console.log('Aliyun OSS:', fileList)
    if (onChange) {
      onChange([...fileList])
    }
  }

  // TODO: options里的file属性问题：类型定义里去除了File类型，但Upload使用文档里file是File类型，实际打印出来也是File类型
  // https://github.com/ant-design/ant-design/issues/28744
  // https://github.com/react-component/upload#customrequest
  const onRequest = async (
    options:
      | UploadRequestOption
      | {
          file: any // 为了解决上面 TODO 里提到的问题
          onSuccess: any // 为了解决上面 TODO 里提到的问题
        }
  ) => {
    setRunning(true)
    const { file, onSuccess } = options
    console.log(options)
    const client = await getOssClient()
    const suffix = file.name.slice(file.name.lastIndexOf('.'))
    try {
      const res = await client?.put(`${dir}/${Date.now()}${suffix}`, file)
      onSuccess(res || {}, file)
      if (res?.url) {
        await onUploaded?.(res.url)
        !noMessage && message.success('上传成功')
      }
    } catch (e) {
      // onError(e)
      message.error('上传失败')
    } finally {
      setRunning(false)
    }
    return {
      abort() {
        message.info('image uploader aborted')
      }
    }
  }

  return (
    <Upload
      accept={accept}
      onChange={onFileChange}
      customRequest={onRequest}
      showUploadList={false}
    >
      <Button loading={!!running}>
        {!running || running === null ? (
          <Row wrap={false} align='middle'>
            <UploadOutlined style={{ color: '#1890ff' }} />
            <span style={{ paddingLeft: 8, color: '#1890ff' }}>
              {buttonText || '上传文件'}
            </span>
          </Row>
        ) : null}
      </Button>
    </Upload>
  )
}

export default UploadButton
