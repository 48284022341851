import React, { useState, FC, useEffect } from 'react'
import { notification, Modal, Form, Select, Input, Button } from 'antd'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { HRCompanySelector } from '../../components/SelectorCollection'
import { useSphereTypeColumns } from './data'
import {
  useUpadminCompaniesQuery,
  useCreateCompanySphereMutation,
  useQueryDocumentSphereQuery,
  QueryDocumentSphereQuery
} from '../../graphql'
import { useSearchStore } from '../../hooks/pageSearch'

const getDataSource = (data?: QueryDocumentSphereQuery['spheres']) => {
  if (!data?.length) {
    return []
  }
  return data
}

const tabKey = 'companySphereType'
const CompanySphereType: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[pageKey] }
  const [form] = Form.useForm()
  const table = useSearchTable<any>({}, { pageSize: 15, currentPage: 1 })
  const [curCompanyId, setCurCompanyId] = useState(searchParams?.companyId)
  const [modalShow, updateModalShow] = useState(false)
  const [companyIdOfAdd, setCompanyIdOfAdd] = useState<string>('')

  const [{ data: selectOptions }] = useUpadminCompaniesQuery()
  const [{ fetching: bindLoading }, createSphere] =
    useCreateCompanySphereMutation()

  const [{ data, error, fetching }, refetch] = useQueryDocumentSphereQuery({
    variables: {
      params: {
        companyIds: [curCompanyId || selectOptions?.companies[0]?.id]
      }
    },
    pause: !selectOptions?.companies?.length
  })

  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
  }

  const onConfirm = async () => {
    try {
      const values = await form.validateFields()
      const { sphereName } = values

      const result = await createSphere({
        sphere: {
          companyId: companyIdOfAdd,
          sphereName
        }
      })
      if (!result.data?.result?.success) {
        notification.error({
          message: '企业新增领域失败',
          description: result.data?.result?.message || '失败'
        })
      } else {
        notification.success({
          message: '企业新增领域成功',
          description: '新增成功'
        })
        updateModalShow(false)
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  // 自动查询第一个企业的数据
  useEffect(() => {
    if (!curCompanyId && selectOptions?.companies[0]?.id) {
      table.updateSearchParams(
        {
          companyId: selectOptions?.companies[0]?.id
        },
        undefined,
        pageKey
      )
      setCurCompanyId(selectOptions?.companies[0]?.id)
    }
  }, [JSON.stringify(selectOptions?.companies)])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        showOperaBtn={false}
        showPagination={false}
        table={table}
        tabKey={tabKey}
        bordered
        loading={fetching}
        dataSource={getDataSource(data?.spheres)}
        size='middle'
        rowKey='id'
        columns={useSphereTypeColumns()}
        searchProps={{
          fields: [
            {
              name: 'companyId',
              label: '企业',
              span: 8,
              defaultValue: selectOptions?.companies[0]?.id!,
              render() {
                return (
                  <Select
                    showSearch
                    placeholder='请选择企业'
                    optionFilterProp='children'
                    key={selectOptions?.companies[0]?.id || ''}
                    onChange={onCompanyChange}
                    defaultValue={selectOptions?.companies[0]?.id || ''}
                  >
                    {selectOptions?.companies.map((company, i) => (
                      <Select.Option
                        value={company?.id || ''}
                        key={company?.id || i}
                      >
                        {company.cnName || ''}
                      </Select.Option>
                    ))}
                  </Select>
                )
              }
            }
          ]
        }}
        extraPanelContent={
          <Button
            type='primary'
            style={{ position: 'absolute', right: '5%', bottom: '15px' }}
            onClick={() => updateModalShow(true)}
          >
            企业新增领域
          </Button>
        }
      />

      <Modal
        maskClosable={false}
        title='企业新增领域'
        visible={modalShow}
        onOk={onConfirm}
        onCancel={() => updateModalShow(false)}
        okText='确认'
        cancelText='取消'
        confirmLoading={bindLoading}
        afterClose={() => {
          setCompanyIdOfAdd('')
          form.resetFields()
        }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} form={form}>
          <Form.Item
            label='企业'
            name='companyId'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <HRCompanySelector
              onChange={(value) => {
                // 公司切换时，清空一下会议类型、审批流
                const { companyId } = form.getFieldsValue()

                form.setFieldsValue({
                  companyId
                })
                setCompanyIdOfAdd(value)
              }}
            />
          </Form.Item>

          <Form.Item
            label='领域名称'
            name='sphereName'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </main>
  )
}

export default CompanySphereType
