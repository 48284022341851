import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { FC } from 'react'
import { DoctorTitleSelector } from '../../components/SelectorCollection'
import FormItem from 'antd/lib/form/FormItem'

export const EditTitleBlkListModal: FC<
  ModalProps & {
    companyId?: string
    onUpdate: (titles: string[]) => void
    titleList?: string[]
  }
> = (props) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({ titles: props.titleList })
  }, [JSON.stringify(props.titleList)])
  return (
    <Modal
      {...props}
      title='选择需要排除的职称'
      onOk={() => {
        const titles = form.getFieldValue('titles')
        console.log(titles)
        props.onUpdate(titles)
      }}
    >
      <Form form={form}>
        <FormItem name='titles'>
          <DoctorTitleSelector companyId={props.companyId} />
        </FormItem>
      </Form>
    </Modal>
  )
}
