import React, { useState, useEffect } from 'react'
import { Modal, Spin, message } from 'antd'
import omit from 'lodash.omit'

import { searchFields, useTableColumns, FieldTypes, excelHead } from './data'
import { useHrMeetingListQuery, useHrMeetingExportQuery } from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { generateExport } from '../../components/UploadBatch'
import { getData } from '../../lib/getExportList'

const MeetingList: QCPage = () => {
  const [isExporting, setExporting] = useState(false)

  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })

  const queryVariable = {
    ...omit(table.searchParams, 'date'),
    startTime:
      (table.searchParams?.date?.[0]?.startOf('day').unix() || 0) * 1000 ||
      undefined, // 0 will not be ignore by server
    endTime:
      (table.searchParams?.date?.[1]?.endOf('day').unix() || 0) * 1000 ||
      undefined // 0 will not be ignore by server
  }

  const [{ data, error, fetching }, refetchMeetingList] = useHrMeetingListQuery(
    {
      variables: {
        pageQuery: {
          pager: omit(table.pager, 'total'),
          query: {
            meetingType: 'MEDICAL_RESEARCH_PROJECT',
            ...queryVariable
          }
        }
      }
    }
  )

  // 复用请求列表的接口，用于导出数据
  const [{ data: exportResult, error: exportErr }] = useHrMeetingExportQuery({
    variables: {
      pageQuery: {
        pager: {
          currentPage: 1,
          pageSize: 500
        },
        query: {
          meetingType: 'MEDICAL_RESEARCH_PROJECT',
          ...queryVariable
        }
      }
    },
    pause: !isExporting
  })

  const tableColumns = useTableColumns({
    onCancel: refetchMeetingList
  })

  const onExportHandle = () => {
    setExporting(true)

    if (exportErr) {
      setExporting(false)
      message.error('导出失败')
      return
    }
  }

  const concatAttenders = (source: any[]) => {
    source.forEach((element) => {
      element.speakers = element.speakers.concat(element.attenders)
    })

    return source
  }

  useEffect(() => {
    if (isExporting) {
      const dataResult = getData(
        concatAttenders(exportResult?.meetings?.items || []),
        excelHead
      )

      generateExport(
        dataResult,
        `医学项目_${new Date().getTime()}`,
        5000,
        (err) => {
          setExporting(false)
          if (err) {
            message.error('导出失败')
          } else {
            message.success('EXCEL下载成功，请查收')
          }
        }
      )
    }
  }, [exportResult])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchMeetingList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        searchProps={{ fields: searchFields }}
        bordered
        loading={fetching}
        pager={data?.meetings?.pager}
        dataSource={data?.meetings?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
        showExportBtn
        onExport={onExportHandle}
      />
      <Modal
        visible={!!isExporting}
        footer={null}
        maskClosable={false}
        closable={false}
        width={400}
      >
        <Spin tip='正在为您生成EXCEL文件，请不要关闭此网页…' />
      </Modal>
    </main>
  )
}

MeetingList.pageTitle = '项目列表'

export default MeetingList
