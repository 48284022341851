import React, { FC, useState } from 'react'
import { Button, Modal } from 'antd'
import { isVideoElement } from '../constant/user'
import { PlayCircleOutlined } from '@ant-design/icons'

const iconStyle = {
  fontSize: 80
}

export type VideoButtonProps = {
  title?: string
  url: string
  style?: React.CSSProperties
}
const VideoButton: FC<VideoButtonProps> = ({ title = '', url = '', style }) => {
  const [visible, setVisible] = useState(false)

  const handleOnClick = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    const video = document.getElementById('videoBtn')
    if (isVideoElement(video)) {
      video && video.pause()
    }
    setVisible(false)
  }

  return (
    <>
      <Button
        onClick={handleOnClick}
        style={style}
        icon={<PlayCircleOutlined style={iconStyle} />}
      ></Button>

      <Modal
        visible={visible}
        title={
          title || decodeURIComponent(url.substring(url.lastIndexOf('/') + 1))
        }
        footer={null}
        onCancel={handleCancel}
      >
        <video id='videoBtn' width='460' controls>
          <source src={url} />
          Your browser does not support HTML5 video.
        </video>
      </Modal>
    </>
  )
}

export default VideoButton
