import React, { useState, FC, useEffect } from 'react'
import { notification, Modal, Form, Select, Input, Button, Space } from 'antd'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import {
  CompanyUserSelector,
  HRCompanySelector,
  MeetingTypeSelector,
  RegionSelector
} from '../../components/SelectorCollection'
import { useRegionPermissionColumns } from './data'
import {
  useUpadminCompaniesQuery,
  useSearchMeetingUserAuthorityQuery,
  useAddMeetingUserAuthorityMutation,
  useEditMeetingUserAuthorityMutation,
  useRemoveMeetingUserAuthorityMutation,
  MeetingUserAuthorityDetail,
  useQueryCompanyRegionsQuery,
  useMeetingTypesByCompanyQuery
} from '../../graphql'
import { useSearchStore } from '../../hooks/pageSearch'
import omit from 'lodash.omit'
import { FormInstance } from 'antd/lib/form'

const tabKey = 'regionPermissionManageOfFinance'
const RegionPermissionManageOfFinance: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams, getPageParams } = useSearchStore()
  const searchParams = { ...pageParams[pageKey] }
  const [form] = Form.useForm()
  const tableForm = React.useRef<any>(null)
  const table = useSearchTable<any>({}, { pageSize: 15, currentPage: 1 })
  const [curCompanyId, setCurCompanyId] = useState(searchParams?.companyId)
  const [modalShow, updateModalShow] = useState(false)
  const [companyIdOfAdd, setCompanyIdOfAdd] = useState<string>('')
  const [curAuthInfo, setCurAuthInfo] =
    useState<MeetingUserAuthorityDetail | null>()

  const [{ data: selectOptions }] = useUpadminCompaniesQuery()

  const [{ data, error, fetching }, refetch] =
    useSearchMeetingUserAuthorityQuery({
      variables: {
        pageQuery: {
          pager: omit(table.pager, 'total'),
          query: {
            companyId: curCompanyId || selectOptions?.companies[0]?.id,
            keyword: table.searchParams?.keyword
          }
        }
      },
      pause: !selectOptions?.companies?.length
    })

  const [{ data: regionData, fetching: regionDataLoading }] =
    useQueryCompanyRegionsQuery({
      variables: {
        companyId: curCompanyId,
        type: 'region'
      },
      pause: !curCompanyId
    })
  const [{ data: meetingTypeData, fetching: meetingTypeDataLoading }] =
    useMeetingTypesByCompanyQuery({
      variables: { companyId: curCompanyId },
      pause: !curCompanyId
    })
  const [{ fetching: addLoading }, addAuth] =
    useAddMeetingUserAuthorityMutation()
  const [{ fetching: editLoading }, editAuth] =
    useEditMeetingUserAuthorityMutation()
  const [{ fetching: removeLoading }, removeAuth] =
    useRemoveMeetingUserAuthorityMutation()

  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
  }

  const onConfirm = async () => {
    try {
      const values = await form.validateFields()
      const { user, meetingType, region } = values

      const result = curAuthInfo?.id
        ? await editAuth({
            auth: {
              id: curAuthInfo?.id,
              activityCode: meetingType,
              regionId: region
            }
          })
        : await addAuth({
            auth: {
              companyId: companyIdOfAdd,
              userId: user,
              activityCode: meetingType,
              regionId: region
            }
          })
      if (!result.data?.result?.success) {
        notification.error({
          message: `${curAuthInfo?.id ? '编辑' : '新增'}失败`,
          description: result.data?.result?.message || '失败'
        })
      } else {
        notification.success({
          message: `${curAuthInfo?.id ? '编辑' : '新增'}成功`,
          description: '用户区域权限更新成功'
        })
        updateModalShow(false)
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  // 自动查询第一个企业的数据
  useEffect(() => {
    if (!curCompanyId && selectOptions?.companies[0]?.id) {
      table.updateSearchParams(
        {
          companyId: selectOptions?.companies[0]?.id
        },
        undefined,
        pageKey
      )
      setCurCompanyId(selectOptions?.companies[0]?.id)
    }
  }, [JSON.stringify(selectOptions?.companies)])

  const handleSearch = () => {
    const searchParams = getPageParams(pageKey)
    table.updateSearchParams(searchParams, undefined, pageKey)
  }

  const handleOnReset = () => {
    const searchParams = getPageParams(pageKey)
    table.updateSearchParams(
      { ...searchParams, keyword: '' },
      undefined,
      pageKey
    )
    setTimeout(() => {
      tableForm.current && tableForm.current.resetFields()
    }, 0)
  }

  const handleAdd = () => {
    setCurAuthInfo(null)
    updateModalShow(true)
    form.resetFields()
  }

  const handleDelete = async (id: string) => {
    try {
      const result = await removeAuth({
        id
      })
      if (!result.data?.result?.success) {
        notification.error({
          message: '删除失败',
          description: result.data?.result?.message || '失败'
        })
      } else {
        notification.success({
          message: '删除成功',
          description: '用户区域权限删除成功'
        })
        updateModalShow(false)
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleEdit = (item: any) => {
    setCurAuthInfo(item)
    setCompanyIdOfAdd(item.companyId)
    updateModalShow(true)
    form.setFieldsValue({
      user: [`${item.name}-${item.phone}`],
      meetingType: item.activityCode,
      region: item.regionId
    })
  }

  const setTableFormRef = (form: FormInstance<any>) => {
    if (!tableForm.current) {
      tableForm.current = form
    }
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        showOperaBtn={false}
        showPagination={false}
        table={table}
        tabKey={tabKey}
        bordered
        loading={
          fetching ||
          regionDataLoading ||
          meetingTypeDataLoading ||
          removeLoading
        }
        dataSource={data?.result?.items || []}
        size='middle'
        rowKey='id'
        columns={useRegionPermissionColumns({
          handleDelete,
          handleEdit,
          regionData: regionData?.regions!,
          meetingTypeData: meetingTypeData?.result!
        })}
        setTableFormRef={setTableFormRef}
        searchProps={{
          fields: [
            {
              name: 'companyId',
              label: '企业',
              span: 8,
              defaultValue: selectOptions?.companies[0]?.id!,
              render() {
                return (
                  <Select
                    showSearch
                    placeholder='请选择企业'
                    optionFilterProp='children'
                    key={selectOptions?.companies[0]?.id || ''}
                    onChange={onCompanyChange}
                    defaultValue={selectOptions?.companies[0]?.id || ''}
                  >
                    {selectOptions?.companies.map((company, i) => (
                      <Select.Option
                        value={company?.id || ''}
                        key={company?.id || i}
                      >
                        {company.cnName || ''}
                      </Select.Option>
                    ))}
                  </Select>
                )
              }
            },
            {
              name: 'keyword',
              label: null,
              span: 8,
              render() {
                return (
                  <Input allowClear placeholder='输入用户姓名或电话进行搜索' />
                )
              }
            }
          ]
        }}
        extraPanelContent={
          <Space style={{ position: 'absolute', right: '5%', bottom: '15px' }}>
            <Button type='primary' onClick={handleSearch}>
              搜索
            </Button>
            <Button onClick={handleOnReset}>重置</Button>
            <Button type='primary' onClick={handleAdd}>
              新增用户权限
            </Button>
          </Space>
        }
      />

      <Modal
        maskClosable={false}
        destroyOnClose
        title={`${curAuthInfo?.id ? '编辑' : '新增'}用户权限`}
        visible={modalShow}
        onOk={onConfirm}
        onCancel={() => updateModalShow(false)}
        okText='确认'
        cancelText='取消'
        confirmLoading={addLoading || editLoading}
        afterClose={() => {
          setCompanyIdOfAdd('')
          setCurAuthInfo(null)
          form.resetFields()
        }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={form}>
          {!curAuthInfo?.id && (
            <Form.Item
              label='企业'
              name='companyId'
              rules={[{ required: true, message: '请选择企业' }]}
            >
              <HRCompanySelector
                onChange={(value) => {
                  // 公司切换时，清空一下会议类型、审批流
                  const { companyId } = form.getFieldsValue()

                  form.setFieldsValue({
                    companyId,
                    user: undefined,
                    meetingType: undefined,
                    region: undefined
                  })
                  setCompanyIdOfAdd(value)
                }}
                optionData={selectOptions?.companies || []}
              />
            </Form.Item>
          )}
          <Form.Item
            label='用户'
            name='user'
            rules={[{ required: true, message: '请选择用户' }]}
          >
            <CompanyUserSelector
              mode='multiple'
              companyId={companyIdOfAdd}
              disabled={!companyIdOfAdd || !!curAuthInfo?.id}
              auto={true}
            />
          </Form.Item>
          <Form.Item
            label='会议类型'
            name='meetingType'
            rules={[{ required: true, message: '请选择会议类型' }]}
          >
            <MeetingTypeSelector
              mode='multiple'
              filterByCompanyId
              companyId={companyIdOfAdd}
              disabled={!companyIdOfAdd}
              auto={true}
              optionData={!curAuthInfo?.id ? [] : meetingTypeData?.result!}
            />
          </Form.Item>
          <Form.Item
            label='区域'
            name='region'
            rules={[{ required: true, message: '请选择区域' }]}
          >
            <RegionSelector
              companyId={companyIdOfAdd}
              disabled={!companyIdOfAdd}
              optionData={!curAuthInfo?.id ? [] : regionData?.regions!}
            />
          </Form.Item>
        </Form>
      </Modal>
    </main>
  )
}

export default RegionPermissionManageOfFinance
