import React, { useState, useEffect, FC } from 'react'
import omit from 'lodash.omit'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import {
  OutPlanBudgetDocument,
  OutPlanBudgetQuery,
  OutPlanBudgetQueryVariables,
  PersonalPlanBudgetType,
  useOutPlanBudgetQuery
} from '../../graphql'
import {
  PlanBudgetFieldTypes,
  usePlanBudgetTableColumns,
  planBudgetExcelHead,
  getPlanBudgetData
} from './data'
import ErrorView from '../../components/ErrorView'
import { useClient } from 'urql'
import ExportQueueModal from '../../components/ExportQueueModal'
import { Input } from 'antd'
import { PersonalPlanBudgetBusinessSelector } from '../../components/SelectorCollection'

const tabKey = 'outPlanBudget'
const BudgetList: FC = () => {
  const table = useSearchTable<PlanBudgetFieldTypes>(
    {},
    { pageSize: 15, currentPage: 1 }
  )
  const urqlClient = useClient()
  const [isExporting, setExporting] = useState(false)

  const queryVariable = {
    ...omit(table.searchParams, ['businessId']),
    ...(table.searchParams?.businessId
      ? { businessIds: [table.searchParams.businessId] }
      : {})
  }

  const [{ data, error, fetching }, refetchList] = useOutPlanBudgetQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        ...queryVariable
      }
    }
  })

  const tableColumns = usePlanBudgetTableColumns({ type: 'outPlan' })

  const queryExportData = async (currentPage: number, pageSize: number) => {
    const { data, error } = await urqlClient
      .query<OutPlanBudgetQuery, OutPlanBudgetQueryVariables>(
        OutPlanBudgetDocument,
        {
          pager: {
            currentPage,
            pageSize
          },
          query: {
            ...queryVariable
          }
        }
      )
      .toPromise()
    return { currentPage, exportData: data?.result?.items, error }
  }

  const onExportHandle = async () => {
    setExporting(true)
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        tabKey={tabKey}
        searchProps={{
          fields: [
            {
              name: 'businessId',
              label: '业务部',
              span: 10,
              render() {
                return (
                  <PersonalPlanBudgetBusinessSelector
                    planBudgetType={PersonalPlanBudgetType.ExtraPersonal}
                  />
                )
              }
            },
            {
              name: 'keyword',
              label: '搜索',
              span: 14,
              render() {
                return (
                  <Input allowClear placeholder='请输入姓名、报销编号搜索' />
                )
              }
            }
          ]
        }}
        bordered
        loading={fetching}
        pager={data?.result?.pager}
        dataSource={data?.result?.items || []}
        rowKey={'id'}
        columns={tableColumns}
        showExportBtn
        onExport={onExportHandle}
      />
      <ExportQueueModal
        queryExportDataFc={queryExportData}
        formatDataFc={getPlanBudgetData}
        excelHead={planBudgetExcelHead()}
        fileName={`专项预算查询_${new Date().getTime()}`}
        isExport={isExporting}
        total={data?.result?.pager?.total || 0}
        concurrency={2}
        callback={() => {
          setExporting(false)
        }}
      />
    </main>
  )
}

export default BudgetList
