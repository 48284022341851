import React, { FC } from 'react'
import {
  Form,
  Input,
  Card,
  InputNumber,
  Button,
  Select,
  message,
  Skeleton
} from 'antd'
import DatePicker from '../../components/CustomDatePicker'
import HotelSearch from '../../components/HotelSearch'
import {
  useCreatMeetingMutation,
  UpMeetingPeriod,
  useMeetingDetailQuery
} from '../../graphql'
import { Store } from 'antd/lib/form/interface'
import dayjs, { Dayjs } from 'dayjs'
import { useHistory, useParams } from 'react-router-dom'
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}
const MeetingCreate: QCPage = () => {
  const { meetingId } = useParams()
  const [form] = Form.useForm()
  const history = useHistory()

  const isEditing = !!meetingId

  const [{ data, fetching }] = useMeetingDetailQuery({
    variables: {
      meetingId
    },
    pause: !isEditing
  })

  const [, createMeeting] = useCreatMeetingMutation()

  const submit = async (values: Store) => {
    const [startTime, endTime] = values.date
    const { data, error } = await createMeeting({
      meetingInfo: {
        ...(isEditing ? { id: meetingId } : null),
        period: UpMeetingPeriod.Allday,
        travels: [],
        city: values.hotel?.city,
        hotel: values.hotel,
        address: values.hotel?.address,
        startTime: (startTime as Dayjs).startOf('day').unix() * 1000,
        endTime: (endTime as Dayjs).endOf('day').unix() * 1000,
        name: values.name,
        ownerName: values.owner,
        type: values.type,
        code: values.code,
        insideAttenderNum: values.insideAttenderNum,
        outsideAttenderNum: values.outsideAttenderNum,
        department: values.department
      }
    })
    if (error) {
      message.error(error.graphQLErrors[0].message)
    }
    if (data?.bmsMeetingCreate) {
      message.success(isEditing ? '编辑成功' : '创建成功', 1, () => {
        history.replace(`/meeting/${data.bmsMeetingCreate.id}`)
      })
    }
  }

  if (fetching) {
    return <Skeleton active />
  }

  return (
    <Card>
      <Form
        form={form}
        scrollToFirstError
        {...formItemLayout}
        onFinish={submit}
        onValuesChange={console.log}
        initialValues={
          isEditing
            ? {
                name: data?.meetingDetail?.name,
                hotel: data?.meetingDetail?.bmsMeetingHotel,
                date: [
                  dayjs(data?.meetingDetail?.startTime),
                  dayjs(data?.meetingDetail?.endTime)
                ],
                outsideAttenderNum: data?.meetingDetail?.outsideAttenderNum,
                insideAttenderNum: data?.meetingDetail?.insideAttenderNum,
                department: data?.meetingDetail?.department,
                owner: data?.meetingDetail?.ownerName,
                code: data?.meetingDetail?.code,
                type: data?.meetingDetail?.type
              }
            : {}
        }
      >
        <Form.Item
          label='会议名称'
          name='name'
          rules={[
            {
              max: 25,
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='会议日期'
          name='date'
          rules={[
            {
              required: true
            }
          ]}
        >
          <DatePicker.RangePicker />
        </Form.Item>

        <Form.Item
          label='会议酒店'
          name='hotel'
          rules={[
            {
              required: true
            }
          ]}
        >
          <HotelSearch />
        </Form.Item>
        <Form.Item
          label='预计外部参会人数'
          name='outsideAttenderNum'
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label='预计内部参会人数'
          name='insideAttenderNum'
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label='会议类型'
          name='type'
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select>
            {['TTT', 'NSSE', 'SSE', 'CP', 'AB'].map((type) => {
              return (
                <Select.Option value={type} key={type}>
                  {type}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label='会议代码'
          name='code'
          rules={[
            {
              max: 25,
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='会议负责人'
          name='owner'
          rules={[
            {
              max: 10,
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='会议部门'
          name='department'
          rules={[
            {
              max: 25,
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button htmlType='submit' size='large' type='primary'>
            {isEditing ? '编辑会议' : '创建会议'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

MeetingCreate.pageTitle = '创建会议'

export default MeetingCreate
