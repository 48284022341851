import React, { FC } from 'react'
import { useUserStore } from '../hooks/user'
import { Button } from 'antd'

const LogoutButton: FC = () => {
  const userStore = useUserStore()

  return <Button onClick={userStore.logout}>登出</Button>
}

export default LogoutButton
