import React, { useState, useEffect } from 'react'
import { Form, Button, Input, Spin, message } from 'antd'
import { useCountDown } from '../../hooks/countDown'
import { LoadingOutlined } from '@ant-design/icons'
import './index.less'
import {
  useGetSessionIdMutation,
  
  useCurrentUserQuery,
  usePwdLoginMutation,
  useUserLoginWithCodeMutation
} from '../../graphql'
import { useUserStore } from '../../hooks/user'
import { getMessage } from '../../lib/getMessage'


const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />

export const Login: QCPage = () => {
  const [sessionId, setSessionId] = useState('')
  const [hasUserQueried, setUserQueried] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState(0)
  const [resetModalVisible, setResetModalVisible] = useState(false)
  // 初始化一个表单对象
  const [form] = Form.useForm()
  // 初始化一个60s的倒计时器
  const countDown = useCountDown(60)
  const userStore = useUserStore()

  // 验证码接口
  const [
    { fetching: sendCodeFetching },
    getSessionId
  ] = useGetSessionIdMutation()
  // 登录接口
  const [{ fetching: loginFetching }, login] = useUserLoginWithCodeMutation()
  const [
    { fetching: loginWithPwdFetching },
    loginWithPwd
  ] = usePwdLoginMutation()
  // 查询用户信息接口
  const [
    { data: userInfo, error: userError, fetching: userFetching },
    fetchUserInfoQuery
  ] = useCurrentUserQuery({ pause: true })

  // 点击获取验证码
  const sendCodeHandler = async () => {
    try {
      // 校验表单元素合法性
      const values = await form.validateFields(['phone'])
      const result = await getSessionId({ mobile: values['phone'] })

      if (!result?.data?.sessionId) {
        message.destroy()
        const message_ = getMessage(result?.error?.message)
        message.error(message_ || '获取验证码失败')
      } else {
        // 发送短信成功之后，开始倒计时
        setSessionId(result.data.sessionId)
        countDown.startCount()
      }
    } catch (err) {
      console.log(err)
    }
  }

  // 点击登录
  const loginHandler = async () => {
    try {
      // 校验表单元素合法性
      const values = await form.validateFields()

      const result =
        activeTabKey === 1
          ? await loginWithPwd({
              mobile: values['phone'],
              password: values['password']
            })
          : await login({
              sessionId,
              code: values['code'],
              mobile: values['phone']
            })
      if (!result?.data?.result) {
        const message_ = getMessage(result?.error?.message)
        message.error(message_ || '登录失败')
      } else {
        // 写入 token
        userStore.setToken(result.data.result)
        // 请求用户信息
        await fetchUserInfoQuery()
        setUserQueried(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onTabChange = (e: any) => {
    if (e.target.innerText === '验证码登录') {
      setActiveTabKey(0)
    }
    if (e.target.innerText === '密码登录') {
      setActiveTabKey(1)
    }
  }

  // 更新用户信息
  useEffect(() => {
    if (hasUserQueried) {
      if (userInfo?.user) {
        userStore?.login(
          localStorage.getItem('bms-login-token') || '',
          userInfo?.user
        )
      } else if (userError?.message) {
        message.error(userError?.message)
        // 如果获取用户信息失败，清除上面写入的token
        userStore.logout()
      }
    }
  }, [userInfo?.user, hasUserQueried])

  return (
    <div className='container'>
      {/* 蓝色框区域 */}
      <div className='content'>
        {/* 左侧图片 */}
        <div className='leftBody' />
        {/* 右侧登录区域 */}
        <div className='rightBody'>
          <div className='header'>欢迎登录学术交流平台管理系统</div>
          <div className='loginTab' onClick={onTabChange}>
            <div
              className={`${activeTabKey === 0 ? 'active' : ''} loginTabPane`}
            >
              验证码登录
            </div>
            <div
              className={`${activeTabKey === 1 ? 'active' : ''} loginTabPane`}
            >
              密码登录
            </div>
          </div>
          <div className='center'>
            {activeTabKey === 1 && (
              <Form form={form} className='loginForm'>
                <Form.Item
                  colon={false}
                  name='phone'
                  rules={[
                    { required: true, message: '请输入手机号' },
                    { pattern: /^1[0-9]{10}$/, message: '不合法的手机号' }
                  ]}
                  label={<div className='label'>手机号</div>}
                >
                  <Input className='formInput' bordered={false} />
                </Form.Item>
                <Form.Item
                  colon={false}
                  label={<div className='label'>密码</div>}
                  name='password'
                  rules={[{ required: true, message: '请输入密码' }]}
                >
                  <Input.Password className='formInput' bordered={false} />
                </Form.Item>
              </Form>
            )}
            {activeTabKey === 0 && (
              <Form form={form} className='loginForm'>
                <Form.Item
                  colon={false}
                  name='phone'
                  rules={[
                    { required: true, message: '请输入手机号' },
                    { pattern: /^1[0-9]{10}$/, message: '不合法的手机号' }
                  ]}
                  label={<div className='label'>手机号</div>}
                >
                  <Input className='formInput' bordered={false} />
                </Form.Item>
                <Form.Item
                  colon={false}
                  label={<div className='label'>验证码</div>}
                  name='code'
                  rules={[{ required: true, message: '请输入验证码' }]}
                >
                  <Input className='formInput' bordered={false} />
                </Form.Item>

                {/* 获取验证码 */}
                <Button
                  loading={sendCodeFetching}
                  type='link'
                  className='getCodeBtn'
                  disabled={countDown.status !== 0}
                >
                  <span onClick={sendCodeHandler}>
                    {countDown.status === 1
                      ? '发送中'
                      : countDown.status === 0
                      ? '获取验证码'
                      : `获取验证码(${countDown.count}s)`}
                  </span>
                  {countDown.status === 1 && <Spin indicator={antIcon} />}
                </Button>
              </Form>
            )}
            <Button
              loading={loginFetching || userFetching || loginWithPwdFetching}
              className='loginBtn'
              onClick={loginHandler}
            >
              登录
            </Button>
          </div>

          <div className='footer'>联系客服：400-800-0202</div>
        </div>
      </div>
    </div>
  )
}

Login.pageTitle = '登录'
