import React, { useState, useEffect } from 'react'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import { HRRegionSelector } from '../../components/SelectorCollection'
import { Button, Select } from 'antd'
import { tableColumns } from './data'
import { useBalanceListQuery, useUpadminCompaniesQuery } from '../../graphql'
import ErrorView from '../../components/ErrorView'
import { useUserStore } from '../../hooks/user'
import RegisterModal from './modal'
import { useSearchStore } from '../../hooks/pageSearch'

type FieldTypes = {
  companyId?: string
  regionIds?: string[] | null
}

const BalanceManageOfFinance: QCPage = () => {
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[window.location.pathname] }
  const userStore = useUserStore()
  const [visible, setVisible] = useState(false)
  const [curRegionId, setCurRegionId] = useState(searchParams?.regionIds)
  const [curCompanyId, setCurCompanyId] = useState(searchParams?.companyId)

  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })

  const [{ data: selectOptions }] = useUpadminCompaniesQuery()

  //搜索字段声明
  const searchFields = (
    companyId: string,
    onRegionChange: any,
    onCompanyChange: any
  ) => [
    {
      name: 'companyId',
      label: '企业',
      span: 6,
      defaultValue: selectOptions?.companies[0]?.id!,
      render() {
        return (
          <Select
            showSearch
            placeholder='请选择企业'
            optionFilterProp='children'
            key={selectOptions?.companies[0]?.id || ''}
            defaultValue={selectOptions?.companies[0]?.id || ''}
            onChange={onCompanyChange}
            filterOption={(input: any, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {selectOptions?.companies.map((company, i) => (
              <Select.Option value={company?.id || ''} key={company?.id || i}>
                {company.cnName || ''}
              </Select.Option>
            ))}
          </Select>
        )
      }
    },
    {
      name: 'regionIds',
      label: '省区',
      span: 6,
      render() {
        return (
          <HRRegionSelector
            disabled={!companyId}
            auto={true}
            companyId={companyId}
            onChange={onRegionChange}
          />
        )
      }
    }
  ]

  // 自动查询第一个企业的数据
  useEffect(() => {
    if (!curCompanyId && selectOptions?.companies[0]?.id) {
      table.updateSearchParams({
        companyId: selectOptions?.companies[0]?.id
      })
      setCurCompanyId(selectOptions?.companies[0]?.id)
    }
  }, [JSON.stringify(selectOptions?.companies)])

  // 余额列表数据
  const [{ data, error, fetching }, refetch] = useBalanceListQuery({
    variables: {
      // ...omit(table.searchParams, 'regionIds'),
      ...(!curRegionId ? {} : { regionIds: [curRegionId] }),
      companyId: curCompanyId || selectOptions?.companies[0]?.id
    },
    pause: !selectOptions?.companies?.length
  })

  // 省区选项改变，手动触发状态更改
  const onRegionChange = (value: string) => {
    setCurRegionId(value)
  }
  // 企业选项改变，省区置空，待重新选择
  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
    setCurRegionId('')
  }

  // 登记成功回调
  const registerBalanceSucCb = () => {
    setVisible(false)
    refetch()
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        searchProps={
          userStore.hasAuth('uplatform_balance_pool_view_all')
            ? {
                fields: searchFields(
                  curCompanyId,
                  onRegionChange,
                  onCompanyChange
                )
              }
            : undefined
        }
        bordered
        loading={fetching}
        showPagination={false}
        dataSource={data?.lists || []}
        size='middle'
        rowKey='regionId'
        columns={tableColumns}
        showOperaBtn={false}
        extraPanelContent={
          <>
            {userStore.hasAuth('uplatform_balance_pool_addnew') ? (
              <Button
                type='primary'
                style={{ position: 'absolute', right: '5%', bottom: '15px' }}
                onClick={() => setVisible(true)}
              >
                登记预付金额
              </Button>
            ) : null}
          </>
        }
      />
      {visible ? (
        <RegisterModal
          callback={registerBalanceSucCb}
          onCancel={() => setVisible(false)}
        />
      ) : null}
    </main>
  )
}

BalanceManageOfFinance.pageTitle = '余额管理'

export default BalanceManageOfFinance
