import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import { Query } from 'urql'

// table 列
export const tableColumns: ColumnsType = [
  {
    title: '省区',
    dataIndex: 'regionInfo',
    align: 'left',
    key: 'regionName',
    render(v, item: any) {
      return (
        <Link
          to={{
            pathname: `/balanceDetail/${item.regionId}`,
            state: { item }
          }}
        >
          {v?.name}
        </Link>
      )
    }
  },
  {
    title: '总预付金额',
    dataIndex: 'totalPrepaid',
    align: 'left',
    key: 'totalPrepaid',
    render(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString()
    }
  },
  {
    title: '已使用金额',
    dataIndex: 'usedFee',
    align: 'left',
    key: 'usedFee',
    render(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString()
    }
  },
  {
    title: '冻结金额',
    dataIndex: 'frozenFee',
    align: 'left',
    key: 'frozenFee',
    render(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString()
    }
  },
  {
    title: '余额',
    dataIndex: 'balance',
    align: 'left',
    key: 'balance',
    render(v) {
      return (
        <span style={{ color: Number(v) < 0 ? 'red' : 'black' }}>
          {Number(parseFloat(v).toFixed(2)).toLocaleString()}
        </span>
      )
    }
  }
]

export const formateDate = (datetime: Date) => {
  // let  = "2019-11-06T16:00:00.000Z"
  const d = new Date(datetime)
  const addDateZero = (num: number) => {
    return num < 10 ? '0' + num : num
  }
  const formatDateTime = `${d.getFullYear()}-${addDateZero(
    d.getMonth() + 1
  )}-${addDateZero(d.getDate())} ${addDateZero(d.getHours())}:${addDateZero(
    d.getMinutes()
  )}:${addDateZero(d.getSeconds())}`

  return formatDateTime
}
