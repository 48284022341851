import _ from 'lodash'
import {
  DOCTOR_INVITE_STATUS,
  ATTENDER_BILL_STATUS,
  SETTLEMENT_STATUS_MAP,
  INVITE_STATUS_MAP,
  BUDGET_TYPE_MAP
} from '../constant/hrradiation'
import dayjs from 'dayjs'

// 整理出表格数据
export const getData = (data: any, head: any, needSplit = true) => {
  let result = data ? _.cloneDeep(data) : []
  if (needSplit) {
    let needDelete = []

    // 先删除参会人数为0的会议
    _.remove(result, (item: { speakers: [] }) => {
      return !item?.speakers?.length
    })
    // console.log(result)
    // 拿出多人参会的会议
    needDelete = _.remove(result, (item: { speakers: [] }) => {
      return item?.speakers?.length > 1
    })

    // 把多人参会的每个老师单独拿出来，成为一个订单
    needDelete?.length > 0 &&
      needDelete.map((item) => {
        item?.speakers?.forEach((speak: { [key: string]: any }) => {
          result.push({
            ...item,
            speakers: [speak]
          })
        })
      })
  }

  // 按照 excelHeader 拼装
  result = _.map(result, (item) => {
    const obj: any = {}

    head.forEach((cell: any) => {
      const value = _.get(item, cell.path, '')
      switch (cell.key) {
        // 签约状态
        case 'contractStatus':
          obj[cell.name] = DOCTOR_INVITE_STATUS?.[value]?.name || '待邀请'
          break

        case 'attenderStatus':
          obj[cell.name] = (value && INVITE_STATUS_MAP[value]) || '待邀请'
          break

        // 开票状态
        case 'billStatus':
          obj[cell.name] = ATTENDER_BILL_STATUS?.[value] || ''
          break

        // 结算状态
        case 'attenderSettlementStatus':
          obj[cell.name] = SETTLEMENT_STATUS_MAP[value] || ''
          break

        // 总费用/费用/服务费用
        case 'attenderFee':
        case 'fee':
        case 'totalFee':
        case 'serviceFee':
          obj[cell.name] = parseFloat(
            (!isNaN(Number(value)) && Number(value).toFixed(2)) || '0'
          )

          break

        case 'attenderSettlementTime':
          obj[cell.name] =
            (value &&
              !isNaN(Number(value)) &&
              dayjs(Number(value)).format('YYYY-MM-DD')) ||
            ''
          break

        // 时间
        case 'startTime':
        case 'endTime':
        case 'billTime':
          obj[cell.name] = value && dayjs(value).format('YYYY-MM-DD')
          break

        case 'budgetChannel':
          obj[cell.name] = value
            ? BUDGET_TYPE_MAP.find((i) => i.value === value)?.text || '-'
            : '-'
          break

        case 'conferencePlatform':
          obj[cell.name] = value
            ? JSON.parse(value || '{}').conferencePlatform
            : '-'
          break
        case 'conferenceNo':
          obj[cell.name] = value ? JSON.parse(value || '{}').conferenceNo : '-'
          break

        default:
          obj[cell.name] = value
          break
      }
    })
    return obj
  })

  return result
}
