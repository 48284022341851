import React, { FC, Fragment } from 'react'
import omit from 'lodash.omit'
import { useOrderOperationLogQuery } from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { MEETING_STATUS_MAP } from '../../constant/hrradiation'

const OrderOperationLogTable: FC<any> = ({ meetingId }) => {
  const table = useSearchTable({}, { pageSize: 10, currentPage: 1 })
  const [{ data: logs, error, fetching }, refetchLogs] =
    useOrderOperationLogQuery({
      variables: {
        pager: omit(table.pager, 'total'),
        query: {
          meetingId
        }
      }
    })

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchLogs}
        title={error?.name}
        message={error?.message}
      />
    )
  }

  return (
    <SearchTable
      table={table}
      bordered
      loading={fetching}
      showPanel={false}
      pager={logs?.result?.pager}
      dataSource={logs?.result?.items || []}
      columns={[
        {
          title: '操作日期',
          key: 'operationDate',
          dataIndex: 'operationDate',
          align: 'center',
          width: 150
        },
        {
          title: '操作类型',
          key: 'category',
          dataIndex: 'category',
          align: 'center',
          width: 100,
          render: (value) => {
            return value === 'CANCEL_ORDER'
              ? '订单退回'
              : value === 'DELETE_USER'
              ? '删除老师'
              : value === 'SPLIT_USER'
              ? '拆分老师'
              : '-'
          }
        },
        {
          title: '订单状态【操作前】',
          key: 'beforeSnapshotStatus',
          dataIndex: 'beforeSnapshotStatus',
          align: 'center',
          width: 160,
          render: (value) => {
            return MEETING_STATUS_MAP?.[value]?.name || '-'
          }
        },
        {
          title: '订单状态【操作后】',
          key: 'afterSnapshotStatus',
          dataIndex: 'afterSnapshotStatus',
          align: 'center',
          width: 160,
          render: (value) => {
            return MEETING_STATUS_MAP?.[value]?.name || '-'
          }
        },
        {
          title: '操作说明',
          key: 'explain',
          dataIndex: 'explain',
          align: 'center'
        },
        {
          title: '操作人员',
          key: 'operatorName',
          dataIndex: 'operatorName',
          align: 'center',
          width: 100
        }
      ]}
      scroll={{ x: true }}
    />
  )
}

export default OrderOperationLogTable
