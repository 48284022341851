import React from 'react'
import { Tabs } from 'antd'
import PayFailedTable from './payFailed'
import PayingTable from './payingTable'

const { TabPane } = Tabs

const SettlementManage: QCPage = () => {
  const onTabKeyChange = (key: any) => {
    console.log(key)
  }

  return (
    <main>
      <Tabs defaultActiveKey='1' onChange={onTabKeyChange}>
        <TabPane tab='打款中' key='paying'>
          <PayingTable />
        </TabPane>

        <TabPane tab='打款失败' key='payFailed'>
          <PayFailedTable />
        </TabPane>
      </Tabs>
    </main>
  )
}

SettlementManage.pageTitle = '结算管理'

export default SettlementManage
