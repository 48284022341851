import React, { useEffect, useState } from 'react'
import { Form, Input, InputNumber, Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { FC } from 'react'
import { DoctorTitleSelector } from '../../components/SelectorCollection'
import FormItem from 'antd/lib/form/FormItem'

export const EditPropertiesModal: FC<
  ModalProps & {
    companyId?: string
    onUpdate: (values: any) => void
    category?: any
  }
> = (props) => {
  const [form] = Form.useForm()

  useEffect(() => {
    const properties = JSON.parse(props.category.activityProperties || '{}')
    form.setFieldsValue({
      activityPurpose: properties.activityPurpose,
      activityRequire: properties.activityRequire,
      sort: props.category.sort
    })
  }, [JSON.stringify(props.category)])
  return (
    <Modal
      {...props}
      title='编辑属性'
      onOk={() => {
        const values = form.getFieldsValue()
        console.log(values)
        props.onUpdate({
          sort: values.sort,
          properties: JSON.stringify({
            activityPurpose: values.activityPurpose,
            activityRequire: values.activityRequire
          })
        })
      }}
    >
      <Form form={form}>
        <FormItem label='排序规则' name='sort'>
          <InputNumber placeholder='请输入' />
        </FormItem>
        <FormItem label='活动目的' name='activityPurpose'>
          <Input.TextArea rows={4} placeholder='请输入' />
        </FormItem>
        <FormItem label='活动要求' name='activityRequire'>
          <Input.TextArea rows={4} placeholder='请输入' />
        </FormItem>
      </Form>
    </Modal>
  )
}
