import React, { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import { useLocalStore } from 'mobx-react'

export interface SearchContext {
  setPageParams: (pageName: string, updateParam: object) => void
  getPageParams: (pageName: string) => object
  pageParams: { [key: string]: any }
}

const searchContext = createContext<MaybeNull<SearchContext>>(null)

export const createSearchStore = (): SearchContext => {
  return {
    pageParams: {},
    setPageParams(pageName, updateParam) {
      console.log(this.pageParams)
      this.pageParams[pageName] = updateParam
    },
    getPageParams(pageName: string) {
      return this.pageParams[pageName]
    }
  }
}

export const PageStoreProvider = ({
  children
}: PropsWithChildren<{}>): JSX.Element => {
  const store = useLocalStore(createSearchStore)
  return (
    <searchContext.Provider value={store}>{children}</searchContext.Provider>
  )
}

export const useSearchStore = (): SearchContext => {
  const store = useContext(searchContext)
  if (!store) {
    throw new Error('useSearchStore must be used within a StoreProvider.')
  }
  return store
}
