import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import {
  PersonalPlanBudgetLog,
  PersonalPlanBudgetUsedDetail,
  ProcessBudgetLog
} from '../../graphql'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getFormCategoryText } from '../../constant/sales'

export interface BudgetFormItems {
  transferAccount?: string | undefined
  amount?: number | undefined
  explain?: string | undefined
}

export const useTableColumns = (
  budgetType: string
): ColumnsType<ProcessBudgetLog> => {
  return [
    {
      align: 'center',
      title: '操作日期',
      dataIndex: 'operationDate',
      key: 'operationDate',
      width: 120,
      fixed: 'left',
      render(v: string) {
        return dayjs(v).format('YYYY-MM-DD')
      }
    },
    {
      align: 'center',
      title: '增减金额',
      dataIndex:
        budgetType === 'personal' ? 'BudgetAmountChanged' : 'changeValue',
      key: 'changeValue',
      width: 100,
      render(v: string) {
        if (!v) return '-'

        const changeValue = Number(v)
        return (
          <span className={changeValue > 0 ? 'greenColor' : 'orangeColor'}>
            {changeValue > 0 && '+'}
            {changeValue}
          </span>
        )
      }
    },
    {
      align: 'center',
      title: <span className='blueColor'>【前】预算金额</span>,
      dataIndex:
        budgetType === 'personal'
          ? 'BudgetAmountBefore'
          : 'snapshotBudgetAmount',
      key: 'snapshotBudgetAmount',
      width: 140,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    ...(budgetType === 'personal'
      ? [
          {
            align: 'center' as 'center',
            title: <span className='purpleColor'>本月释放增加</span>,
            dataIndex: 'increaseAmount',
            key: 'increaseAmount',
            width: 120,
            render(v: string) {
              return v ? Number(v) : '-'
            }
          }
        ]
      : []),
    {
      align: 'center',
      title: <span className='orangeColor'>使用金额</span>,
      dataIndex: budgetType === 'personal' ? 'usedAmount' : 'snapshotUsedFee',
      key: 'snapshotUsedFee',
      width: 100,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: <span className='greenColor'>【前】结余金额</span>,
      dataIndex:
        budgetType === 'personal'
          ? 'balanceAmountBefore'
          : 'snapshotBalanceFee',
      key: 'snapshotBalanceFee',
      width: 140,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: <span className='greenColor'>【后】结余金额</span>,
      dataIndex:
        budgetType === 'personal'
          ? 'balanceAmountChanged'
          : 'currentBalanceFee',
      key: 'currentBalanceFee',
      width: 140,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: '操作说明',
      dataIndex: budgetType === 'personal' ? 'operatorRemark' : 'description',
      key: 'description'
    },
    {
      align: 'center',
      title: '操作人员',
      dataIndex:
        budgetType === 'personal' ? 'operatorAccountInfo' : 'operatorName',
      key: 'operatorName',
      width: 100,
      fixed: 'right',
      render(v) {
        if (budgetType === 'personal') {
          return v?.cnName || '-'
        }
        return v || '-'
      }
    }
  ]
}

export const useDetailTableColumns = (): ColumnsType<ProcessBudgetLog> => {
  return [
    {
      align: 'center',
      title: '会议ID',
      dataIndex: 'meetingId',
      key: 'meetingId',
      render(v) {
        return (
          <Link target='_blank' to={`/meeting/${v}`}>
            {v}
          </Link>
        )
      }
    },
    {
      align: 'center',
      title: '会议名称',
      dataIndex: 'meetingName',
      key: 'meetingName'
    },
    {
      align: 'center',
      title: '老师姓名',
      dataIndex: 'attenderName',
      key: 'attenderName'
    },
    {
      align: 'center',
      title: '积分',
      dataIndex: 'attenderFee',
      key: 'attenderFee',
      render(v: string) {
        return Number(v)
      }
    },
    {
      align: 'center',
      title: '申请人',
      dataIndex: 'accountInfo',
      key: 'cnName',
      render(v) {
        return v?.cnName
      }
    },
    {
      align: 'center',
      title: '报销编号',
      dataIndex: 'accountInfo',
      key: 'staffId',
      render(v) {
        return v?.staffId
      }
    },
    {
      align: 'center',
      title: '办事处',
      dataIndex: 'accountInfo',
      key: 'department',
      render(v) {
        return v?.department?.name
      }
    },
    {
      align: 'center',
      title: '提交时间',
      dataIndex: 'submitTime',
      key: 'submitTime',
      render(v: string) {
        return v ? dayjs(v).format('YYYY-MM-DD') : '-'
      }
    }
  ]
}

export const getStartTimestampByTargetMonth = (targetMonth: string) => {
  return new Date(
    moment(targetMonth).startOf('month').format('YYYY-MM-DD HH:mm:ss')
  )
    .getTime()
    .toString()
}

export const getEndTimestampByTargetMonth = (targetMonth: string) => {
  return new Date(
    moment(targetMonth).endOf('month').format('YYYY-MM-DD HH:mm:ss')
  )
    .getTime()
    .toString()
}

export const getStartTimestampByTargetYear = (targetYear: string) => {
  return new Date(
    moment(targetYear).startOf('year').format('YYYY-MM-DD HH:mm:ss')
  )
    .getTime()
    .toString()
}

export const getEndTimestampByTargetYear = (targetYear: string) => {
  return new Date(
    moment(targetYear).endOf('year').format('YYYY-MM-DD HH:mm:ss')
  )
    .getTime()
    .toString()
}

export const usePlanBudgetTableColumns = (): ColumnsType<ProcessBudgetLog> => {
  return [
    {
      align: 'center',
      title: '操作日期',
      dataIndex: 'operationTime',
      key: 'operationTime',
      fixed: 'left',
      width: 120,
      render(v: string) {
        return dayjs(v).format('YYYY-MM-DD')
      }
    },
    {
      align: 'center',
      title: '增减金额',
      dataIndex: 'budgetAmountChanged',
      key: 'budgetAmountChanged',
      width: 100,
      render(v: string) {
        if (!v) return '-'

        const changeValue = Number(v)
        return (
          <span className={changeValue > 0 ? 'greenColor' : 'orangeColor'}>
            {changeValue > 0 && '+'}
            {changeValue}
          </span>
        )
      }
    },
    {
      align: 'center',
      title: <span className='blueColor'>【前】预算金额</span>,
      dataIndex: 'budgetAmountBefore',
      key: 'budgetAmountBefore',
      width: 140,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: <span className='purpleColor'>本月释放增加</span>,
      dataIndex: 'increaseAmount',
      key: 'increaseAmount',
      width: 120,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: <span className='orangeColor'>使用金额</span>,
      dataIndex: 'usedAmount',
      key: 'usedAmount',
      width: 100,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: <span className='greenColor'>【前】结余金额</span>,
      dataIndex: 'balanceAmountBefore',
      key: 'balanceAmountBefore',
      width: 140,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: <span className='greenColor'>【后】结余金额</span>,
      dataIndex: 'balanceAmountChanged',
      key: 'balanceAmountChanged',
      width: 140,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: '操作说明',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      align: 'center',
      title: '操作人员',
      dataIndex: 'operatorAccountInfo',
      key: 'operatorAccountInfo',
      fixed: 'right',
      width: 100,
      render(v) {
        return v?.cnName || '-'
      }
    }
  ]
}

export const usePlanBudgetUsedDetailTableColumns =
  (): ColumnsType<ProcessBudgetLog> => {
    return [
      {
        align: 'center',
        title: '消费ID',
        dataIndex: 'orderId',
        key: 'orderId',
        render(v) {
          return (
            <Link target='_blank' to={`/consumptionOrderBillDetail/${v}`}>
              {v}
            </Link>
          )
        }
      },
      {
        align: 'center',
        title: '消费类型',
        dataIndex: 'formCategory',
        key: 'formCategory',
        render(v) {
          return getFormCategoryText(v)
        }
      },
      {
        align: 'center',
        title: '商户名称',
        dataIndex: 'targetName',
        key: 'targetName',
        render(v) {
          return v || '消费订单_未选消费场所'
        }
      },
      {
        align: 'center',
        title: '用户名称',
        dataIndex: 'accountName',
        key: 'accountName'
      },
      {
        align: 'center',
        title: '用户手机号',
        dataIndex: 'accountMobile',
        key: 'accountMobile'
      },
      {
        align: 'center',
        title: '预计消费金额',
        dataIndex: 'planAmount',
        key: 'planAmount'
      },
      {
        align: 'center',
        title: '核减预计金额',
        dataIndex: 'deductionAmount',
        key: 'deductionAmount'
      },
      {
        align: 'center',
        title: '确认消费金额',
        dataIndex: 'actualAmount',
        key: 'actualAmount'
      },
      {
        align: 'center',
        title: '报销金额',
        dataIndex: 'claimAmount',
        key: 'claimAmount'
      },
      {
        align: 'center',
        title: '核减报销金额',
        dataIndex: 'financialDeductionAmount',
        key: 'financialDeductionAmount'
      },
      {
        align: 'center',
        title: '提交时间',
        dataIndex: 'submitTime',
        key: 'submitTime',
        render(v: string) {
          return v ? dayjs(v).format('YYYY-MM-DD') : '-'
        }
      }
    ]
  }

export const useCostCenterTableColumns = (): ColumnsType<ProcessBudgetLog> => {
  return [
    {
      align: 'center',
      title: '操作日期',
      dataIndex: 'operationTime',
      key: 'operationTime',
      fixed: 'left',
      width: 120,
      render(v: string) {
        return dayjs(v).format('YYYY-MM-DD')
      }
    },
    {
      align: 'center',
      title: '增减金额',
      dataIndex: 'budgetAmountChanged',
      key: 'budgetAmountChanged',
      width: 100,
      render(v: string) {
        if (!v) return '-'

        const changeValue = Number(v)
        return (
          <span className={changeValue > 0 ? 'greenColor' : 'orangeColor'}>
            {changeValue > 0 && '+'}
            {changeValue}
          </span>
        )
      }
    },
    {
      align: 'center',
      title: <span className='blueColor'>【前】预算金额</span>,
      dataIndex: 'budgetAmountBefore',
      key: 'budgetAmountBefore',
      width: 140,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: <span className='orangeColor'>使用金额</span>,
      dataIndex: 'usedAmount',
      key: 'usedAmount',
      width: 100,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: <span className='greenColor'>【前】结余金额</span>,
      dataIndex: 'balanceAmountBefore',
      key: 'balanceAmountBefore',
      width: 140,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: <span className='greenColor'>【后】结余金额</span>,
      dataIndex: 'balanceAmountChanged',
      key: 'balanceAmountChanged',
      width: 140,
      render(v: string) {
        return v ? Number(v) : '-'
      }
    },
    {
      align: 'center',
      title: '操作说明',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      align: 'center',
      title: '操作人员',
      dataIndex: 'operatorAccountInfo',
      key: 'operatorAccountInfo',
      fixed: 'right',
      width: 100,
      render(v) {
        return v?.cnName || '-'
      }
    }
  ]
}
