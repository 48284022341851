/**
 * 记录费用模块 + 会议日程表模块
 */
import React, { FC, Fragment } from 'react'
import { Table, Image, Card, Row, Col, Tag } from 'antd'
import { sumBy } from 'lodash'
import Column from 'antd/lib/table/Column'

import { HrMeetingDetailQuery } from '../../graphql'
import AuditRecordTag from './auditRecordTag'

const getImgSource = (pic: string) => {
  const sliceIndex = pic?.indexOf('?')
  const url = sliceIndex > -1 ? pic.slice(0, sliceIndex) : pic
  if (/\.(png|jpe?g|jpeg|gif|webp|bmp)$/i.test(url)) {
    return url
  }
  return url + '?x-oss-process=image/format,jpg'
}

const labelStyle = {
  color: 'rgb(136, 136, 136)'
}

const SchedulePicAuditRecordData = [
  {
    label: '重复性',
    type: '会议日程表-重复性',
    dimension: 'repeat'
  },
  {
    label: '日期一致性',
    type: '会议日程表-日期关联性',
    dimension: 'relation'
  },
  {
    label: '老师参会验证',
    type: '会议日程表-老师参会关联性',
    dimension: 'relation'
  }
]

const SignPicAuditRecordData = [
  {
    label: '重复性',
    type: '会议签到表-重复性',
    dimension: 'repeat'
  },
  {
    label: '老师签到验证',
    type: '会议签到表-关联性',
    dimension: 'relation'
  }
]

const CostsRecord: FC<{ data: HrMeetingDetailQuery | undefined }> = ({
  data
}) => (
  <Card
    title='记录费用'
    extra={`共计${sumBy(data?.detail?.costs, (d: any) =>
      parseFloat(d?.amount as any)
    )}元`}
  >
    <Table dataSource={data?.detail?.costs} pagination={false}>
      <Column title='费用项目' dataIndex='type' />
      <Column title='费用金额' dataIndex='amount' />
    </Table>
  </Card>
)

const SchedulePics: FC<{ data: HrMeetingDetailQuery | undefined }> = ({
  data
}) => {
  // const schedulePicAuditRecord = data?.detail?.meetingAuditRecord?.filter((x) =>
  //   x.verifyType?.includes('会议日程表')
  // )
  // const auditRecordData = SchedulePicAuditRecordData.map((x) => {
  //   const item =
  //     schedulePicAuditRecord?.find(
  //       (item) =>
  //         item.verifyType === x.type && item.verifyDimension === x.dimension
  //     ) || {}
  //   return {
  //     label: x.label,
  //     status: item.status
  //   }
  // })
  return (
    <>
      <Card
        title='会议日程表'
        className='schedulePicsCard'
        bordered={false}
        style={{ height: '100%' }}
        // extra={
        //   <Row>
        //     {auditRecordData.map((item, index) => {
        //       return (
        //         <Fragment key={index}>
        //           <Col span={12} style={labelStyle}>
        //             {item.label}：
        //           </Col>
        //           <Col span={12}>
        //             <AuditRecordTag status={item.status} />
        //           </Col>
        //         </Fragment>
        //       )
        //     })}
        //   </Row>
        // }
      >
        <Row gutter={16}>
          <Image.PreviewGroup>
            {data?.detail?.schedulePics?.map((pic, index) => {
              return (
                <Col key={index} className='gutter-row'>
                  <Image width={100} key={index} src={getImgSource(pic)} />
                </Col>
              )
            })}
          </Image.PreviewGroup>
        </Row>
      </Card>
    </>
  )
}

const SignPics: FC<{ data: HrMeetingDetailQuery | undefined }> = ({ data }) => {
  // const signPicAuditRecord = data?.detail?.meetingAuditRecord?.filter((x) =>
  //   x.verifyType?.includes('会议签到表')
  // )
  // const auditRecordData = SignPicAuditRecordData.map((x) => {
  //   const item =
  //     signPicAuditRecord?.find(
  //       (item) =>
  //         item.verifyType === x.type && item.verifyDimension === x.dimension
  //     ) || {}
  //   return {
  //     label: x.label,
  //     status: item.status
  //   }
  // })
  return (
    <>
      <Card
        title='会议签到表'
        className='signPicsCard'
        bordered={false}
        style={{ height: '100%' }}
        // extra={
        //   <Row>
        //     {auditRecordData.map((item, index) => {
        //       return (
        //         <Fragment key={index}>
        //           <Col span={12} style={labelStyle}>
        //             {item.label}：
        //           </Col>
        //           <Col span={12}>
        //             <AuditRecordTag status={item.status} />
        //           </Col>
        //         </Fragment>
        //       )
        //     })}
        //   </Row>
        // }
      >
        <Row gutter={16}>
          <Image.PreviewGroup>
            {data?.detail?.signPics?.map((pic, index) => {
              return (
                <Col key={index} className='gutter-row'>
                  <Image width={100} key={index} src={getImgSource(pic)} />
                </Col>
              )
            })}
          </Image.PreviewGroup>
        </Row>
      </Card>
    </>
  )
}

const ScheduleAndCosts: FC<{
  data: HrMeetingDetailQuery | undefined
}> = ({ data }) => (
  <>
    {(data?.detail?.schedulePics?.length || data?.detail?.signPics?.length) && (
      <Row style={{ marginBottom: 8 }}>
        <Col span={data?.detail?.signPics?.length ? 12 : 24}>
          <SchedulePics data={data} />
        </Col>
        <Col span={12}>
          <SignPics data={data} />
        </Col>
      </Row>
    )}

    <CostsRecord data={data} />
  </>
)

export default ScheduleAndCosts
