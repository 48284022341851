import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { Form, InputNumber, Select, Row } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import FormItem from 'antd/lib/form/FormItem'
import {
  MeetingDetailQuery,
  UpMeetingBudgetType,
  MeetingOrderInfo,
  HrMeetingDetailQuery,
  Maybe
} from '../../graphql'
import { CitySelector } from '../../components/SelectorCollection'
import AcquireCarOfferPriceButton from '../../components/AcquireCarOfferPriceButton'
import { Store } from 'antd/lib/form/interface'
import omit from 'lodash.omit'
import { BUDGET_TYPE_MAP } from '../../constant/hrradiation'
import { HR_PRODUCTION } from '../../constant/user'

const fieldName = (index: number, name: string) => `quotation${index}.${name}`

export const useMeetingBaseInfo = (
  detail: HrMeetingDetailQuery['detail'],
  meetingType: string,
  production: string
) => {
  const fields = !detail
    ? []
    : [
        ...([
          'CATE_DOCUMENT_COMMENT',
          'CATE_QUESTIONNAIRE_RESEARCH',
          'CATE_CASE_COLLECTION',
          'CATE_DOCUMENT_COMPOSE'
        ].includes(meetingType)
          ? [
              {
                label: '会议类型',
                content: detail.meetingType || ''
              },
              {
                label: '产品类型',
                content: detail?.base?.belongProduct?.productName || ''
              }
            ]
          : []),
        ...(detail.city?.name
          ? [
              {
                label: '城市',
                content: detail.city?.name
              }
            ]
          : []),
        ...(production === HR_PRODUCTION.radiation ||
        production === HR_PRODUCTION.anesthesiology
          ? [
              {
                label: '成本中心',
                content: detail.costcenter?.name
              }
            ]
          : []),

        {
          label: '开始时间',
          content: dayjs(detail.base?.startTime!).format('YYYY-MM-DD')
        },
        {
          label: '结束时间',
          content: dayjs(detail.base?.endTime!).format('YYYY-MM-DD')
        },
        {
          label: '创建时间',
          content: dayjs(detail.base?.createTime!).format('YYYY-MM-DD')
        },
        {
          label: '申请人',
          content: detail.base?.owner?.cnName
        },
        // {
        //   label: '申请人电话',
        //   content: detail.base?.owner?.companyMobile
        // },
        // {
        //   label: '申请人省区',
        //   content: detail.mettingRegion?.name
        // },
        ...(production === HR_PRODUCTION.radiation && detail.vendor?.cnName
          ? [{ label: '项目服务商', content: detail.vendor?.cnName }]
          : []),
        ...([
          'ADVERTISING_ACTIVITIES',
          'CATE_DOCUMENT_COMMENT',
          'CATE_QUESTIONNAIRE_RESEARCH',
          'CATE_CASE_COLLECTION',
          'CATE_DOCUMENT_COMPOSE'
        ].includes(meetingType)
          ? [
              {
                label: '活动类型',
                content: detail.activityType || ''
              }
            ]
          : []),
        ...(meetingType === 'NORTHERN_FUJIAN_CASE'
          ? [
              {
                label: '闽北病例类型',
                content: detail.activityType || ''
              }
            ]
          : []),
        ...(meetingType === 'JIANGXI_CASE'
          ? [
              {
                label: '江西病例类型',
                content: detail.activityType || ''
              }
            ]
          : []),
        ...(production === HR_PRODUCTION.hansoh
          ? [
              {
                label: '预算渠道',
                content: detail.base?.budgetChannel
                  ? BUDGET_TYPE_MAP.find(
                      (i: { value: Maybe<number> | undefined }) =>
                        i.value === detail.base?.budgetChannel
                    )?.text || '-'
                  : '-'
              },
              {
                label: '预算编号',
                content: detail.base?.budgetNumber || ''
              }
            ]
          : []),
        ...(production === HR_PRODUCTION.cttq &&
        detail.base?.belongProduct?.productName
          ? [
              {
                label: '产品线',
                content: detail.base?.belongProduct?.productName
              }
            ]
          : []),
        ...(meetingType === 'ACADEMIC_CONFERENCE'
          ? [
              {
                label: '会议类型',
                content: detail.activityType || '-'
              },
              {
                label: '会议平台',
                content:
                  JSON.parse(detail.conferencePlatformInfo || '{}')
                    .conferencePlatform || '-'
              },
              {
                label: '会议号',
                content:
                  JSON.parse(detail.conferencePlatformInfo || '{}')
                    .conferenceNo || '-'
              }
            ]
          : []),
        ...([
          HR_PRODUCTION.qc,
          HR_PRODUCTION.qcDemo,
          HR_PRODUCTION.hansoh
        ].includes(production) && meetingType === 'ACADEMIC_CONFERENCE'
          ? [
              {
                label: '会议种类',
                content: detail.meetingCategory || '-'
              }
            ]
          : [])
      ]

  return fields
}

export const useProjectBaseInfo = (detail: HrMeetingDetailQuery['detail']) => {
  const fields = !detail
    ? []
    : [
        {
          label: '活动城市',
          content: detail.city?.name
        },
        {
          label: '参与单位',
          content: detail.hospital
        },
        {
          label: '活动开始时间',
          content: dayjs(detail.base?.startTime!).format('YYYY-MM-DD')
        },
        {
          label: '活动结束时间',
          content: dayjs(detail.base?.endTime!).format('YYYY-MM-DD')
        },
        {
          label: '活动介绍',
          content: detail.base?.desc
        },
        {
          label: '创建时间',
          content: dayjs(detail.base?.createTime!).format('YYYY-MM-DD')
        },
        {
          label: '申请人',
          content: detail.base?.owner?.cnName
        },
        // {
        //   label: '申请人电话',
        //   content: detail.base?.owner?.companyMobile
        // }
        // {
        //   label: '申请人省区',
        //   content: detail.base?.owner?.region?.name
        // }
      ]

  return fields
}

export const useMeetingOrders = (
  detail: MeetingDetailQuery['meetingDetail']
) => {
  const orders = useMemo(() => {
    if (!detail) return []
    return (
      detail.budgets.items.find(
        (b) => b.type === UpMeetingBudgetType.ShortDistanceTransportationFee
      )?.orders || []
    )
  }, [JSON.stringify(detail)])

  return orders
}

type useMeetingOrdersTableProps = {
  detail: MeetingDetailQuery['meetingDetail']
  canQuotation?: boolean
  onPriceApply?: (price: MaybeNull<string>, fieldName: string) => void
}

export const generateTotalObj = (carTotal: number, numTotal: number) => {
  if (!carTotal || carTotal === 0) {
    return {
      carTotal: 0,
      poTotal: 0,
      taxTotal: 0,
      totalAmount: 0,
      numTotal
    }
  }
  const poTotal = carTotal + numTotal * 3
  const taxTotal = (poTotal * 6) / 100

  return {
    carTotal,
    poTotal,
    taxTotal,
    totalAmount: poTotal + taxTotal,
    numTotal
  }
}

export const useMeetingOrdersTable = ({
  detail,
  canQuotation,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onPriceApply = () => {}
}: useMeetingOrdersTableProps): {
  columns: ColumnsType<DeepPartial<MeetingOrderInfo>>
  orders: Partial<MeetingOrderInfo>[]
  totalObj: {
    carTotal: number
    poTotal: number
    taxTotal: number
    totalAmount: number
    numTotal: number
  }
} => {
  const orders = useMeetingOrders(detail)
  const baseTotal = orders.reduce(
    (all, order) => {
      all.carTotal += order.quotations[0]?.total || 0
      all.numTotal += order.num || 0
      return all
    },
    {
      carTotal: 0,
      numTotal: 0
    }
  )
  return {
    totalObj: generateTotalObj(baseTotal.carTotal, baseTotal.numTotal),
    columns: [
      {
        title: '项目',
        key: 'project',
        dataIndex: 'attributes',
        render: (att) => {
          return att.type
        }
      },
      {
        title: '描述',
        key: 'carType',
        dataIndex: 'attributes',
        render: (att) => {
          return att.carType
        }
      },
      {
        title: '数量',
        key: 'num',
        dataIndex: 'attributes',
        render: (att) => {
          return att.num
        }
      },
      {
        title: '单位',
        key: 'unit',
        dataIndex: 'attributes',
        render: (att) => {
          return att.unit
        }
      },
      ...(canQuotation
        ? ([
            {
              title: '用车城市',
              key: 'city',
              dataIndex: 'attributes',
              render(_, order, index) {
                return (
                  <Form.Item
                    style={{ margin: 0 }}
                    noStyle={false}
                    name={fieldName(index, 'cityName')}
                    initialValue={
                      order.quotations?.[0]?.attributes?.cityName ||
                      detail?.city?.name
                    }
                    required
                    rules={[
                      {
                        required: true,
                        message: '请选择城市'
                      }
                    ]}
                  >
                    <CitySelector valueKey='name' />
                  </Form.Item>
                )
              }
            }
          ] as ColumnsType<DeepPartial<MeetingOrderInfo>>)
        : []),
      ...(canQuotation
        ? ([
            {
              title: '用车渠道',
              key: 'supplier',
              dataIndex: 'attributes',
              render(_, order, index) {
                return (
                  <Form.Item
                    style={{ margin: 0 }}
                    noStyle={false}
                    name={fieldName(index, 'supplier')}
                    initialValue={
                      order.quotations?.[0]?.attributes?.supplier || '线下'
                    }
                    required
                    rules={[
                      {
                        required: true,
                        message: '请选择用车渠道'
                      }
                    ]}
                  >
                    <Select>
                      <Select.Option value='线下'>线下车队</Select.Option>
                      <Select.Option value='网约'>网约车</Select.Option>
                    </Select>
                  </Form.Item>
                )
              }
            }
          ] as ColumnsType<DeepPartial<MeetingOrderInfo>>)
        : []),
      {
        title: '单价',
        key: 'unitPrice',
        dataIndex: 'attributes',
        render(_, order, index) {
          const supplier = fieldName(index, 'supplier')
          const cityName = fieldName(index, 'cityName')
          return canQuotation ? (
            <Row gutter={12}>
              <Form.Item
                style={{ margin: 0 }}
                noStyle={false}
                name={fieldName(index, 'unitPrice')}
                initialValue={order.quotations?.[0]?.price}
                required
                rules={[
                  {
                    required: true,
                    message: '请输入报价'
                  }
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                style={{ margin: 0 }}
                shouldUpdate={(prev, cur) => {
                  return (
                    prev[supplier] !== cur[supplier] ||
                    prev[cityName] !== cur[cityName]
                  )
                }}
              >
                {({ getFieldValue }) => {
                  return (
                    <AcquireCarOfferPriceButton
                      serviceName={order.attributes?.type as string | undefined}
                      carType={order.attributes?.carType as string | undefined}
                      supplier={getFieldValue(supplier)}
                      cityName={getFieldValue(cityName)}
                      onResult={(price) => {
                        onPriceApply(
                          price?.price,
                          fieldName(index, 'unitPrice')
                        )
                      }}
                    />
                  )
                }}
              </Form.Item>
            </Row>
          ) : (
            order.quotations?.[0]?.price || '--'
          )
        }
      },
      {
        title: '合计',
        key: 'total',
        dataIndex: 'attributes',
        render(_, order, index) {
          const unitPrice = fieldName(index, 'unitPrice')
          return canQuotation ? (
            <FormItem
              noStyle
              shouldUpdate={(p, c) => p[unitPrice] !== c[unitPrice]}
            >
              {({ getFieldValue }) => {
                return (
                  (getFieldValue(unitPrice) || 0) *
                  parseInt((order.attributes?.num as any) || '0')
                )
              }}
            </FormItem>
          ) : (
            order.quotations?.[0]?.total || '--'
          )
        }
      },
      {
        title: '备注',
        key: 'note',
        dataIndex: 'attributes',
        render: (att) => {
          return att.note
        }
      }
    ],
    orders
  }
}

export const formatQuotations = (
  orders: DeepPartial<MeetingOrderInfo>[],
  values: Store
) => {
  return Object.keys(values).reduce<
    { orderId: string; price: number; num?: number; attributes?: any }[]
  >((quotations, current) => {
    const [quotation, field] = current.split('.')
    const index = parseInt(quotation.replace('quotation', ''))
    quotations[index] = quotations[index] || {
      ...omit(orders[index]?.quotations?.[0], 'total'),
      orderId: orders[index].id!,
      price: 0,
      num: orders[index].num,
      attributes: {}
    }
    switch (field) {
      case 'unitPrice':
        quotations[index].price = values[current]
        break

      case 'supplier':
        quotations[index].attributes.supplier = values[current]
        break

      case 'cityName':
        quotations[index].attributes.cityName = values[current]
        break
    }

    return quotations
  }, [])
}
