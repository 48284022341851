import React, { FC } from 'react'
import { Skeleton, Descriptions, Card, Button, DatePicker } from 'antd'
import moment from 'moment'
import _ from 'lodash'

const basicLabel = {
  fontWeight: 'bold'
}
const basicLabelColor = {
  fontWeight: 'bold'
}
const budgetAmountStyle = {
  color: 'rgb(71, 103, 236)',
  fontWeight: 'bold'
}
const budgetAmountColumnStyle = {
  flexDirection: 'column' as 'column',
  color: 'rgb(71, 103, 236)',
  fontWeight: 'bold',
  fontSize: 20,
  backgroundColor: 'rgb(235, 237, 254)',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0',
  margin: '0 5px 0 0'
}
const usedFeeStyle = {
  color: 'rgb(224, 99, 17)',
  fontWeight: 'bold'
}
const usedFeeColumnStyle = {
  flexDirection: 'column' as 'column',
  color: 'rgb(224, 99, 17)',
  fontWeight: 'bold',
  fontSize: 20,
  backgroundColor: 'rgb(255, 238, 227)',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0',
  margin: '0 5px 0 0'
}
const increaseAmountStyle = {
  color: 'rgb(192, 73, 233)',
  fontWeight: 'bold'
}
const increaseAmountColumnStyle = {
  flexDirection: 'column' as 'column',
  color: 'rgb(192, 73, 233)',
  fontWeight: 'bold',
  fontSize: 20,
  backgroundColor: 'rgb(246, 234, 254)',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0',
  margin: '0 5px 0 0'
}
const deductionAmountStyle = {
  color: 'rgb(40, 178, 236)',
  fontWeight: 'bold'
}
const deductionAmountColumnStyle = {
  flexDirection: 'column' as 'column',
  color: 'rgb(40, 178, 236)',
  fontWeight: 'bold',
  fontSize: 20,
  backgroundColor: 'rgb(224, 246, 254)',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0',
  margin: '0 5px 0 0'
}
const balanceAmountStyle = {
  color: 'rgb(76, 144, 68)',
  fontWeight: 'bold'
}
const balanceAmountColumnStyle = {
  flexDirection: 'column' as 'column',
  color: 'rgb(76, 144, 68)',
  fontWeight: 'bold',
  fontSize: 20,
  backgroundColor: 'rgb(235, 242, 236)',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0'
}

const formatAmountStr = (amountStr: string) => {
  if (!amountStr) return '-'

  return Number(amountStr)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const BudgetDetailCard: FC<{
  fetching: boolean
  data: any
  budgetType: string
  bodyStyle?: React.CSSProperties | undefined
  includeExtraOperation?: boolean
  operationDisable?: boolean
  basicInfoColumn?: number
  hideBasicTitle?: boolean
  hideLabel?: boolean
  budgetAddSubtract?: () => void
  budgetTransfer?: () => void
  isCurrentMonth?: boolean
  targetMonth?: string
  isCurrentYear?: boolean
  targetYear?: string
  onMonthChange?: (date: any, dateString: string) => void
}> = ({
  fetching,
  data,
  budgetType,
  bodyStyle,
  includeExtraOperation = true,
  operationDisable = false,
  basicInfoColumn = 3,
  hideBasicTitle = false,
  hideLabel = true,
  budgetAddSubtract,
  budgetTransfer,
  isCurrentMonth = true,
  targetMonth,
  isCurrentYear = true,
  targetYear,
  onMonthChange
}) => {
  const addSubtract = () => {
    budgetAddSubtract && budgetAddSubtract()
  }
  if (fetching) {
    return <Skeleton active />
  }
  return (
    <Card bordered={false} bodyStyle={bodyStyle}>
      {budgetType === 'process' ? (
        <Descriptions
          title={
            <span>
              流程预算编号：
              <span className='blueColor'>{data?.budgetNumber}</span>
            </span>
          }
          column={3}
          extra={
            includeExtraOperation ? (
              <Button
                type='primary'
                disabled={operationDisable}
                onClick={addSubtract}
              >
                流程预算增减
              </Button>
            ) : null
          }
        >
          <Descriptions.Item
            label={hideLabel ? '' : '预算金额'}
            labelStyle={hideLabel ? {} : budgetAmountStyle}
            contentStyle={
              hideLabel ? budgetAmountColumnStyle : budgetAmountStyle
            }
          >
            <div>{formatAmountStr(data?.budgetAmount)}</div>
            {hideLabel && <div className='fz12'>预算金额</div>}
          </Descriptions.Item>
          <Descriptions.Item
            label={hideLabel ? '' : '使用金额'}
            labelStyle={hideLabel ? {} : usedFeeStyle}
            contentStyle={hideLabel ? usedFeeColumnStyle : usedFeeStyle}
          >
            <div>{formatAmountStr(data?.usedFee)}</div>
            {hideLabel && <div className='fz12'>使用金额</div>}
          </Descriptions.Item>
          <Descriptions.Item
            label={hideLabel ? '' : '结余金额'}
            labelStyle={hideLabel ? {} : balanceAmountStyle}
            contentStyle={
              hideLabel ? balanceAmountColumnStyle : balanceAmountStyle
            }
          >
            <div>{formatAmountStr(data?.balanceFee)}</div>
            {hideLabel && <div className='fz12'>结余金额</div>}
          </Descriptions.Item>
        </Descriptions>
      ) : budgetType === 'costCenter' ? (
        <>
          <Descriptions
            title={hideBasicTitle ? null : '基础信息'}
            column={1}
            className='pb16'
          >
            <Descriptions.Item label='成本中心' labelStyle={basicLabel}>
              {data?.groupName || '-'}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            title={
              <span>
                预算年份：
                {targetYear ? (
                  <DatePicker
                    onChange={onMonthChange}
                    picker='year'
                    allowClear={false}
                    // disabledDate={(current) => {
                    //   return (
                    //     current < moment('2022') ||
                    //     current > moment().add(2, 'years')
                    //   )
                    // }}
                    value={moment(targetYear, 'YYYY')}
                  />
                ) : (
                  <span className='blueColor'>
                    {data?.year || moment(data?.start).format('YYYY')}年
                  </span>
                )}
              </span>
            }
            column={3}
            extra={
              includeExtraOperation ? (
                <Button
                  type='primary'
                  disabled={operationDisable}
                  onClick={addSubtract}
                >
                  预算调整
                </Button>
              ) : null
            }
          >
            <Descriptions.Item
              label={hideLabel ? '' : '预算金额'}
              labelStyle={hideLabel ? {} : budgetAmountStyle}
              contentStyle={
                hideLabel ? budgetAmountColumnStyle : budgetAmountStyle
              }
            >
              <div>{formatAmountStr(data?.budgetAmount)}</div>
              {hideLabel && <div className='fz12'>预算金额</div>}
            </Descriptions.Item>
            <Descriptions.Item
              label={hideLabel ? '' : '使用金额'}
              labelStyle={hideLabel ? {} : usedFeeStyle}
              contentStyle={hideLabel ? usedFeeColumnStyle : usedFeeStyle}
            >
              <div>{formatAmountStr(data?.usedAmount)}</div>
              {hideLabel && <div className='fz12'>使用金额</div>}
            </Descriptions.Item>
            <Descriptions.Item
              label={hideLabel ? '' : '结余金额'}
              labelStyle={hideLabel ? {} : balanceAmountStyle}
              contentStyle={
                hideLabel ? balanceAmountColumnStyle : balanceAmountStyle
              }
            >
              <div>{formatAmountStr(data?.balanceAmount)}</div>
              {hideLabel && <div className='fz12'>结余金额</div>}
            </Descriptions.Item>
          </Descriptions>
        </>
      ) : (
        <>
          <Descriptions
            title={hideBasicTitle ? null : '基础信息'}
            column={basicInfoColumn}
            className='pb16'
          >
            {['inPlan', 'outPlan'].includes(budgetType) ? (
              <>
                <Descriptions.Item label='预算类别' labelStyle={basicLabel}>
                  {budgetType === 'inPlan' ? '日常' : '专项'}
                </Descriptions.Item>
                <Descriptions.Item label='业务部' labelStyle={basicLabel}>
                  {data?.business || '-'}
                </Descriptions.Item>
                <Descriptions.Item label='姓名' labelStyle={basicLabel}>
                  {data?.accountName || '-'}
                </Descriptions.Item>
                <Descriptions.Item label='报销编号' labelStyle={basicLabel}>
                  {data?.financeNo || '-'}
                </Descriptions.Item>
              </>
            ) : (
              <>
                <Descriptions.Item label='业务部' labelStyle={basicLabel}>
                  {data?.business || '-'}
                </Descriptions.Item>
                <Descriptions.Item label='大区' labelStyle={basicLabel}>
                  {data?.region || '-'}
                </Descriptions.Item>
                <Descriptions.Item label='地区' labelStyle={basicLabel}>
                  {data?.area || '-'}
                </Descriptions.Item>
                <Descriptions.Item label='报销编号' labelStyle={basicLabel}>
                  {data?.financeNo || '-'}
                </Descriptions.Item>
                <Descriptions.Item label='用户姓名' labelStyle={basicLabel}>
                  {data?.accountName || '-'}
                </Descriptions.Item>
                <Descriptions.Item label='用户手机' labelStyle={basicLabel}>
                  {data?.accountInfo?.companyMobile || '-'}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
          <Descriptions
            title={
              <span>
                预算月份：
                {targetMonth ? (
                  <DatePicker
                    onChange={onMonthChange}
                    picker='month'
                    allowClear={false}
                    disabledDate={(current) => {
                      return (
                        current < moment('2021-09') ||
                        current > moment().add(2, 'years')
                      )
                    }}
                    value={moment(targetMonth, 'YYYY-MM')}
                  />
                ) : (
                  <span className='blueColor'>
                    {data?.month || moment(data?.start).format('MM')}月
                  </span>
                )}
              </span>
            }
            column={isCurrentMonth ? 4 : 5}
            extra={
              includeExtraOperation ? (
                <>
                  {_.isFunction(budgetAddSubtract) && (
                    <Button
                      type='primary'
                      disabled={operationDisable}
                      onClick={budgetAddSubtract}
                    >
                      本月预算增减
                    </Button>
                  )}
                  {_.isFunction(budgetTransfer) && (
                    <Button
                      type='primary'
                      className='budgetTransfer backGroundGreenColor borderNode'
                      disabled={operationDisable}
                      onClick={budgetTransfer}
                    >
                      人员预算调整
                    </Button>
                  )}
                </>
              ) : null
            }
          >
            <Descriptions.Item
              label={hideLabel ? '' : '预算金额'}
              labelStyle={hideLabel ? {} : budgetAmountStyle}
              contentStyle={
                hideLabel ? budgetAmountColumnStyle : budgetAmountStyle
              }
            >
              <div>{formatAmountStr(data?.budgetAmount)}</div>
              {hideLabel && <div className='fz12'>预算金额</div>}
            </Descriptions.Item>
            <Descriptions.Item
              label={hideLabel ? '' : '释放增加金额'}
              labelStyle={hideLabel ? {} : increaseAmountStyle}
              contentStyle={
                hideLabel ? increaseAmountColumnStyle : increaseAmountStyle
              }
            >
              <div>{formatAmountStr(data?.increaseAmount)}</div>
              {hideLabel && <div className='fz12'>释放增加金额</div>}
            </Descriptions.Item>
            {!isCurrentMonth && (
              <Descriptions.Item
                label={hideLabel ? '' : '释放减少金额'}
                labelStyle={hideLabel ? {} : deductionAmountStyle}
                contentStyle={
                  hideLabel ? deductionAmountColumnStyle : deductionAmountStyle
                }
              >
                <div>{formatAmountStr(data?.deductionAmount)}</div>
                {hideLabel && <div className='fz12'>释放减少金额</div>}
              </Descriptions.Item>
            )}
            <Descriptions.Item
              label={hideLabel ? '' : '使用金额'}
              labelStyle={hideLabel ? {} : usedFeeStyle}
              contentStyle={hideLabel ? usedFeeColumnStyle : usedFeeStyle}
            >
              <div>{formatAmountStr(data?.usedAmount)}</div>
              {hideLabel && <div className='fz12'>使用金额</div>}
            </Descriptions.Item>
            <Descriptions.Item
              label={hideLabel ? '' : '结余金额'}
              labelStyle={hideLabel ? {} : balanceAmountStyle}
              contentStyle={
                hideLabel ? balanceAmountColumnStyle : balanceAmountStyle
              }
            >
              <div>{formatAmountStr(data?.balanceAmount)}</div>
              {hideLabel && <div className='fz12'>结余金额</div>}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </Card>
  )
}

export default BudgetDetailCard
