// 拷贝 H5 的 @ruiyun/ajax 插件
import axios from 'axios'

const isCustomCatch = function (error: any) {
  const catchConfig = error.config.headers.catch
  if (catchConfig) {
    if (catchConfig === 'all') {
      return true
    } else {
      const status = error.response && error.response.status
      if (status) {
        const needCatchedStatusCodes = catchConfig
          .split(',')
          .map(function (statusCodeStr: any) {
            return parseInt(statusCodeStr)
          })
        if (needCatchedStatusCodes.indexOf(status) > -1) {
          return true
        }
      }
    }
  }
  return false
}

const defaultErrorHandler = function (error: any) {
  console.warn('需要自定义错误处理函数')
  console.log(error)
}

const Loading = {
  show: function () {
    console.warn('需要自定义loading.show方法')
  },
  hide: function () {
    console.warn('需要自定义loading.hide方法')
  }
}

axios.interceptors.request.use(function (config) {
  if (config.headers.loading !== 'false') {
    config.headers.loadingId = Loading.show()
  }

  return config
})

axios.interceptors.response.use(
  function (response) {
    if (response.config.headers.loading !== 'false') {
      Loading.hide()
    }
    return response.data
  },
  function (error) {
    if (error.config.headers.loading !== 'false') {
      Loading.hide()
    }
    if (isCustomCatch(error)) {
      // 用户希望自己处理异常
      return Promise.reject(error)
    } else {
      // 默认错误处理
      defaultErrorHandler(error)
      // 终止promise链
      return new Promise(function () {
        console.log('')
      })
    }
  }
)

export default axios
