import React, { FC } from 'react'
import { Spin, Tag, Card, Row, Col } from 'antd'
import { useTaskDocumentAuditRecordByTaskIdQuery } from '../../graphql'

const commentData = [
  {
    label: '点评内容重复',
    dimension: 'repeat'
  },
  {
    label: '点评内容与文献关联性',
    dimension: 'relation'
  }
]

export const CommentContentAuditRecord: FC<{
  taskId: string
  style?: React.CSSProperties | undefined
}> = ({ taskId, style }) => {
  const [{ data, fetching }] = useTaskDocumentAuditRecordByTaskIdQuery({
    variables: {
      taskId
    },
    pause: !taskId
  })

  const formatData = commentData.map((x) => {
    const item =
      data?.result?.find((item) => item.verifyDimension === x.dimension) || {}
    return {
      label: x.label,
      status: item.status
    }
  })

  return (
    <Spin spinning={fetching}>
      <Card title='点评内容验证' className='checkContainer' style={style}>
        {formatData.map((item, index) => {
          return (
            <Row key={index} wrap={false} gutter={[0, 12]}>
              <Col>{item.label}：</Col>
              <Col>
                <Tag
                  color={
                    item.status === 1
                      ? 'success'
                      : item.status === 0
                      ? 'error'
                      : 'default'
                  }
                  className='checkTag'
                >
                  {item?.status === 1
                    ? '通过'
                    : item?.status === 0
                    ? '不通过'
                    : '-'}
                </Tag>
              </Col>
            </Row>
          )
        })}
      </Card>
    </Spin>
  )
}
