import React, { FC, useEffect, useState } from 'react'
import { Modal, Input, Form } from 'antd'
import { FormProps } from 'antd/lib/form'
import { ApproveUserSelector } from '../../components/SelectorCollection'
import {
  returnApprovalReasons,
  CATE_DOCUMENT_COMMENT_REASONS,
  CATE_QUESTIONNAIRE_RESEARCH_REASONS,
  CATE_CASE_COLLECTION_REASONS,
  ACADEMIC_CONFERENCE_REASONS
} from '../../constant/user'
import './index.less'

const ApprovalModal: FC<{
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
  meetingType?: string | null
  isQCXS?: boolean
  failReason?: string | null
}> = ({ onCancel, onSubmit, meetingType, isQCXS = false, failReason }) => {
  const [form] = Form.useForm()
  const [reasons, setReasons] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    switch (meetingType) {
      case 'CATE_DOCUMENT_COMMENT':
        setReasons(CATE_DOCUMENT_COMMENT_REASONS)
        break
      case 'CATE_QUESTIONNAIRE_RESEARCH':
        setReasons(CATE_QUESTIONNAIRE_RESEARCH_REASONS)
        break
      case 'CATE_CASE_COLLECTION':
        setReasons(CATE_CASE_COLLECTION_REASONS)
        break
      case 'ACADEMIC_CONFERENCE':
        setReasons(ACADEMIC_CONFERENCE_REASONS)
        break
      default:
        setReasons(returnApprovalReasons)
        break
    }
  }, [meetingType])

  const tagOnClick = (tag: string) => {
    const prev = form.getFieldValue('reason') || ''
    form.setFieldsValue({
      reason: prev + tag,
      checkReason: prev + tag
    })
  }

  const onValuesChange: FormProps['onValuesChange'] = (value, values) => {
    const changeField = Object.keys(value)[0]
    if (changeField === 'reason') {
      form.setFieldsValue({
        checkReason: value.reason
      })
      if (!value.reason) {
        form.setFields([
          {
            name: 'checkReason',
            errors: ['请输入退回原因']
          }
        ])
      }
    }
  }

  const onFinish = (values: any) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  return (
    <Modal
      maskClosable={false}
      title='审批退回'
      visible
      onCancel={onCancel}
      centered
      closable={false}
      destroyOnClose
      width={480}
      onOk={() => {
        form.submit()
      }}
      okText={'确定'}
      confirmLoading={loading}
    >
      <Form
        preserve={false}
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        <Form.Item name='reason' noStyle initialValue={failReason}>
          <Input.TextArea
            // maxLength={300}
            placeholder='请输入退回原因'
            autoSize={{ minRows: 4, maxRows: 6 }}
            style={{ marginBottom: '10px' }}
          />
        </Form.Item>
        <Form.Item
          name='checkReason'
          rules={[{ required: true, message: '请输入退回原因' }]}
          initialValue={failReason}
        >
          <div className='tagsContainer'>
            {reasons.map((tag, index) => (
              <div
                title={tag}
                key={index}
                onClick={() => tagOnClick(tag)}
                className='tag'
              >
                {tag.length > 15 ? `${tag.slice(0, 15)}...` : tag}
              </div>
            ))}
          </div>
        </Form.Item>
        {isQCXS && (
          <Form.Item
            label='审批人员'
            name='approveUser'
            rules={[{ required: true, message: '请选择审批人员' }]}
            initialValue={localStorage.getItem('meeting-approve-user')}
          >
            <ApproveUserSelector auto={true} isSave={true} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default ApprovalModal
