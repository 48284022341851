import React, { useState, useEffect } from 'react'
import omit from 'lodash.omit'
import { searchFields, useTableColumns, FieldTypes, excelHead } from './data'
import { useHrMeetingListQuery, useHrMeetingExportQuery } from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { generateExport } from '../../components/UploadBatch'
import Modal from 'antd/lib/modal/Modal'
import { message, Spin } from 'antd'
import { getData } from '../../lib/getExportList'
import { useSearchStore } from '../../hooks/pageSearch'

const MeetingManage: QCPage = () => {
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[window.location.pathname] }
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const [isExporting, setExporting] = useState(false)
  const [curRegionId, setCurRegionId] = useState<string | undefined>(
    searchParams?.region
  )
  const [curCompanyId, setCurCompanyId] = useState<string | undefined>(
    searchParams?.companyId
  )
  const [curActivityTypes, setCurActivityTypes] = useState<
    Array<any> | undefined
  >(searchParams?.activityTypes)
  const [curMeetingType, setCurMeetingType] = useState<string | undefined>(
    searchParams?.meetingType
  )

  const queryVariable = {
    ...omit(table.searchParams, ['date', 'region', 'activityTypes']),
    ...(!curActivityTypes?.length || curMeetingType === 'ACADEMIC_CONFERENCE'
      ? {}
      : { activityTypes: curActivityTypes }),
    ...(!curRegionId ? {} : { region: curRegionId }),
    startTime:
      (table.searchParams?.date?.[0]?.startOf('day').unix() || 0) * 1000 ||
      undefined,
    endTime:
      (table.searchParams?.date?.[1]?.endOf('day').unix() || 0) * 1000 ||
      undefined
  }

  const [{ data, error, fetching }, refetchMeetingList] = useHrMeetingListQuery(
    {
      variables: {
        isFinanceAdmin: true,
        pageQuery: {
          pager: omit(table.pager, 'total'),
          query: {
            ...queryVariable
          }
        }
      }
    }
  )

  // 需求确认，导出字段和会议列表页面一致
  const [{ data: exportResult, error: exportErr }] = useHrMeetingExportQuery({
    variables: {
      isFinanceAdmin: true,
      pageQuery: {
        pager: {
          currentPage: 1,
          pageSize: 500
        },
        query: {
          ...queryVariable
        }
      }
    },
    pause: !isExporting
  })

  const tableColumns = useTableColumns()

  const onExportHandle = () => {
    setExporting(true)

    if (exportErr) {
      setExporting(false)
      message.error('导出失败')
      return
    }
  }

  const concatAttenders = (source: any[]) => {
    source.forEach((element) => {
      element.speakers = element.speakers.concat(element.attenders)
    })

    return source
  }

  useEffect(() => {
    if (isExporting) {
      const dataResult = getData(
        concatAttenders(exportResult?.meetings?.items || []),
        excelHead()
      )
      generateExport(
        dataResult,
        `会议管理_${new Date().getTime()}`,
        5000,
        (err) => {
          setExporting(false)
          if (err) {
            message.error('导出失败')
          } else {
            message.success('EXCEL下载成功，请查收')
          }
        }
      )
    }
  }, [exportResult])

  // 省区选项改变，手动触发状态更改
  const onRegionChange = (value: string) => {
    setCurRegionId(value)
  }
  // 企业选项改变，省区置空，待重新选择
  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
    setCurRegionId(undefined)
    setCurMeetingType(undefined)
    setCurActivityTypes(undefined)
  }
  // 活动类型选项改变
  const onActivityTypesChange = (value: Array<any>) => {
    setCurActivityTypes(value)
  }

  // 会议类型选项改变
  const onMeetingTypeChange = (value: string) => {
    setCurActivityTypes(undefined)
    setCurMeetingType(value)
  }

  // table 重置时清空公司和会议类型状态
  const onTableReset = () => {
    setCurMeetingType(undefined)
    setCurCompanyId(undefined)
    setCurActivityTypes(undefined)
    setCurRegionId(undefined)
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchMeetingList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        searchProps={{
          fields: searchFields(
            curCompanyId,
            curMeetingType,
            onRegionChange,
            onCompanyChange,
            onActivityTypesChange,
            onMeetingTypeChange
          )
        }}
        bordered
        loading={fetching}
        pager={data?.meetings?.pager}
        dataSource={data?.meetings?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
        showExportBtn
        onExport={onExportHandle}
        onReset={onTableReset}
      />

      <Modal
        visible={!!isExporting}
        footer={null}
        maskClosable={false}
        closable={false}
        width={400}
      >
        <Spin tip='正在为您生成EXCEL文件，请不要关闭此网页…' />
      </Modal>
    </main>
  )
}

MeetingManage.pageTitle = '会议管理'

export default MeetingManage
