import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { createClient, Provider, dedupExchange, fetchExchange } from 'urql'
import 'antd/dist/antd.less'
import zhCN from 'antd/es/locale/zh_CN'

import App from './App'
import { StoreProvider } from './hooks/user'
import { PageStoreProvider } from './hooks/pageSearch'
import { ConfigProvider } from 'antd'
import { isMobileClient } from './constant/user'

const client = createClient({
  url: process.env.GRAPHQL_URL || 'http://localhost:4000/graphql',
  fetchOptions: () => {
    return {
      headers: {
        token: localStorage.getItem('bms-login-token') || '',
        hrpro: 'QC-DEMO'
      }
    }
  },
  exchanges: [
    dedupExchange,
    // NOTE: Leave out to disable caching:
    // cacheExchange
    fetchExchange
  ]
})

const isMobile = isMobileClient()

ReactDOM.render(
  <ConfigProvider
    locale={zhCN}
    autoInsertSpaceInButton={!isMobile}
    componentSize={isMobile ? 'small' : undefined}
  >
    <StoreProvider>
      <PageStoreProvider>
        <Provider value={client}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </PageStoreProvider>
    </StoreProvider>
  </ConfigProvider>,
  document.getElementById('app')
)
