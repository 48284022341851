import React from 'react'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import { ColumnsType } from 'antd/lib/table'
import { Descriptions, Row, Col, Card } from 'antd'
import { useLocation } from 'react-router-dom'

type FieldTypes = {
  cityId?: string
}

const BalanceDetail: QCPage = () => {
  // 拿到页面参数
  const { state }: any = useLocation()

  const detail = state?.item
  const lists = detail?.recordInfo?.map((list: object, index: any) => {
    return {
      ...list,
      id: `row_${index}`
    }
  })

  // 只有一个省区的搜索字段
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })

  const tableColumns: ColumnsType = [
    {
      title: '预付时间',
      dataIndex: 'paymentTime',
      align: 'center',
      key: 'paymentTime',
      width: '40%'
    },
    {
      title: '预付金额',
      dataIndex: 'amount',
      align: 'center',
      key: 'amount',
      render(v) {
        return Number(parseFloat(v).toFixed(2)).toLocaleString()
      }
    }
  ]

  return (
    <main>
      <Card title={detail?.regionInfo?.name || ''}>
        <Descriptions column={4}>
          <Descriptions.Item label='总预付金额'>
            {detail?.totalPrepaid &&
              Number(
                parseFloat(detail.totalPrepaid).toFixed(2)
              ).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label='已使用金额'>
            {detail?.usedFee &&
              Number(parseFloat(detail.usedFee).toFixed(2)).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label='冻结金额'>
            {detail?.frozenFee &&
              Number(parseFloat(detail.frozenFee).toFixed(2)).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label='余额'>
            {detail?.balance &&
              Number(parseFloat(detail.balance).toFixed(2)).toLocaleString()}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card style={{ marginTop: '20px' }} title={'预付金额登记记录'}>
        <Row>
          <Col span={16}>
            <SearchTable
              table={table}
              bordered
              showPanel={false}
              showPagination={false}
              dataSource={lists || []}
              size='middle'
              rowKey='id'
              columns={tableColumns}
              showOperaBtn={false}
            />
          </Col>
        </Row>
      </Card>
    </main>
  )
}

BalanceDetail.pageTitle = '余额明细'

export default BalanceDetail
