import _ from 'lodash'

// 表格头替换为后端所需字段
export const maps: { [key: string]: any } = {
  会议ID: 'meetingId',
  老师的用户ID: 'attenderId',
  开票日期: 'billTime'
}

// 模版字段
export const template = [
  {
    会议ID: 111111,
    老师的用户ID: 2222222,
    开票日期: '2020/11/28'
  }
]

// 校验表格数据合法性
export const checkValidate = (
  fileLists: {
    会议ID?: any
    老师的用户ID?: any
    开票日期?: any
  }[]
) => {
  let res = {
    success: true,
    message: ''
  }

  const lists = _.cloneDeep(fileLists)

  // 校验是否为空文件
  if (!lists?.length) {
    res = {
      success: false,
      message: '文件不能为空'
    }
    return res
  }

  // 校验表格头字段是否正确
  const head = Object.keys(template[0])

  const headerNoCorrect = Object.keys(lists[0]).findIndex((item) => {
    return head.indexOf(item) < 0
  })
  if (headerNoCorrect > -1) {
    res = {
      success: false,
      message: '数据不正确，请检查数据'
    }
    return res
  }

  // 是否存在空单元格
  const hasBlankRow = lists?.findIndex((list) => {
    return Object.keys(list).length < 3
  })
  if (hasBlankRow > -1) {
    res = {
      success: false,
      message: '数据不正确，请检查数据'
    }
    return res
  }

  // 时间是否合法
  const hasIllegalTimeRow = lists?.findIndex((list) => {
    let dateValue = list['开票日期']
    // 如果单元格不是时间格式
    if (dateValue instanceof Date === false) {
      // 先转换成时间对象
      try {
        dateValue = new Date(dateValue)
      } catch (e) {
        console.log(e)
        return 1
      }
    }
    return (dateValue instanceof Date && !isNaN(dateValue.getTime())) === false
  })
  if (hasIllegalTimeRow > -1) {
    res = {
      success: false,
      message: '数据不正确，请检查数据'
    }
    return res
  }

  return res
}
