import React, { FC, useState } from 'react'
import { Modal, Input, Form, Divider } from 'antd'
import { FormProps } from 'antd/lib/form'

const reasons = ['合规部要求禁用']

const DisabledDoctorModal: FC<{
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
}> = ({ onCancel, onSubmit }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const tagOnClick = (tag: string) => {
    const prev = form.getFieldValue('reason') || ''
    form.setFieldsValue({
      reason: prev + tag,
      checkReason: prev + tag
    })
  }

  const onValuesChange: FormProps['onValuesChange'] = (value, values) => {
    const changeField = Object.keys(value)[0]
    if (changeField === 'reason') {
      form.setFieldsValue({
        checkReason: value.reason
      })
      if (!value.reason) {
        form.setFields([
          {
            name: 'checkReason',
            errors: ['请输入禁用理由']
          }
        ])
      }
    }
  }

  const onFinish = (values: any) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  return (
    <Modal
      maskClosable={false}
      title='禁用老师'
      visible
      onCancel={onCancel}
      centered
      closable={false}
      destroyOnClose
      onOk={() => {
        form.submit()
      }}
      okText='确认'
      confirmLoading={loading}
    >
      <Form
        preserve={false}
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item name='reason' noStyle>
          <Input.TextArea
            maxLength={40}
            placeholder='请输入禁用理由'
            autoSize={{ minRows: 4, maxRows: 6 }}
            style={{ marginBottom: '10px' }}
          />
        </Form.Item>
        <Form.Item
          name='checkReason'
          rules={[{ required: true, message: '请输入禁用理由' }]}
        >
          <div className='tagsContainer'>
            {reasons.map((tag, index) => (
              <div
                title={tag}
                key={index}
                onClick={() => tagOnClick(tag)}
                className='tag'
              >
                {tag.length > 15 ? `${tag.slice(0, 15)}...` : tag}
              </div>
            ))}
          </div>
        </Form.Item>
      </Form>
      <Divider style={{ margin: '12px 0' }} />
      确认将老师禁用，并将老师“结算中“之前的订单都删除，并归还预算？
    </Modal>
  )
}

export default DisabledDoctorModal
