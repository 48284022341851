import React, { useState, useEffect } from 'react'
import omit from 'lodash.omit'
import { searchFields, useTableColumns, FieldTypes } from './data'
import {
  useQueryDocumentsReviewsQuery,
  QueryDocumentsReviewsQuery,
  useReviewContentQuery,
  // usePayReviewMutation,
  useStartSettlementMutation
} from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import Modal from 'antd/lib/modal/Modal'
import { message, Spin, Space } from 'antd'
import { REVIEW_STATUS_MAP } from '../../constant/hrradiation'
import { useUserStore } from '../../hooks/user'

const divStyle = {
  height: 77,
  background: 'rgb(245, 246, 250)',
  borderRadius: '4px',
  width: '100%',
  color: '#666666',
  padding: '10px',
  lineHeight: '1.5',
  overflow: 'auto'
}

const LiteratureReview: QCPage = () => {
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const userStore = useUserStore()
  const searchParams = table.searchParams

  const [reviewModalShow, setReviewModalShow] = useState(false)
  const [settlementConfirmShow, setSettlementConfirmShow] = useState(false)
  const [curReviewInfo, setCurReviewInfo] = useState(
    {} as QueryDocumentsReviewsQuery['reviews']['items'][0]
  )

  const queryVariable = {
    ...omit(searchParams, ['documentCategoryIds', 'documentReviewStatus']),
    ...(searchParams?.documentCategoryIds
      ? { documentCategoryIds: [searchParams?.documentCategoryIds] }
      : {}),
    ...(searchParams?.documentReviewStatus
      ? {
          documentReviewStatus: [
            ...searchParams?.documentReviewStatus,
            // 点评状态选择已评审时，点评状态需要额外添加 REJECT 的状态id
            ...(searchParams.documentReviewStatus.includes(
              REVIEW_STATUS_MAP.HAS_SCORE.id
            )
              ? [REVIEW_STATUS_MAP.REJECT.id]
              : [])
          ]
        }
      : {})
  }

  const [{ fetching: settlementFetching }, startSettlementMutation] =
    useStartSettlementMutation()

  // 查询点评内容
  const [
    {
      data: reviewContentData,
      error: reviewContentError,
      fetching: reviewContentFetching
    }
  ] = useReviewContentQuery({
    variables: {
      reviewId: curReviewInfo?.id || ''
    },
    pause: !curReviewInfo?.id
  })

  const [{ data, error, fetching }, refetchReviews] =
    useQueryDocumentsReviewsQuery({
      variables: {
        search: {
          pager: omit(table.pager, 'total'),
          query: { ...queryVariable }
        }
      }
    })

  // 查看点评详情
  const openReviewDetail = (
    record: QueryDocumentsReviewsQuery['reviews']['items'][0]
  ) => {
    setReviewModalShow(true)
    setCurReviewInfo(record)
  }

  // 开始结算
  const startSettlement = async (
    curReviewInfo: QueryDocumentsReviewsQuery['reviews']['items'][0]
  ) => {
    const result = await startSettlementMutation({
      reviewId: curReviewInfo?.id || ''
    })

    if (!result?.data?.result) {
      message.error(result?.error?.message || '发起结算失败')
    } else {
      message.success('发起结算成功')
      setSettlementConfirmShow(false)
      refetchReviews()
    }
  }

  const tableColumns = useTableColumns(
    openReviewDetail,
    (record) => {
      setCurReviewInfo(record)
      setSettlementConfirmShow(true)
    },
    userStore.hasAuth('uplatform_medical_literature_settlement_opt')
  )

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchReviews}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        scroll={{ x: 1500 }}
        table={table}
        searchProps={{ fields: searchFields }}
        bordered
        loading={fetching}
        pager={data?.reviews?.pager}
        dataSource={data?.reviews?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
      />

      {/* 评审 */}
      {reviewModalShow && (
        <Modal
          visible
          maskClosable={false}
          destroyOnClose
          title={'查看点评'}
          onCancel={() => setReviewModalShow(false)}
          centered
          footer={null}
          width={600}
        >
          <Spin spinning={reviewContentFetching}>
            <Space
              direction='vertical'
              size={35}
              style={{ marginBottom: 36, width: '100%' }}
            >
              <Space direction='vertical' size={8}>
                <span style={{ color: '#888' }}>
                  文献名称：
                  {curReviewInfo?.documentInfo?.archive &&
                  curReviewInfo.documentInfo.archive[0].signedUrl ? (
                    <a
                      target='__blank'
                      href={curReviewInfo.documentInfo.archive[0].signedUrl}
                    >
                      {curReviewInfo.documentInfo?.document?.title}
                    </a>
                  ) : (
                    <span> {curReviewInfo?.documentInfo?.document?.title}</span>
                  )}
                </span>
                <Space size={40}>
                  <span style={{ color: '#888' }}>
                    点评老师：
                    <span style={{ color: '#181818' }}>
                      {curReviewInfo?.name}
                    </span>
                  </span>
                  <span style={{ color: '#888' }}>
                    邀请人（销售）：
                    <span style={{ color: '#181818' }}>
                      {curReviewInfo?.inviterName}
                    </span>
                  </span>
                </Space>
              </Space>

              <Space direction='vertical' size={22} style={{ width: '100%' }}>
                {reviewContentData?.result?.map((review, index) => (
                  <Space
                    key={index}
                    direction='vertical'
                    style={{ width: '100%' }}
                  >
                    <span style={{ color: '#888' }}>{review.key}</span>
                    <div style={divStyle}>{review.value}</div>
                  </Space>
                ))}
              </Space>
            </Space>
          </Spin>
        </Modal>
      )}

      {/* 发起结算确认 */}
      {settlementConfirmShow && (
        <Modal
          width={350}
          confirmLoading={settlementFetching}
          title='发起结算'
          visible
          onOk={() => startSettlement(curReviewInfo)}
          onCancel={() => setSettlementConfirmShow(false)}
          okText='确认'
          cancelText='取消'
        >
          确认发起结算？
        </Modal>
      )}
    </main>
  )
}

export default LiteratureReview
