import React from 'react'
import { Tabs } from 'antd'
import RegionManage from './regionManageOfFinance'
import RegionPermissionManage from './regionPermissionManageOfFinance'

const { TabPane } = Tabs

const RegionManageOfFinance: QCPage = () => {
  return (
    <main>
      <Tabs defaultActiveKey='1'>
        <TabPane tab='区域管理' key='1'>
          <RegionManage />
        </TabPane>

        <TabPane tab='区域权限管理' key='2'>
          <RegionPermissionManage />
        </TabPane>
      </Tabs>
    </main>
  )
}

RegionManageOfFinance.pageTitle = '区域/区域权限管理'

export default RegionManageOfFinance
