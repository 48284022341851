import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import get from 'lodash/get'
import uniq from 'lodash/uniq'

// 表头字断映射
const personalMaps: { [key: string]: string | number } = {
  '1月': '1',
  '2月': '2',
  '3月': '3',
  '4月': '4',
  '5月': '5',
  '6月': '6',
  '7月': '7',
  '8月': '8',
  '9月': '9',
  '10月': '10',
  '11月': '11',
  '12月': '12',
  业务部: 'bu',
  地区: 'area',
  大区: 'region',
  报销编号: 'financeNo',
  用户姓名: 'name',
  预算年份: 'year',
  操作说明: 'operationRemark'
}

const processMaps: { [key: string]: string | number } = {
  流程编号: 'number',
  预算金额: 'amount',
  操作说明: 'operationRemark'
}

// 个人日常预算表头字断映射
const personalPlanMaps: { [key: string]: string | number } = {
  '1月': '1',
  '2月': '2',
  '3月': '3',
  '4月': '4',
  '5月': '5',
  '6月': '6',
  '7月': '7',
  '8月': '8',
  '9月': '9',
  '10月': '10',
  '11月': '11',
  '12月': '12',
  报销编号: 'financeNo',
  用户姓名: 'name',
  预算年份: 'year',
  操作说明: 'operationRemark'
}

const costCenterMaps: { [key: string]: string | number } = {
  成本中心: 'costCenterName',
  预算年份: 'year',
  预算金额: 'amount',
  操作说明: 'operationRemark'
}

export const checkExcelValidate = (
  list: { [key: string]: string | number }[]
) => {
  let res = { success: true, message: '' }

  // 校验是否为空文件
  if (!list?.length) {
    res = { success: false, message: '文件不能为空' }
    return res
  }

  // 校验表格头字段是否正确
  const head = Object.keys(personalMaps)
  const headerNoCorrect = Object.keys(list[0]).findIndex(
    (item) => head.indexOf(item) < 0
  )
  if (headerNoCorrect > -1) {
    res = { success: false, message: '表头不正确，请检查数据' }
    return res
  }

  // 是否存在空单元格
  const hasBlankRow = list?.findIndex((item) => {
    return !item.报销编号 || !item.用户姓名
  })
  if (hasBlankRow > -1) {
    res = {
      success: false,
      message: `第 ${hasBlankRow + 2} 行：报销编号/姓名不能为空，请检查数据`
    }
    return res
  }

  // 是否存在重复的预算信息
  const spliceCells = list.map((item) => {
    return `${String(item.报销编号).trim()}${String(item.用户姓名).trim()}`
  })

  const uniqSpliceCells = uniq(spliceCells)
  if (uniqSpliceCells.length !== spliceCells.length) {
    res = {
      success: false,
      message: `导入的预算文件每次导入只支持每位员工一条预算信息导入`
    }
    return res
  }

  return res
}

export const checkProcessExcelValidate = (
  list: { [key: string]: string | number }[]
) => {
  let res = { success: true, message: '' }
  const head = Object.keys(processMaps)

  // 校验是否为空文件
  if (!list?.length) {
    res = { success: false, message: '文件不能为空' }
    return res
  }

  // 校验表格头字段是否正确
  const headerNoCorrect = Object.keys(list[0]).findIndex(
    (item) => head.indexOf(item) < 0
  )
  if (headerNoCorrect > -1) {
    res = { success: false, message: '表头不正确，请检查数据' }
    return res
  }

  // 是否存在重复的流程编号
  const uniqList = uniq(list?.map((i) => i.流程编号))
  if (uniqList.length !== list.length) {
    res = {
      success: false,
      message: `存在重复的流程编号，请检查数据`
    }
    return res
  }

  // 流程编号/金额是否为空
  const hasBlankRow = list?.findIndex((item) => {
    return !item.流程编号 || !item.预算金额
  })
  if (hasBlankRow > -1) {
    res = {
      success: false,
      message: `第 ${hasBlankRow + 2} 流程编号/预算金额不能为空，请检查数据`
    }
    return res
  }
  return res
}

export const checkPersonalPlanBudgetExcelValidate = (
  list: { [key: string]: string | number }[]
) => {
  let res = { success: true, message: '' }

  // 校验是否为空文件
  if (!list?.length) {
    res = { success: false, message: '文件不能为空' }
    return res
  }

  // 校验表格头字段是否正确
  const head = Object.keys(personalPlanMaps)
  const headerNoCorrect = Object.keys(list[0]).findIndex(
    (item) => head.indexOf(item) < 0
  )
  if (headerNoCorrect > -1) {
    res = { success: false, message: '表头不正确，请检查数据' }
    return res
  }

  // 是否存在空单元格
  const hasBlankRow = list?.findIndex((item) => {
    return !item.报销编号 || !item.用户姓名
  })
  if (hasBlankRow > -1) {
    res = {
      success: false,
      message: `第 ${hasBlankRow + 2} 行：报销编号/姓名不能为空，请检查数据`
    }
    return res
  }

  // 是否存在重复的预算信息
  const spliceCells = list.map((item) => {
    return `${String(item.报销编号).trim()}${String(item.用户姓名).trim()}`
  })

  const uniqSpliceCells = uniq(spliceCells)
  if (uniqSpliceCells.length !== spliceCells.length) {
    res = {
      success: false,
      message: `导入的预算文件每次导入只支持每位员工一条预算信息导入`
    }
    return res
  }

  return res
}

export const checkCostCenterBudgetExcelValidate = (
  list: { [key: string]: any }[]
) => {
  let res = { success: true, message: '' }

  // 校验是否为空文件
  if (!list?.length) {
    res = { success: false, message: '文件不能为空' }
    return res
  }

  // 校验表格头字段是否正确
  const head = Object.keys(costCenterMaps)
  const headerNoCorrect = Object.keys(list[0]).findIndex(
    (item) => head.indexOf(item) < 0
  )
  if (headerNoCorrect > -1) {
    res = { success: false, message: '导入文件字段不正确' }
    return res
  }

  // 是否存在空单元格
  const hasBlankRow = list?.findIndex((item) => {
    return (
      !item.成本中心 ||
      !item.预算年份 ||
      (item.预算金额 !== 0 && !item.预算金额)
    )
  })
  if (hasBlankRow > -1) {
    res = {
      success: false,
      message: `第 ${
        hasBlankRow + 2
      } 行：成本中心/预算年份/预算金额不能为空，请检查数据`
    }
    return res
  }

  const hasAmountRow = list?.findIndex((item) => {
    return isNaN(item.预算金额)
  })
  if (hasAmountRow > -1) {
    res = {
      success: false,
      message: `第 ${hasAmountRow + 2} 行：导入金额只能为数值`
    }
    return res
  }

  // 是否存在重复的预算信息
  const spliceCells = list.map((item) => {
    return `${String(item.成本中心).trim()}${String(item.预算年份).trim()}`
  })

  const uniqSpliceCells = uniq(spliceCells)
  if (uniqSpliceCells.length !== spliceCells.length) {
    res = {
      success: false,
      message: `导入的预算文件每次导入只支持一个成本中心一个预算年份一条预算信息导入`
    }
    return res
  }

  return res
}

export const columns: ColumnsType<any> = [
  // {
  //   align: 'center',
  //   title: '业务部',
  //   dataIndex: 'bu',
  //   key: 'bu',
  //   render(v: string) {
  //     return v || '-'
  //   }
  // },
  // {
  //   align: 'center',
  //   title: '大区',
  //   dataIndex: 'region',
  //   key: 'region',
  //   render(v: string) {
  //     return v || '-'
  //   }
  // },
  // {
  //   align: 'center',
  //   title: '地区',
  //   dataIndex: 'area',
  //   key: 'area',
  //   render(v: string) {
  //     return v || '-'
  //   }
  // },
  {
    align: 'center',
    title: '报销编号',
    dataIndex: 'financeNo',
    key: 'financeNo',
    render(v: string) {
      return v || '-'
    }
  },
  {
    align: 'center',
    title: '用户姓名',
    dataIndex: 'accountName',
    key: 'accountName',
    render(v: string) {
      return v || '-'
    }
  },
  {
    align: 'center',
    title: '预算年份',
    dataIndex: 'year',
    key: 'year',
    render(v: string) {
      return v || '-'
    }
  },
  {
    align: 'center',
    title: '预算月份',
    dataIndex: 'month',
    key: 'month',
    render(v: string) {
      return v || '-'
    }
  },
  {
    align: 'center',
    title: '金额',
    dataIndex: 'budget',
    key: 'budget',
    render(v: string) {
      return v || '-'
    }
  },
  {
    align: 'center',
    title: '操作说明',
    dataIndex: 'operationRemark',
    key: 'operationRemark',
    render(v: string) {
      return v || '-'
    }
  },
  {
    align: 'center',
    title: '报错信息',
    dataIndex: 'errorMsg',
    key: 'errorMsg',
    render(v: string) {
      return <span style={{ color: 'red' }}>{v || '-'}</span>
    }
  }
]

export const costCenterColumns: ColumnsType<any> = [
  {
    align: 'center',
    title: '成本中心',
    dataIndex: 'costCenterName',
    key: 'costCenterName',
    render(v: string) {
      return v || '-'
    }
  },
  {
    align: 'center',
    title: '预算年份',
    dataIndex: 'year',
    key: 'year',
    render(v: string) {
      return v || '-'
    }
  },
  {
    align: 'center',
    title: '预算金额',
    dataIndex: 'amount',
    key: 'amount',
    render(v: string) {
      return v || '-'
    }
  },
  {
    align: 'center',
    title: '操作说明',
    dataIndex: 'operationRemark',
    key: 'operationRemark',
    render(v: string) {
      return v || '-'
    }
  },
  {
    align: 'center',
    title: '报错信息',
    dataIndex: 'errorInfo',
    key: 'errorInfo',
    render(v: string) {
      return <span style={{ color: 'red' }}>{v || '-'}</span>
    }
  }
]

export const excelHead = [
  // { key: 'bu', path: 'bu', name: '业务部' },
  // { key: 'region', path: 'region', name: '大区' },
  // { key: 'area', path: 'area', name: '地区' },
  { key: 'financeNo', path: 'financeNo', name: '报销编号' },
  { key: 'accountName', path: 'accountName', name: '用户姓名' },
  { key: 'year', path: 'year', name: '预算年份' },
  { key: 'month', path: 'month', name: '预算月份' },
  { key: 'budget', path: 'budget', name: '预算金额' },
  { key: 'operationRemark', path: 'operationRemark', name: '操作说明' },
  { key: 'errorMsg', path: 'errorMsg', name: '报错信息' }
]

export const processExcelHead = [
  { key: 'budgetNumber', path: 'budgetNumber', name: '流程编号' },
  { key: 'amount', path: 'amount', name: '预算金额' },
  { key: 'operationRemark', path: 'operationRemark', name: '操作说明' },
  { key: 'msg', path: 'msg', name: '报错信息' }
]

export const costCenterExcelHead = [
  { key: 'costCenterName', path: 'costCenterName', name: '成本中心' },
  { key: 'year', path: 'year', name: '预算年份' },
  { key: 'amount', path: 'amount', name: '预算金额' },
  { key: 'operationRemark', path: 'operationRemark', name: '操作说明' },
  { key: 'errorInfo', path: 'errorInfo', name: '报错信息' }
]

export const getData = (data: any, isProcess?: boolean) => {
  let result = []

  result = data.map((item: any) => {
    const obj: any = {}
    const head = !isProcess ? excelHead : processExcelHead
    head.forEach((cell: any) => {
      const value = get(item, cell.path, '-')
      obj[cell.name] = value
    })
    return obj
  })

  return result
}

export const getCostCenterData = (data: any) => {
  let result = []

  result = data.map((item: any) => {
    const obj: any = {}
    costCenterExcelHead.forEach((cell: any) => {
      const value = get(item, cell.path, '-')
      obj[cell.name] = value
    })
    return obj
  })

  return result
}
