import React, { FC, useRef, useState } from 'react'
import { Button, message, Modal } from 'antd'
import { isVideoElement } from '../constant/user'
import {
  FilePdfOutlined,
  FilePptOutlined,
  FileWordOutlined
} from '@ant-design/icons'
import { useUserStore } from '../hooks/user'
import useAxios from 'axios-hooks'
import OSS from 'ali-oss'

const docFileType = ['.doc', '.docx']
const pptFileType = ['.ppt', '.pptx']
const pdfFileType = ['.pdf']
const iconStyle = {
  fontSize: 80
}

interface STSServerResponse {
  SecurityToken: string
  AccessKeyId: string
  AccessKeySecret: string
  Expiration: string
}

const useOssClient = (bucket: string) => {
  const { token } = useUserStore()
  const [credentials, setCredentials] = useState<STSServerResponse | null>(null)
  const [, fetchSTS] = useAxios<STSServerResponse>(
    {
      url: process.env.STS_SERVICE,
      headers: {
        token
      }
    },
    { manual: true }
  )

  const ossClient = useRef<OSS | null>(null)

  const createOSSClient = (creds: STSServerResponse) => {
    const client = new OSS({
      region: 'oss-cn-shanghai',
      bucket,
      accessKeyId: creds.AccessKeyId,
      accessKeySecret: creds.AccessKeySecret,
      stsToken: creds.SecurityToken,
      timeout: 10 * 60 * 1000
    })
    ossClient.current = client
    return client
  }

  return async () => {
    const expired = credentials?.Expiration
      ? new Date().getTime() - new Date(credentials.Expiration).getTime() >= 0
      : true

    if (expired) {
      const result = await fetchSTS()
      const data = result?.data || result
      setCredentials(data)
      return createOSSClient(data)
    }
    return ossClient.current
  }
}

export type DocumentPreviewButtonProps = {
  fileUrl: string
  bucket?: string
  dir?: string
  style?: React.CSSProperties
}
const DocumentPreviewButton: FC<DocumentPreviewButtonProps> = ({
  fileUrl = '',
  bucket = '',
  dir = '',
  style
}) => {
  const num = fileUrl.lastIndexOf('.')
  const fileType = fileUrl.substring(num).toLocaleLowerCase()
  const getOssClient = useOssClient(bucket)

  const handleOnClick = async () => {
    try {
      const forceHttpsFile = fileUrl.replace('http://', 'https://')
      if (pdfFileType.includes(fileType)) {
        window.open(forceHttpsFile)
        return
      }
      const client = await getOssClient()
      const signUrl = client?.signatureUrl(
        `${dir}/${decodeURIComponent(
          fileUrl.substring(fileUrl.lastIndexOf('/') + 1)
        )}`,
        {
          expires: 600,
          process: 'imm/previewdoc,copy_0'
        }
      )
      window.open(signUrl)
    } catch (e) {
      message.error('文档预览失败')
    }
  }

  return (
    <>
      <Button
        onClick={handleOnClick}
        style={style}
        icon={
          pdfFileType.includes(fileType) ? (
            <FilePdfOutlined style={iconStyle} />
          ) : pptFileType.includes(fileType) ? (
            <FilePptOutlined style={iconStyle} />
          ) : docFileType.includes(fileType) ? (
            <FileWordOutlined style={iconStyle} />
          ) : null
        }
      ></Button>
    </>
  )
}

export default DocumentPreviewButton
