import { useState } from 'react'
import { useInterval } from './common'

// 1: 准备计时
// 2: 计时中
// 3: 暂停计时
// 0: 计时结束，恢复初始状态
export const useCountDown = (count?: number, preCheck?: () => any) => {
  const initialCount = count || 60 // 初始化计数值
  const [status, setStatus] = useState(0)
  const [countNum, setCountNum] = useState(initialCount)

  useInterval(
    () => {
      if (countNum > 0) {
        setCountNum(countNum - 1)
      } else {
        setStatus(0)
        setCountNum(0)
      }
    },
    status === 2 ? 1000 : null
  )

  // 开始倒计时
  const startCount = async () => {
    setStatus(1)
    try {
      if (preCheck) {
        const isOk = await preCheck()
        if (isOk) {
          setStatus(2)
          setCountNum(initialCount)
        } else {
          setStatus(0)
        }
      } else {
        setStatus(2)
        setCountNum(initialCount)
      }
    } catch (err) {
      setStatus(0)
      console.log(err)
    }
  }

  return {
    count: countNum,
    status: status,
    startCount,
    clearCount() {
      setStatus(0)
      setCountNum(0)
    },
    stopCount() {
      setStatus(3)
    },
    resumeCount() {
      setStatus(2)
    }
  }
}
