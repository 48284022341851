import React, { FC, useState } from 'react'
import { Modal, Input, Form, InputNumber, Button, Popconfirm } from 'antd'
import BudgetDetailCard from './budgetDetailCard'
import { Rule } from 'antd/lib/form'
import { BudgetFormItems } from './data'

const BudgetAddSubtractModal: FC<{
  title: string
  budgetType: string
  budgetDetail: any
  modalWidth?: number
  onCancel: () => void
  onSubmit: (value: BudgetFormItems, callBack?: () => void) => Promise<void>
}> = ({ title, budgetType, budgetDetail, modalWidth, onCancel, onSubmit }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [addSubtractAmount, setAddSubtractAmount] = useState(0)
  const [popVisible, setPopVisible] = useState(false)

  const checkShowPopconfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setAddSubtractAmount(values.amount)
        setPopVisible(true)
      })
      .catch((errorInfo) => {
        setPopVisible(false)
      })
  }

  const checkValidate = (rule: Rule, value: any) => {
    if (value && value !== 0) {
      return Promise.resolve()
    } else {
      return Promise.reject('请输入增减金额')
    }
  }

  const onFinish = (values: BudgetFormItems) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  const onFieldsChange = (changedFields: any, allFields: any) => {
    setPopVisible(false)
  }

  return (
    <Modal
      maskClosable={false}
      title={<div style={{ textAlign: 'center' }}>{title}</div>}
      visible
      onCancel={onCancel}
      centered
      destroyOnClose
      width={modalWidth || 500}
      footer={
        <>
          <Button onClick={onCancel}>取消</Button>
          <Popconfirm
            placement='topRight'
            visible={popVisible}
            title={
              budgetType === 'process' ? (
                <span>
                  是否确认操作，流程预算
                  <span className='popTitleTag'>
                    {budgetDetail?.budgetNumber}
                  </span>
                  预算增减
                  <span className='popTitleTag'>
                    {addSubtractAmount > 0 ? '+' : ''}
                    {addSubtractAmount}
                  </span>
                  ？
                </span>
              ) : ['personal', 'inPlan', 'outPlan'].includes(budgetType) ? (
                <span>
                  是否确认操作，
                  <span className='popTitleTag'>
                    员工{budgetDetail?.accountName}
                  </span>
                  的<span className='popTitleTag'>{budgetDetail?.month}</span>
                  月份
                  {budgetType === 'personal'
                    ? '个人'
                    : budgetType === 'inPlan'
                    ? '日常'
                    : '专项'}
                  预算增减
                  <span className='popTitleTag'>
                    {addSubtractAmount > 0 ? '+' : ''}
                    {addSubtractAmount}
                  </span>
                  ？
                </span>
              ) : budgetType === 'costCenter' ? (
                <span>
                  是否确认操作，成本中心
                  <span className='popTitleTag'>{budgetDetail?.groupName}</span>
                  预算增减
                  <span className='popTitleTag'>
                    {addSubtractAmount > 0 ? '+' : ''}
                    {addSubtractAmount}
                  </span>
                  ？
                </span>
              ) : null
            }
            onConfirm={() => {
              setPopVisible(false)
              form.submit()
            }}
            onCancel={() => {
              setPopVisible(false)
            }}
          >
            <Button
              type='primary'
              loading={loading}
              onClick={checkShowPopconfirm}
            >
              提交
            </Button>
          </Popconfirm>
        </>
      }
      confirmLoading={loading}
    >
      <BudgetDetailCard
        data={budgetDetail}
        budgetType={budgetType}
        bodyStyle={{ padding: 0, paddingLeft: 10 }}
        fetching={false}
        includeExtraOperation={false}
        hideLabel={false}
        hideBasicTitle={true}
      />
      <Form
        form={form}
        name='processBudgetAddSubtractForm'
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          label='增减金额'
          name='amount'
          rules={[
            // { validator: checkValidate },
            {
              required: true,
              message: '请输入增减金额'
            }
          ]}
          required
          className='amount'
        >
          <InputNumber
            placeholder='请输入增减金额'
            min={-100000000}
            max={100000000}
            precision={2}
            style={{ width: 250 }}
          />
        </Form.Item>
        <Form.Item
          label='操作说明'
          name='explain'
          className='explain'
          validateFirst={true}
          rules={[
            {
              max: 20,
              message: '超出最大长度，最多20个中文字'
            },
            {
              pattern: /^[\u4E00-\u9FA5]+$/,
              message: '只能输入中文'
            }
          ]}
        >
          <Input.TextArea
            rows={2}
            placeholder='请输入操作说明，限制20个中文字'
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default BudgetAddSubtractModal
