import React from 'react'
import { Input } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import dayjs, { Dayjs } from 'dayjs'
import { sumBy } from 'lodash'
import {
  HRRegionSelector,
  HRMeetingStatusSelector,
  HRVendorsSelector,
  HRCompanySelector,
  ActivitiesTypeSelector,
  MeetingTypeSelector
} from '../../components/SelectorCollection'
import DatePicker from '../../components/CustomDatePicker'
import {
  HrMeetingListQuery,
  StandardMeetingServiceBizStatus
} from '../../graphql'

//搜索字段声明
export const searchFields = (
  companyId: string | undefined,
  meetingType: string | undefined,
  onRegionChange: any,
  onCompanyChange: any,
  onActivityTypesChange: any,
  onMeetingTypeChange: any
) => [
  {
    name: 'companyId',
    label: '企业',
    grow: true,
    render() {
      return (
        <HRCompanySelector
          onChange={onCompanyChange}
          style={{ minWidth: 120 }}
        />
      )
    }
  },
  {
    name: 'meetingType',
    label: '会议类型',
    grow: true,
    render() {
      return (
        <MeetingTypeSelector
          filterByCompanyId
          companyId={companyId}
          disabled={!companyId}
          auto={true}
          onChange={onMeetingTypeChange}
          style={{ minWidth: 120 }}
        />
      )
    }
  },
  {
    name: 'activityTypes',
    label: '活动类型',
    grow: true,
    render() {
      return (
        <ActivitiesTypeSelector
          companyId={companyId}
          auto={true}
          filterByMeetingType
          disabled={!meetingType}
          meetingType={meetingType}
          onChange={onActivityTypesChange}
          style={{ minWidth: 160 }}
        />
      )
    }
  },
  {
    name: 'region',
    label: '省区',
    grow: true,
    render() {
      return (
        <HRRegionSelector
          disabled={!companyId}
          auto={true}
          companyId={companyId}
          onChange={onRegionChange}
          style={{ minWidth: 120 }}
        />
      )
    }
  },
  {
    name: 'serviceBizStatus',
    label: '会议状态',
    grow: true,
    render() {
      return <HRMeetingStatusSelector style={{ minWidth: 120 }} />
    }
  },
  {
    name: 'date',
    label: '会议日期',
    grow: true,
    render() {
      return <DatePicker.RangePicker locale={locale} format='YYYY-MM-DD' />
    }
  },
  {
    name: 'vendor',
    label: '项目服务商',
    grow: true,
    render() {
      return <HRVendorsSelector style={{ minWidth: 120 }} />
    }
  },
  {
    name: 'keyword',
    label: null,
    span: 12,
    render() {
      return (
        <Input allowClear placeholder='请输入会议ID/名称、申请人名字搜索' />
      )
    }
  }
]

export const useTableColumns = (): ColumnsType<
  HrMeetingListQuery['meetings']['items'][0]
> => {
  return [
    {
      title: '会议ID',
      dataIndex: 'base',
      key: 'id',
      render(v, meeting) {
        return <Link to={`/meeting/${meeting?.base?.id}`}>{v?.id}</Link>
      }
    },
    {
      title: '企业',
      dataIndex: 'base',
      key: 'company',
      render(v) {
        return v?.owner?.company?.cnName
      }
    },
    {
      title: '会议类型',
      dataIndex: 'meetingType',
      key: 'meetingType'
    },
    {
      title: '活动类型',
      dataIndex: 'activityType',
      key: 'activityType'
    },
    {
      title: '会议名称',
      dataIndex: 'base',
      key: 'name',
      render(v) {
        return v?.name
      }
    },
    {
      title: '会议城市',
      dataIndex: 'city',
      align: 'center',
      key: 'name',
      render(v) {
        return v?.name
      }
    },
    {
      title: '会议开始时间',
      dataIndex: 'base',
      key: 'startTime',
      render(v) {
        return dayjs(v?.startTime).format('YYYY-MM-DD')
      }
    },
    {
      title: '会议结束时间',
      dataIndex: 'base',
      key: 'endTime',
      render(v) {
        return dayjs(v?.endTime).format('YYYY-MM-DD')
      }
    },
    {
      title: '老师数量',
      dataIndex: 'speakers',
      key: 'speakers',
      render(v) {
        return v?.length
      }
    },
    {
      title: '总积分',
      dataIndex: 'speakers',
      key: 'speakerFees',
      render(v) {
        return `${sumBy(v, (d: any) => parseFloat(d?.attenderFee))}`
      }
    },
    {
      title: '创建时间',
      dataIndex: 'base',
      key: 'applyTime',
      render(v) {
        return dayjs(v?.createTime).format('YYYY-MM-DD')
      }
    },
    {
      title: '申请人',
      dataIndex: 'base',
      key: 'ownerName',
      render(v) {
        return v?.owner?.cnName
      }
    },
    {
      title: '申请人电话',
      dataIndex: 'base',
      key: 'ownerMobile',
      render(v) {
        return v?.owner?.companyMobile
      }
    },
    {
      title: '申请人省区',
      dataIndex: 'mettingRegion',
      key: 'ownerRegion',
      render(v) {
        return v?.name
      }
    },
    {
      title: '成本中心',
      dataIndex: 'costcenter',
      key: 'costcenter',
      render(v) {
        return v?.name
      }
    },
    {
      title: '项目服务商',
      dataIndex: 'vendor',
      key: 'vendor',
      render(v: any) {
        return v?.cnName
      }
    },
    {
      title: '会议状态',
      dataIndex: 'statusExtra',
      key: 'statusExtra',
      align: 'center',
      render(v, meeting) {
        return meeting?.status ===
          StandardMeetingServiceBizStatus.MeetingInApproving
          ? v || meeting?.statusText
          : meeting?.statusText
      }
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render(_, meeting) {
        return <Link to={`/meeting/${meeting?.base?.id}`}>查看</Link>
      }
    }
  ]
}

export type FieldTypes = {
  companyId?: string
  meetingType?: string | undefined
  activityTypes?: string[]
  region?: string
  serviceBizStatus?: StandardMeetingServiceBizStatus[] | undefined
  date?: [Dayjs | undefined, Dayjs | undefined]
  vendor?: string
  keyword?: string
}

// 表格导出字段
export const excelHead = () => [
  {
    key: 'attenderId',
    path: 'speakers[0].attenderId',
    name: '讲师ID'
  },
  {
    key: 'speakerName',
    path: 'speakers[0].cnName',
    name: '讲师姓名'
  }, // 讲师姓名
  { key: 'hospital', path: 'speakers[0].attenderInfo.hospital', name: '单位' }, //单位
  {
    key: 'companyMobile',
    path: 'speakers[0].companyMobile',
    name: '手机'
  }, //手机
  {
    key: 'contractStatus',
    path: 'speakers[0].attenderStatus',
    name: '签约状态'
  }, //签约状态
  { key: 'fee', path: 'speakers[0].attenderFee', name: '费用' }, //费用
  { key: 'serviceFee', path: 'speakers[0].serviceFee', name: '服务费' }, // 服务费
  { key: 'totalFee', path: 'speakers[0].totalFee', name: '总费用' }, // 总费用
  { key: 'id', path: 'baseData.id', name: '会议ID' }, //会议ID
  { key: 'meetingName', path: 'baseData.name', name: '会议名称' }, // 会议名称
  { key: 'cnName', path: 'baseData.owner.cnName', name: '申请人' }, //申请人
  { key: 'startTime', path: 'baseData.startTime', name: '会议开始日期' }, //开始日期
  { key: 'endTime', path: 'baseData.endTime', name: '会议结束日期' }, //结束日期
  { key: 'attenderId', path: 'speakers[0].id', name: '订单ID' }, // 订单id
  { key: 'statusText', path: 'statusText', name: '会议状态' }, //会议状态
  { key: 'regionName', path: 'region.name', name: '大区' }, //大区
  {
    key: 'legalEntity',
    path: 'baseData.owner.legalEntity.cnName',
    name: '法律实体'
  }, //法律实体
  { key: 'costCenter', path: 'costCenter.name', name: '成本中心' }, //成本中心
  {
    key: 'attenderSettlementTime',
    path: 'speakers[0].attenderSettlementTime',
    name: '结算时间'
  }, //结算时间
  { key: 'type', path: 'meetingType', name: '会议类型' }, //会议类型
  { key: 'billStatus', path: 'speakers[0].billStatus', name: '开票状态' }, //开票状态
  { key: 'billTime', path: 'speakers[0].billTime', name: '开票日期' }, //开票日期
  { key: 'vendor', path: 'vendor.cnName', name: '项目服务商' } // 项目服务商
]
