import React, { useState, FC, useEffect } from 'react'
import {
  notification,
  Modal,
  Form,
  Select,
  Input,
  Button,
  Card,
  Tree,
  Spin,
  Row,
  Col,
  Descriptions
} from 'antd'

import { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import {
  useUpadminCompaniesQuery,
  useUpdateCompanyGroupMutation,
  useQueryCompanyRegionsQuery
} from '../../graphql'
import { useSearchStore } from '../../hooks/pageSearch'
import SearchPanel from '../../components/SearchPanel'
import { treeDataFormat } from './data'
import './index.less'
import { ProvinceSelector } from '../../components/SelectorCollection'

const tabKey = 'regionManageOfFinance'
const RegionManageOfFinance: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[pageKey] }
  const [form] = Form.useForm()
  const table = useSearchTable<any>({}, { pageSize: 15, currentPage: 1 })
  const [curCompanyId, setCurCompanyId] = useState(searchParams?.companyId)
  const [curRegion, setCurRegion] = useState<any>(null)
  const [isAdd, setIsAdd] = useState(false)
  const [modalShow, updateModalShow] = useState(false)
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [autoExpandParent, setAutoExpandParent] = useState(true)

  const [{ data: selectOptions }] = useUpadminCompaniesQuery()
  const [{ fetching: bindLoading }, createRegion] =
    useUpdateCompanyGroupMutation()

  const [{ data, error, fetching }, refetch] = useQueryCompanyRegionsQuery({
    variables: {
      companyId: curCompanyId || selectOptions?.companies[0]?.id,
      type: 'region'
    },
    pause: !selectOptions?.companies?.length
  })

  const onCompanyChange = (value: string) => {
    setSearchValue('')
    setCurRegion(null)
    setCurCompanyId(value)
  }

  const onConfirm = async () => {
    try {
      const values = await form.validateFields()
      const { regionName, parentRegionId, province } = values

      const result = await createRegion({
        group: {
          groupId: isAdd ? undefined : curRegion?.groupId,
          companyId: curCompanyId,
          name: regionName,
          pid: isAdd ? parentRegionId : curRegion?.pid,
          type: 'region',
          province
        }
      })
      if (!result.data?.result?.success) {
        notification.error({
          message: `${isAdd ? '新建' : '编辑'}区域失败`,
          description: result.data?.result?.message
        })
      } else {
        notification.success({
          message: `${isAdd ? '新建' : '编辑'}区域成功`
        })
        updateModalShow(false)
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  // 自动查询第一个企业的数据
  useEffect(() => {
    if (!curCompanyId && selectOptions?.companies[0]?.id) {
      table.updateSearchParams(
        {
          companyId: selectOptions?.companies[0]?.id
        },
        undefined,
        pageKey
      )
      setCurCompanyId(selectOptions?.companies[0]?.id)
    }
  }, [JSON.stringify(selectOptions?.companies)])

  const onTreeSelect = (selectedKeys: any, e: any) => {
    setCurRegion(e.node)
  }

  useEffect(() => {
    if (!fetching && curRegion) {
      const region = data?.regions?.find(
        (x: any) => x.groupId === curRegion.groupId
      )
      setCurRegion(region)
    }
  }, [data])

  useEffect(() => {
    if (modalShow && !isAdd) {
      form.setFieldsValue({
        regionName: curRegion?.name,
        province: curRegion?.provinceId ? curRegion?.provinceId.split(',') : []
      })
    }
  }, [curRegion, isAdd, modalShow])

  const onAddClick = () => {
    setIsAdd(true)
    updateModalShow(true)
  }

  const onEditClick = () => {
    setIsAdd(false)
    updateModalShow(true)
  }

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys)
    setAutoExpandParent(false)
  }

  const onChange = (e: any) => {
    const { value } = e.target
    const newExpandedKeys = data?.regions
      ?.map((item: any) => {
        if (item.name.indexOf(value) > -1) {
          return item.pid
        }
        return null
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)
    setExpandedKeys(newExpandedKeys!)
    setSearchValue(value)
    setAutoExpandParent(true)
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <Card>
        <SearchPanel
          fields={[
            {
              name: 'companyId',
              label: '企业',
              span: 8,
              defaultValue: selectOptions?.companies[0]?.id!,
              render() {
                return (
                  <Select
                    showSearch
                    placeholder='请选择企业'
                    optionFilterProp='children'
                    key={selectOptions?.companies[0]?.id || ''}
                    onChange={onCompanyChange}
                    defaultValue={selectOptions?.companies[0]?.id || ''}
                  >
                    {selectOptions?.companies.map((company, i) => (
                      <Select.Option
                        value={company?.id || ''}
                        key={company?.id || i}
                      >
                        {company.cnName || ''}
                      </Select.Option>
                    ))}
                  </Select>
                )
              }
            }
          ]}
          needSearchStore={true}
          onChange={(value) => {
            // 禁止关键词跟随输入实时查询
            table.updateSearchParams(value, undefined, pageKey)
          }}
          initialFormValues={searchParams}
        />
      </Card>
      <div
        className='regionContent'
        style={{
          minHeight: 400,
          padding: '30px 50px',
          textAlign: 'center',
          background: '#fff'
        }}
      >
        <Spin spinning={fetching}>
          {!fetching && data?.regions && data?.regions?.length > 0 && (
            <>
              <Row justify='space-between'>
                <Col span={10} style={{ textAlign: 'left' }}>
                  <Input.Search
                    style={{ marginBottom: 15, width: 250, marginLeft: 25 }}
                    placeholder='请输入区域名称进行搜索'
                    onChange={onChange}
                    value={searchValue}
                  />
                </Col>
                <Col span={14} style={{ textAlign: 'right' }}>
                  <Button
                    type='primary'
                    onClick={onAddClick}
                    style={{ marginBottom: 15 }}
                  >
                    新建区域
                  </Button>
                </Col>
              </Row>
              <Row justify='space-between'>
                <Col span={10}>
                  <Tree
                    height={400}
                    treeData={treeDataFormat(data?.regions, '', searchValue)}
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    // defaultExpandAll
                    selectedKeys={[curRegion?.groupId!]}
                    onSelect={onTreeSelect}
                  />
                </Col>
                <Col
                  span={14}
                  style={{
                    background: 'rgb(239,239,239)',
                    height: 400
                  }}
                >
                  {curRegion ? (
                    <Card
                      bordered={false}
                      bodyStyle={{ paddingTop: 0 }}
                      style={{ background: 'rgb(236,239,243)' }}
                      extra={
                        <Button type='link' onClick={onEditClick}>
                          编辑
                        </Button>
                      }
                    >
                      <Descriptions
                        column={2}
                        contentStyle={{ textAlign: 'left' }}
                      >
                        <Descriptions.Item label='区域名称'>
                          {curRegion?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label='区域所属省份'>
                          {curRegion?.province
                            ?.map((x: any) => x.name)
                            .join(',') || '-'}
                        </Descriptions.Item>
                      </Descriptions>
                    </Card>
                  ) : (
                    <span style={{ display: 'block', marginTop: 190 }}>
                      请选择区域
                    </span>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Spin>
      </div>
      <Modal
        maskClosable={false}
        title={`${isAdd ? '新建' : '编辑'}区域`}
        visible={modalShow}
        onOk={onConfirm}
        onCancel={() => updateModalShow(false)}
        okText='确认'
        cancelText='取消'
        confirmLoading={bindLoading}
        afterClose={() => {
          form.resetFields()
        }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} form={form}>
          <Form.Item
            label='区域名称'
            name='regionName'
            rules={[{ required: true, message: '请填写区域名称' }]}
          >
            <Input placeholder='请填写区域名称' allowClear />
          </Form.Item>
          {isAdd && (
            <Form.Item
              label='上级区域'
              name='parentRegionId'
              rules={[{ required: true, message: '请选择上级区域' }]}
            >
              <Select
                placeholder='请选择上级区域'
                showSearch
                allowClear
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {data?.regions?.map((x) => {
                  return (
                    <Select.Option key={x.groupId} value={x.groupId}>
                      {x.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item label='区域所属省份' name='province'>
            <ProvinceSelector mode='multiple' />
          </Form.Item>
        </Form>
      </Modal>
    </main>
  )
}

export default RegionManageOfFinance
