import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Input, Button, Skeleton, notification } from 'antd'
import { FrownOutlined } from '@ant-design/icons'

import {
  useGetArchiveSignedUrlQuery,
  useTranslateDocumentContentQuery
} from '../../graphql'
import ErrorView from '../../components/ErrorView'
import './index.less'

const LiteraturePreview: QCPage = () => {
  console.log(1)
  const { archiveId }: { archiveId: string } = useParams()
  const [selectContent, updateSelectContent] = useState('')
  const [translateContent, updateTranslateContent] = useState('')
  const [translatePause, updateTranslatePause] = useState(true)

  const [{ data, fetching, error }, refetchDetail] =
    useGetArchiveSignedUrlQuery({
      variables: {
        archiveId
      }
    })

  const [
    { data: translateData, error: translateError, fetching: translateFetching }
  ] = useTranslateDocumentContentQuery({
    variables: { text: selectContent },
    pause: translatePause
  })

  const onTranslate = () => {
    updateTranslatePause(false)
  }

  useEffect(() => {
    if (!translatePause) {
      if (translateError || !translateData?.result) {
        updateTranslateContent('')
        updateTranslatePause(true)
        notification.destroy()
        notification.open({
          message: '翻译失败',
          icon: <FrownOutlined style={{ color: '#ff4d4f' }} />,
          description: '',
          placement: 'topRight'
        })
        return
      }
      updateTranslateContent(translateData?.result || '')
    }
    updateTranslatePause(true)
  }, [translateData])

  window.addEventListener('message', (e) => {
    if (e.data.text) {
      updateSelectContent(e.data.text.substr(0, 800))
    }
  })

  if (fetching) {
    return <Skeleton active />
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error.name}
        message={error.message}
      />
    )
  }

  const url = (data?.result && encodeURIComponent(data.result)) || ''
  return (
    <Row style={{ height: '100%' }}>
      <Col span={16}>
        <iframe
          src={`${
            process.env.CDN_HOST || ''
          }/pdfviewer/viewer.html?file=${url}`}
          width='100%'
          height='100%'
          style={{ border: 'unset' }}
        ></iframe>
      </Col>

      <Col span={8}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: '100%',
            paddingLeft: 30,
            height: '100%'
          }}
        >
          <Input.TextArea
            className='reviewTextArea'
            allowClear
            showCount
            placeholder='请在PDF中选择需要翻译的文本'
            maxLength={800}
            style={{ width: '100%', height: 'calc( 50% - 38px )' }}
            value={selectContent}
            onChange={(e) => updateSelectContent(e.target.value)}
            // autoSize={{ minRows: 8 }}
          />
          <Button
            loading={translateFetching}
            disabled={translateFetching || !selectContent.length}
            type='primary'
            style={{ width: 100, margin: '20px 0' }}
            onClick={onTranslate}
          >
            翻译
          </Button>
          <Input.TextArea
            style={{ width: '100%', flex: 1, resize: 'none' }}
            value={translateContent}
            placeholder='翻译'
            // autoSize={{ minRows: 8 }}
          />
        </div>
      </Col>
    </Row>
  )
}

export default LiteraturePreview
