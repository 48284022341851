import React, { FC } from 'react'
import { Spin, Card, Row, Col, Empty } from 'antd'
import { useTaskLogByTaskIdQuery } from '../../graphql'
import _ from 'lodash'

export const OperationRecord: FC<{
  taskId: string
  style?: React.CSSProperties | undefined
}> = ({ taskId, style }) => {
  const [{ data, fetching }] = useTaskLogByTaskIdQuery({
    variables: {
      taskId
    },
    pause: !taskId
  })

  return (
    <Spin spinning={fetching}>
      <Card title='操作记录' style={style}>
        {data?.result?.length ? (
          _.sortBy(data.result, function (o) {
            return o.gmtCreated
          }).map((item, index) => {
            return (
              <Row key={index} style={{ marginTop: index > 0 ? 10 : 0 }}>
                <Col span={8}>任务退回</Col>
                <Col span={16}>{item.gmtCreated}</Col>
                <Col span={24} style={{ color: '#eb2525' }}>
                  退回理由：{item.rejectMsg}
                </Col>
              </Row>
            )
          })
        ) : (
          <Empty
            style={{ margin: 0 }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='暂无数据'
          />
        )}
      </Card>
    </Spin>
  )
}
