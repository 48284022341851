import React, { useState, FC } from 'react'
import {
  Modal,
  Form,
  Input,
  notification,
  Button,
  Card,
  Space,
  Switch,
  InputNumber
} from 'antd'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { usePlatFormTypeColumns } from './data'
import {
  MeetingPlatform,
  useAllConferencePlatformsQuery,
  useOperationConferencePlatformMutation
} from '../../graphql'

const PlatformType: FC = () => {
  const [form] = Form.useForm()
  const table = useSearchTable({})
  const [modalShow, updateModalShow] = useState(false)
  const [platformId, setPlatformId] = useState<string | null>(null)

  const [{ data, error, fetching }, refetch] = useAllConferencePlatformsQuery()
  const [{ fetching: modalLoading }, createOrUpdate] =
    useOperationConferencePlatformMutation()

  const onEdit = (platform: MeetingPlatform) => {
    form.setFieldsValue({
      ...platform
    })
    setPlatformId(platform.id!)
    updateModalShow(true)
  }

  const onConfirm = async () => {
    try {
      const values = await form.validateFields()
      const params = {
        ...values
      }
      if (platformId) {
        params.id = platformId
      }
      const result = await createOrUpdate({
        platForm: params
      })
      if (!result.data?.result?.success) {
        notification.error({
          message: `${!platformId ? '新增' : '编辑'}失败`,
          description: `【${values.name}】${result.data?.result?.message || ''}`
        })
      } else {
        notification.success({
          message: `${!platformId ? '新增' : '编辑'}成功`,
          description: `【${values.name}】`
        })
        updateModalShow(false)
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <Card
        style={{ marginTop: '20px' }}
        extra={
          <Button
            type='primary'
            onClick={() => {
              setPlatformId(null)
              updateModalShow(true)
            }}
          >
            新增会议平台
          </Button>
        }
      >
        <SearchTable
          showPanel={false}
          showPagination={false}
          showOperaBtn={false}
          table={table}
          bordered
          loading={fetching}
          dataSource={data?.platformList || []}
          size='middle'
          rowKey='code'
          columns={usePlatFormTypeColumns({ onEdit })}
        />
      </Card>

      <Modal
        maskClosable={false}
        title={`${platformId ? '编辑' : '新增'}会议平台信息`}
        visible={modalShow}
        onOk={onConfirm}
        onCancel={() => updateModalShow(false)}
        okText='确认'
        cancelText='取消'
        confirmLoading={modalLoading}
        afterClose={() => {
          form.resetFields()
        }}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          form={form}
          name='basicForm'
        >
          <Form.Item
            label='平台名称'
            name='name'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Input placeholder='请填写' />
          </Form.Item>
          <Form.Item
            label='code'
            name='code'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Input placeholder='请填写' />
          </Form.Item>
          <Form.Item label='排序' name='sort'>
            <InputNumber placeholder='请填写' style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label='备注信息' name='remark'>
            <Input placeholder='请填写' />
          </Form.Item>
          <Form.Item label='状态' name='enabled' valuePropName='checked'>
            <Switch checkedChildren={'启用'} unCheckedChildren={'禁用'} />
          </Form.Item>
        </Form>
      </Modal>
    </main>
  )
}

export default PlatformType
