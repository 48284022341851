import React, { FC, useState, useEffect } from 'react'
import omit from 'lodash.omit'
import {
  approvePendingSearchFields,
  useTableColumns,
  FieldTypes,
  excelHead,
  getData
} from './data'
import { useHrApprovalListQuery, useApprovalExportQuery } from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { Button, Col, message, Modal, Row } from 'antd'
import { generateExport } from '../../components/UploadBatch'
import { useUserStore } from '../../hooks/user'
import { useSearchStore } from '../../hooks/pageSearch'
import { HR_PRODUCTION } from '../../constant/user'

const tabKey = 'approvePending'
const ApproveWait: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const [isExporting, setExporting] = useState(false)
  const userStore = useUserStore()
  const { pageParams } = useSearchStore()
  const production = userStore.profile?.hrProduction || ''
  const companyId = userStore.profile?.companyId || ''
  const isQCXS = userStore.hasAuth('uplatform_approval_manage_quanchengxueshu')
  const isPlatformReview = userStore.hasAuth(
    'uplatform_approval_manage_platform_review'
  )
  const queryVariable = {
    startTime:
      (table.searchParams?.dateApprovePending?.[0]?.startOf('day').unix() ||
        0) * 1000 || undefined,
    endTime:
      (table.searchParams?.dateApprovePending?.[1]?.endOf('day').unix() || 0) *
        1000 || undefined,
    keyword: table.searchParams?.keywordApprovePending,
    meetingTypes: table.searchParams?.meetingTypes,
    meetingRegion: table.searchParams?.meetingRegion,
    meetingCostCenter: table.searchParams?.meetingCostCenter,
    sort: table.searchParams?.sortApprovePending || 'approvalTime',
    order: table.searchParams?.orderApprovePending ? 'ASC' : 'DESC',
    timeField: 'approval_time'
  }

  const tableColumns = useTableColumns({
    type: 'approvePending',
    production
    // isQCXS
  })

  const [{ data, error, fetching }, refetch] = useHrApprovalListQuery({
    variables: {
      approveStatus: 'wait',
      pageQuery: {
        query: {
          ...queryVariable
        },
        pager: omit(table.pager, 'total')
      }
    }
  })

  // 导出
  const [{ data: exportResult, error: exportErr }] = useApprovalExportQuery({
    variables: {
      approveStatus: 'wait',
      pageQuery: {
        query: {
          ...queryVariable
        },
        pager: {
          currentPage: 1,
          pageSize: 500
        }
      }
    },
    pause: !isExporting
  })

  const onExportHandle = () => {
    setExporting(true)

    if (exportErr) {
      setExporting(false)
      message.error('导出失败')
      return
    }
  }

  const handleCollectMeeting = (type: string, typeName: string) => {
    Modal.confirm({
      title: `收取${typeName}`,
      maskClosable: true,
      content: `确认收取【${typeName}】类型的订单进行审核吗？`,
      okText: '确认',
      okButtonProps: { loading: fetching },
      onOk: async () => {
        message.success('收取成功')
      }
    })
  }

  useEffect(() => {
    if (isExporting) {
      const dataResult = getData(
        exportResult?.approval?.items,
        excelHead(production)
      )
      generateExport(
        dataResult,
        `审批管理_${new Date().getTime()}`,
        5000,
        (err) => {
          setExporting(false)
          if (err) {
            message.error('导出失败')
          } else {
            message.success('EXCEL下载成功，请查收')
          }
        }
      )
    }
  }, [exportResult])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        delayFields={['keywordApprovePending', 'meetingTypes']}
        table={table}
        tabKey={tabKey}
        scroll={{ x: production === HR_PRODUCTION.hansoh ? 2800 : 2650 }}
        searchProps={{
          fields: approvePendingSearchFields(
            'approvePending',
            pageParams?.[pageKey],
            production,
            companyId
            // isQCXS
          )
        }}
        bordered
        loading={fetching}
        pager={data?.approval?.pager}
        dataSource={data?.approval?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
        showExportBtn
        onExport={onExportHandle}
        extraOpera={
          isPlatformReview ? (
            <Row style={{ textAlign: 'right', display: 'inline-flex' }}>
              <Col>
                <Button
                  type='primary'
                  onClick={() =>
                    handleCollectMeeting('ACADEMIC_CONFERENCE', '学术会议')
                  }
                  style={{
                    background: 'rgb(240,135,28)',
                    borderColor: 'rgb(240,135,28)',
                    marginRight: 10
                  }}
                >
                  收取学术会议
                </Button>
                <Button
                  type='primary'
                  onClick={() =>
                    handleCollectMeeting('CATE_CASE_COLLECTION', '病例征集')
                  }
                  style={{
                    background: 'rgb(60,104,3)',
                    borderColor: 'rgb(60,104,3)',
                    marginRight: 10
                  }}
                >
                  收取病例征集
                </Button>
                <Button
                  type='primary'
                  onClick={() =>
                    handleCollectMeeting('CATE_DOCUMENT_COMMENT', '文献点评')
                  }
                  style={{
                    background: 'rgb(14,110,108)',
                    borderColor: 'rgb(14,110,108)',
                    marginRight: 10
                  }}
                >
                  收取文献点评
                </Button>
                <Button
                  type='primary'
                  onClick={() =>
                    handleCollectMeeting(
                      'CATE_QUESTIONNAIRE_RESEARCH',
                      '问卷调研'
                    )
                  }
                  style={{
                    marginRight: 10
                  }}
                >
                  收取问卷调研
                </Button>
              </Col>
            </Row>
          ) : undefined
        }
      />
    </main>
  )
}

export default ApproveWait
