import React, { FC, useState, useEffect } from 'react'
import omit from 'lodash.omit'
import {
  approvePendingSearchFields,
  useTableColumns,
  FieldTypes,
  excelHead,
  getData
} from './data'
import { useHrApprovalListQuery, useApprovalExportQuery } from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { message } from 'antd'
import { generateExport } from '../../components/UploadBatch'
import { useUserStore } from '../../hooks/user'
import { useSearchStore } from '../../hooks/pageSearch'
import { HR_PRODUCTION } from '../../constant/user'
import _ from 'lodash'

const tabKey = 'approved'
const Approved: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const [isExporting, setExporting] = useState(false)
  const userStore = useUserStore()
  const { pageParams } = useSearchStore()
  const production = userStore.profile?.hrProduction || ''
  const isPlatformReview = userStore.hasAuth(
    'uplatform_approval_manage_platform_review'
  )
  const queryVariable = {
    startTime:
      (table.searchParams?.dateApproved?.[0]?.startOf('day').unix() || 0) *
        1000 || undefined,
    endTime:
      (table.searchParams?.dateApproved?.[1]?.endOf('day').unix() || 0) *
        1000 || undefined,
    keyword: table.searchParams?.keywordApproved,
    approveUsers: table.searchParams?.approveUsers
  }

  const isPause = _.values(queryVariable).find((x) => !!x)

  const tableColumns = useTableColumns({
    type: 'approved',
    production
  })

  const [{ data, error, fetching }, refetch] = useHrApprovalListQuery({
    variables: {
      approveStatus: 'approved',
      pageQuery: {
        query: {
          ...queryVariable
        },
        pager: omit(table.pager, 'total')
      }
    },
    pause: !isPause
  })

  // 导出
  const [{ data: exportResult, error: exportErr }] = useApprovalExportQuery({
    variables: {
      approveStatus: 'approved',
      pageQuery: {
        query: {
          ...queryVariable
        },
        pager: {
          currentPage: 1,
          pageSize: 500
        }
      }
    },
    pause: !isExporting
  })

  const onExportHandle = () => {
    setExporting(true)

    if (exportErr) {
      setExporting(false)
      message.error('导出失败')
      return
    }
  }

  useEffect(() => {
    if (isExporting) {
      const dataResult = getData(
        exportResult?.approval?.items,
        excelHead(production)
      )
      generateExport(
        dataResult,
        `审批管理_${new Date().getTime()}`,
        5000,
        (err) => {
          setExporting(false)
          if (err) {
            message.error('导出失败')
          } else {
            message.success('EXCEL下载成功，请查收')
          }
        }
      )
    }
  }, [exportResult])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        delayFields={['keywordApproved']}
        table={table}
        tabKey={tabKey}
        scroll={{ x: production === HR_PRODUCTION.hansoh ? 2700 : 2550 }}
        searchProps={{
          fields: approvePendingSearchFields(
            'approved',
            pageParams?.[pageKey],
            undefined,
            undefined,
            undefined,
            isPlatformReview
          )
        }}
        bordered
        loading={fetching}
        pager={data?.approval?.pager}
        dataSource={data?.approval?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
        showExportBtn
        onExport={onExportHandle}
      />
    </main>
  )
}

export default Approved
