import React from 'react'
import { Tabs } from 'antd'

import PersonalBudget from './personalBudget'
import ProcessBudget from './processBudget'

export type FileProps = {
  name?: string
  list?: Array<{ [key: string]: any }>
}

const BudgetList: QCPage = () => {
  return (
    <Tabs defaultActiveKey='1'>
      <Tabs.TabPane tab='个人预算查询' key='1'>
        <PersonalBudget />
      </Tabs.TabPane>
      <Tabs.TabPane tab='流程预算查询' key='2'>
        <ProcessBudget />
      </Tabs.TabPane>
    </Tabs>
  )
}

BudgetList.pageTitle = '预算查询'

export default BudgetList
