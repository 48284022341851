import { Space } from 'antd'
import React, { useMemo } from 'react'
import { ColumnsType } from 'antd/lib/table'
import {
  MeetingActivityTemplate,
  MeetingConfigBase,
  MeetingPlatform
} from '../../graphql'

export const useMeetingTypeColumns = ({
  onEdit
}: {
  onEdit: (meetingType: MeetingConfigBase) => void
}): ColumnsType<MeetingConfigBase> => {
  return [
    {
      title: '会议类型',
      dataIndex: 'title',
      align: 'center'
    },
    {
      title: 'code',
      dataIndex: 'code',
      align: 'center'
    },
    {
      title: '图标名称',
      dataIndex: 'icon',
      align: 'center'
    },
    // {
    //   title: '状态',
    //   dataIndex: 'enable',
    //   align: 'center',
    //   render(v) {
    //     return (
    //       <span style={{ color: !v ? '#ff4d4f' : '' }}>
    //         {!v ? '已停用' : '使用中'}
    //       </span>
    //     )
    //   }
    // },
    {
      title: '操作',
      dataIndex: 'operation',
      align: 'center',
      render(v, meetingType) {
        return (
          <Space>
            <a onClick={() => onEdit(meetingType)}>编辑</a>
          </Space>
        )
      }
    }
  ]
}

export const useActivityTypeColumns = ({
  onEdit
}: {
  onEdit: (activityType: MeetingConfigBase) => void
}): ColumnsType<MeetingConfigBase> => {
  return [
    {
      title: '活动类型',
      dataIndex: 'title',
      align: 'center'
    },
    {
      title: 'code',
      dataIndex: 'code',
      align: 'center'
    },
    {
      title: '图标名称',
      dataIndex: 'icon',
      align: 'center'
    },
    // {
    //   title: '状态',
    //   dataIndex: 'enable',
    //   align: 'center',
    //   render(v) {
    //     return (
    //       <span style={{ color: !v ? '#ff4d4f' : '' }}>
    //         {!v ? '已停用' : '使用中'}
    //       </span>
    //     )
    //   }
    // },
    {
      title: '操作',
      dataIndex: 'operation',
      align: 'center',
      render(v, activityType) {
        return (
          <Space>
            <a onClick={() => onEdit(activityType)}>编辑</a>
          </Space>
        )
      }
    }
  ]
}

export const usePlatFormTypeColumns = ({
  onEdit
}: {
  onEdit: (platform: MeetingPlatform) => void
}): ColumnsType<MeetingPlatform> => {
  return [
    {
      title: '平台名称',
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: 'code',
      dataIndex: 'code',
      align: 'center'
    },
    {
      title: '排序',
      dataIndex: 'sort',
      align: 'center'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      align: 'center'
    },
    {
      title: '状态',
      dataIndex: 'enabled',
      align: 'center',
      render(v) {
        return (
          <span style={{ color: !v ? '#ff4d4f' : '' }}>
            {!v ? '已停用' : '使用中'}
          </span>
        )
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      align: 'center',
      render(v, activityType) {
        return (
          <Space>
            <a onClick={() => onEdit(activityType)}>编辑</a>
          </Space>
        )
      }
    }
  ]
}
