import React, { FC, useState, useEffect } from 'react'
import omit from 'lodash.omit'
import {
  searchFields,
  useTableColumns,
  FieldTypes,
  excelHead,
  getData
} from './data'
import {
  SalesOrderPageQuery,
  SalesOrderPageQueryVariables,
  SalesOrderPageDocument,
  useSalesOrderPageQuery
} from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { useUserStore } from '../../hooks/user'
import { useClient } from 'urql'
import ExportQueueModal from '../../components/ExportQueueModal'
import { isMobileClient } from '../../constant/user'
import { Button, Col, message, Modal, Row } from 'antd'

const tabKey = 'approvePending'
const ApproveWait: FC = () => {
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const urqlClient = useClient()
  const isMobile = isMobileClient()
  const [isExporting, setExporting] = useState(false)
  const userStore = useUserStore()
  const production = userStore.profile?.hrProduction || ''
  const isPlatformReview = userStore.hasAuth(
    'uplatform_approval_manage_platform_review'
  )

  const queryVariable = {
    ...omit(table.searchParams, ['submitTime']),
    preAuditStart:
      table.searchParams?.submitTime?.[0]
        ?.startOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    preAuditEnd:
      table.searchParams?.submitTime?.[1]
        ?.endOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    status: [6],
    field: 'pre_voucher_audit_time',
    sort: 1
  }

  const [{ data, error, fetching }, refetchList] = useSalesOrderPageQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        ...queryVariable
      }
    }
  })

  const tableColumns = useTableColumns({
    type: 'approvePending',
    isMobile
  })

  const queryExportData = async (currentPage: number, pageSize: number) => {
    const { data, error } = await urqlClient
      .query<SalesOrderPageQuery, SalesOrderPageQueryVariables>(
        SalesOrderPageDocument,
        {
          pager: {
            currentPage,
            pageSize
          },
          query: {
            ...queryVariable
          }
        }
      )
      .toPromise()
    return { currentPage, exportData: data?.result?.items, error }
  }

  const onExportHandle = async () => {
    setExporting(true)
  }

  const handleCollectSalesOrder = () => {
    Modal.confirm({
      title: '收取消费订单',
      maskClosable: true,
      content: '确认收取“消费订单”进行票据审核吗？',
      okText: '确认',
      okButtonProps: { loading: fetching },
      onOk: async () => {
        message.success('收取成功')
      }
    })
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        tabKey={tabKey}
        scroll={{ x: isMobile ? 720 : 1400 }}
        searchProps={{
          fields: searchFields()
        }}
        bordered
        loading={fetching}
        pager={data?.result?.pager}
        dataSource={data?.result?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
        showExportBtn
        onExport={onExportHandle}
        extraOpera={
          isPlatformReview ? (
            <Row style={{ textAlign: 'right', display: 'inline-flex' }}>
              <Col>
                <Button
                  type='primary'
                  onClick={() => handleCollectSalesOrder()}
                  style={{
                    background: 'rgb(240,135,28)',
                    borderColor: 'rgb(240,135,28)',
                    marginRight: 10
                  }}
                >
                  收取消费订单
                </Button>
              </Col>
            </Row>
          ) : undefined
        }
      />
      <ExportQueueModal
        queryExportDataFc={queryExportData}
        formatDataFc={getData}
        excelHead={excelHead()}
        fileName={`消费订单票据待审核_${new Date().getTime()}`}
        isExport={isExporting}
        total={data?.result?.pager?.total || 0}
        concurrency={2}
        callback={() => {
          setExporting(false)
        }}
      />
    </main>
  )
}

export default ApproveWait
