import React, { FC, useState, useEffect } from 'react'
import ProLayout, { PageLoading } from '@ant-design/pro-layout'
import { Switch, Route, useLocation } from 'react-router-dom'

import { useCurrentUserQuery, useAvailableChannelsQuery } from './graphql'
import { useUserStore } from './hooks/user'
import { Login } from './pages/Login'
import SideBarLink from './components/SideBarLink'
import { defaultRoutes } from './defaultRoutes'
import { observer } from 'mobx-react'
import UserPanel from './components/UserPanel'
import { isMobileClient } from './constant/user'

const App: FC = () => {
  const isMobile = isMobileClient()
  const [collapsed, setCollapsed] = useState(isMobile)
  const location = useLocation()
  const [{ data, fetching }] = useCurrentUserQuery()
  const [{ data: channels, fetching: loading }] = useAvailableChannelsQuery({
    pause: !data?.user
  })
  const userStore = useUserStore()

  const [routes, setRoutes] = useState(defaultRoutes.routes)

  useEffect(() => {
    if (data?.user) {
      userStore.login(localStorage.getItem('bms-login-token') || '', data.user)
    }
    if (channels?.result) {
      userStore.setChannels(channels?.result)
    }
  }, [data?.user, channels?.result])

  useEffect(() => {
    setRoutes(
      defaultRoutes.routes?.filter(
        (route) =>
          !route.code ||
          !!data?.user?.appMenus?.find((menu) => menu.code === route.code)
      )
    )
  }, [data?.user?.appMenus])

  console.log(!userStore.isLoggedIn)

  if (fetching || loading) return <PageLoading />
  if (!userStore.isLoggedIn) return <Login />

  return (
    <ProLayout
      title='学术管理后台'
      fixedHeader
      fixSiderbar
      navTheme='dark'
      siderWidth={200}
      route={{ routes }}
      collapsed={collapsed}
      onCollapse={setCollapsed}
      location={{ pathname: location.pathname }}
      menuItemRender={(menuItemProps): React.ReactNode => {
        if (
          !userStore.profile?.appMenus?.find(
            (menu) => menu.code === menuItemProps.code
          )
        )
          return null
        return <SideBarLink {...menuItemProps} collapsed={collapsed} />
      }}
      menuHeaderRender={
        isMobile
          ? (logo, title): React.ReactNode => null
          : (_, __, props): React.ReactNode => (
              <h1 style={{ color: '#fff', marginTop: 20 }}>
                {props?.collapsed ? 'HR' : '学术管理后台'}
              </h1>
            )
      }
      rightContentRender={(): React.ReactNode => <UserPanel />}
      onPageChange={() => {
        if (isMobile) {
          setCollapsed(true)
        }
      }}
    >
      <Switch>
        {routes?.map((route) => {
          return (
            <Route exact path={route.path} key={route.name}>
              {/* <PageHeaderWrapper title={route.component?.pageTitle}> */}
              {route.component ? <route.component /> : null}
              {/* </PageHeaderWrapper> */}
            </Route>
          )
        })}
      </Switch>
    </ProLayout>
  )
}

export default observer(App)
