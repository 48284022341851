import React, { FC, useEffect, useState } from 'react'
import { Button, message, Tag } from 'antd'
import ClipBoard from 'clipboard'
import { isMobileClient } from '../constant/user'

let copy: ClipBoard

interface CopiableTagProps {
  title: string
  text: string
  isShowCopyBtn?: boolean | null | undefined
  copyBtnText?: string | null | undefined
}

const CopiableTag: FC<CopiableTagProps> = ({
  title,
  text,
  isShowCopyBtn = true,
  copyBtnText = '复制'
}) => {
  const isMobile = isMobileClient()
  const onCopyText = (title: string, text: string) => {
    copy = new ClipBoard('#inviteMessage', {
      text: () => text
    })
    copy.on('success', () => {
      message.success(`${title}复制成功`)
      copy.destroy()
    })
  }

  useEffect(() => {
    if (isShowCopyBtn) {
      document.getElementById('inviteMessage')?.click()
      return () => {
        copy.destroy()
      }
    }
  }, [])

  return (
    <Tag
      className={isMobile ? 'mobileCopiableTag' : ''}
      color='#F5F6FA'
      style={{ marginLeft: 15, color: '#888' }}
    >
      {title}：{text}
      {isShowCopyBtn && (
        <Button
          type='link'
          size='small'
          style={{ fontSize: 12, color: '#5581FA', marginLeft: 10 }}
          id='inviteMessage'
          onClick={() => {
            onCopyText(title, text)
          }}
        >
          {copyBtnText}
        </Button>
      )}
    </Tag>
  )
}

export default CopiableTag
