import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, message, Space } from 'antd'
import moment from 'moment'
import omit from 'lodash.omit'
import {
  useAdjustCostCenterBudgetMutation,
  useCostCenterBudgetByYearQuery,
  useCostCenterBudgetDetailQuery,
  useCostCenterBudgetLogQuery,
  useCostCenterBudgetUsedDetailQuery
} from '../../graphql'
import BudgetDetailCard from './budgetDetailCard'
import ErrorView from '../../components/ErrorView'
import BudgetAddSubtractModal from './budgetAddSubtractModal'
import {
  BudgetFormItems,
  getStartTimestampByTargetYear,
  getEndTimestampByTargetYear,
  useCostCenterTableColumns,
  usePlanBudgetUsedDetailTableColumns
} from './data'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import './index.less'

const curYear = moment().format('YYYY')

const CostCenterBudgetDetail: QCPage = () => {
  const { id } = useParams<{ id: string }>()
  const table = useSearchTable({}, { pageSize: 10, currentPage: 1 })
  const detailTable = useSearchTable({}, { pageSize: 10, currentPage: 1 })
  const [isShowAddSubtractModal, updateAddSubtractModalShow] = useState(false)
  const [budgetIdYear, setBudgetIdYear] = useState('')
  const [targetYear, setTargetYear] = useState('')

  const [{ data, error, fetching }, refetchDetail] =
    useCostCenterBudgetDetailQuery({
      variables: {
        id: Number(id)
      },
      pause: !id
    })

  const [
    {
      data: yearBudgetData,
      error: yearBudgetDataError,
      fetching: yearBudgetDataFetching
    },
    refetchYearDetail
  ] = useCostCenterBudgetByYearQuery({
    variables: {
      param: {
        startTimestamp: getStartTimestampByTargetYear(targetYear),
        endTimestamp: getEndTimestampByTargetYear(targetYear),
        groupId: data?.result?.groupId
      }
    },
    pause: !data?.result?.id || targetYear === budgetIdYear
  })

  const [
    { data: logData, error: logDataError, fetching: logDataFetching },
    refetchBudgetLog
  ] = useCostCenterBudgetLogQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        budgetId:
          targetYear === budgetIdYear
            ? data?.result?.id
            : yearBudgetData?.result?.id
      }
    },
    pause:
      !data?.result?.id ||
      (targetYear !== budgetIdYear && !yearBudgetData?.result?.id)
  })

  const [
    { data: detailData, error: detailDataError, fetching: detailDataFetching }
  ] = useCostCenterBudgetUsedDetailQuery({
    variables: {
      param: {
        pager: omit(detailTable.pager, 'total'),
        query: {
          targetYear,
          groupId:
            targetYear === budgetIdYear
              ? data?.result?.groupId
              : yearBudgetData?.result?.groupId
        }
      }
    },
    pause:
      !data?.result?.id ||
      (targetYear !== budgetIdYear && !yearBudgetData?.result?.id)
  })

  const [, adjustPersonalBudget] = useAdjustCostCenterBudgetMutation()

  const budgetAddSubtract = () => {
    updateAddSubtractModalShow(true)
  }

  const onCancelAddSubtract = () => {
    updateAddSubtractModalShow(false)
  }

  const onSubmitAddSubtract = async (
    values: BudgetFormItems,
    callBack?: () => void
  ) => {
    const budgetData =
      curYear !== budgetIdYear ? yearBudgetData?.result : data?.result
    const result = await adjustPersonalBudget({
      id: Number(budgetData?.id),
      amount: values?.amount!.toString(),
      operatorRemark: values?.explain
    })
    if (result?.error) {
      throw new Error(result.error?.message)
    } else if (result?.data) {
      if (result.data?.result?.success) {
        message.success('预算调整成功')
        updateAddSubtractModalShow(false)
        if (curYear !== budgetIdYear) {
          refetchYearDetail()
        } else {
          refetchDetail()
        }
        refetchBudgetLog()
      } else {
        message.error(result.data?.result?.message || '预算调整失败')
        callBack && callBack()
      }
    }
  }

  const onYearChange = (date: any, dateString: string) => {
    console.log(dateString)
    table.updatePager((currentPager) => ({
      ...currentPager,
      currentPage: 1
    }))
    detailTable.updatePager((currentPager) => ({
      ...currentPager,
      currentPage: 1
    }))
    setTargetYear(dateString)
  }

  useEffect(() => {
    if (data?.result?.start) {
      const budgetIdYear = moment(data?.result?.start).format('YYYY')
      setBudgetIdYear(budgetIdYear)
      setTargetYear(budgetIdYear)
    }
  }, [data])

  const tableColumns = useCostCenterTableColumns()
  const detailTableColumns = usePlanBudgetUsedDetailTableColumns()

  if (error || yearBudgetDataError) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error?.name || yearBudgetDataError?.name}
        message={error?.message || yearBudgetDataError?.message}
      />
    )
  }

  return (
    <Space direction='vertical' className='personalBudgetDetail'>
      <BudgetDetailCard
        fetching={fetching || yearBudgetDataFetching}
        data={
          targetYear !== budgetIdYear ? yearBudgetData?.result : data?.result
        }
        budgetType='costCenter'
        budgetAddSubtract={budgetAddSubtract}
        bodyStyle={{ paddingBottom: 0 }}
        operationDisable={
          targetYear !== curYear ||
          (budgetIdYear !== curYear
            ? !yearBudgetData?.result?.id
            : !data?.result?.id)
        }
        isCurrentYear={targetYear === curYear}
        targetYear={targetYear}
        onMonthChange={onYearChange}
      />
      {(targetYear !== budgetIdYear
        ? yearBudgetData?.result
        : data?.result) && (
        <Card
          title='操作历史记录'
          bordered={false}
          headStyle={{ border: 'none' }}
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <SearchTable
            table={table}
            bordered
            loading={logDataFetching}
            showPanel={false}
            pager={logData?.result?.pager}
            dataSource={logData?.result?.items || []}
            columns={tableColumns}
            scroll={{ x: 1100 }}
            rowKey={'id'}
          />
        </Card>
      )}
      {(targetYear !== budgetIdYear
        ? yearBudgetData?.result
        : data?.result) && (
        <Card
          title='预算使用明细'
          bordered={false}
          headStyle={{ border: 'none' }}
          bodyStyle={{ paddingTop: 0 }}
        >
          <SearchTable
            table={detailTable}
            bordered
            loading={detailDataFetching}
            showPanel={false}
            pager={detailData?.result?.pager}
            dataSource={detailData?.result?.items || []}
            columns={detailTableColumns}
            scroll={{ x: true }}
          />
        </Card>
      )}
      {isShowAddSubtractModal && (
        <BudgetAddSubtractModal
          title='预算调整'
          budgetDetail={
            curYear !== budgetIdYear ? yearBudgetData?.result : data?.result
          }
          budgetType='costCenter'
          modalWidth={700}
          onCancel={onCancelAddSubtract}
          onSubmit={onSubmitAddSubtract}
        />
      )}
    </Space>
  )
}

export default CostCenterBudgetDetail
