import React, { FC, useState } from 'react'
import { Modal, Input, Form } from 'antd'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 }
}

const EditModal: FC<{
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
  accountInfo?: any | null
}> = ({ onCancel, onSubmit, accountInfo }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = (values: any) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  return (
    <Modal
      maskClosable={false}
      title='编辑用户信息'
      visible
      onCancel={onCancel}
      centered
      closable={false}
      destroyOnClose
      width={480}
      onOk={() => {
        form.submit()
      }}
      okText={'确定'}
      confirmLoading={loading}
    >
      <Form preserve={false} form={form} {...layout} onFinish={onFinish}>
        <Form.Item label='用户姓名'>{accountInfo?.cnName}</Form.Item>
        <Form.Item label='报销编号'>{accountInfo?.staffId}</Form.Item>
        <Form.Item
          label='手机号'
          name='mobile'
          initialValue={accountInfo?.companyMobile}
          rules={[
            { required: true, message: '请输入手机号' },
            { pattern: /^1[0-9]{10}$/, message: '不合法的手机号' }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditModal
