import React from 'react'
import { Tabs } from 'antd'

import PersonalBudgetImport from './personalImport'
import ProcessBudgetImport from './processImport'

export type FileProps = {
  name?: string
  list?: Array<{ [key: string]: any }>
}

const BudgetImport: QCPage = () => {
  return (
    <Tabs defaultActiveKey='1'>
      <Tabs.TabPane tab='个人预算导入' key='1'>
        <PersonalBudgetImport />
      </Tabs.TabPane>
      <Tabs.TabPane tab='流程预算导入' key='2'>
        <ProcessBudgetImport />
      </Tabs.TabPane>
    </Tabs>
  )
}

BudgetImport.pageTitle = '预算导入'

export default BudgetImport
