import React, { FC, useEffect, useState } from 'react'
import { Modal, Input, Form, Divider, Select } from 'antd'
import { FormProps } from 'antd/lib/form'
import {
  OperationOrderDeleteReasons,
  OperationOrderReturnReasons
} from '../../constant/user'
import { useUserStore } from '../../hooks/user'
import './index.less'
import { OrderOperationCategory } from '../../graphql'

const OperationOrderModal: FC<{
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
  operationType: OrderOperationCategory
}> = ({ onCancel, onSubmit, operationType }) => {
  const [form] = Form.useForm()
  const [reasons, setReasons] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const operationTypeText =
    operationType === OrderOperationCategory.DeleteUser
      ? '删除'
      : operationType === OrderOperationCategory.SplitUser
      ? '拆分'
      : operationType === OrderOperationCategory.CancelOrder
      ? '退回'
      : '操作'

  useEffect(() => {
    switch (operationType) {
      case OrderOperationCategory.DeleteUser:
        setReasons(OperationOrderDeleteReasons)
        break
      case OrderOperationCategory.CancelOrder:
        setReasons(OperationOrderReturnReasons)
        break
    }
  }, [operationType])

  const tagOnClick = (tag: string) => {
    const prev = form.getFieldValue('reason') || ''
    form.setFieldsValue({
      reason: prev + tag,
      checkReason: prev + tag
    })
  }

  const onValuesChange: FormProps['onValuesChange'] = (value, values) => {
    const changeField = Object.keys(value)[0]
    if (changeField === 'reason') {
      form.setFieldsValue({
        checkReason: value.reason
      })
      if (!value.reason) {
        form.setFields([
          {
            name: 'checkReason',
            errors: [`请输入${operationTypeText}理由`]
          }
        ])
      }
    }
  }

  const onFinish = (values: any) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  return (
    <Modal
      maskClosable={false}
      title={`${operationTypeText}老师`}
      visible
      onCancel={onCancel}
      centered
      closable={false}
      destroyOnClose
      width={530}
      onOk={() => {
        form.submit()
      }}
      confirmLoading={loading}
    >
      <Form
        preserve={false}
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        {[
          OrderOperationCategory.SplitUser,
          OrderOperationCategory.CancelOrder
        ].includes(operationType) && (
          <>
            <Form.Item label='老师渠道' name='channel'>
              <Select
                showSearch
                allowClear
                placeholder='请选择老师渠道'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {useUserStore().channels?.map((k) => {
                  return (
                    <Select.Option value={k.value} key={k.value}>
                      {k.text}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </>
        )}
        {[
          OrderOperationCategory.DeleteUser,
          OrderOperationCategory.SplitUser,
          OrderOperationCategory.CancelOrder
        ].includes(operationType) && (
          <>
            <Form.Item
              name='reason'
              rules={[
                { required: true, message: `请输入${operationTypeText}理由` }
              ]}
              noStyle={reasons?.length > 0}
            >
              <Input.TextArea
                maxLength={80}
                placeholder={`请输入${operationTypeText}理由`}
                autoSize={{ minRows: 4, maxRows: 6 }}
                style={{ marginBottom: '10px' }}
              />
            </Form.Item>
            {reasons?.length > 0 && (
              <Form.Item
                name='checkReason'
                rules={[
                  { required: true, message: `请输入${operationTypeText}理由` }
                ]}
              >
                <div className='tagsContainer'>
                  {reasons.map((tag, index) => (
                    <div
                      title={tag}
                      key={index}
                      onClick={() => tagOnClick(tag)}
                      className='tag'
                    >
                      {tag.length > 15 ? `${tag.slice(0, 15)}...` : tag}
                    </div>
                  ))}
                </div>
              </Form.Item>
            )}
            <Divider />
            <div>
              {operationType === OrderOperationCategory.DeleteUser
                ? '确认将老师从该订单中删除吗？删除成功后，该老师所占用的预算将会释放。'
                : operationType === OrderOperationCategory.SplitUser
                ? '拆分成功后，老师将从订单中删除，所占用的预算会释放，同时复制订单和订单中该老师信息，生成一个状态为“执行中”的新订单。'
                : operationType === OrderOperationCategory.CancelOrder
                ? '确认将该老师所在的订单退回吗？退回成功后，老师所在的订单状态将变为“执行中”，同时该订单占用的预算会释放。'
                : ''}
            </div>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default OperationOrderModal
