import React from 'react'
import { Input } from 'antd'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import dayjs, { Dayjs } from 'dayjs'

import DatePicker from '../../components/CustomDatePicker'
import { cloneDeep, map, get } from 'lodash'
import {
  ComponyGroupSelector,
  ConsumptionOrderStatusSelector,
  ConsumptionTypesSelector
} from '../../components/SelectorCollection'
import {
  getSalesBudgetChannelText,
  getSalesOrderStatus
} from '../../constant/sales'

//搜索字段声明
export const searchFields = (type?: string) => [
  {
    name: 'businessId',
    label: '业务部',
    span: 8,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <ComponyGroupSelector level='1' />
    }
  },
  {
    name: 'formCategory',
    label: '消费类型',
    span: 8,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <ConsumptionTypesSelector />
    }
  },
  ...(type === 'approved'
    ? [
        {
          name: 'status',
          label: '消费订单状态',
          span: 8,
          screenColumn: { xs: 24, sm: 12, md: 8 },
          render() {
            return (
              <ConsumptionOrderStatusSelector
                availableStatus={[7, 8, 10, 11]}
              />
            )
          }
        }
      ]
    : []),

  {
    name: 'submitTime',
    label: '提交时间',
    span: 8,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return (
        <DatePicker.RangePicker
          locale={locale}
          format='YYYY-MM-DD'
          style={{ width: '100%' }}
        />
      )
    }
  },
  {
    name: 'keyword',
    label: '搜索',
    span: 12,
    screenColumn: { xs: 24, sm: 12, md: 12 },
    render() {
      return (
        <Input
          allowClear
          placeholder='请输入姓名、手机号、消费订单ID/名称进行搜索'
        />
      )
    }
  }
]

export const useTableColumns = (props: {
  type: string
  isMobile?: boolean
}): any => [
  {
    title: '消费订单ID',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    align: 'center',
    width: props.isMobile ? 100 : 200,
    render(v: any) {
      return <Link to={`/consumptionOrderBillDetail/${v}`}>{v}</Link>
    }
  },
  {
    title: '消费订单名称',
    dataIndex: 'targetName',
    key: 'targetName',
    align: 'center',
    width: props.isMobile ? 120 : 200,
    render(v: any) {
      return v || '消费订单_未选消费场所'
    }
  },
  {
    title: '申请人',
    dataIndex: 'accountName',
    key: 'accountName',
    align: 'center',
    width: props.isMobile ? 70 : 100
  },
  {
    title: '申请人手机',
    dataIndex: 'accountMobile',
    key: 'accountMobile',
    align: 'center',
    width: props.isMobile ? 100 : 150
  },
  {
    title: '预算渠道',
    dataIndex: 'budgetChannel',
    key: 'budgetChannel',
    align: 'center',
    width: props.isMobile ? 80 : 150,
    render(v: any) {
      return getSalesBudgetChannelText(v)
    }
  },
  {
    title: '预计',
    dataIndex: 'consumptionAmount',
    key: 'consumptionAmount',
    align: 'center',
    width: props.isMobile ? 50 : 100
  },
  {
    title: '实际',
    dataIndex: 'actualConsumptionAmount',
    key: 'actualConsumptionAmount',
    align: 'center',
    width: props.isMobile ? 50 : 100
  },
  {
    title: '提交时间',
    dataIndex: 'preVoucherAuditTime',
    key: 'preVoucherAuditTime',
    align: 'center',
    width: props.isMobile ? 80 : 150,
    render(v: any) {
      return v ? dayjs(v).format('YYYY-MM-DD') : '-'
    }
  },
  ...(props.type === 'approved'
    ? [
        {
          title: '订单状态',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          fixed: 'right',
          width: props.isMobile ? 80 : 120,
          render(v: any) {
            const status = getSalesOrderStatus(v)
            return <span style={{ color: status.color }}>{status.text}</span>
          }
        }
      ]
    : [
        {
          title: '操作',
          dataIndex: 'orderInfo',
          key: 'action',
          align: 'center',
          fixed: 'right',
          width: props.isMobile ? 70 : 100,
          render(v: any, data: any) {
            return (
              <Link to={`/consumptionOrderBillDetail/${data?.id}`}>去审核</Link>
            )
          }
        }
      ])
]

export type FieldTypes = {
  keyword?: string
  formCategory?: string[] | undefined
  submitTime?: [Dayjs | undefined, Dayjs | undefined]
  status?: number[] | undefined
}

// 表格导出字段
export const excelHead = () => [
  {
    key: 'id',
    path: 'id',
    name: '消费订单ID'
  },
  {
    key: 'targetName',
    path: 'targetName',
    name: '消费订单名称'
  },
  {
    key: 'accountName',
    path: 'accountName',
    name: '申请人'
  },
  {
    key: 'accountMobile',
    path: 'accountMobile',
    name: '申请人手机'
  },
  {
    key: 'budgetChannel',
    path: 'budgetChannel',
    name: '预算渠道'
  },
  { key: 'consumptionAmount', path: 'consumptionAmount', name: '预计' },
  {
    key: 'actualConsumptionAmount',
    path: 'actualConsumptionAmount',
    name: '实际'
  },
  {
    key: 'preVoucherAuditTime',
    path: 'preVoucherAuditTime',
    name: '提交时间'
  },
  {
    key: 'status',
    path: 'status',
    name: '订单状态'
  }
]

// 整理出表格数据
export const getData = (data: any, head: any) => {
  const result = data ? cloneDeep(data) : []
  return map(result, (item) => {
    const obj: any = {}

    head.forEach((cell: any) => {
      const value = get(item, cell.path, '-')

      switch (cell.key) {
        case 'targetName':
          obj[cell.name] = value || '消费订单_未选消费场所'
          break
        case 'budgetChannel':
          obj[cell.name] = getSalesBudgetChannelText(value)
          break
        case 'status':
          obj[cell.name] = getSalesOrderStatus(value).text
          break
        default:
          obj[cell.name] = value
          break
      }
    })

    return obj
  })
}
