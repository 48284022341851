import React, { FC, useEffect, useState } from 'react'
import PQueue from 'p-queue'
import { Modal, notification, Progress, Spin } from 'antd'
import _ from 'lodash'
import { OperationResult } from 'urql'
import { generateExport } from './UploadBatch'
import { getExportPageSizeByDataCount } from '../constant/user'

interface ExportQueueModalProps {
  queryExportDataFc: (currentPage: number, pageSize: number) => Promise<any>
  formatDataFc: (data: any, head: any) => any[]
  excelHead: any
  fileName: string
  isExport: boolean
  total: number
  pageSize?: number | undefined
  concurrency?: number | undefined
  callback?: () => void
}

const ExportQueueModal: FC<ExportQueueModalProps> = ({
  queryExportDataFc,
  formatDataFc,
  excelHead,
  fileName,
  isExport = false,
  total = 0,
  pageSize,
  concurrency = 2,
  callback
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [exportDataList, setExportDataList] = useState<any>([])
  const [progressPercent, setProgressPercent] = useState(0)
  const [progressStatus, setProgressStatus] = useState<
    'success' | 'normal' | 'exception' | 'active' | undefined
  >(undefined)

  const exportResult = (type: 'success' | 'error', message: string) => {
    notification[type]({
      message: message,
      duration: 0.5,
      onClose: () => {
        setIsVisible(false)
        setProgressPercent(0)
        setProgressStatus(undefined)
        callback && callback()
      }
    })
  }

  const getExportData = async () => {
    if (total === 0) return
    if (!pageSize) {
      pageSize = getExportPageSizeByDataCount(total)
    }
    const pageCount = _.ceil(total / pageSize)
    let exportList: any[] = []
    const errors: any[] = []
    const pageList = []
    for (let index = 1; index <= pageCount; index++) {
      pageList.push({
        pageIndex: index,
        pageSize
      })
    }

    const queue = new PQueue({ concurrency })
    queue.addAll(
      pageList.map((page: any) => {
        return async () => {
          try {
            const { currentPage, exportData, error } = await queryExportDataFc(
              page.pageIndex,
              page.pageSize
            )
            if (error) {
              queue.pause()
              queue.clear()
              setProgressStatus('exception')
              errors.push(error)
            } else {
              exportList = _.concat(exportList, { currentPage, exportData })
              setProgressPercent(_.round((exportList.length / pageCount) * 100))
            }
          } catch (e) {
            errors.push(e)
            queue.pause()
            queue.clear()
            setProgressStatus('exception')
          }
        }
      })
    )

    // 当队列成为空且所有任务都执行完时,返回promise,保证所有任务执行完
    await queue.onIdle()

    if (errors && errors.length > 0) {
      exportResult('error', '导出失败')
      return
    } else {
      exportList = _.sortBy(exportList, function (o) {
        return o.currentPage
      })
      exportList = _.flatten(exportList.map((x) => x.exportData))
      setExportDataList(exportList)
    }
  }

  useEffect(() => {
    if (isExport && isVisible && exportDataList.length > 0) {
      const dataResult = formatDataFc(exportDataList, excelHead)
      generateExport(dataResult, fileName, null, (err) => {
        exportResult(
          err ? 'error' : 'success',
          err ? '导出失败' : 'EXCEL下载成功，请查收'
        )
      })
    }
  }, [exportDataList])

  useEffect(() => {
    setIsVisible(isExport)
    if (isExport) {
      getExportData()
    }
  }, [isExport])

  return (
    <Modal
      visible={isVisible}
      footer={null}
      maskClosable={false}
      closable={false}
      width={400}
    >
      <Spin tip='正在为您生成EXCEL文件，请不要关闭此网页…' />
      <Progress
        size='small'
        percent={progressPercent}
        status={progressStatus}
      />
    </Modal>
  )
}

export default ExportQueueModal
