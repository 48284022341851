import React, { useState, FC } from 'react'
import { notification, Modal, Form, Input, Button, Card } from 'antd'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { useMeetingTypeColumns } from './data'
import {
  useFetchAllBaseMeetingTypesQuery,
  MeetingConfigBase,
  useCreateOrUpdateBaseMeetingTypeMutation
} from '../../graphql'

const MeetingType: FC = () => {
  const [form] = Form.useForm()
  const table = useSearchTable({})
  const [modalShow, updateModalShow] = useState(false)
  const [meetingTypeId, setMeetingTypeId] = useState<string | null>(null)

  const [
    { data, error, fetching },
    refetch
  ] = useFetchAllBaseMeetingTypesQuery()
  const [
    { fetching: modalLoading },
    createOrUpdate
  ] = useCreateOrUpdateBaseMeetingTypeMutation()

  const onEdit = (meetingType: MeetingConfigBase) => {
    form.setFieldsValue({
      ...meetingType
      // enable: !meetingType?.enable ? 0 : 1
    })
    setMeetingTypeId(meetingType.id)
    updateModalShow(true)
  }

  const onConfirm = async () => {
    try {
      const values = await form.validateFields()
      const params = { ...values, enable: true, props: [] }
      if (meetingTypeId) {
        params.id = meetingTypeId
      }
      const result = await createOrUpdate({
        config: params
      })
      if (!result.data?.result?.success) {
        notification.error({
          message: `${!meetingTypeId ? '新增' : '编辑'}失败`,
          description: `【${values.title}】${
            result.data?.result?.message || ''
          }`
        })
      } else {
        notification.success({
          message: `${!meetingTypeId ? '新增' : '编辑'}成功`,
          description: `【${values.title}】`
        })
        updateModalShow(false)
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <Card
        style={{ marginTop: '20px' }}
        extra={
          <Button
            type='primary'
            onClick={() => {
              setMeetingTypeId(null)
              updateModalShow(true)
            }}
          >
            新增会议类型
          </Button>
        }
      >
        <SearchTable
          showPanel={false}
          showPagination={false}
          showOperaBtn={false}
          table={table}
          bordered
          loading={fetching}
          dataSource={data?.result || []}
          size='middle'
          rowKey='code'
          columns={useMeetingTypeColumns({ onEdit })}
        />
      </Card>

      <Modal
        maskClosable={false}
        title={`${meetingTypeId ? '编辑' : '新增'}会议类型`}
        visible={modalShow}
        onOk={onConfirm}
        onCancel={() => updateModalShow(false)}
        okText='确认'
        cancelText='取消'
        confirmLoading={modalLoading}
        afterClose={() => form.resetFields()}
      >
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} form={form}>
          <Form.Item
            label='会议类型名称'
            name='title'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Input placeholder='请填写类型名称' />
          </Form.Item>
          <Form.Item
            label='code'
            name='code'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Input placeholder='请填写类型code' />
          </Form.Item>
          <Form.Item
            label='图标名称'
            name='icon'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Input placeholder='请填写图标名称' />
          </Form.Item>
          {/* <Form.Item
            label='状态'
            name='enable'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Select placeholder='请选择状态'>
              <Select.Option value={1}>启用</Select.Option>
              <Select.Option value={0}>禁用</Select.Option>
            </Select>
          </Form.Item> */}
        </Form>
      </Modal>
    </main>
  )
}

export default MeetingType
