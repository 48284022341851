import React from 'react'
import { Input } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { map, get } from 'lodash'

import {
  CostCenterBudgetInfo,
  HansohBudget,
  PersonalPlanBudgetInfo,
  ProcessBudget
} from '../../graphql'
import {
  BusinessDepartmentSelector,
  ComponyGroupSelector
} from '../../components/SelectorCollection'
import { Link } from 'react-router-dom'

export const searchFields = (
  businessId?: string | undefined,
  onBusinessChange?: (value: string) => void,
  regionId?: string | undefined,
  onRegionChange?: (value: string) => void
) => [
  {
    name: 'businessId',
    label: '业务部',
    span: 7,
    render() {
      // return <ComponyGroupSelector level='1' onChange={onBusinessChange} />
      return <BusinessDepartmentSelector onChange={onBusinessChange} />
    }
  },
  {
    name: 'regionId',
    label: '大区',
    span: 7,
    render() {
      return (
        <ComponyGroupSelector
          pid={businessId}
          level='2'
          disabled={!businessId}
          onChange={onRegionChange}
        />
      )
    }
  },
  {
    name: 'areaId',
    label: '地区',
    span: 7,
    render() {
      return (
        <ComponyGroupSelector pid={regionId} level='3' disabled={!regionId} />
      )
    }
  },
  {
    name: 'keyword',
    label: '搜索',
    span: 10,
    render() {
      return <Input allowClear placeholder='请输入姓名、报销编号搜索' />
    }
  }
]

export const useTableColumns = (): ColumnsType<HansohBudget> => {
  return [
    {
      align: 'center',
      title: '业务部',
      dataIndex: 'business',
      key: 'business',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '大区',
      dataIndex: 'region',
      key: 'region',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '地区',
      dataIndex: 'area',
      key: 'area',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '报销编号',
      dataIndex: 'financeNo',
      key: 'financeNo',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '用户姓名',
      dataIndex: 'accountName',
      key: 'accountName',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '预算年份',
      dataIndex: 'budget',
      key: 'year',
      render(v) {
        return v?.[1]?.year || '-'
      }
    },
    {
      align: 'center',
      title: '上月预算',
      dataIndex: 'budget',
      key: 'prevBudget',
      render(v) {
        return v?.[0]?.budgetAmount || '-'
      }
    },
    {
      align: 'center',
      title: '上月结余',
      dataIndex: 'budget',
      key: 'prevBalance',
      render(v) {
        return v?.[0]?.balanceAmount || '-'
      }
    },
    {
      align: 'center',
      title: '本月预算',
      dataIndex: 'budget',
      key: 'budget',
      render(v) {
        return (
          <Link to={`/personalBudgetDetail/${v?.[1]?.id}`}>
            {v?.[1]?.budgetAmount || '-'}
          </Link>
        )
      }
    },
    {
      align: 'center',
      title: '本月释放增加',
      dataIndex: 'budget',
      key: 'increase',
      render(v) {
        return v?.[1]?.increaseAmount || '-'
      }
    },
    {
      align: 'center',
      title: '本月使用',
      dataIndex: 'budget',
      key: 'used',
      render(v) {
        return v?.[1]?.usedAmount || '-'
      }
    },
    {
      align: 'center',
      title: '下月预算',
      dataIndex: 'budget',
      key: 'nextBudget',
      render(v) {
        return v?.[2]?.budgetAmount || '-'
      }
    }
  ]
}

export type FieldTypes = {
  businessId?: string
  regionId?: string
  areaId?: string
  keyword?: string
}

export const budgetFormat = (data: HansohBudget[]) => {
  const month = new Date().getMonth() + 1
  const prevMonth = month === 1 ? 12 : month - 1
  const nextMonth = month === 12 ? 1 : month + 1

  return data
    .map((row) => {
      return {
        ...row,
        budget: [
          row.budget?.find((b) => b.month === prevMonth && b.year) || {},
          row.budget?.find((b) => b.month === month && b.year) || {},
          row.budget?.find((b) => b.month === nextMonth && b.year) || {}
        ]
      }
    })
    .filter((item) => !!item.budget[1].budgetAmount)
}

export const excelHead = [
  { name: '业务部', path: 'business', key: 'business' },
  { name: '大区', path: 'region', key: 'region' },
  { name: '地区', path: 'area', key: 'area' },
  { name: '报销编号', path: 'financeNo', key: 'financeNo' },
  { name: '用户姓名', path: 'accountName', key: 'accountName' },
  { name: '预算年份', path: 'budget[1].year', key: 'year' },
  { name: '上月预算', path: 'budget[0].budgetAmount', key: 'prevBudget' },
  { name: '上月结余', path: 'budget[0].balanceAmount', key: 'prevBalance' },
  { name: '本月预算', path: 'budget[1].budgetAmount', key: 'budget' },
  { name: '本月释放增加', path: 'budget[1].increaseAmount', key: 'increase' },
  { name: '本月使用', path: 'budget[1].usedAmount', key: 'used' },
  { name: '下月预算', path: 'budget[2].budgetAmount', key: 'nextBudget' }
]

export const processExcelHead = [
  { name: '流程编号', path: 'budgetNumber', key: 'budgetNumber' },
  { name: '预算金额', path: 'budgetAmount', key: 'budgetAmount' },
  { name: '已使用金额', path: 'usedFee', key: 'usedFee' }
]

export const getData = (
  data: Array<HansohBudget | ProcessBudget>,
  isProcess?: boolean
) => {
  const head = !isProcess ? excelHead : processExcelHead
  return map(data, (item) => {
    const obj: { [key: string]: string | number } = {}

    head.forEach((cell: { [key: string]: string }) => {
      const value = get(item, cell.path, '-')
      obj[cell.name] = value
    })
    return obj
  })
}

export type PlanBudgetFieldTypes = {
  businessId?: string
  keyword?: string
}

export const planBudgetExcelHead = () => [
  { name: '业务部', path: 'business', key: 'business' },
  { name: '报销编号', path: 'financeNo', key: 'financeNo' },
  { name: '用户姓名', path: 'accountName', key: 'accountName' },
  { name: '预算年份', path: 'year', key: 'year' },
  { name: '上月预算', path: 'lastMonthAmount', key: 'lastMonthAmount' },
  {
    name: '上月结余',
    path: 'lastMonthBalanceAmount',
    key: 'lastMonthBalanceAmount'
  },
  { name: '本月预算', path: 'currentMonthAmount', key: 'currentMonthAmount' },
  {
    name: '本月释放增加',
    path: 'currentMonthIncreaseAmount',
    key: 'currentMonthIncreaseAmount'
  },
  {
    name: '本月使用',
    path: 'currentMonthUsedAmount',
    key: 'currentMonthUsedAmount'
  },
  { name: '下月预算', path: 'nextMonthAmount', key: 'nextMonthAmount' }
]

export const getPlanBudgetData = (
  data: Array<PersonalPlanBudgetInfo>,
  head: any
) => {
  return map(data, (item) => {
    const obj: { [key: string]: string | number } = {}

    head.forEach((cell: { [key: string]: string }) => {
      const value = get(item, cell.path, '-')
      obj[cell.name] = value
    })
    return obj
  })
}

export const usePlanBudgetTableColumns = (props: { type: string }): any => {
  return [
    {
      align: 'center',
      title: '业务部',
      dataIndex: 'business',
      key: 'business',
      render(v: any) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '报销编号',
      dataIndex: 'financeNo',
      key: 'financeNo',
      render(v: any) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '用户姓名',
      dataIndex: 'accountName',
      key: 'accountName',
      render(v: any) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '预算年份',
      dataIndex: 'year',
      key: 'year',
      render(v: any) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '上月预算',
      dataIndex: 'lastMonthAmount',
      key: 'lastMonthAmount',
      render(v: any) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '上月结余',
      dataIndex: 'lastMonthBalanceAmount',
      key: 'lastMonthBalanceAmount',
      render(v: any) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '本月预算',
      dataIndex: 'currentMonthAmount',
      key: 'currentMonthAmount',
      render(v: any, data: any) {
        return (
          <Link
            to={`/${
              props.type === 'inPlan'
                ? 'inPlanBudgetDetail'
                : 'outPlanBudgetDetail'
            }/${data?.budgetId}`}
          >
            {v || '-'}
          </Link>
        )
      }
    },
    {
      align: 'center',
      title: '本月释放增加',
      dataIndex: 'currentMonthIncreaseAmount',
      key: 'currentMonthIncreaseAmount',
      render(v: any) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '本月使用',
      dataIndex: 'currentMonthUsedAmount',
      key: 'currentMonthUsedAmount',
      render(v: any) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '下月预算',
      dataIndex: 'nextMonthAmount',
      key: 'nextMonthAmount',
      render(v: any) {
        return v || '-'
      }
    }
  ]
}

export type CostCenterBudgetFieldTypes = {
  businessId?: string
  keyword?: string
}

export const costCenterBudgetExcelHead = () => [
  { name: '成本中心', path: 'groupName', key: 'groupName' },
  { name: '预算年份', path: 'year', key: 'year' },
  { name: '预算金额', path: 'currentMonthAmount', key: 'currentMonthAmount' },
  {
    name: '已使用金额',
    path: 'currentMonthUsedAmount',
    key: 'currentMonthUsedAmount'
  }
]

export const getCostCenterBudgetData = (
  data: Array<CostCenterBudgetInfo>,
  head: any
) => {
  return map(data, (item) => {
    const obj: { [key: string]: string | number } = {}

    head.forEach((cell: { [key: string]: string }) => {
      const value = get(item, cell.path, '-')
      obj[cell.name] = value
    })
    return obj
  })
}

export const useCostCenterBudgetTableColumns = (): any => {
  return [
    {
      align: 'center',
      title: '成本中心',
      dataIndex: 'groupName',
      key: 'groupName',
      render(v: any) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '预算年份',
      dataIndex: 'year',
      key: 'year',
      render(v: any) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '预算金额',
      dataIndex: 'currentMonthAmount',
      key: 'currentMonthAmount',
      render(v: any, data: any) {
        return (
          <Link to={`/costCenterBudgetDetail/${data?.id}`}>{v || '-'}</Link>
        )
      }
    },
    {
      align: 'center',
      title: '已使用金额',
      dataIndex: 'currentMonthUsedAmount',
      key: 'currentMonthUsedAmount',
      render(v: any) {
        return v || '-'
      }
    }
  ]
}
