import React, { useState, useCallback, useEffect } from 'react'
import PQueue from 'p-queue'
import { chunk } from 'lodash'
import { message, notification } from 'antd'
import XLSX from 'xlsx'
import useLegacyState from 'use-merge-value'
import { isJSON } from '../lib/isJSON'

type batchProps = {
  concurrency?: number
  chunkSize?: number
}

// 导出表格方法
export const generateExport = (
  data: any,
  filename: string,
  maxLength?: number | null,
  callback?: (err?: string | null) => void
) => {
  if (maxLength && data.length > maxLength) {
    message.warning(`最多导出${maxLength}条数据`, 5)
    data = data.slice(0, maxLength)
  }

  try {
    const sheet = XLSX.utils.json_to_sheet(data)
    const wb = { SheetNames: [], Sheets: {} }
    XLSX.utils.book_append_sheet(wb, sheet, 'sheet1')
    XLSX.writeFile(wb, `${filename}.xlsx`)
    callback && callback()
  } catch (err) {
    // console.log(err)
    callback && callback(err)
  }
}

// 错误提示
export const useBatchFailNotification = ({
  errors,
  rows,
  errorResolver
}: {
  errors: any[]
  rows: any[]
  errorResolver?: () => void
}) => {
  useEffect(() => {
    if (errors && errors.length > 0) {
      errorResolver?.()
      message.destroy()
      notification.destroy()
      notification.error({
        message: '失败',
        description: (
          <ul
            style={{
              overflow: 'auto',
              maxHeight: 200,
              margin: '10px 0 0 -40px'
            }}
          >
            {errors.map((item, index) => (
              <li key={index}>
                {isJSON(item.message)
                  ? JSON.parse(item.message).map((m: any, i: number) => {
                      return <p key={`mag_${i}`}>{m}</p>
                    })
                  : item.message}
              </li>
            ))}
          </ul>
        ),
        duration: 0
      })
    }
  }, [errors, rows])
}

// 批量上传方法
export const useBatchUpload = ({
  task,
  options,
  noMessage,
  resultResolver,
  errorResolver
}: {
  task: (list: any, uniqId?: string) => void
  options: batchProps
  noMessage?: boolean
  resultResolver?: (results: any[], list: any[]) => void
  errorResolver?: () => void
}) => {
  const concurrency = options.concurrency || 10
  const chunkSize = options.chunkSize || 0

  const [state, setState] = useLegacyState({
    batchErrors: [] as any[],
    batchRowsOfError: [] as any[]
  })

  const batchRun = useCallback(
    async (list: any[], uniqId?: string) => {
      const errors: any[] = []
      const rowsOfError: any[] = []
      //concurrency:并发数量,一个批次发送的请求数量，最小为1，最大无限制
      const queue = new PQueue({ concurrency })
      const results: any[] = []

      // 按照chunkSize拆分成多个长度为chunkSize大小的数组，最终返回一个二维数组
      queue.addAll(
        (chunkSize > 0 ? chunk(list, chunkSize) : [list]).map((item: any) => {
          return async () => {
            try {
              const result = await task(item, uniqId)
              results.push(result)
            } catch (e) {
              errors.push(e)
              rowsOfError.push(item)
            }
          }
        })
      )

      !noMessage && message.loading('上传中，请耐心等待...', 0)

      // 当队列成为空且所有任务都执行完时,返回promise,保证所有任务执行完
      await queue.onIdle()

      setState({ batchErrors: errors, batchRowsOfError: rowsOfError })

      if (errors.length < 1) {
        resultResolver?.(results, list)
        if (!noMessage) {
          message.destroy()
          message.success('上传成功')
        }
      }
    },
    [setState, task]
  )

  useBatchFailNotification({
    errors: state.batchErrors,
    rows: state.batchRowsOfError,
    errorResolver: errorResolver
  })

  return [batchRun]
}
