import React, { FC, useState } from 'react'
import { Button } from 'antd'
import RealNameAuthModal from './RealNameAuthModal'

const RealNameAuthButton: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleOkCallBack = () => {
    setIsModalVisible(false)
  }
  const handleCancelCallBack = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type='primary' onClick={showModal} style={{ borderRadius: 5 }}>
        实名认证
      </Button>
      <RealNameAuthModal
        visible={isModalVisible}
        handleOkCallBack={handleOkCallBack}
        handleCancelCallBack={handleCancelCallBack}
      />
    </>
  )
}

export default RealNameAuthButton
