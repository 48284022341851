import React, { FC } from 'react'
import { Spin, Tag, Card, Row, Col, Empty } from 'antd'

export const ContentAuditRecord: FC<{
  taskId: string
  title?: string
  style?: React.CSSProperties | undefined
}> = ({ taskId, title = '内容验证', style }) => {
  return (
    <Card title={title} className='checkContainer' style={style}>
      <Empty
        style={{ margin: 0 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description='暂无数据'
      />
    </Card>
  )
}
