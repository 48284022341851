import React, { useState } from 'react'
import ErrorView from '../../components/ErrorView'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import { searchFields, useTableColumns } from './data'
import { useUserStore } from '../../hooks/user'
import { Button, message, Modal } from 'antd'
import omit from 'lodash/omit'
import {
  useQueryDocumentsQuery,
  useAddDocumentMutation,
  useRemoveDocumentMutation,
  useUpadminCompaniesQuery,
  useCompanyUpProductsQuery,
  useEditDocumentMutation
} from '../../graphql'
import UploadModal from './uploadModal'
import dayjs from 'dayjs'
import { HR_PRODUCTION } from '../../constant/user'
import { useSearchStore } from '../../hooks/pageSearch'
import './index.less'

type FieldTypes = {
  tagIds?: [string]
  documentCategoryIds?: string // 权限是单选
  keywords?: string
  companyId?: string
  productCodes?: string
  sphere?: [string]
}

const LiteratureManage: QCPage = () => {
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[window.location.pathname] }
  const table = useSearchTable<FieldTypes>(searchParams, {
    pageSize: 15,
    currentPage: 1
  })
  const [visible, updateVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [curDocumentInfo, setCurDocumentInfo] = useState({} as any)
  const userStore = useUserStore()
  const production = userStore.profile?.hrProduction || ''
  const userCompanyId = userStore.profile?.companyId || ''
  const isQC =
    production === HR_PRODUCTION.qc || production === HR_PRODUCTION.qcDemo
  const [curCompanyId, setCurCompanyId] = useState<string | undefined>(
    isQC ? table.searchParams?.companyId : userCompanyId
  )
  const [{ data: selectOptions }] = useUpadminCompaniesQuery({
    pause: !isQC
  })
  const [{ data: productOptions }] = useCompanyUpProductsQuery({
    variables: {
      companyId: curCompanyId
    },
    pause: !curCompanyId
  })

  const [{ data, error, fetching }, refetchDocumentList] =
    useQueryDocumentsQuery({
      variables: {
        isAdmin: true,
        search: {
          pager: omit(table.pager, 'total'),
          query: {
            ...omit(table.searchParams, 'documentCategoryIds', 'productCodes'),
            ...(table.searchParams?.documentCategoryIds
              ? {
                  documentCategoryIds: [table.searchParams?.documentCategoryIds]
                }
              : {}),
            ...(table.searchParams?.productCodes
              ? { productCodes: [table.searchParams.productCodes] }
              : {}),
            ...(!isQC ? { companyId: userCompanyId } : {})
          }
        }
      }
    })

  const [{ fetching: addFetching }, addDocument] = useAddDocumentMutation()
  const [{ fetching: editFetching }, editDocument] = useEditDocumentMutation()

  const [{ fetching: removeFetching }, removeDocument] =
    useRemoveDocumentMutation()

  // 删除文献
  const onDeleteHandle = async () => {
    const result = await removeDocument({
      documentId: curDocumentInfo?.document?.id
    })
    if (result?.data?.result?.success) {
      setDeleteModalVisible(false)
      message.success('删除成功')
      refetchDocumentList()
    } else {
      message.error(result.data?.result?.message || '删除失败')
    }
  }

  // 添加文献
  const onSubmitHandle = async (values: any) => {
    const result = await addDocument({
      info: {
        ...values,
        publishDate: dayjs(values?.publishDate).valueOf(),
        companyId: isQC ? values.companyId : userCompanyId
      }
    })

    if (result?.data?.result?.success) {
      updateVisible(false)
      message.success('上传成功')
      refetchDocumentList()
    } else {
      message.error(result.data?.result?.message || '上传失败')
    }
  }

  // 添加文献
  const onSubmitEditHandle = async (values: any) => {
    const result = await editDocument({
      info: {
        id: curDocumentInfo?.document?.id,
        title: values?.title,
        author: values?.author,
        source: values?.source,
        publishDate: dayjs(values?.publishDate).valueOf(),
        describe: values?.describe
      }
    })

    if (result?.data?.result?.success) {
      updateVisible(false)
      message.success('编辑文献成功')
      refetchDocumentList()
    } else {
      message.error(result.data?.result?.message || '编辑文献失败')
    }
  }

  const handleEdit = (record: any) => {
    setCurDocumentInfo(record)
    updateVisible(true)
  }

  const tableColumns = useTableColumns(
    userStore.hasAuth('uplatform_medical_literature_doc_delete_opt'),
    (record) => {
      setDeleteModalVisible(true)
      setCurDocumentInfo(record)
    },
    handleEdit,
    isQC
  )

  // 企业选项改变
  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDocumentList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <>
      <SearchTable
        sticky={true}
        scroll={{ x: 1700 }}
        table={table}
        searchProps={{
          fields: searchFields(
            selectOptions,
            productOptions,
            isQC,
            onCompanyChange,
            curCompanyId,
            userCompanyId
          )
        }}
        bordered
        loading={fetching}
        pager={data?.documents?.pager}
        dataSource={data?.documents?.items || []}
        size='middle'
        rowKey={(row) => `${row.document.id}_${row.archive[0]?.id}`}
        columns={tableColumns}
        extraOpera={
          <Button
            style={{ marginRight: 20 }}
            type='primary'
            onClick={() => {
              setCurDocumentInfo(null)
              updateVisible(true)
            }}
          >
            上传文献
          </Button>
        }
      />
      {visible && (
        <UploadModal
          isQC={isQC}
          userCompanyId={userCompanyId}
          loading={addFetching || editFetching}
          onCancel={() => updateVisible(false)}
          onSubmit={onSubmitHandle}
          onSubmitEdit={onSubmitEditHandle}
          document={curDocumentInfo?.document}
        />
      )}

      {deleteModalVisible ? (
        <Modal
          width={350}
          confirmLoading={removeFetching}
          title='确认删除'
          visible
          onOk={onDeleteHandle}
          onCancel={() => setDeleteModalVisible(false)}
          okText='确认'
          cancelText='取消'
        >
          确认删除该文献？
        </Modal>
      ) : null}
    </>
    // </PageContainer>
  )
}

export default LiteratureManage
