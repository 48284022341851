import React, { FC } from 'react'
import { Spin, Descriptions, Tag, Card, Row, Col } from 'antd'
import { useTaskDocumentAuditRecordByTaskIdQuery } from '../../graphql'

const caseData = [
  {
    groupType: '出院小结',
    children: [
      {
        label: '重复性',
        dimension: 'repeat'
      },
      {
        label: '脱敏',
        dimension: 'desensitize'
      },
      {
        label: '是否是该类型图片',
        dimension: 'relation'
      }
    ]
  },
  {
    groupType: '门诊病历',
    children: [
      {
        label: '重复性',
        dimension: 'repeat'
      },
      {
        label: '脱敏',
        dimension: 'desensitize'
      },
      {
        label: '是否是该类型图片',
        dimension: 'relation'
      }
    ]
  },
  {
    groupType: '诊断报告',
    children: [
      {
        label: '重复性',
        dimension: 'repeat'
      },
      {
        label: '脱敏',
        dimension: 'desensitize'
      },
      {
        label: '是否是该类型图片',
        dimension: 'relation'
      }
    ]
  },
  {
    groupType: '检测报告',
    children: [
      {
        label: '重复性',
        dimension: 'repeat'
      },
      {
        label: '脱敏',
        dimension: 'desensitize'
      },
      {
        label: '是否是该类型图片',
        dimension: 'relation'
      }
    ]
  },
  {
    groupType: '处方笺',
    children: [
      {
        label: '重复性',
        dimension: 'repeat'
      },
      {
        label: '脱敏',
        dimension: 'desensitize'
      },
      {
        label: '是否是该类型图片',
        dimension: 'relation'
      }
    ]
  }
]

export const CaseContentAuditRecord: FC<{
  taskId: string
  style?: React.CSSProperties | undefined
}> = ({ taskId, style }) => {
  const [{ data, fetching }] = useTaskDocumentAuditRecordByTaskIdQuery({
    variables: {
      taskId
    },
    pause: !taskId
  })

  const formatData = caseData.map((x) => {
    const items =
      data?.result?.filter((item) => item.verifyType === x.groupType) || []
    const children = x.children.map((c) => {
      const item: any =
        items.find((item) => item.verifyDimension === c.dimension) || {}
      return {
        label: c.label,
        status: item.status
      }
    })
    return {
      groupType: x.groupType,
      children
    }
  })

  return (
    <Spin spinning={fetching}>
      <Card title='病例内容验证' className='checkContainer' style={style}>
        <Descriptions column={1} labelStyle={{ width: 130 }}>
          {formatData.map((item, index) => {
            const renderNodes = []
            if (item.groupType) {
              renderNodes.push(
                <Descriptions.Item
                  key={item.groupType}
                  className={`checkTitle ${index > 0 && 'pt12'} `}
                >
                  {item.groupType}
                </Descriptions.Item>
              )
              renderNodes.push(
                item.children.map((citem: any, index: number) => {
                  return (
                    <Descriptions.Item key={index} label={citem.label}>
                      <Tag
                        color={
                          citem.status === 1
                            ? 'success'
                            : citem.status === 0
                            ? 'error'
                            : 'default'
                        }
                        className='checkTag'
                      >
                        {citem?.status === 1
                          ? '通过'
                          : citem?.status === 0
                          ? '不通过'
                          : '-'}
                      </Tag>
                    </Descriptions.Item>
                  )
                })
              )
              return renderNodes
            }
          })}
        </Descriptions>
      </Card>
    </Spin>
  )
}
