import React from 'react'
import { Input, Popconfirm, Space } from 'antd'
import { Link } from 'react-router-dom'
import { ColumnsType } from 'antd/lib/table'

import { StandardMeetingBaseAttender } from '../../graphql'
import { INVITE_STATUS_MAP } from '../../constant/hrradiation'
import {
  HRCompanySelector,
  HRRegionSelector,
  MeetingTypeSelector,
  ActivitiesTypeSelector,
  HRMeetingStatusSelector
} from '../../components/SelectorCollection'
import { HR_PRODUCTION } from '../../constant/user'
import dayjs from 'dayjs'

export const searchFields = (
  companyId: string | undefined,
  onCompanyChange: any,
  onRegionChange: any,
  meetingType: string | undefined,
  onActivityTypesChange: any,
  onMeetingTypeChange: any,
  production: string,
  userCompanyId: string
) => {
  const isQC =
    production === HR_PRODUCTION.qc || production === HR_PRODUCTION.qcDemo
  return [
    ...(isQC
      ? [
          {
            name: 'companyId',
            label: '所属企业',
            grow: true,
            render() {
              return (
                <HRCompanySelector
                  onChange={onCompanyChange}
                  style={{ minWidth: 120 }}
                />
              )
            }
          },
          {
            name: 'region',
            label: '省区',
            grow: true,
            render() {
              return (
                <HRRegionSelector
                  onChange={onRegionChange}
                  disabled={!companyId}
                  auto={true}
                  companyId={companyId}
                  style={{ minWidth: 120 }}
                />
              )
            }
          }
        ]
      : []),
    {
      name: 'meetingType',
      label: '会议类型',
      grow: true,
      render() {
        return (
          <MeetingTypeSelector
            filterByCompanyId
            companyId={isQC ? companyId : userCompanyId}
            disabled={isQC ? !companyId : false}
            auto={true}
            onChange={onMeetingTypeChange}
            style={{ minWidth: 120 }}
          />
        )
      }
    },
    {
      name: 'activityType',
      label: '活动类型',
      grow: true,
      render() {
        return (
          <ActivitiesTypeSelector
            companyId={isQC ? companyId : userCompanyId}
            auto={true}
            filterByMeetingType
            disabled={!meetingType}
            meetingType={meetingType}
            onChange={onActivityTypesChange}
            style={{ minWidth: 160 }}
          />
        )
      }
    },
    {
      name: 'meetingState',
      label: '活动状态',
      grow: true,
      render() {
        return <HRMeetingStatusSelector style={{ minWidth: 120 }} />
      }
    },
    {
      name: 'keyword',
      label: '搜索',
      colon: false,
      span: 10,
      render() {
        return <Input allowClear placeholder='请输入老师的姓名或手机号搜索' />
      }
    }
  ]
}

export const useTableColumns = ({
  onOpenModal,
  onDelete
}: {
  onOpenModal: (attender: StandardMeetingBaseAttender) => void
  onDelete: (attenderId: any, meetingId: any, attenderType: any) => void
}): ColumnsType<StandardMeetingBaseAttender> => [
  {
    title: '老师姓名',
    dataIndex: 'attenderName',
    key: 'attenderName',
    align: 'center',
    width: 100
  },
  {
    title: '老师手机',
    dataIndex: 'attenderPhone',
    key: 'attenderPhone',
    align: 'center',
    width: 100
  },
  {
    title: '活动ID',
    dataIndex: 'meetingId',
    key: 'meetingId',
    align: 'center',
    width: 200,
    render(v) {
      return (
        <Link target='_blank' to={`/meeting/${v}`}>
          {v}
        </Link>
      )
    }
  },
  {
    title: '任务创建时间',
    dataIndex: 'taskListInfo',
    key: 'gmtCreated',
    align: 'center',
    width: 120,
    render(v) {
      return v?.[0]?.gmtCreated
        ? dayjs(v?.[0]?.gmtCreated).format('YYYY-MM-DD')
        : '-'
    }
  },
  {
    title: '所属大区',
    dataIndex: 'meetingAttending',
    key: 'region',
    align: 'center',
    width: 150,
    render(v) {
      return v?.meetingRegion?.name
    }
  },
  {
    title: '所属产品',
    dataIndex: 'meetingAttending',
    key: 'belongProduct',
    align: 'center',
    width: 100,
    render(v) {
      return v?.meetingBaseData?.belongProduct?.productName
    }
  },
  {
    title: '所属领域',
    dataIndex: 'meetingAttending',
    key: 'hengruiImage_activityTypeSphere',
    align: 'center',
    width: 100,
    render(v) {
      return v?.hengruiImage_activityTypeSphere
    }
  },
  {
    title: '所属企业',
    dataIndex: 'meetingAttending',
    key: 'owner',
    align: 'center',
    width: 150,
    render(v) {
      return v?.meetingBaseData?.owner?.company?.cnName
    }
  },
  {
    title: '任务类型',
    dataIndex: 'meetingAttending',
    key: 'type',
    align: 'center',
    width: 100,
    render(v) {
      return v?.hengruiImage_categoryName
    }
  },
  {
    title: '活动类型',
    dataIndex: 'meetingAttending',
    key: 'activityType',
    align: 'center',
    width: 100,
    render(v) {
      return v?.hengruiImage_activityType
    }
  },
  {
    title: '任务积分',
    dataIndex: 'attenderFee',
    key: 'attenderFee',
    align: 'center',
    width: 100,
    render(v) {
      return (!isNaN(Number(v)) && Number(v).toFixed(2)) || ''
    }
  },
  {
    title: '是否公示',
    dataIndex: 'taskIsOpen',
    key: 'taskIsOpen',
    align: 'center',
    width: 100,
    render(v) {
      return v === null ? '-' : v ? '是' : '否'
    }
  },
  {
    title: '活动状态',
    dataIndex: 'meetingAttending',
    key: 'status',
    align: 'center',
    width: 100,
    render(v) {
      return v?.statusText
    }
  },
  {
    title: '邀请状态',
    dataIndex: 'attenderStatus',
    key: 'attenderStatus',
    align: 'center',
    width: 100,
    render(v) {
      return (v && INVITE_STATUS_MAP[v]) || '待邀请'
    }
  },
  // {
  //   title: '是否签约',
  //   dataIndex: 'attenderInfo',
  //   key: 'hasCertAndSigned',
  //   align: 'center',
  //   width: 100,
  //   render(v) {
  //     return v?.hasCertAndSigned ? '是' : '否'
  //   }
  // },
  {
    title: '任务状态',
    dataIndex: 'taskListInfo',
    key: 'taskStatus',
    align: 'center',
    width: 100,
    render(v) {
      const taskStatusList = v?.map((task: any) => task.status) || []
      if (taskStatusList.includes(3)) {
        return '审核退回'
      } else if (taskStatusList.includes(1)) {
        return '未完成'
      } else if (taskStatusList.includes(2)) {
        return '已完成'
      }
      return '-'
    }
  },
  {
    title: '任务详情',
    dataIndex: 'id',
    key: 'taskId',
    align: 'center',
    width: 100,
    render(v) {
      if (v) {
        return <Link to={`/taskDetail/${v}`}>查看</Link>
      }
      return '-'
    }
  },
  {
    title: '操作',
    width: 110,
    dataIndex: 'meetingAttending',
    key: 'operation',
    align: 'center',
    render(v, attender) {
      if (
        v?.status &&
        ['MEETING_IN_PROGRESS', 'MEETING_RETREATED'].includes(v.status)
      ) {
        return (
          <Space>
            <a onClick={() => onOpenModal(attender)}>邀请老师</a>
            <Popconfirm
              title='确定删除此任务？'
              onConfirm={() => {
                onDelete(
                  attender?.attenderId,
                  attender?.meetingId,
                  attender?.attenderType
                )
              }}
              okText='确定'
              cancelText='取消'
            >
              <a>删除</a>
            </Popconfirm>
          </Space>
        )
      }
      return '-'
    }
  }
]
