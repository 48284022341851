import React from 'react'
import { Input } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import { Dayjs } from 'dayjs'
import {
  HRRegionSelector,
  HRCompanySelector
} from '../../components/SelectorCollection'
import DatePicker from '../../components/CustomDatePicker'
import { UpadminSearchAttendersQuery } from '../../graphql'

//搜索字段声明
export const searchFields = (
  companyId: string,
  onRegionChange: any,
  onCompanyChange: any
) => [
  {
    name: 'companyId',
    label: '企业',
    span: 6,
    render() {
      return <HRCompanySelector onChange={onCompanyChange} />
    }
  },
  {
    name: 'regionId',
    label: '省区',
    span: 6,
    render() {
      return (
        <HRRegionSelector
          disabled={!companyId}
          auto={true}
          companyId={companyId}
          onChange={onRegionChange}
        />
      )
    }
  },
  {
    name: 'date',
    label: '会议日期',
    span: 10,
    render() {
      return <DatePicker.RangePicker locale={locale} format='YYYY-MM-DD' />
    }
  },
  {
    name: 'keyword',
    label: null,
    span: 12,
    render() {
      return <Input allowClear placeholder='请输入会议名称、讲师名字搜索' />
    }
  }
]

export const useTableColumns = (props: {
  onCancel: () => void
  type: string
  openConfirmModal: (v: any, meeting: any) => void
}): ColumnsType<UpadminSearchAttendersQuery['orders']['items'][0]> =>
  props.type === 'paying'
    ? [
        {
          title: '讲师姓名',
          dataIndex: 'attenders',
          key: 'attenderName',
          align: 'center',
          render(v: any) {
            return v?.attenderInfo?.cnName
          }
        },
        {
          title: '单位',
          dataIndex: 'attenders',
          key: 'hospital',
          align: 'center',
          render(v: any) {
            return v?.attenderInfo?.custom1
          }
        },
        {
          title: '手机号',
          dataIndex: 'attenders',
          key: 'mobile',
          align: 'center',
          render(v: any) {
            return v?.attenderInfo?.companyMobile
          }
        },
        {
          title: '企业',
          dataIndex: 'attenders',
          key: 'companyName',
          render(v) {
            return v?.meetingInfo?.base?.owner?.company?.cnName
          }
        },
        {
          title: '省区',
          dataIndex: 'attenders',
          key: 'region',
          render(v) {
            return v?.meetingInfo?.metingRegion?.name
          }
        },
        {
          title: '会议ID',
          dataIndex: 'attenders',
          key: 'meetingId',
          render(v) {
            return <Link to={`/meeting/${v?.meetingId}`}>{v?.meetingId}</Link>
          }
        },
        {
          title: '会议名称',
          dataIndex: 'attenders',
          key: 'meetingName',
          render(v) {
            return v?.meetingInfo?.base?.name
          }
        },
        {
          title: '费用',
          dataIndex: 'attenders',
          key: 'attenderFee',
          align: 'center',
          render(v: any) {
            return (
              (!isNaN(Number(v?.attenderFee)) &&
                Number(v?.attenderFee).toFixed(2)) ||
              ''
            )
          }
        },
        {
          title: '服务费',
          dataIndex: 'attenders',
          key: 'serviceFee',
          align: 'center',
          render(v: any) {
            return (
              (!isNaN(Number(v?.serviceFee)) &&
                Number(v?.serviceFee).toFixed(2)) ||
              ''
            )
          }
        },
        {
          title: '总费用',
          dataIndex: 'attenders',
          key: 'totalFee',
          align: 'center',
          render(v: any) {
            return (
              (!isNaN(Number(v?.totalFee)) && Number(v?.totalFee).toFixed(2)) ||
              ''
            )
          }
        }
      ]
    : [
        {
          title: '讲师姓名',
          dataIndex: 'attenders',
          key: 'attenderName',
          align: 'center',
          render(v: any) {
            return v?.attenderInfo?.cnName
          }
        },
        {
          title: '单位',
          dataIndex: 'attenders',
          key: 'hospital',
          align: 'center',
          render(v: any) {
            return v?.attenderInfo?.custom1
          }
        },
        {
          title: '手机号',
          dataIndex: 'attenders',
          key: 'mobile',
          align: 'center',
          render(v: any) {
            return v?.attenderInfo?.companyMobile
          }
        },
        {
          title: '企业',
          dataIndex: 'attenders',
          key: 'companyName',
          render(v) {
            return v?.meetingInfo?.base?.owner?.company?.cnName
          }
        },
        {
          title: '省区',
          dataIndex: 'attenders',
          key: 'region',
          render(v) {
            return v?.meetingInfo?.metingRegion?.name
          }
        },
        {
          title: '会议ID',
          dataIndex: 'attenders',
          key: 'meetingId',
          render(v) {
            return <Link to={`/meeting/${v?.meetingId}`}>{v?.meetingId}</Link>
          }
        },
        {
          title: '会议名称',
          dataIndex: 'attenders',
          key: 'meetingName',
          render(v) {
            return v?.meetingInfo?.base?.name
          }
        },
        {
          title: '费用',
          dataIndex: 'attenders',
          key: 'attenderFee',
          align: 'center',
          render(v: any) {
            return (
              (!isNaN(Number(v?.attenderFee)) &&
                Number(v?.attenderFee).toFixed(2)) ||
              ''
            )
          }
        },
        {
          title: '服务费',
          dataIndex: 'attenders',
          key: 'serviceFee',
          align: 'center',
          render(v: any) {
            return (
              (!isNaN(Number(v?.serviceFee)) &&
                Number(v?.serviceFee).toFixed(2)) ||
              ''
            )
          }
        },
        {
          title: '总费用',
          dataIndex: 'attenders',
          key: 'totalFee',
          align: 'center',
          render(v: any) {
            return (
              (!isNaN(Number(v?.totalFee)) && Number(v?.totalFee).toFixed(2)) ||
              ''
            )
          }
        },
        {
          title: '失败原因',
          dataIndex: 'attenders',
          key: 'failedReason',
          align: 'center',
          render(v: any) {
            return v?.settlementRecord?.note || ''
          }
        }
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   key: 'action',
        //   align: 'center',
        //   render(v, meeting) {
        //     return (
        //       <React.Fragment>
        //         <a onClick={() => props.openConfirmModal(v, meeting)}>
        //           重新打款
        //         </a>
        //       </React.Fragment>
        //     )
        //   }
        // }
      ]

export type FieldTypes = {
  companyId?: string
  region?: string
  keyword?: string
  date?: [Dayjs | undefined, Dayjs | undefined]
}
