import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, message, Space } from 'antd'
import moment from 'moment'
import omit from 'lodash.omit'
import {
  useAdjustOutPlanBudgetMutation,
  useOutPlanBudgetByMonthQuery,
  useOutPlanBudgetDetailQuery,
  useOutPlanBudgetLogQuery,
  usePersonalPlanBudgetUsedDetailQuery,
  PersonalPlanBudgetType
} from '../../graphql'
import BudgetDetailCard from './budgetDetailCard'
import ErrorView from '../../components/ErrorView'
import BudgetAddSubtractModal from './budgetAddSubtractModal'
import {
  BudgetFormItems,
  getEndTimestampByTargetMonth,
  getStartTimestampByTargetMonth,
  usePlanBudgetTableColumns,
  usePlanBudgetUsedDetailTableColumns
} from './data'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import './index.less'

const curMonth = moment().format('YYYY-MM')

const OutPlanBudgetDetail: QCPage = () => {
  const { id } = useParams<{ id: string }>()
  const table = useSearchTable({}, { pageSize: 10, currentPage: 1 })
  const detailTable = useSearchTable({}, { pageSize: 10, currentPage: 1 })
  const [isShowAddSubtractModal, updateAddSubtractModalShow] = useState(false)
  const [budgetIdMonth, setBudgetIdMonth] = useState('')
  const [targetMonth, setTargetMonth] = useState('')

  const [{ data, error, fetching }, refetchDetail] =
    useOutPlanBudgetDetailQuery({
      variables: {
        id: Number(id)
      },
      pause: !id
    })

  const [
    {
      data: monthBudgetData,
      error: monthBudgetDataError,
      fetching: monthBudgetDataFetching
    },
    refetchMonthDetail
  ] = useOutPlanBudgetByMonthQuery({
    variables: {
      param: {
        accountId: data?.result?.accountId,
        startTimestamp: getStartTimestampByTargetMonth(targetMonth),
        endTimestamp: getEndTimestampByTargetMonth(targetMonth)
      }
    },
    pause: !data?.result?.id || targetMonth === budgetIdMonth
  })

  const [
    { data: logData, error: logDataError, fetching: logDataFetching },
    refetchBudgetLog
  ] = useOutPlanBudgetLogQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        budgetId:
          targetMonth === budgetIdMonth
            ? data?.result?.id
            : monthBudgetData?.result?.id,
        accountId: data?.result?.accountId
        // startTimestamp: getStartTimestampByTargetMonth(targetMonth),
        // endTimestamp: getEndTimestampByTargetMonth(targetMonth)
      }
    },
    pause:
      !data?.result?.id ||
      (targetMonth !== budgetIdMonth && !monthBudgetData?.result?.id)
  })

  const [
    { data: detailData, error: detailDataError, fetching: detailDataFetching }
  ] = usePersonalPlanBudgetUsedDetailQuery({
    variables: {
      param: {
        pager: omit(detailTable.pager, 'total'),
        query: {
          type: PersonalPlanBudgetType.ExtraPersonal,
          accountId: data?.result?.accountId,
          targetMonth
        }
      }
    },
    pause:
      !data?.result?.id ||
      (targetMonth !== budgetIdMonth && !monthBudgetData?.result?.id)
  })

  const [, adjustPersonalBudget] = useAdjustOutPlanBudgetMutation()

  const budgetAddSubtract = () => {
    updateAddSubtractModalShow(true)
  }

  const onCancelAddSubtract = () => {
    updateAddSubtractModalShow(false)
  }

  const onSubmitAddSubtract = async (
    values: BudgetFormItems,
    callBack?: () => void
  ) => {
    const budgetData =
      curMonth !== budgetIdMonth ? monthBudgetData?.result : data?.result
    const result = await adjustPersonalBudget({
      id: Number(budgetData?.id),
      amount: values?.amount!.toString(),
      operatorRemark: values?.explain
    })
    if (result?.error) {
      throw new Error(result.error?.message)
    } else if (result?.data) {
      if (result.data?.result?.success) {
        message.success('本月预算增减成功')
        updateAddSubtractModalShow(false)
        if (curMonth !== budgetIdMonth) {
          refetchMonthDetail()
        } else {
          refetchDetail()
        }
        refetchBudgetLog()
      } else {
        message.error(result.data?.result?.message || '本月预算增减失败')
        callBack && callBack()
      }
    }
  }

  const onMonthChange = (date: any, dateString: string) => {
    console.log(dateString)
    table.updatePager((currentPager) => ({
      ...currentPager,
      currentPage: 1
    }))
    detailTable.updatePager((currentPager) => ({
      ...currentPager,
      currentPage: 1
    }))
    setTargetMonth(dateString)
  }

  useEffect(() => {
    if (data?.result?.start) {
      const budgetIdMonth = moment(data?.result?.start).format('YYYY-MM')
      setBudgetIdMonth(budgetIdMonth)
      setTargetMonth(budgetIdMonth)
    }
  }, [data])

  const tableColumns = usePlanBudgetTableColumns()
  const detailTableColumns = usePlanBudgetUsedDetailTableColumns()

  if (error || monthBudgetDataError) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error?.name || monthBudgetDataError?.name}
        message={error?.message || monthBudgetDataError?.message}
      />
    )
  }

  return (
    <Space direction='vertical' className='personalBudgetDetail'>
      <BudgetDetailCard
        fetching={fetching || monthBudgetDataFetching}
        data={
          targetMonth !== budgetIdMonth ? monthBudgetData?.result : data?.result
        }
        budgetType='outPlan'
        budgetAddSubtract={budgetAddSubtract}
        bodyStyle={{ paddingBottom: 0 }}
        basicInfoColumn={4}
        operationDisable={
          targetMonth !== curMonth ||
          (budgetIdMonth !== curMonth
            ? !monthBudgetData?.result?.id
            : !data?.result?.id)
        }
        isCurrentMonth={targetMonth === curMonth}
        targetMonth={targetMonth}
        onMonthChange={onMonthChange}
      />
      {(targetMonth !== budgetIdMonth
        ? monthBudgetData?.result
        : data?.result) && (
        <Card
          title='操作历史记录'
          bordered={false}
          headStyle={{ border: 'none' }}
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <SearchTable
            table={table}
            bordered
            loading={logDataFetching}
            showPanel={false}
            pager={logData?.result?.pager}
            dataSource={logData?.result?.items || []}
            columns={tableColumns}
            scroll={{ x: 1100 }}
            rowKey={'id'}
          />
        </Card>
      )}
      {(targetMonth !== budgetIdMonth
        ? monthBudgetData?.result
        : data?.result) && (
        <Card
          title='预算使用明细'
          bordered={false}
          headStyle={{ border: 'none' }}
          bodyStyle={{ paddingTop: 0 }}
        >
          <SearchTable
            table={detailTable}
            bordered
            loading={detailDataFetching}
            showPanel={false}
            pager={detailData?.result?.pager}
            dataSource={detailData?.result?.items || []}
            columns={detailTableColumns}
            scroll={{ x: true }}
          />
        </Card>
      )}
      {isShowAddSubtractModal && (
        <BudgetAddSubtractModal
          title='本月预算增减'
          budgetDetail={
            curMonth !== budgetIdMonth ? monthBudgetData?.result : data?.result
          }
          budgetType='outPlan'
          modalWidth={700}
          onCancel={onCancelAddSubtract}
          onSubmit={onSubmitAddSubtract}
        />
      )}
    </Space>
  )
}

export default OutPlanBudgetDetail
