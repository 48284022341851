import React, { FC } from 'react'
import { Space, Image } from 'antd'

import { TaskDocumentReviewContentQuery } from '../../graphql'

export const DocumentCommentResult: FC<{
  reviewContent?: TaskDocumentReviewContentQuery['result']
  properties?: any
}> = ({ reviewContent, properties }) => {
  const divStyle = {
    minHeight: 40,
    background: 'rgb(245, 246, 250)',
    borderRadius: '4px',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '10px',
    lineHeight: '1.5',
    margin: '0 0 24px 0'
  }
  const target = properties?.find(
    (i: { key: string }) => i.key === 'REVIEW_CONTENT_TEMPLATE'
  )?.value
  const reviewTemplate = target && JSON.parse(target)
  const signImgUrl = reviewContent?.find((x) => x.key === 'signImgUrl')?.value

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      {!reviewTemplate?.length
        ? '暂无数据'
        : reviewTemplate.map((input: { key: string }, index: number) => {
            return (
              <Space key={index} direction='vertical' style={{ width: '100%' }}>
                <span style={{ color: '#888' }}>{input.key}</span>
                <div style={divStyle}>
                  {reviewContent?.find((i) => i.key === input.key)?.value}
                </div>
              </Space>
            )
          })}
      {signImgUrl && (
        <Space direction='vertical' style={{ width: '100%' }}>
          <span style={{ color: '#888' }}>老师手写签名</span>
          <div style={divStyle}>
            <Image alt='签名' preview={false} src={signImgUrl} />
          </div>
        </Space>
      )}
    </Space>
  )
}
