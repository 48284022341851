import React, { FC, useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Select,
  InputNumber,
  DatePicker,
  message,
  Spin
} from 'antd'
import { Rule } from 'antd/lib/form'
import { formateDate } from './data'
import { useBalanceRecordMutation } from '../../graphql'
import {
  HRCompanySelector,
  HRRegionSelector
} from '../../components/SelectorCollection'
import moment from 'moment'

const RegisterModal: FC<{
  onCancel: () => void
  callback: () => void
}> = ({ callback, onCancel }) => {
  const [, balanceRecord] = useBalanceRecordMutation()
  const [form] = Form.useForm()
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [curCompanyId, setCurCompanyId] = useState('')
  const [curRegionId, setCurRegionId] = useState('')

  const checkValidate = (rule: Rule, value: any) => {
    if (value) {
      return Promise.resolve()
    } else {
      return Promise.reject('不能为空')
    }
  }

  const checkRegionValidate = () => {
    if (!curRegionId) {
      return Promise.reject('不能为空')
    } else {
      return Promise.resolve()
    }
  }

  // 提交登记金额
  const onOkHandle = async () => {
    try {
      const values = await form.validateFields()
      const { moneyType, companyId, time, count } = values
      setIsLoading(true)

      const result = await balanceRecord({
        paymentTime: formateDate(time),
        type: moneyType,
        amount: parseFloat(count),
        regionId: curRegionId,
        companyId: companyId
      })

      if (!result.data?.result) {
        setIsLoading(false)

        message.error('登记失败')
      } else {
        setIsLoading(false)
        message.success('登记成功')
        callback && callback()
      }
    } catch (err) {
      if (err?.errorFields?.length) {
        console.log(err)
        setIsLoading(false)
      } else {
        console.log(err)
        setIsLoading(false)
        message.success('登记失败')
      }
    }
  }

  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
    setCurRegionId('')
  }

  const onRegionChange = (value: string) => {
    setCurRegionId(value)
  }

  return (
    <Modal
      maskClosable={false}
      title={'登记预付金额'}
      visible
      onCancel={onCancel}
      centered
      width={500}
      onOk={onOkHandle}
      okText={'登记'}
      afterClose={() => form.resetFields()}
    >
      <Spin spinning={isLoading} tip='金额登记中，请耐心等待...'>
        <Form
          {...layout}
          form={form}
          initialValues={{
            time: moment(new Date(), 'YYYY-MM-DD'),
            moneyType: '1'
          }}
        >
          <Form.Item label='公司'>
            <Form.Item
              name='companyId'
              rules={[{ validator: checkValidate }]}
              noStyle
            >
              <HRCompanySelector onChange={onCompanyChange} />
            </Form.Item>
          </Form.Item>
          <Form.Item label='省区'>
            <Form.Item
              name='regionId'
              rules={[{ validator: checkRegionValidate }]}
              noStyle
            >
              <HRRegionSelector
                disabled={!curCompanyId}
                auto={false}
                companyId={curCompanyId}
                onChange={onRegionChange}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label='预付方向'
            name='moneyType'
            rules={[{ validator: checkValidate }]}
          >
            <Select placeholder='请选择预付方向'>
              <Select.Option value='1'>登记</Select.Option>
              <Select.Option value='2'>退回</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label='预付时间'
            name='time'
            rules={[{ validator: checkValidate }]}
          >
            <DatePicker showTime />
          </Form.Item>
          <Form.Item
            label='金额'
            name='count'
            rules={[{ validator: checkValidate }]}
          >
            <InputNumber
              precision={2}
              style={{ width: '100%' }}
              placeholder='请填写金额'
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default RegisterModal
