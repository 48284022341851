import Ajax from './ajax'

export const getShortUrl = async (longUrl: string) => {
  let response: { success: boolean; data?: string; error?: any } = {
    success: false
  }
  try {
    const ret: { hash: string } = await Ajax.post(
      'https://s.shangyantong.com/generate',
      {
        realUrl: longUrl
      }
    )
    if (ret && ret.hash) {
      response = {
        success: true,
        data: ret.hash
      }
    }
  } catch (error) {
    console.log(error)
    response.error = error
  }
  return response
}
