import React, { FC, useState } from 'react'
import {
  Modal,
  Input,
  Form,
  Checkbox,
  Row,
  Col,
  Tag,
  Button,
  Popconfirm
} from 'antd'
import dayjs from 'dayjs'
import { CloseCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { SalesOrderDetail } from '../../graphql'
import './index.less'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}

const ApprovalRetreatModal: FC<{
  title?: string
  orders: Array<any>
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
}> = ({ title = '审核', orders, onCancel, onSubmit }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [popConfirmVisible, setPopConfirmVisible] = useState(false)

  const onFinish = (values: any) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  const onConfirmSubmit = () => {
    form.submit()
  }

  const onCancelPopconfirm = () => {
    setPopConfirmVisible(false)
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formValues = _.values(values)
        if (!formValues.includes(true)) {
          return
        }
        if (!popConfirmVisible) {
          setPopConfirmVisible(true)
        }
      })
      .catch((errorInfo) => {
        if (popConfirmVisible) {
          setPopConfirmVisible(false)
        }
      })
  }

  return (
    <>
      <Modal
        maskClosable={false}
        title={`${title}退回`}
        className='financialReviewModal'
        visible
        onCancel={onCancel}
        centered
        destroyOnClose
        onOk={handleSubmit}
        okText={'确定'}
        closeIcon={
          <CloseCircleOutlined style={{ fontSize: 20, color: '#ccc' }} />
        }
      >
        <Form form={form} colon={false} {...layout} onFinish={onFinish}>
          {orders?.map((order, index) => {
            const salesOrder = order?.salesOrder
            return (
              <React.Fragment key={index}>
                <Form.Item
                  name={`orderId-${salesOrder?.id}`}
                  label='订单信息'
                  className='hideFormLabel formCheckboxItem'
                  valuePropName='checked'
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox value={salesOrder?.id} style={{ marginLeft: -45 }}>
                    <Tag className='checkboxTag'>
                      <span className='fontWeightText'>
                        订单信息({index + 1})
                      </span>
                      _{dayjs(salesOrder?.targetOccurTime).format('YYYY-MM-DD')}
                      _{salesOrder?.targetName || '消费订单_未选消费场所'}_¥
                      {salesOrder?.actualConsumptionAmount}
                    </Tag>
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues[`orderId-${salesOrder?.id}`] !==
                    curValues[`orderId-${salesOrder?.id}`]
                  }
                  noStyle
                >
                  {({ getFieldValue }) =>
                    getFieldValue(`orderId-${salesOrder?.id}`) ? (
                      <Form.Item
                        name={`reason-${salesOrder?.id}`}
                        label='退回理由'
                        rules={[{ required: true, message: '请填写退回理由' }]}
                      >
                        <Input.TextArea
                          maxLength={300}
                          placeholder='请填写退回理由'
                          autoSize={{ minRows: 4, maxRows: 6 }}
                        />
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </React.Fragment>
            )
          })}

          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues !== curValues}
            noStyle
          >
            {({ getFieldsValue }) => {
              const values = getFieldsValue(true)
              const formValues = _.values(values)
              return !formValues.includes(true) ? (
                <Row>
                  <Col span={20} offset={4} style={{ color: '#ff4d4f' }}>
                    请选择要退回的订单
                  </Col>
                </Row>
              ) : null
            }}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        maskClosable={false}
        title='确认退回单据'
        className='financialReviewModal'
        visible={popConfirmVisible}
        cancelText='否'
        onCancel={onCancelPopconfirm}
        centered
        destroyOnClose
        okText='是'
        onOk={onConfirmSubmit}
        confirmLoading={loading}
        closeIcon={
          <CloseCircleOutlined style={{ fontSize: 20, color: '#ccc' }} />
        }
        bodyStyle={{ padding: '24px 80px', color: '#888' }}
      >
        确认退回单据，本单据作废，用户将重新处理问题订单？
      </Modal>
    </>
  )
}

export default ApprovalRetreatModal
