import React, { FC, useState } from 'react'
import { Col, Form, Input, Modal, Row } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}

const ApprovalRetreatPreReviewModal: FC<{
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
}> = ({ onCancel, onSubmit }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = (values: any) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  const startSubmit = () => {
    form.submit()
  }

  return (
    <Modal
      maskClosable={false}
      title='退至初审'
      className='financialReviewModal'
      visible
      onCancel={onCancel}
      centered
      destroyOnClose
      onOk={startSubmit}
      okText={'确认'}
      confirmLoading={loading}
      closeIcon={
        <CloseCircleOutlined style={{ fontSize: 20, color: '#ccc' }} />
      }
      bodyStyle={{ padding: '24px 40px' }}
    >
      <Form form={form} colon={false} {...layout} onFinish={onFinish}>
        <Form.Item
          name='reason'
          label='退回理由'
          rules={[{ required: true, message: '请填写退回理由' }]}
        >
          <Input.TextArea
            maxLength={300}
            placeholder='请填写退回理由'
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </Form.Item>
      </Form>
      <Row>
        <Col offset={4}>点击“确认”按钮，该报销单状态将变为【待初审】</Col>
      </Row>
    </Modal>
  )
}

export default ApprovalRetreatPreReviewModal
