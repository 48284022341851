import React from 'react'
import { Input } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { map, get } from 'lodash'

import { HansohBudget, ProcessBudget } from '../../graphql'
import {
  BusinessDepartmentSelector,
  ComponyGroupSelector
} from '../../components/SelectorCollection'
import { Link } from 'react-router-dom'
import { ACCOUNT_STATUS } from '../../constant/user'

export const searchFields = (
  businessId?: string | undefined,
  onBusinessChange?: (value: string) => void,
  regionId?: string | undefined,
  onRegionChange?: (value: string) => void
) => [
  {
    name: 'businessId',
    label: '业务部',
    span: 8,
    render() {
      return <BusinessDepartmentSelector onChange={onBusinessChange} />
    }
  },
  {
    name: 'regionId',
    label: '大区',
    span: 8,
    render() {
      return (
        <ComponyGroupSelector
          pid={businessId}
          level='2'
          disabled={!businessId}
          onChange={onRegionChange}
        />
      )
    }
  },
  {
    name: 'areaId',
    label: '地区',
    span: 8,
    render() {
      return (
        <ComponyGroupSelector pid={regionId} level='3' disabled={!regionId} />
      )
    }
  },
  {
    name: 'keyword',
    label: '搜索',
    span: 12,
    render() {
      return <Input allowClear placeholder='请输入姓名、报销编号搜索' />
    }
  }
]

export const useTableColumns = (): ColumnsType<HansohBudget> => {
  return [
    {
      align: 'center',
      title: '业务部',
      dataIndex: 'business',
      key: 'business',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '大区',
      dataIndex: 'region',
      key: 'region',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '地区',
      dataIndex: 'area',
      key: 'area',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '报销编号',
      dataIndex: 'financeNo',
      key: 'financeNo',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '姓名',
      dataIndex: 'accountName',
      key: 'accountName',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '手机号',
      dataIndex: 'accountMobile',
      key: 'accountMobile',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '预算年份',
      dataIndex: 'year',
      key: 'year',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '预算月份',
      dataIndex: 'month',
      key: 'month',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '邮箱',
      dataIndex: 'accountEmail',
      key: 'accountEmail',
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '账号状态',
      dataIndex: 'accountStatus',
      key: 'accountStatus',
      render(v) {
        return ACCOUNT_STATUS[v]
      }
    },
    {
      align: 'center',
      title: '预算详情',
      dataIndex: 'budgetId',
      key: 'budgetId',
      render(v) {
        return <Link to={`/personalBudgetDetail/${v}`}>查看</Link>
      }
    }
  ]
}

export type FieldTypes = {
  businessId?: string
  regionId?: string
  areaId?: string
  keyword?: string
}

export const excelHead = [
  { name: '业务部', path: 'business', key: 'business' },
  { name: '大区', path: 'region', key: 'region' },
  { name: '地区', path: 'area', key: 'area' },
  { name: '报销编号', path: 'financeNo', key: 'financeNo' },
  { name: '姓名', path: 'accountName', key: 'accountName' },
  { name: '手机号', path: 'accountMobile', key: 'accountMobile' },
  { name: '预算年份', path: 'year', key: 'year' },
  { name: '预算月份', path: 'month', key: 'month' },
  { name: '邮箱', path: 'accountEmail', key: 'accountEmail' },
  { name: '账号状态', path: 'accountStatus', key: 'accountStatus' }
]

export const getData = (data: Array<HansohBudget>) => {
  return map(data, (item) => {
    const obj: { [key: string]: string | number } = {}

    excelHead.forEach((cell: { [key: string]: string }) => {
      const value = get(item, cell.path, '-')
      switch (cell.key) {
        case 'accountStatus':
          obj[cell.name] = ACCOUNT_STATUS[value]
          break
        default:
          obj[cell.name] = value
          break
      }
    })
    return obj
  })
}
