import React, { FC, useEffect, useState } from 'react'
import { notification, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { useMeetingTypeColumns } from './data'
import {
  MeetingCategory,
  useMeetingTypesByCompanyQuery,
  useUpadminCompaniesQuery,
  useUpdateMeetingCategoryEnabledMutation,
  useToggleMeetingCategoryAutoCompleteMutation,
  useUpdateMeetingCategoryTitleBlkListMutation,
  useUpdateMeetingCategoryPropertiesMutation
} from '../../graphql'
import { useSearchStore } from '../../hooks/pageSearch'
import { EditTitleBlkListModal } from './editTitleBlkListModal'
import { EditPropertiesModal } from './editPropertiesModal'
const tabKey = 'componyMeetingType'
const ComponyMeetingType: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[pageKey] }
  const table = useSearchTable<{ companyId?: string }>({})

  const [{ data: selectOptions }] = useUpadminCompaniesQuery()
  const [{ fetching: updateLoading }, updateStatus] =
    useUpdateMeetingCategoryEnabledMutation()

  const [{ fetching: toggleAutoCompleteLoading }, toggleAutoComplete] =
    useToggleMeetingCategoryAutoCompleteMutation()

  const [{ data, error, fetching }, refetch] = useMeetingTypesByCompanyQuery({
    variables: {
      companyId: table.searchParams.companyId
    },
    pause: !selectOptions?.companies?.length
  })

  const [, updateMeetingCategoryTitleBlkList] =
    useUpdateMeetingCategoryTitleBlkListMutation()

  const [, updateMeetingCategoryProperties] =
    useUpdateMeetingCategoryPropertiesMutation()

  const [editTitleBlkListVisible, setEditTitleBlkListVisible] = useState(false)
  const [editPropertiesVisible, setEditPropertiesVisible] = useState(false)
  const [currentCategory, setCurrentCategory] =
    useState<MeetingCategory | null>(null)

  const onToggleAutoComplete = (typeInfo: MeetingCategory) => {
    Modal.confirm({
      title: '确认',
      icon: <ExclamationCircleOutlined />,
      content: `确认${!typeInfo.autoComplete ? '启用' : '禁用'}${
        typeInfo.config?.title
      }自动完成？`,
      okText: '确认',
      cancelText: '取消',
      okButtonProps: { loading: toggleAutoCompleteLoading },
      onOk: async () => {
        const result = await toggleAutoComplete({
          categoryId: typeInfo.id
        })

        if (!result.data?.result?.success) {
          notification.error({
            message: `设置失败`,
            description: `【${typeInfo.config?.title}】${
              result.data?.result?.message || ''
            }`
          })
        } else {
          notification.success({
            message: `设置成功`,
            description: `【${typeInfo.config?.title}】`
          })
          refetch()
        }
      }
    })
  }

  const onStatusChange = (typeInfo: MeetingCategory) => {
    Modal.confirm({
      title: '确认',
      icon: <ExclamationCircleOutlined />,
      content: `确认${!typeInfo.enable ? '启用' : '禁用'}${
        typeInfo.config?.title
      }？`,
      okText: '确认',
      cancelText: '取消',
      okButtonProps: { loading: updateLoading },
      onOk: async () => {
        const result = await updateStatus({
          enabled: !typeInfo.enable,
          categoryId: typeInfo.id
        })

        if (!result.data?.result?.success) {
          notification.error({
            message: `${!typeInfo.enable ? '启用' : '禁用'}失败`,
            description: `【${typeInfo.config?.title}】${
              result.data?.result?.message || ''
            }`
          })
        } else {
          notification.success({
            message: `${!typeInfo.enable ? '启用' : '禁用'}成功`,
            description: `【${typeInfo.config?.title}】`
          })
          refetch()
        }
      }
    })
  }

  const onEditTitleBlacklist = (typeInfo: MeetingCategory) => {
    setEditTitleBlkListVisible(true)
    setCurrentCategory(typeInfo)
  }

  const onEditProperties = (typeInfo: MeetingCategory) => {
    setEditPropertiesVisible(true)
    setCurrentCategory(typeInfo)
  }

  // 自动查询第一个企业的数据
  useEffect(() => {
    if (!searchParams?.companyId && selectOptions?.companies[0]?.id) {
      table.updateSearchParams(
        {
          companyId: selectOptions?.companies[0]?.id
        },
        undefined,
        pageKey
      )
    }
  }, [JSON.stringify(selectOptions?.companies)])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        showOperaBtn={false}
        showPagination={false}
        table={table}
        tabKey={tabKey}
        bordered
        loading={fetching}
        dataSource={data?.result || []}
        size='middle'
        rowKey='id'
        columns={useMeetingTypeColumns({
          onStatusChange,
          onToggleAutoComplete,
          onEditTitleBlacklist,
          onEditProperties
        })}
        searchProps={{
          fields: [
            {
              name: 'companyId',
              label: '企业',
              span: 6,
              defaultValue: selectOptions?.companies[0]?.id!,
              render() {
                return (
                  <Select
                    showSearch
                    placeholder='请选择企业'
                    optionFilterProp='children'
                    key={selectOptions?.companies[0]?.id || ''}
                    defaultValue={selectOptions?.companies[0]?.id || ''}
                  >
                    {selectOptions?.companies.map((company, i) => (
                      <Select.Option
                        value={company?.id || ''}
                        key={company?.id || i}
                      >
                        {company.cnName || ''}
                      </Select.Option>
                    ))}
                  </Select>
                )
              }
            }
          ]
        }}
        // 新建base会议类型时，会默认给所有企业都加上禁用状态的新会议类型，所以不用手动给企业新增
        // extraPanelContent={
        //   <Button
        //     type='primary'
        //     style={{ position: 'absolute', right: '5%', bottom: '15px' }}
        //     onClick={() => updateModalShow(true)}
        //   >
        //     企业新增会议类型
        //   </Button>
        // }
      />

      {/* <Modal
        title='企业新增会议类型'
        visible={modalShow}
        onOk={onConfirm}
        onCancel={() => updateModalShow(false)}
        okText='确认'
        cancelText='取消'
        afterClose={() => form.resetFields()}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} form={form}>
          <Form.Item
            label='企业'
            name='componyId'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <HRCompanySelector />
          </Form.Item>
          <Form.Item
            label='会议类型'
            name='meetingType'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <MeetingTypeSelector />
          </Form.Item>
          <Form.Item
            label='状态'
            name='status'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Select placeholder='请选择状态'>
              <Select.Option value={1}>启用</Select.Option>
              <Select.Option value={0}>禁用</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal> */}
      <EditTitleBlkListModal
        visible={editTitleBlkListVisible}
        titleList={currentCategory?.titleFilter || []}
        onCancel={() => setEditTitleBlkListVisible(false)}
        onUpdate={async (titles) => {
          console.log(titles)
          if (!currentCategory) return
          const { data } = await updateMeetingCategoryTitleBlkList({
            categoryId: currentCategory.id,
            titleList: titles || []
          })
          if (data?.result?.success) {
            setEditTitleBlkListVisible(false)
          }
          refetch()
        }}
        companyId={searchParams.companyId}
      ></EditTitleBlkListModal>
      <EditPropertiesModal
        visible={editPropertiesVisible}
        category={currentCategory || {}}
        onCancel={() => setEditPropertiesVisible(false)}
        onUpdate={async (values) => {
          console.log(values)
          if (!currentCategory) return
          const { data } = await updateMeetingCategoryProperties({
            categoryId: currentCategory.id,
            properties: values.properties,
            sort: values.sort
          })
          if (data?.result?.success) {
            setEditPropertiesVisible(false)
          }
          refetch()
        }}
        companyId={searchParams.companyId}
      ></EditPropertiesModal>
    </main>
  )
}

export default ComponyMeetingType
