import React, { useMemo } from 'react'
import { Input, Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import dayjs, { Dayjs } from 'dayjs'
import { sumBy } from 'lodash'
import {
  DocumentCategories,
  ReviewStatusSelector,
  ArchiveTagsSelector
} from '../../components/SelectorCollection'
import {
  HrMeetingListQuery,
  StandardMeetingServiceBizStatus,
  QueryDocumentsReviewsQuery
} from '../../graphql'
import { HR_PRODUCTION } from '../../constant/user'
import {
  REVIEW_STATUS_MAP,
  REVIEW_LEVEL_PROPS
} from '../../constant/hrradiation'

//搜索字段声明
export const searchFields = [
  {
    name: 'tagIds',
    label: '文献标签',
    span: 8,
    render() {
      return <ArchiveTagsSelector />
    }
  },
  {
    name: 'documentCategoryIds',
    label: '文献权限',
    span: 6,
    render() {
      return <DocumentCategories />
    }
  },
  {
    name: 'documentReviewStatus',
    label: '点评状态',
    span: 9,
    render() {
      return <ReviewStatusSelector />
    }
  },
  {
    name: 'keywords',
    label: null,
    span: 10,
    render() {
      return (
        <Input
          allowClear
          placeholder='根据文献名称、点评老师名字、邀请人名字搜索'
        />
      )
    }
  }
]

export const useTableColumns = (
  openReviewDetail: (
    record: QueryDocumentsReviewsQuery['reviews']['items'][0]
  ) => void,
  startSettlement: (
    record: QueryDocumentsReviewsQuery['reviews']['items'][0]
  ) => void,
  hasSettlementAuth: boolean
): ColumnsType<QueryDocumentsReviewsQuery['reviews']['items'][0]> => {
  return [
    {
      title: '文献名称',
      dataIndex: 'documentInfo',
      align: 'center',
      key: 'title',
      render(v) {
        return v?.document?.title
      }
    },
    {
      title: '点评老师',
      dataIndex: 'name',
      align: 'center',
      key: 'title',
      width: 190,
      render(v, record) {
        return `${v}${record?.phone && `(${record.phone})`}`
      }
    },
    {
      title: '文献标签',
      width: 150,
      align: 'center',
      dataIndex: 'documentInfo',
      key: 'tags',
      render(v) {
        const tags = v?.archive[0]?.archiveTags
          ?.map((item: { tag: string }) => item?.tag)
          ?.join('、')
        return tags
      }
    },
    {
      title: '文献权限',
      width: 80,
      align: 'center',
      dataIndex: 'documentInfo',
      key: 'category',
      render(v) {
        return v?.document?.category?.title
      }
    },
    {
      title: '邀请人（销售）',
      width: 120,
      align: 'center',
      dataIndex: 'inviterName',
      key: 'inviterName',
      render(v) {
        return v
      }
    },
    {
      title: '点评状态',
      dataIndex: 'stateText',
      align: 'center',
      key: 'stateText',
      width: 120,
      render(v) {
        return v
      }
    },
    {
      title: '点评时间',
      width: 120,
      align: 'center',
      dataIndex: 'createdTimestamp',
      key: 'createdTime',
      render(v, record) {
        return record?.state !== REVIEW_STATUS_MAP.WAIT_REVIEW.id
          ? (v && !isNaN(Number(v)) && dayjs(Number(v)).format('YYYY-MM-DD')) ||
              '点评时间'
          : '-'
      }
    },
    {
      title: '点评内容',
      width: 80,
      ellipsis: true,
      align: 'center',
      dataIndex: 'state',
      key: 'reviewContent',
      render(v, record) {
        return v !== REVIEW_STATUS_MAP.WAIT_REVIEW.id ? (
          <a onClick={() => openReviewDetail(record)}>查看</a>
        ) : (
          '-'
        )
      }
    },
    {
      title: '点评结果',
      dataIndex: 'level',
      align: 'center',
      key: 'title',
      width: 80,
      render(v, record) {
        const levelKey = Object.keys(REVIEW_LEVEL_PROPS).find(
          (level) => REVIEW_LEVEL_PROPS[level].id === v
        )
        return levelKey ? REVIEW_LEVEL_PROPS[levelKey]?.text : '-'
      }
    },
    {
      title: '打款金额',
      dataIndex: 'fee',
      align: 'center',
      key: 'title',
      width: 110,
      render(v) {
        return v || '-'
      }
    },

    {
      fixed: 'right',
      align: 'center',
      width: 100,
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render(_, record) {
        return hasSettlementAuth &&
          record?.state === REVIEW_STATUS_MAP.SETTLEMENT_CONFIRMING.id ? (
          <a onClick={() => startSettlement(record)}>发起结算</a>
        ) : (
          '-'
        )
      }
    }
  ]
}

export type FieldTypes = {
  tagIds?: [string]
  documentCategoryIds?: string
  documentReviewStatus?: [number]
  keywords?: string
}
