import React, { FC, useRef, useEffect, useState } from 'react'
import { Select, Spin, TreeSelect } from 'antd'
import useAxios from 'axios-hooks'
import throttle from 'lodash.throttle'
import { SelectProps } from 'antd/lib/select'

import {
  useCityListQuery,
  useHrRegionListQuery,
  useHrMeetingBizStatusQuery,
  useHrVendorsListQuery,
  useUpadminCompaniesQuery,
  useUpadminRegionsQuery,
  useUpadminDoctorCompaniesQuery,
  useDepartmentsQuery,
  useDoctorTitlesQuery,
  useQueryArchiveTagsQuery,
  useQueryDocumentCategoriesQuery,
  useCompanyGroupQuery,
  useActivityTypesByMeetingTypeQuery,
  useFetchAllBaseMeetingTypesQuery,
  useMeetingTypesByCompanyQuery,
  useFetchAllBaseActivityTypesQuery,
  useCompanyApprovalProcessIdQuery,
  useCompanyUpProductsQuery,
  useQueryActivityTemplateQuery,
  usePersonalBudgetBusinessDepartmentQuery,
  useUpadminQueryDoctorTitlesQuery,
  useLimitSwapPeopleQuery,
  LimitSwapPeople,
  useQueryDocumentSphereQuery,
  useProvinceListQuery,
  useQueryCompanyRegionsQuery,
  useQueryCompanyAccountsByKeywordQuery,
  useApproveUserQuery,
  useSalesOrderStatusQuery,
  useSalesOrderTypesQuery,
  useSalesClaimFormStatusQuery,
  usePersonalPlanBudgetBusinessAuthorityQuery,
  PersonalPlanBudgetType,
  useUpadminCostCentersQuery
} from '../graphql'
import {
  ATTENDER_BILL_STATUS,
  BUDGET_TYPES,
  REVIEW_STATUS_MAP,
  SETTLEMENT_STATUS_MAP
} from '../constant/hrradiation'
import { treeDataFormat } from '../pages/RegionManageOfFinance/data'

const { TreeNode } = TreeSelect

interface Props<T = string> {
  mode?: SelectProps<T>['mode']
  value: string
  valueKey?: 'id' | 'name'
  onChange: SelectProps<T>['onChange']
  style?: React.CSSProperties
  placeholder?: string
  companyId?: string
}

interface TreeProps<T = string> {
  value: string[] | undefined
  style?: React.CSSProperties
  placeholder?: string
  companyId?: string
  onChange: (value: string[], labelList: React.ReactNode[], extra: any) => void
}

interface MultipleProps<T = Array<string>> {
  value: T
  valueKey?: 'id' | 'name'
  onChange: SelectProps<T>['onChange']
  style?: React.CSSProperties
  companyId?: string
}

export const PoiSelector: FC<
  Partial<Props<AmapPOI | undefined> & { city: string }>
> = ({ value = '', city = '', onChange, style }) => {
  const [{ data }, fetchPOIs] = useAxios<AmapPOIResponse>(
    {
      url: 'https://restapi.amap.com/v3/place/text',
      method: 'GET'
    },
    {
      manual: true
    }
  )
  const throttledFetchPOIs = useRef<any>(null)

  useEffect(() => {
    throttledFetchPOIs.current = throttle(
      (value: string) =>
        value &&
        fetchPOIs({
          params: {
            city,
            keywords: value,
            key: process.env.AMAP_KEY
          }
        }),
      1200
    )
  }, [])

  useEffect(() => {
    if (value) {
      fetchPOIs({
        params: {
          city,
          keywords: value,
          key: process.env.AMAP_KEY
        }
      })
    }
  }, [])

  return (
    <Select
      showSearch
      allowClear
      style={style}
      placeholder='Select a person'
      optionFilterProp='children'
      onSearch={(v) => throttledFetchPOIs.current?.(v)}
      value={value}
      onChange={(id, options) => {
        onChange?.(
          data.pois.find((poi) => poi.id === id),
          options
        )
      }}
    >
      {data?.pois.map((poi) => {
        return (
          <Select.Option value={poi.id!} key={poi.id}>
            {poi.name}
            <p style={{ fontSize: 12 }}>{poi.address}</p>
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 省区
export const HRRegionSelector: FC<
  Partial<Props> & {
    isMulti?: boolean
    auto?: boolean
    companyId?: string
    disabled?: boolean
  }
> = ({
  disabled = false,
  isMulti = false,
  auto = true,
  companyId,
  value,
  valueKey = 'id',
  onChange,
  style
}) => {
  const props_ = auto ? { value: value } : { defaultValue: undefined }
  let data = [] as any
  if (!disabled) {
    const [{ data: data_ }] = companyId
      ? useUpadminRegionsQuery({
          variables: {
            companyId
          }
        })
      : useHrRegionListQuery()

    data = data_
  }

  return (
    <Select
      mode={isMulti ? 'multiple' : undefined}
      disabled={disabled}
      showSearch
      allowClear
      style={style}
      placeholder='请选择省区'
      optionFilterProp='children'
      key={(data?.regions && data?.regions[0]?.id) || ''} // 每当省区列表改变，select重新渲染
      {...props_}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.regions &&
        data?.regions.map((region: any) => {
          if (region.name === 'rootRegion') return null

          return (
            <Select.Option value={region[valueKey]} key={region[valueKey]}>
              {region?.name || ''}
            </Select.Option>
          )
        })}
    </Select>
  )
}

// 城市
export const CitySelector: FC<Partial<Props>> = ({
  value,
  valueKey = 'id',
  onChange,
  style
}) => {
  const [{ data }] = useCityListQuery()
  return (
    <Select
      showSearch
      allowClear
      style={style}
      placeholder='Select a person'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.cities.map((city) => {
        return (
          <Select.Option value={city[valueKey]} key={city[valueKey]}>
            {city.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 省份
export const ProvinceSelector: FC<Partial<Props>> = ({
  value,
  valueKey = 'id',
  onChange,
  mode = undefined,
  style
}) => {
  const [{ data }] = useProvinceListQuery()
  return (
    <Select
      mode={mode}
      showSearch
      allowClear
      style={style}
      placeholder='请选择省份'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.cities.map((province) => {
        return (
          <Select.Option value={province[valueKey]} key={province[valueKey]}>
            {province.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 会议/活动状态
export const HRMeetingStatusSelector: FC<Partial<MultipleProps>> = ({
  value = [],
  onChange,
  style
}) => {
  const [{ data }] = useHrMeetingBizStatusQuery()
  return (
    <Select
      mode='multiple'
      allowClear
      showSearch
      style={style}
      placeholder='请选择状态'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.status.map((status) => {
        return (
          <Select.Option value={status.value} key={status.value}>
            {status.label}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 结算状态
export const SettlementStatusSelector: FC<
  Partial<MultipleProps & { auto?: boolean; disabled?: boolean }>
> = ({ auto = true, disabled = false, value = [], onChange, style }) => {
  const props_ = auto ? { value: value } : { defaultValue: undefined }
  return (
    <Select
      allowClear
      style={style}
      placeholder='请选择状态'
      value={value}
      disabled={disabled}
      {...props_}
      onChange={onChange}
    >
      {Object.keys(SETTLEMENT_STATUS_MAP)?.map((status) => {
        return (
          <Select.Option value={status} key={status}>
            {SETTLEMENT_STATUS_MAP[status]}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 供应商
export const HRVendorsSelector: FC<Partial<Props>> = ({
  value,
  onChange,
  style
}) => {
  const [{ data }] = useHrVendorsListQuery()

  return (
    <Select
      showSearch
      allowClear
      style={style}
      placeholder='选择项目服务商'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {(data?.vendors || []).map((vendor, index) => {
        return (
          <Select.Option
            value={vendor.companyId || ''}
            key={`${vendor.companyId}_${index}`}
          >
            {vendor.cnName}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 企业列表选择
export const HRCompanySelector: FC<
  Partial<
    Props & {
      isFromDoctorPage?: boolean
      placeholder?: string
      allowClear?: boolean
      optionData?: any[]
    }
  >
> = ({
  allowClear = true,
  isFromDoctorPage = false,
  value,
  valueKey = 'id',
  onChange,
  style,
  placeholder = '请选择企业',
  optionData = []
}) => {
  const [{ data }] = !isFromDoctorPage
    ? useUpadminCompaniesQuery({ pause: optionData.length > 0 })
    : useUpadminDoctorCompaniesQuery({ pause: optionData.length > 0 })
  return (
    <Select
      showSearch
      allowClear={allowClear}
      style={style}
      // placeholder=''
      optionFilterProp='children'
      placeholder={placeholder}
      value={value}
      key={data?.companies[0]?.id || ''}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {(data?.companies || optionData).map((company, i) => (
        <Select.Option
          value={company[valueKey] || ''}
          key={company[valueKey] || i}
        >
          {company.cnName || ''}
        </Select.Option>
      ))}
    </Select>
  )
}

// 科室
export const HRDepartmentSelector: FC<
  Partial<Props & { auto?: boolean; disabled?: boolean; companyId?: string }>
> = ({ value, onChange, style, auto = true, disabled = false, companyId }) => {
  const [{ data }] = useDepartmentsQuery({
    variables: {
      companyId: companyId,
      query: {
        pager: {
          currentPage: 1,
          pageSize: 999
        }
      }
    },
    pause: !companyId
  })

  const matchedValue = data?.result?.items?.filter(
    (item: any) => item.groupId === value
  )
  const value_ = !matchedValue?.length ? '' : value

  return (
    <Select
      showSearch
      allowClear
      className={!value_ ? 'select_title_value_blank_' : ''}
      style={style}
      placeholder='选择部门'
      optionFilterProp='children'
      disabled={disabled}
      key={!auto ? companyId : ''} // 公司改变，select就重新渲染
      value={value_}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.result?.items
        ?.filter((item) => item.name !== 'rootDepartment')
        ?.map((depart) => {
          return (
            <Select.Option value={depart.groupId} key={depart.groupId}>
              {depart.name}
            </Select.Option>
          )
        })}
    </Select>
  )
}

// 职称
export const HRDoctorTitleSelector: FC<
  Partial<Props & { auto?: boolean; disabled?: boolean; companyId?: string }>
> = ({ auto = true, value, onChange, style, disabled = false, companyId }) => {
  const [{ data }] = useDoctorTitlesQuery({
    variables: {
      companyId: companyId,
      query: {
        pager: {
          currentPage: 1,
          pageSize: 999
        }
      }
    },
    pause: !companyId
  })

  // 会存在职称id并不在查询列表里的情况，此时清空value
  const matchedValue = data?.result?.items?.filter(
    (item: any) => item.groupId === value
  )
  const value_ = !matchedValue?.length ? '' : value

  return (
    <Select
      className={!value_ ? 'select_department_value_blank_' : ''}
      showSearch
      allowClear
      style={style}
      placeholder='选择职称'
      optionFilterProp='children'
      disabled={disabled}
      value={value_}
      // {...props_}
      key={!auto ? companyId : ''} // 公司改变，select就重新渲染
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.result?.items
        ?.filter((item) => item.name !== 'rootTitle')
        ?.map((depart) => {
          return (
            <Select.Option value={depart.groupId} key={depart.groupId}>
              {depart.name}
            </Select.Option>
          )
        })}
    </Select>
  )
}

// 开票状态
export const HRBillStatusSelector: FC<Partial<Props>> = ({
  value,
  onChange,
  style
}) => {
  return (
    <Select
      allowClear
      showSearch
      style={style}
      placeholder='选择开票状态'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {Object.keys(ATTENDER_BILL_STATUS).map((value) => {
        return (
          <Select.Option value={value} key={value}>
            {ATTENDER_BILL_STATUS[value]}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 文献标签
export const ArchiveTagsSelector: FC<Partial<MultipleProps>> = ({
  value = [],
  onChange,
  style,
  companyId
}) => {
  const [{ data }] = useQueryArchiveTagsQuery({
    variables: {
      companyId
    }
  })
  return (
    <Select
      mode='multiple'
      allowClear
      showSearch
      style={style}
      // placeholder='Select a person'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.tags?.map((tag, index) => {
        return (
          <Select.Option value={tag?.id || ''} key={tag?.id || index}>
            {tag?.tag}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 文献权限
export const DocumentCategories: FC<Partial<Props>> = ({
  value = '',
  onChange,
  style,
  companyId
}) => {
  const [{ data }] = useQueryDocumentCategoriesQuery({
    variables: { companyId }
  })

  return (
    <Select
      showSearch
      allowClear
      style={style}
      placeholder='选择项目服务商'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.categories?.map((item, index) => (
        <Select.Option key={item?.id || index} value={item?.id || ''}>
          {item?.title}
        </Select.Option>
      ))}
    </Select>
  )
}

// 点评状态
// 文献标签
export const ReviewStatusSelector: FC<Partial<MultipleProps>> = ({
  value = [],
  onChange,
  style
}) => {
  return (
    <Select
      mode='multiple'
      allowClear
      showSearch
      style={style}
      // placeholder='Select a person'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {/* 不显示“评审退回修改”的状态 */}
      {Object.keys(REVIEW_STATUS_MAP)
        .filter((status) => status !== 'REJECT')
        .map((key) => (
          <Select.Option
            key={REVIEW_STATUS_MAP[key].id}
            value={REVIEW_STATUS_MAP[key].id}
          >
            {REVIEW_STATUS_MAP[key].text}
          </Select.Option>
        ))}
    </Select>
  )
}

// 公司组织架构
export const ComponyGroupSelector: FC<
  Partial<Props & { level?: string; disabled?: boolean; pid?: string }>
> = ({ value, onChange, style, companyId, level, disabled = false, pid }) => {
  const [{ data, fetching }] = useCompanyGroupQuery({
    variables: {
      ...(!companyId ? {} : { companyId: companyId }),
      ...(!level ? {} : { level: level }),
      ...(!pid ? {} : { pid: pid }),
      type: 'department'
    },
    pause: disabled
  })

  return (
    <Select
      disabled={disabled}
      loading={fetching}
      showSearch
      allowClear
      style={style}
      placeholder='请选择'
      optionFilterProp='children'
      value={!fetching ? value : undefined}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.result?.map((group) => {
        return (
          <Select.Option value={group.groupId} key={group.groupId}>
            {group.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 活动类型
export const ActivitiesTypeSelector: FC<
  Partial<{
    auto: boolean
    isMulti: boolean // 是否多选
    value: string | Array<string>
    onChange: SelectProps<string | Array<string>>['onChange']
    style: React.CSSProperties
    allowClear: boolean
    disabled: boolean
    meetingType: string
    filterByMeetingType: boolean // 是否根据会议类型查询活动类型
    companyId: string
  }>
> = ({
  auto = true,
  isMulti = true,
  allowClear = true,
  value,
  onChange,
  style,
  disabled = false,
  filterByMeetingType = false,
  meetingType,
  companyId
}) => {
  const props_ = auto ? { value: value } : { defaultValue: undefined }
  let options: Array<{ [key: string]: any }> = []

  if (!filterByMeetingType) {
    // 全量查询活动类型
    const [{ data }] = useFetchAllBaseActivityTypesQuery()
    options =
      data?.result?.map((i) => {
        return { name: i.title, value: i.code }
      }) || []
  } else {
    const [{ data }] = useActivityTypesByMeetingTypeQuery({
      variables: { meetingType, companyId },
      pause: !meetingType
    })
    options =
      data?.result?.map((i: any) => {
        return { name: i?.config?.title, value: i?.config?.code }
      }) || []
  }

  return (
    <Select
      disabled={disabled}
      mode={isMulti ? 'multiple' : undefined}
      allowClear={allowClear}
      showSearch
      style={style}
      placeholder='请选择活动类型'
      optionFilterProp='children'
      key={`${JSON.stringify(options)}${JSON.stringify(disabled)}`}
      {...props_}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options.map((type: any, index: number) => (
        <Select.Option value={type?.value} key={index}>
          {type?.name}
        </Select.Option>
      ))}
    </Select>
  )
}

// 会议类型
export const MeetingTypeSelector: FC<{
  auto?: boolean
  value?: string
  onChange?: SelectProps<string>['onChange']
  style?: React.CSSProperties
  disabled?: boolean
  allowClear?: boolean
  filterByCompanyId?: boolean
  companyId?: string // 是否根据公司查询
  mode?: 'multiple' | 'tags' | undefined
  optionData?: any[]
}> = ({
  auto = true,
  allowClear = true,
  value,
  onChange,
  style,
  disabled = false,
  filterByCompanyId = false,
  companyId,
  mode = undefined,
  optionData = []
}) => {
  const props_ = auto ? { value: value } : { defaultValue: undefined }
  let options: Array<{ [key: string]: any }> = []

  if (!filterByCompanyId) {
    // 请求全量活动类型
    const [{ data }] = useFetchAllBaseMeetingTypesQuery({
      pause: optionData.length > 0
    })
    options =
      (data?.result || optionData)?.map((i) => {
        return { name: i.title, value: i.code }
      }) || []
  } else {
    // 根据公司查询活动类型
    const [{ data }] = useMeetingTypesByCompanyQuery({
      variables: { companyId },
      pause: !companyId || optionData.length > 0
    })
    options =
      (data?.result || optionData)
        ?.filter((x) => x.enable)
        .map((i) => {
          return { name: i.config?.title, value: i.config?.code }
        }) || []
  }

  return (
    <Select
      mode={mode}
      disabled={disabled}
      allowClear={allowClear}
      showSearch
      style={style}
      placeholder='请选择会议类型'
      optionFilterProp='children'
      {...props_}
      key={`${JSON.stringify(options)}${JSON.stringify(disabled)}`}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options.map((meeting) => {
        return (
          <Select.Option value={meeting.value} key={meeting.value}>
            {meeting.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 审批流
export const ProcessSelector: FC<
  Partial<Props & { companyId?: string; disabled?: boolean }>
> = ({ value, onChange, style, companyId, disabled }) => {
  const [{ data, fetching }] = useCompanyApprovalProcessIdQuery({
    variables: {
      companyId: companyId || ''
    },
    pause: !companyId
  })

  return (
    <Select
      disabled={disabled}
      loading={fetching}
      showSearch
      allowClear
      style={style}
      placeholder='请选择审批流'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.result?.map((process, index) => {
        return (
          <Select.Option value={process.id || ''} key={process.id || index}>
            {process.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 文献标签选择器
export const LiteratureTagSelector: FC<Partial<Props> & { auto?: boolean }> = ({
  mode, // 不传此参数时单选，mode = 'multiple'时多选
  onChange,
  placeholder,
  style,
  companyId,
  value,
  auto = false
}) => {
  const props_ = auto ? { value: value } : { defaultValue: undefined }
  const [{ data, error, fetching }] = useQueryArchiveTagsQuery({
    variables: { companyId }
  })

  return (
    <Select
      loading={fetching}
      disabled={fetching}
      mode={mode}
      allowClear
      showSearch
      style={style}
      placeholder={placeholder || '请选择'}
      optionFilterProp='children'
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props_}
    >
      {data?.tags?.map((tag, index) => {
        return (
          <Select.Option value={tag?.id || ''} key={tag?.id || index}>
            {tag?.tag}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export const LiteratureProductSelector: FC<
  Partial<Props> & { auto?: boolean }
> = ({
  mode,
  onChange,
  placeholder,
  style,
  companyId,
  value,
  auto = false
}) => {
  const props_ = auto ? { value: value } : { defaultValue: undefined }
  const [{ data, error, fetching }] = useCompanyUpProductsQuery({
    variables: { companyId }
  })

  return (
    <Select
      loading={fetching}
      disabled={fetching}
      mode={mode}
      allowClear
      showSearch
      style={style}
      placeholder={placeholder || '请选择'}
      optionFilterProp='children'
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props_}
    >
      {data?.companyUpProducts?.map((item, index) => (
        <Select.Option
          key={item?.productCode || index}
          value={item?.productCode || ''}
        >
          {item?.productName}
        </Select.Option>
      ))}
    </Select>
  )
}

// 文献权限选择器
export const LiteratureAuthSelector: FC<
  Partial<Props> & { auto?: boolean }
> = ({
  mode,
  onChange,
  placeholder,
  style,
  companyId,
  value,
  auto = false
}) => {
  const props_ = auto ? { value: value } : { defaultValue: undefined }
  const [{ data, error, fetching }] = useQueryDocumentCategoriesQuery({
    variables: { companyId },
    pause: !companyId
  })

  return (
    <Select
      loading={fetching}
      disabled={fetching}
      mode={mode}
      allowClear
      showSearch
      style={style}
      placeholder={placeholder || '请选择'}
      optionFilterProp='children'
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props_}
    >
      {data?.categories?.map((item, index) => (
        <Select.Option key={item?.id || index} value={item?.id || ''}>
          {item?.title}
        </Select.Option>
      ))}
    </Select>
  )
}

// 模板选择器
export const ActivityTemplateSelector: FC<Partial<Props>> = ({
  mode,
  onChange,
  placeholder,
  style,
  companyId
}) => {
  const [{ data, error, fetching }] = useQueryActivityTemplateQuery({
    variables: { companyId }
  })

  return (
    <Select
      loading={fetching}
      disabled={fetching}
      mode={mode}
      allowClear
      showSearch
      style={style}
      placeholder={placeholder || '请选择'}
      optionFilterProp='children'
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.result?.map((item, index) => (
        <Select.Option
          key={item?.templateCode || index}
          value={item?.templateCode || ''}
        >
          {item?.templateName}
        </Select.Option>
      ))}
    </Select>
  )
}

// 个人预算业务部
export const BusinessDepartmentSelector: FC<Partial<Props>> = ({
  value,
  onChange,
  style
}) => {
  const [{ data, fetching }] = usePersonalBudgetBusinessDepartmentQuery()

  return (
    <Select
      loading={fetching}
      showSearch
      allowClear
      style={style}
      placeholder='请选择'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.result?.map((department) => {
        return (
          <Select.Option
            value={department.businessId}
            key={department.businessId}
          >
            {department.businessName}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 个人预算业务部
export const DoctorTitleSelector: FC<Partial<Props>> = ({
  value,
  onChange,
  companyId,
  style
}) => {
  const [{ data, fetching }] = useUpadminQueryDoctorTitlesQuery({
    variables: { companyId },
    pause: !companyId
  })

  return (
    <Select
      loading={fetching}
      mode='multiple'
      showSearch
      allowClear
      style={style}
      placeholder='请选择'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.upadminQueryDoctorTitles?.map((department) => {
        return (
          <Select.Option value={department.groupId} key={department.groupId}>
            {department.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 人员预算调整-人员选择
export const LimitSwapPeopleSelector: FC<
  Partial<Props & { disableValue?: string }>
> = ({ value, onChange, style, disableValue }) => {
  const [keyword, setKeyword] = useState('')
  const [{ data, error, fetching }, fetchSwapPeoples] = useLimitSwapPeopleQuery(
    {
      variables: {
        keyword
      },
      pause: !keyword
    }
  )
  const throttledFetchSwapPeoples = useRef<any>(null)

  useEffect(() => {
    throttledFetchSwapPeoples.current = throttle(
      (value: string) => value && setKeyword(value),
      800,
      { leading: false, trailing: true }
    )
  }, [])

  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      style={style}
      placeholder='请输入报销编号，姓名，手机号'
      onSearch={(v) => throttledFetchSwapPeoples.current?.(v)}
      notFoundContent={fetching ? <Spin size='small' /> : null}
      value={value}
      onChange={(accountId, option) => {
        onChange?.(accountId, option)
      }}
    >
      {data?.result?.map((people: LimitSwapPeople) => {
        return (
          <Select.Option
            value={people.accountId!}
            key={people.accountId!}
            name={people.name}
            disabled={disableValue === people.accountId}
          >
            {people.financeNo}-{people.name}-{people.phone}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 领域选择器
export const DomainSelector: FC<Partial<Props> & { auto?: boolean }> = ({
  mode,
  value,
  onChange,
  placeholder,
  companyId,
  style,
  auto = false
}) => {
  const props_ = auto ? { value: value } : { defaultValue: undefined }
  const [{ data, error, fetching }] = useQueryDocumentSphereQuery({
    variables: {
      params: {
        companyIds: [companyId!]
      }
    },
    pause: !companyId
  })

  return (
    <Select
      loading={fetching}
      disabled={fetching}
      mode={mode}
      value={value}
      allowClear
      showSearch
      style={style}
      placeholder={placeholder || '请选择'}
      optionFilterProp='children'
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props_}
    >
      {data?.spheres.map((sphere) => {
        return (
          <Select.Option value={sphere.sphereCode!} key={sphere.sphereCode!}>
            {sphere.sphereName}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 企业用户选择器
export const CompanyUserSelector: FC<
  Partial<Props & { auto?: boolean; disabled?: boolean }>
> = ({
  value,
  onChange,
  style,
  companyId,
  mode,
  auto = false,
  disabled = false
}) => {
  const [keyword, setKeyword] = useState('')
  const props_ = auto ? { value: value } : { defaultValue: undefined }
  const [{ data, error, fetching }] = useQueryCompanyAccountsByKeywordQuery({
    variables: {
      companyId: companyId!,
      keyword
    },
    pause: !companyId || !keyword
  })
  const throttledFetchUsers = useRef<any>(null)

  useEffect(() => {
    throttledFetchUsers.current = throttle(
      (value: string) => value && setKeyword(value),
      800,
      { leading: false, trailing: true }
    )
  }, [])

  return (
    <Select
      showSearch
      allowClear
      mode={mode}
      disabled={disabled}
      filterOption={false}
      style={style}
      placeholder='请输入用户姓名/手机号'
      onSearch={(v) => throttledFetchUsers.current?.(v)}
      notFoundContent={fetching ? <Spin size='small' /> : null}
      onChange={onChange}
      {...props_}
    >
      {data?.users?.map((user: any) => {
        return (
          <Select.Option value={user.accountId!} key={user.accountId!}>
            {user.cnName}-{user.companyMobile}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 区域选择器
export const RegionSelector: FC<
  Partial<TreeProps> & {
    disabled?: boolean
    optionData?: any[]
  }
> = ({
  value,
  onChange,
  placeholder,
  companyId,
  style,
  disabled = false,
  optionData = []
}) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const [treeData, setTreeData] = useState([])
  const [{ data, error, fetching }] = useQueryCompanyRegionsQuery({
    variables: {
      companyId,
      type: 'region'
    },
    pause: !companyId || optionData.length > 0
  })

  useEffect(() => {
    if ((!fetching && companyId) || optionData.length > 0) {
      const tdata = treeDataFormat(data?.regions || optionData, '')
      setTreeData(tdata)
      setExpandedKeys([tdata[0]?.value])
    }
  }, [data, fetching, optionData])

  const handleOnTreeExpand = (expandedKeys: any) => {
    setExpandedKeys(expandedKeys)
  }

  return (
    <TreeSelect
      disabled={disabled}
      showSearch
      treeCheckable
      multiple
      style={style}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={placeholder || '请选择区域'}
      allowClear
      treeDefaultExpandAll
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      treeExpandedKeys={expandedKeys}
      onTreeExpand={handleOnTreeExpand}
      value={value}
      treeData={treeData}
      treeNodeFilterProp='title'
      onChange={(value, labelList, extra) =>
        onChange && onChange(value, labelList, extra)
      }
    />
  )
}

// 审批人员选择器
export const ApproveUserSelector: FC<
  Partial<Props> & { auto?: boolean; isSave?: boolean; isRemoveSys?: boolean }
> = ({
  mode,
  value,
  onChange,
  placeholder,
  style,
  auto = false,
  isSave = false,
  isRemoveSys = true
}) => {
  const props_ = auto ? { value: value } : { defaultValue: undefined }
  const [{ data, error, fetching }] = useApproveUserQuery()

  const onValueChange = (value: string, option: any) => {
    if (isSave) {
      if (!value) {
        localStorage.removeItem('meeting-approve-user')
      } else {
        localStorage.setItem('meeting-approve-user', value)
      }
    }
    onChange?.(value, option)
  }

  return (
    <Select
      loading={fetching}
      disabled={fetching}
      mode={mode}
      value={value}
      allowClear
      style={style}
      placeholder={placeholder || '请选择审批人员'}
      optionFilterProp='children'
      onChange={onValueChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props_}
    >
      {data?.result?.map((user) => {
        if (isRemoveSys && user.toLocaleLowerCase() === 'sys') return null

        return (
          <Select.Option value={user!} key={user!}>
            {user}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 成本中心
export const CostCenterSelector: FC<
  Partial<Props & { isMulti?: boolean; pid?: string }>
> = ({ value, onChange, style, companyId, pid, isMulti = true }) => {
  const props_ = value ? { value: value } : { defaultValue: undefined }
  const [{ data, error, fetching }, fetchCostCenters] =
    useUpadminCostCentersQuery({
      variables: {
        companyId,
        pid
      }
    })

  return (
    <Select
      mode={isMulti ? 'multiple' : undefined}
      showArrow
      loading={fetching}
      showSearch
      allowClear
      style={style}
      placeholder='请选择成本中心'
      optionFilterProp='children'
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props_}
    >
      {data?.items?.map((group: any) => {
        return (
          <Select.Option value={group.id} key={group.id}>
            {group.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 财务复核状态
export const FinancialReviewStatusSelector: FC<
  Partial<MultipleProps & { isMulti?: boolean }>
> = ({ value = [], onChange, style, isMulti = true }) => {
  const [{ data, fetching }] = useSalesClaimFormStatusQuery()
  return (
    <Select
      mode={isMulti ? 'multiple' : undefined}
      showArrow
      loading={fetching}
      showSearch
      allowClear
      style={style}
      placeholder='请选择'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.status.map((type: any) => {
        return (
          <Select.Option value={type.value} key={type.value}>
            {type.label}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 消费类型
export const ConsumptionTypesSelector: FC<
  Partial<MultipleProps & { isMulti?: boolean }>
> = ({ value = [], onChange, style, isMulti = true }) => {
  const [{ data, fetching }] = useSalesOrderTypesQuery()

  return (
    <Select
      mode={isMulti ? 'multiple' : undefined}
      showArrow
      loading={fetching}
      showSearch
      allowClear
      style={style}
      placeholder='请选择'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.types.map((type: any) => {
        return (
          <Select.Option value={type.value} key={type.value}>
            {type.label}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 消费订单状态
export const ConsumptionOrderStatusSelector: FC<
  Partial<MultipleProps & { isMulti?: boolean; availableStatus?: number[] }>
> = ({ value = [], onChange, style, isMulti = true, availableStatus = [] }) => {
  const [{ data, fetching }] = useSalesOrderStatusQuery()

  return (
    <Select
      mode={isMulti ? 'multiple' : undefined}
      showArrow
      loading={fetching}
      showSearch
      allowClear
      style={style}
      placeholder='请选择'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.status.map((status: any) => {
        if (
          availableStatus.length > 0 &&
          !availableStatus.includes(status.value)
        )
          return null

        return (
          <Select.Option value={status.value} key={status.value}>
            {status.label}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 计划预算业务部
export const PersonalPlanBudgetBusinessSelector: FC<
  Partial<Props & { planBudgetType?: PersonalPlanBudgetType }>
> = ({ value, onChange, style, planBudgetType }) => {
  const [{ data, fetching }] = usePersonalPlanBudgetBusinessAuthorityQuery({
    variables: {
      param: {
        type: planBudgetType || PersonalPlanBudgetType.Personal
      }
    }
  })

  return (
    <Select
      loading={fetching}
      showSearch
      allowClear
      style={style}
      placeholder='请选择'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.result?.map((department) => {
        return (
          <Select.Option
            value={department.businessId}
            key={department.businessId}
          >
            {department.businessName}
          </Select.Option>
        )
      })}
    </Select>
  )
}

// 开票状态
export const HSConsumptionOrderBudgetChannelSelector: FC<Partial<Props>> = ({
  value,
  onChange,
  style
}) => {
  return (
    <Select
      allowClear
      showSearch
      style={style}
      placeholder='请选择'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {BUDGET_TYPES.map((budget) => {
        return (
          <Select.Option value={budget.value} key={budget.value}>
            {budget.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}
