import React, { useState, FC } from 'react'
import { Modal, Form, Input, notification, Button, Card, Space } from 'antd'
import {
  MinusCircleOutlined,
  PlusOutlined,
  EditOutlined
} from '@ant-design/icons'
import { sortBy } from 'lodash'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { useActivityTypeColumns } from './data'
import {
  MeetingConfigBase,
  useFetchAllBaseActivityTypesQuery,
  useCreateOrUpdateBaseActivityTypeMutation
} from '../../graphql'

interface ModalFormProps {
  visible: boolean
  onCancel: () => void
  onOk: (information: Array<any>) => void
  defaultInformation: Array<any>
}

const informationTransition = (properties: Array<any>) => {
  const result = properties.map((prop) => {
    if (prop.value instanceof Array) {
      return {
        sort: prop.sort,
        key: prop.key,
        type: 'activity',
        value: prop?.value?.map((i: { VALUE: string }) => i.VALUE)?.join('\n')
      }
    } else {
      return {
        sort: prop.sort,
        key: prop.key,
        value: prop.value,
        type: 'activity'
      }
    }
  })

  return sortBy(result, (o) => o.sort)
}

const formatInformation = (information: Array<any>) => {
  return information.map((info, index) => {
    if (info.value.indexOf('\n') > -1) {
      let array = info.value.split('\n')
      array = array.map((i: string, index: number) => {
        return { KEY: index + 1, VALUE: i }
      })
      return { ...info, value: JSON.stringify(array), sort: index + 1 }
    } else {
      return { ...info, sort: index + 1 }
    }
  })
}

const ModalForm: React.FC<ModalFormProps> = ({
  visible,
  onCancel,
  defaultInformation,
  onOk
}) => {
  const [form] = Form.useForm()
  const onConfirm = async () => {
    try {
      const values = await form.validateFields()
      onOk(values?.information)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal
      maskClosable={false}
      title='活动信息'
      visible={visible}
      width={600}
      onOk={onConfirm}
      onCancel={onCancel}
      destroyOnClose
      afterClose={() => form.resetFields()}
    >
      <Form form={form} name='informationForm' autoComplete='off'>
        <Form.List name='information' initialValue={defaultInformation}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} align='center'>
                  <Form.Item
                    {...restField}
                    name={[name, 'key']}
                    rules={[{ required: true, message: '不能为空' }]}
                  >
                    <Input.TextArea placeholder='标题' style={{ width: 220 }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    rules={[{ required: true, message: '不能为空' }]}
                  >
                    <Input.TextArea placeholder='内容' style={{ width: 300 }} />
                  </Form.Item>
                  <Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Form.Item>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  添加一条活动说明
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}

const ActivityType: FC = () => {
  const [form] = Form.useForm()
  const table = useSearchTable({})
  const [modalShow, updateModalShow] = useState(false)
  const [activityTypeId, setActivityTypeId] = useState<string | null>(null)
  const [childModalShow, updateChildModalShow] = useState(false)
  const [information, updateInformation] = useState<Array<any>>()

  const [
    { data, error, fetching },
    refetch
  ] = useFetchAllBaseActivityTypesQuery()
  const [
    { fetching: modalLoading },
    createOrUpdate
  ] = useCreateOrUpdateBaseActivityTypeMutation()

  const onEdit = (activityType: MeetingConfigBase) => {
    form.setFieldsValue({
      ...activityType
    })
    updateInformation(informationTransition(activityType.properties || []))
    setActivityTypeId(activityType.id)
    updateModalShow(true)
  }

  const onConfirm = async () => {
    try {
      const values = await form.validateFields()
      const params = {
        ...values,
        enable: true, // 默认启用
        props: formatInformation(information || [])
      }
      if (activityTypeId) {
        params.id = activityTypeId
      }
      const result = await createOrUpdate({
        config: params
      })
      if (!result.data?.result?.success) {
        notification.error({
          message: `${!activityTypeId ? '新增' : '编辑'}失败`,
          description: `【${values.title}】${
            result.data?.result?.message || ''
          }`
        })
      } else {
        notification.success({
          message: `${!activityTypeId ? '新增' : '编辑'}成功`,
          description: `【${values.title}】`
        })
        updateModalShow(false)
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <Card
        style={{ marginTop: '20px' }}
        extra={
          <Button
            type='primary'
            onClick={() => {
              updateInformation([])
              setActivityTypeId(null)
              updateModalShow(true)
            }}
          >
            新增活动类型
          </Button>
        }
      >
        <SearchTable
          showPanel={false}
          showPagination={false}
          showOperaBtn={false}
          table={table}
          bordered
          loading={fetching}
          dataSource={data?.result || []}
          size='middle'
          rowKey='code'
          columns={useActivityTypeColumns({ onEdit })}
        />
      </Card>

      <Modal
        maskClosable={false}
        title={`${activityTypeId ? '编辑' : '新增'}活动类型`}
        visible={modalShow}
        onOk={onConfirm}
        onCancel={() => updateModalShow(false)}
        okText='确认'
        cancelText='取消'
        confirmLoading={modalLoading}
        afterClose={() => {
          updateInformation([])
          form.resetFields()
        }}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          form={form}
          name='basicForm'
        >
          <Form.Item
            label='活动类型名称'
            name='title'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Input placeholder='请填写类型名称' />
          </Form.Item>
          <Form.Item
            label='code'
            name='code'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Input placeholder='请填写类型code' />
          </Form.Item>
          <Form.Item
            label='图标名称'
            name='icon'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Input placeholder='请填写图标名称' />
          </Form.Item>
          <Form.Item label='活动说明'>
            <Button
              icon={<EditOutlined />}
              onClick={() => updateChildModalShow(true)}
            />
            {/* <div>
              {information?.map((info, index) => (
                <span key={index}>
                  {info.title}：{info.value}
                </span>
              ))}
            </div> */}
          </Form.Item>
        </Form>
      </Modal>

      <ModalForm
        defaultInformation={information || []}
        visible={childModalShow}
        onCancel={() => updateChildModalShow(false)}
        onOk={(newInfoList) => {
          updateInformation(newInfoList)
          updateChildModalShow(false)
        }}
      />
    </main>
  )
}

export default ActivityType
