import React, { useEffect, useState } from 'react'
import { Modal, Space, message, Button, Row } from 'antd'
import omit from 'lodash.omit'
import ClipBoard from 'clipboard'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import { searchFields, useTableColumns } from './data'
import ErrorView from '../../components/ErrorView'
import {
  useSearchDoctorAttenderTasksQuery,
  useRemoveDoctorsMutation,
  useGetUrlLinkByAppNameQuery,
  useSendTaskInviteMessageMutation,
  StandardMeetingBaseAttender,
  StandardMeetingTypeAttenderType,
  useGetMedicalLiteratureUrlQuery
} from '../../graphql'
import { getShortUrl } from '../../lib/getShortUrl'
import { useUserStore } from '../../hooks/user'
import { useSearchStore } from '../../hooks/pageSearch'

type FieldTypes = {
  keyword?: string
}

const TaskList: QCPage = () => {
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[window.location.pathname] }
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const userStore = useUserStore()
  const production = userStore.profile?.hrProduction || ''
  const companyId = userStore.profile?.companyId || ''
  const [hash, setHash] = useState<string>()
  const [inviteShow, setInviteShow] = useState(false)
  const [attender, updateAttender] = useState({} as StandardMeetingBaseAttender)
  const [inviteUrl, setInviteUrl] = useState('')
  const [curCompanyId, setCurCompanyId] = useState<string | undefined>(
    searchParams?.companyId
  )
  const [curRegionId, setCurRegionId] = useState<string | undefined>(
    searchParams?.region
  )
  const [curActivityTypes, setCurActivityTypes] = useState<
    Array<any> | undefined
  >(searchParams?.activityType)
  const [curMeetingType, setCurMeetingType] = useState<string | undefined>(
    searchParams?.meetingType
  )

  const [, removeDoctor] = useRemoveDoctorsMutation()
  const [{ fetching: modalLoading }, sendMessage] =
    useSendTaskInviteMessageMutation()
  const [{ data: urlLink }, getUrlLinkByAppName] = useGetUrlLinkByAppNameQuery({
    variables: {
      appName: '',
      doctorAccountId: attender?.attenderId || ''
    },
    pause: true
  })
  const [{ data: medicalUrlResult }, getMedicalLiteratureUrl] =
    useGetMedicalLiteratureUrlQuery({
      variables: {
        path: '/reviewManage',
        doctorAccountId: attender?.attenderId || ''
      },
      pause: true
    })

  const setModalData = async (url: string) => {
    const result = await getShortUrl(url)
    if (result.success) {
      setHash(result.data)
      setInviteUrl(`https://s.shangyantong.com/h/${result.data}`)
      setInviteShow(true)
      new ClipBoard('#inviteMessage', {
        text: () =>
          `${attender?.attenderName}老师，很荣幸邀您参加${
            attender.meetingAttending?.hengruiImage_categoryName || ''
          }活动，详见${`https://s.shangyantong.com/h/${result.data}`}`
      })
    } else {
      message.error('任务链接获取失败')
    }
  }

  useEffect(() => {
    if (attender?.attenderId) {
      getUrlLinkByAppName()
      getMedicalLiteratureUrl()
    }
  }, [attender])

  useEffect(() => {
    if (urlLink?.result && medicalUrlResult?.result) {
      const url = `${process.env.DOCAUTH_URL}/docauth/redirect?wxurl=${urlLink?.result}&pcurl=${medicalUrlResult?.result}?attenderId=${attender.attenderId}`
      setModalData(url)
    }
  }, [urlLink, medicalUrlResult])

  const [{ data, error, fetching }, refetch] =
    useSearchDoctorAttenderTasksQuery({
      variables: {
        search: {
          pager: omit(table.pager, 'total'),
          query: {
            ...table.searchParams,
            ...{ region: curRegionId },
            ...(!curMeetingType
              ? { meetingType: [] }
              : { meetingType: [curMeetingType] }),
            ...{ activityType: curActivityTypes }
          }
        }
      }
    })

  // 企业选项改变，省区置空，待重新选择
  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
    setCurRegionId(undefined)
    setCurMeetingType(undefined)
    setCurActivityTypes(undefined)
  }
  // 省区选项改变，手动触发状态更改
  const onRegionChange = (value: string) => {
    setCurRegionId(value)
  }
  // 活动类型选项改变
  const onActivityTypesChange = (value: Array<any>) => {
    setCurActivityTypes(value)
  }

  // 会议类型选项改变
  const onMeetingTypeChange = (value: string) => {
    setCurActivityTypes(undefined)
    setCurMeetingType(value)
  }
  // table 重置时清空公司和会议类型状态
  const onTableReset = () => {
    setCurCompanyId(undefined)
    setCurRegionId(undefined)
    setCurMeetingType(undefined)
    setCurActivityTypes(undefined)
  }

  const onOpenModal = async (attender: StandardMeetingBaseAttender) => {
    updateAttender(attender)
  }

  const onInvite = async () => {
    if (attender?.attenderId) {
      const result = await sendMessage({
        taskUrl: inviteUrl,
        activityName:
          attender.meetingAttending?.hengruiImage_categoryName || '',
        doctorAccountId: attender.attenderId,
        hash
      })
      if (result.data?.result) {
        message.success('发送成功')
        setInviteShow(false)
        updateAttender({} as StandardMeetingBaseAttender)
      }
    }
  }

  const onDelete = async (
    attenderId: string,
    meetingId: string,
    attenderType: StandardMeetingTypeAttenderType
  ) => {
    const result = await removeDoctor({
      attenderId,
      meetingId,
      attenderType
    })
    if (!result.data?.result) {
      message.error('删除失败')
    } else {
      message.success('删除成功')
      refetch()
    }
  }

  const tableColumns = useTableColumns({ onOpenModal, onDelete })

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <>
      <SearchTable
        table={table}
        searchProps={{
          fields: searchFields(
            curCompanyId,
            onCompanyChange,
            onRegionChange,
            curMeetingType,
            onActivityTypesChange,
            onMeetingTypeChange,
            production,
            companyId
          )
        }}
        scroll={{ x: 2030 }}
        bordered
        loading={fetching}
        pager={data?.result?.pager}
        dataSource={data?.result?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
        onReset={onTableReset}
      />
      <Modal
        closable={false}
        visible={inviteShow}
        width={420}
        bodyStyle={{ padding: '40px 30px 30px 30px' }}
        footer={null}
      >
        <Space direction='vertical' size='large'>
          <Space direction='vertical'>
            <div>
              {`${attender?.attenderName}老师，很荣幸邀您参加${
                attender.meetingAttending?.hengruiImage_categoryName || ''
              }活动，详见`}
            </div>
            <div style={{ background: '#f5f5f5', padding: '0 4px' }}>
              {inviteUrl}
            </div>
          </Space>
          <Row justify='end'>
            <Button
              style={{ marginRight: 15 }}
              onClick={() => {
                updateAttender({} as StandardMeetingBaseAttender)
                setInviteShow(false)
              }}
            >
              取消
            </Button>
            <Button
              loading={modalLoading}
              type='primary'
              id='inviteMessage'
              onClick={onInvite}
            >
              确定发送任务并复制
            </Button>
          </Row>
        </Space>
      </Modal>
    </>
  )
}

export default TaskList
