import { Button, Popconfirm, Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React from 'react'
import { GroupStatus } from '../../graphql'

export const treeDataFormat = (
  data: any[],
  pid?: string,
  searchValue?: string
): any => {
  const group = data.filter((item) => item.pid === pid)
  return group.length === 0
    ? []
    : group.map((i) => {
        let title = i.name
        if (searchValue) {
          const index = title.indexOf(searchValue)
          const beforeStr = title.substring(0, index)
          const afterStr = title.slice(index + searchValue.length)
          title =
            index > -1 ? (
              <span>
                {beforeStr}
                <span className='site-tree-search-value'>{searchValue}</span>
                {afterStr}
              </span>
            ) : (
              <span>{title}</span>
            )
        }
        return {
          ...i,
          title,
          value: i.groupId,
          key: i.groupId,
          disable: i.status === GroupStatus.GroupDisabled,
          children: treeDataFormat(data, i.groupId, searchValue)
        }
      })
}

export const useRegionPermissionColumns = ({
  handleDelete,
  handleEdit,
  regionData = [],
  meetingTypeData = []
}: {
  handleDelete: (id: string) => void
  handleEdit: (item: any) => void
  regionData?: any[]
  meetingTypeData?: any[]
}): ColumnsType<any> => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center'
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center'
    },
    {
      title: '会议类型',
      dataIndex: 'activityCode',
      align: 'center',
      render(i) {
        if (!i || i?.length <= 0) return '-'
        const meetingTypes = meetingTypeData
          .map((m) => {
            if (i.includes(m.config?.code)) {
              return m.config.title
            }
            return ''
          })
          .filter((x) => !!x)
        return meetingTypes.join(',')
      }
    },
    {
      title: '区域',
      dataIndex: 'regionId',
      align: 'center',
      render(i) {
        if (!i || i?.length <= 0) return '-'
        const regions = regionData
          .map((r) => {
            if (i.includes(r.groupId)) {
              return r.name
            }
            return ''
          })
          .filter((x) => !!x)
        return regions.join(',')
      }
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'operation',
      align: 'center',
      render(v, item) {
        return (
          <Space>
            <Popconfirm
              title='确定删除该用户信息？'
              onConfirm={() => {
                handleDelete(v)
              }}
              okText='确认'
              cancelText='取消'
            >
              <Button type='link'>删除</Button>
            </Popconfirm>
            <Button
              type='link'
              onClick={() => {
                handleEdit(item)
              }}
            >
              编辑
            </Button>
          </Space>
        )
      }
    }
  ]
}
