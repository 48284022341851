import _ from 'lodash'

const notPositiveInteger = (value: any) => {
  return isNaN(Number(value)) || Number(value) < 1 || Number(value) % 1 !== 0
}

export const replaceBlank = (value: any) => {
  // return (value && value.replace(/\s+/g, '')) || ''
  return (value && value.trim()) || ''
}

const headMaps: { [key: string]: string | number } = {
  姓名: 'name',
  手机号: 'phone',
  所属大区: 'region',
  所属产品: 'product',
  任务类型: 'taskCode',
  活动类型: 'activityCode',
  任务积分: 'fee'
}

const errorHeadMaps: { [key: string]: string | number } = {
  姓名: 'name',
  手机号: 'phone',
  所属大区: 'region',
  所属产品: 'product',
  任务类型: 'taskCode',
  活动类型: 'activityCode',
  任务积分: 'fee',
  报错信息: 'msg'
}

export const checkExcelValidate = (
  list: { [key: string]: string | number }[]
) => {
  console.log(list)
  let res = { success: true, message: '' }

  // 校验是否为空文件
  if (!list?.length) {
    res = { success: false, message: '文件不能为空' }
    return res
  }

  // 校验表格头字段是否正确
  const head = Object.keys(headMaps)
  const headerNoCorrect = Object.keys(list[0]).findIndex(
    (item) => head.indexOf(item) < 0
  )
  if (headerNoCorrect > -1) {
    res = { success: false, message: '表头不正确，请重新下载模版并检查数据' }
    return res
  }

  // 是否存在空单元格
  const hasBlankRow = list.findIndex((item) => {
    return !item || Object.keys(item).length < head.length
  })
  if (hasBlankRow > -1) {
    res = {
      success: false,
      message: `第 ${hasBlankRow + 2} 行：信息缺失，请检查数据`
    }
    return res
  }

  // 检验手机格式
  const hasIllegalMobileRow = list.findIndex((item) => {
    const reg = new RegExp(/^[1]([3-9])[0-9]{9}$/)
    return (
      !item?.手机号 ||
      !reg.test(
        typeof item.手机号 === 'string'
          ? item.手机号
          : JSON.stringify(item.手机号)
      )
    )
  })
  if (hasIllegalMobileRow > -1) {
    res = {
      success: false,
      message: `第 ${hasBlankRow + 2} 行：手机号格式不正确，请检查数据`
    }
    return res
  }

  // 检验费用是否正确
  const hasIllegalFeeRow = list.findIndex((i) => notPositiveInteger(i.任务积分))
  if (hasIllegalFeeRow > -1) {
    res = {
      success: false,
      message: `第 ${
        hasBlankRow + 2
      } 行：任务积分格式不正确，需要是大于0的整数，请检查数据`
    }
    return res
  }

  return res
}

// 整理出导出表格数据
export const getData = (data: any) => {
  let result = data ? _.cloneDeep(data) : []

  // 按照 excelHeader 拼装
  result = _.map(result, (item) => {
    const obj: any = {}

    const heads = Object.keys(errorHeadMaps)
    heads.forEach((cell: any) => {
      const value = _.get(item, errorHeadMaps[cell], '')
      obj[cell] = value
    })
    return obj
  })

  return result
}
