import React from 'react'
import { Space, Radio, Checkbox, Row, Image, Card, Col } from 'antd'

const divStyle = {
  minHeight: 40,
  background: 'rgb(245, 246, 250)',
  borderRadius: '4px',
  width: '100%',
  color: 'rgba(0, 0, 0, 0.85)',
  padding: '10px',
  lineHeight: '1.5',
  margin: '0 0 24px 10px'
}

const RequireSymbol = ({ required }: { required: boolean }) => (
  <span style={{ color: '#ff4d4f', fontSize: 14, marginRight: 4 }}>
    {required ? '*' : ''}
  </span>
)

const widgetCollections = (props: any) => {
  return {
    fillinblank: (
      <Space direction='vertical' style={{ width: '100%' }}>
        <span style={{ color: '#888' }}>
          <RequireSymbol required={props?.required} />
          {props?.label}
        </span>
        <div style={divStyle}>{props?.defaultValue}</div>
      </Space>
    ),
    textarea: (
      <Row gutter={[40, 0]} style={{ width: '100%', marginBottom: 12 }}>
        <Col span={12}>
          <RequireSymbol required={props?.required} />
          <span style={{ color: '#888' }}>{props?.label}</span>
        </Col>
        <Col span={12}>
          <span style={{ color: '#fa8c16' }}>{props?.defaultValue || '-'}</span>
        </Col>
      </Row>
    ),
    choice: (
      <Space direction='vertical' style={{ width: '100%' }}>
        <span style={{ color: '#888' }}>
          <RequireSymbol required={props?.required} />
          {props?.label}
        </span>
        {props?.props?.multiple ? (
          <Checkbox.Group
            style={{ width: '100%', margin: '0 0 24px 10px' }}
            value={
              (props?.defaultValue &&
                props.defaultValue instanceof Array &&
                props.defaultValue) ||
              []
            }
          >
            <Space
              direction='vertical'
              style={{ width: '100%', pointerEvents: 'none' }}
            >
              {props?.props?.options?.map(
                (o: { [key: string]: any }, i: number) => (
                  <Checkbox key={i} value={o.value}>
                    {o.label + o.value}
                  </Checkbox>
                )
              )}
            </Space>
          </Checkbox.Group>
        ) : (
          <Radio.Group
            value={
              props?.defaultValue &&
              props.defaultValue instanceof Array &&
              props.defaultValue?.[0]
            }
            style={{
              width: '100%',
              margin: '0 0 24px 10px',
              pointerEvents: 'none'
            }}
          >
            <Space direction='vertical' style={{ width: '100%' }}>
              {props?.props?.options?.map(
                (o: { [key: string]: any }, i: number) => (
                  <Radio key={i} value={o.value}>
                    {o.label + o.value}
                  </Radio>
                )
              )}
            </Space>
          </Radio.Group>
        )}
      </Space>
    ),
    input: (
      <Row gutter={[40, 0]} style={{ width: '100%', marginBottom: 12 }}>
        <Col span={12}>
          <RequireSymbol required={props?.required} />
          <span style={{ color: '#888' }}>{props?.label}</span>
        </Col>
        <Col span={12}>
          <span style={{ color: '#fa8c16' }}>{props?.defaultValue || '-'}</span>
        </Col>
      </Row>
    ),
    select: (
      <Row gutter={[40, 0]} style={{ width: '100%', marginBottom: 12 }}>
        <Col span={12}>
          <RequireSymbol required={props?.required} />
          <span style={{ color: '#888' }}>{props?.label}</span>
        </Col>
        <Col span={12}>
          <span style={{ color: '#fa8c16' }}>
            {(props?.defaultValue &&
              props.defaultValue instanceof Array &&
              props.defaultValue.map((i: any) => i?.label || i)?.join('、')) ||
              '-'}
          </span>
        </Col>
      </Row>
    ),
    datepicker: (
      <Row gutter={[40, 0]} style={{ width: '100%', marginBottom: 12 }}>
        <Col span={12}>
          <RequireSymbol required={props?.required} />
          <span style={{ color: '#888' }}>{props?.label}</span>
        </Col>
        <Col span={12}>
          <span style={{ color: '#fa8c16' }}>{props?.defaultValue || '-'}</span>
        </Col>
      </Row>
    ),
    imagepicker: (
      <Row gutter={[40, 0]} style={{ width: '100%', marginBottom: 12 }}>
        <Col span={12}>
          <RequireSymbol required={props?.required} />
          <span style={{ color: '#888' }}>{props?.label}</span>
        </Col>
        <Col span={12}>
          <Row gutter={16}>
            <Image.PreviewGroup>
              {(props?.defaultValue &&
                props.defaultValue instanceof Array &&
                props?.defaultValue?.map((item: any, index: number) => {
                  return (
                    <Col key={index}>
                      <Image key={index} height={60} src={item.url || item} />
                    </Col>
                  )
                })) ||
                '-'}
            </Image.PreviewGroup>
          </Row>
        </Col>
      </Row>
    ),
    displayRow: (
      <Row
        style={{
          paddingBottom: 12,
          borderBottom: '1px solid #f0f0f0'
        }}
      >
        <Col span={24}>
          <span style={{ color: '#888', paddingLeft: 8 }}>
            {props?.label}：{props.context?.[props?.props?.contextKey] || '-'}
          </span>
        </Col>
      </Row>
    )
  }
}

export const renderComponent = (data: any, context?: any) => {
  const { children, widget } = data || {}

  const WidgetComponent = ({ ...data }) => {
    return (
      <>
        {
          widgetCollections({ ...data })[
            widget as keyof typeof widgetCollections
          ]
        }
      </>
    )
  }
  return children ? (
    <Card
      title={data.label}
      bordered={false}
      headStyle={{ height: 40, paddingLeft: 8, marginTop: '-20px' }}
      bodyStyle={{
        padding: data.label ? '16px 24px 24px' : ' 16px 0 24px'
      }}
    >
      {children.map((c: any) =>
        renderComponent(
          {
            ...c
          },
          context
        )
      )}
    </Card>
  ) : (
    <WidgetComponent {...data} context={context} />
  )
}
