import React from 'react'
import { Tabs } from 'antd'
import ActivityType from './activityType'
import MeetingType from './meetingType'
import PlatformType from './platformType'

const { TabPane } = Tabs

const CategoriesManage: QCPage = () => {
  return (
    <main>
      <Tabs defaultActiveKey='1'>
        <TabPane tab='会议类型管理' key='1'>
          <MeetingType />
        </TabPane>
        <TabPane tab='活动类型管理' key='2'>
          <ActivityType />
        </TabPane>
        <TabPane tab='会议平台管理' key='3'>
          <PlatformType />
        </TabPane>
      </Tabs>
    </main>
  )
}

CategoriesManage.pageTitle = '类型管理'

export default CategoriesManage
