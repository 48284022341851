import React, { FC, Fragment, useState } from 'react'
import { Card, Row, Space, Tabs } from 'antd'
import { format } from '../lib/timeFormat'
import { ApproveInfo } from '../graphql'
import { isMobileClient } from '../constant/user'

const { TabPane } = Tabs

const statusColor: Record<string, string> = {
  SUBMIT: '#5581FA',
  APPROVED: '#FF6F00',
  RETREAT: '#FF5159',
  APPROVING: '#51A04C'
}

const ApprovalCard = ({ approvalDetail }: { approvalDetail: any }) => {
  const isMobile = isMobileClient()
  return approvalDetail?.map((approval: any, index: number) => {
    return (
      <Space
        key={index}
        direction='vertical'
        size={28}
        style={{
          padding: '0 0 10px 30px',
          borderLeft: '1px solid #f0f0f0',
          width: '100%'
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{
            position: 'relative',
            backgroundColor: '#f5f6fa',
            padding: '8px 16px',
            width: isMobile ? '100%' : '70%',
            flexWrap: 'nowrap'
          }}
        >
          <div style={{ width: '100%' }}>
            <div style={{ color: '#888888' }}>
              {approval.approverInfo?.cnName}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                {approval.status === 'SUBMIT'
                  ? approval.opinion || '提交审批'
                  : approval.status === 'APPROVING'
                  ? '审批中'
                  : approval.status === 'WAITING'
                  ? '待审批'
                  : `审批结果：${
                      approval.status === 'APPROVED' ? '通过' : '退回'
                    }`}
              </span>
              {approval.status &&
              ['SUBMIT', 'APPROVED', 'RETREAT'].includes(approval.status) ? (
                <span
                  style={{
                    color: '#888888',
                    width: isMobile ? '90px' : 'auto',
                    textAlign: 'right'
                  }}
                >
                  {format(approval.createTime)}
                </span>
              ) : null}
            </div>
            {approval.status === 'RETREAT' && (
              <div style={{ color: '#f76060' }}>
                退回理由：{approval.opinion}
              </div>
            )}
          </div>
          <div
            style={{
              position: 'absolute',
              top: 'calc(50% - 6px)',
              left: '-36px',
              width: '11px',
              height: '11px',
              borderRadius: '50%',
              backgroundColor: statusColor[approval.status] || '#CCC'
            }}
          />
        </Row>
      </Space>
    )
  })
}

const SalesApprovalProcess: FC<{
  approvalFlows?: Array<ApproveInfo> | null | undefined
  isShow?: boolean | undefined
  className?: string | undefined
  titleKey?: string | undefined
  headStyle?: React.CSSProperties | undefined
  bodyStyle?: React.CSSProperties | undefined
  bordered?: boolean | undefined
}> = ({
  approvalFlows = [],
  isShow = false,
  className = '',
  titleKey = '',
  headStyle,
  bodyStyle,
  bordered = false
}) => {
  const approvals = approvalFlows?.map((item) => item.tasks)
  const currentApproval = approvals?.pop()

  const [activeKey, setActiveKey] = useState('1')
  return isShow ? (
    <Card
      className={className}
      title={
        approvals?.length ? (
          <Tabs
            defaultActiveKey='1'
            size='large'
            onChange={(key) => setActiveKey(key)}
          >
            <TabPane tab={`${titleKey}审批信息`} key='1' />
            <TabPane tab={`${titleKey}历史审批`} key='2' />
          </Tabs>
        ) : (
          `${titleKey}审批信息`
        )
      }
      bordered={bordered}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
    >
      {activeKey === '1' ? (
        !currentApproval?.length ? (
          `暂无${titleKey}审批信息`
        ) : (
          <ApprovalCard approvalDetail={currentApproval} />
        )
      ) : (
        approvals?.map((group: any, i: number) => {
          return (
            <div key={i} style={{ marginBottom: 40 }}>
              <ApprovalCard approvalDetail={group} />
            </div>
          )
        })
      )}
    </Card>
  ) : null
}

export default SalesApprovalProcess
