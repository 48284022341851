import React, { FC } from 'react'
import { Tag } from 'antd'
import './index.less'

interface SalesStatusTagProps {
  containerStyle?: React.CSSProperties | undefined
  tagColor?: string | undefined
  contentStyle?: React.CSSProperties | undefined
  text?: string | undefined
  triangleClassName?: string | undefined
}

const SalesStatusTag: FC<SalesStatusTagProps> = ({
  containerStyle,
  tagColor,
  contentStyle,
  text,
  triangleClassName
}) => {
  return (
    <span
      style={{
        position: 'relative',
        ...containerStyle
      }}
    >
      <Tag
        color={tagColor}
        style={{
          fontSize: 12,
          padding: '0 16px',
          marginRight: 0,
          borderRadius: 0,
          height: 22,
          ...contentStyle
        }}
      >
        {text}
      </Tag>
      {triangleClassName && <span className={triangleClassName}></span>}
    </span>
  )
}

export default SalesStatusTag
