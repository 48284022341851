import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { AccountStatus, HrDoctorListQuery } from '../../graphql'
import { Button, Popconfirm, Space } from 'antd'
import { ACCOUNT_STATUS, HR_PRODUCTION } from '../../constant/user'

export const useTableColumns = (props: {
  production: string
  openChannelModal: (index: number, record: object) => void
  openDoctorInfoModal: (index: number, record: object) => void
  channels: Array<{ [key: string]: string }>
  unBindAccountWechat: (index: number, record: object) => void
  updateAccountStatus: (record: object) => void
  onOpertionVip: (record: object) => void
}): ColumnsType<HrDoctorListQuery['doctors']['items'][0]> => [
  {
    title: '老师名字',
    dataIndex: 'cnName',
    align: 'center',
    key: 'cnName',
    fixed: 'left',
    width: 100
  },
  {
    title: '老师手机号',
    dataIndex: 'companyMobile',
    align: 'center',
    key: 'companyMobile',
    width: 150
  },
  {
    title: '单位',
    dataIndex: 'hospital',
    key: 'hospital',
    align: 'center',
    width: 200
  },
  {
    title: '部门',
    dataIndex: 'department',
    align: 'center',
    key: 'departmentName',
    width: 100,
    render(v: { name: string }) {
      return v?.name
    }
  },
  {
    title: '职称',
    align: 'center',
    dataIndex: 'groupInfo',
    key: 'groupName',
    width: 100,
    render(v: { name: string }) {
      return v?.name
    }
  },
  {
    title: '企业',
    align: 'center',
    dataIndex: 'company',
    key: 'company',
    width: 200,
    render(v: { cnName: string }) {
      return v?.cnName
    }
  },
  {
    title: '身份证',
    align: 'center',
    dataIndex: 'idCardInfo',
    key: 'certNo',
    width: 200,
    render(v: { rawCertNo: string }) {
      return v?.rawCertNo
    }
  },
  {
    title: '银行卡号',
    align: 'center',
    dataIndex: 'bankInfo',
    key: 'rawCertNo',
    width: 200,
    render(v: { rawCertNo: string }) {
      return v?.rawCertNo
    }
  },
  {
    title: ' 银行',
    align: 'center',
    dataIndex: 'bankInfo',
    key: 'qkkBankName',
    width: 100,
    render(v: { qkkBankName: string }) {
      return v?.qkkBankName
    }
  },
  {
    title: '开户网点',
    align: 'center',
    dataIndex: 'bankInfo',
    key: 'ssqBankName',
    width: 200,
    render(v: { ssqBankName: string }) {
      return v?.ssqBankName
    }
  },
  {
    title: '联行号',
    align: 'center',
    dataIndex: 'bankInfo',
    key: 'cnFirstPinyin',
    width: 100,
    render(v: { cnFirstPinyin: string }) {
      return v?.cnFirstPinyin
    }
  },
  {
    title: '可签约渠道',
    align: 'center',
    dataIndex: 'defaultChannel',
    key: 'defaultChannel',
    width: 100,
    render(v: string) {
      return props.channels.find((i) => i.value === v)?.text || 'SSQ'
    }
  },
  // {
  //   title: '是否签约',
  //   align: 'center',
  //   dataIndex: 'hasCertAndSigned',
  //   key: 'hasCertAndSigned',
  //   width: 100,
  //   render(v: boolean) {
  //     return v ? '是' : '否'
  //   }
  // },
  {
    title: '状态',
    align: 'center',
    dataIndex: 'qkkStudioOpenStatus',
    key: 'qkkStudioOpenStatus',
    width: 100,
    render(v, item: any) {
      const isQC =
        props.production === HR_PRODUCTION.qc ||
        props.production === HR_PRODUCTION.qcDemo
      return isQC ? ACCOUNT_STATUS[item.status] : v
    }
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'opera',
    key: 'opera',
    fixed: 'right',
    width: 280,
    render(text: string, record: any, index: number) {
      const isDisabled = record.status === AccountStatus.AccountDisabled
      const isOpenBudgetWhite = record.budgetWhite && record.budgetWhite.enabled
      return (
        <Space wrap={true}>
          <Button
            type='link'
            style={{ padding: 4 }}
            onClick={() => props.openChannelModal(index, record)}
          >
            修改渠道
          </Button>
          <Button
            type='link'
            style={{ padding: 4 }}
            onClick={() => props.openDoctorInfoModal(index, record)}
          >
            编辑信息
          </Button>
          {[
            AccountStatus.AccountDisabled,
            AccountStatus.AccountActivated
          ].includes(record.status) &&
            (isDisabled ? (
              <Popconfirm
                placement='topRight'
                title='确认将老师启用，并将老师之前的删除的订单都恢复？'
                onConfirm={() => props.updateAccountStatus(record)}
                okText='是'
                cancelText='否'
              >
                <Button type='link' style={{ padding: 4 }}>
                  启用
                </Button>
              </Popconfirm>
            ) : (
              <Button
                type='link'
                style={{ padding: 4 }}
                onClick={() => props.updateAccountStatus(record)}
              >
                禁用
              </Button>
            ))}
          <Popconfirm
            placement='topRight'
            title={
              !isOpenBudgetWhite
                ? '开启vip功能后，将不受到“月3w积分”逻辑限制'
                : '关闭vip功能后，将受到“月3w积分”逻辑限制'
            }
            onConfirm={() => props.onOpertionVip(record)}
            okText='确定'
            cancelText='取消'
          >
            <Button type='link' style={{ padding: 4 }}>
              {!isOpenBudgetWhite ? '开启vip' : '关闭vip'}
            </Button>
          </Popconfirm>

          {/* account有绑定对应的openId时 展示解绑微信按钮 */}
          {record.openId && (
            <Popconfirm
              placement='topRight'
              title='是否确认解绑该老师的微信和手机?'
              onConfirm={() => props.unBindAccountWechat(index, record)}
              okText='是'
              cancelText='否'
            >
              <Button type='link' style={{ padding: 4 }}>
                解绑微信
              </Button>
            </Popconfirm>
          )}
        </Space>
      )
    }
  }
]
