import React, { FC } from 'react'
import { Space, Image, Descriptions, Divider, Row, Col, Tabs } from 'antd'
import dayjs from 'dayjs'
import { isVideoUrl } from '../../constant/user'
import VideoButton from '../../components/VideoButton'
import DocumentPreviewButton from '../../components/DocumentPreviewButton'

export const DocumentComposeResult: FC<{
  documentComposeInfo?: any
  taskAttribute?: any
  meetingBaseInfo?: any
  attenderAccountId?: string
  taskId?: string
}> = ({
  documentComposeInfo,
  taskAttribute,
  meetingBaseInfo,
  attenderAccountId,
  taskId
}) => {
  const divStyle = {
    minHeight: 40,
    background: 'rgb(245, 246, 250)',
    borderRadius: '4px',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '10px',
    lineHeight: '1.5',
    margin: '0 0 24px 0'
  }
  const composeInfo = JSON.parse(documentComposeInfo || '{}')
  const signImgUrl = taskAttribute?.signImgUrl

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      {!composeInfo ? (
        '暂无数据'
      ) : (
        <>
          <Descriptions
            column={1}
            labelStyle={{ fontWeight: 'bold' }}
            contentStyle={{ color: '#888' }}
          >
            <Descriptions.Item label='活动目的'>
              {composeInfo?.activityPurpose}
            </Descriptions.Item>
            <Descriptions.Item label='活动要求'>
              {composeInfo?.activityRequire}
            </Descriptions.Item>
            <Descriptions.Item label='活动日期'>
              {dayjs(meetingBaseInfo?.startTime).format('YYYY-MM-DD') || '-'}至
              {dayjs(meetingBaseInfo?.endTime).format('YYYY-MM-DD') || '-'}
            </Descriptions.Item>
          </Descriptions>
          <Divider style={{ margin: 0, paddingBottom: 16 }} />

          <Row>
            <Col span={24} style={{ fontWeight: 'bold' }}>
              文献材料
            </Col>
            <Col span={24} style={{ paddingBottom: 16 }}>
              <Tabs defaultActiveKey='1'>
                <Tabs.TabPane tab='文档' key='1'>
                  <Space wrap style={{ width: '100%' }}>
                    {taskAttribute?.documents?.map(
                      (img: string, index: number) => {
                        return (
                          <DocumentPreviewButton
                            key={index}
                            fileUrl={img}
                            bucket='doctor-document-compose-materials'
                            dir={`/${attenderAccountId}/${taskId}`}
                            style={{
                              width: 100,
                              height: 100,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          />
                        )
                      }
                    )}
                  </Space>
                </Tabs.TabPane>
                <Tabs.TabPane tab='视频/图片' key='2'>
                  <Space wrap style={{ width: '100%' }}>
                    {taskAttribute?.videoImages?.map(
                      (img: string, index: number) => {
                        return isVideoUrl(img) ? (
                          <VideoButton
                            key={index}
                            url={img}
                            style={{
                              width: 100,
                              height: 100,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          />
                        ) : (
                          <Image
                            key={index}
                            src={img}
                            width={100}
                            height={100}
                          />
                        )
                      }
                    )}
                  </Space>
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
          <Row style={{ color: '#888', paddingBottom: 16 }}>
            <Col span={24}>
              <span style={{ color: 'red' }}>*</span>
              文字类不低于1000字，演示文稿类不低于10页、视频类不低于2分钟。不得抄袭重复、挪用他人学术成果，如有造成侵权，需自负法律责任。
            </Col>
          </Row>
        </>
      )}
      {signImgUrl && (
        <Space direction='vertical' style={{ width: '100%' }}>
          <span style={{ fontWeight: 'bold' }}>老师手写签名</span>
          <div style={divStyle}>
            <Image alt='签名' preview={false} src={signImgUrl} />
          </div>
        </Space>
      )}
    </Space>
  )
}
