import React, { FC, useEffect, useState } from 'react'
import { Modal, Form, message, Select, Col, Row, Image, Radio } from 'antd'
import UploadImageButton from '../../components/UploadImageButton'
import {
  certificateOptions,
  credentialTypeMap,
  isVideoUrl
} from '../../constant/user'
import './index.less'

const UploadImageModal: FC<{
  visible: boolean
  title?: string
  type: number
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
  accountId?: string
  idCardImages?: string[]
  certificationImages?: { certImages: string[]; cnName: string }
  reviewImages?: string[]
  titleCertImages?: string[]
  verifyResultImages?: string[]
  verifyResult?: string
}> = ({
  visible = false,
  title,
  type,
  onCancel,
  onSubmit,
  accountId = '',
  idCardImages = [],
  certificationImages = {},
  reviewImages = [],
  titleCertImages = [],
  verifyResultImages = [],
  verifyResult
}) => {
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()
  const layout = {
    labelCol: { span: type == 2 ? 8 : 0 },
    wrapperCol: { span: type == 2 ? 16 : 24 }
  }

  // 点击确认
  const onOkClick = async () => {
    form.submit()
  }

  const onFinish = (values: any) => {
    console.log(values)
    setLoading(true)
    onSubmit &&
      onSubmit(values, () => {
        setLoading(false)
      })
  }

  return (
    <Modal
      confirmLoading={loading}
      forceRender
      maskClosable={false}
      title={title}
      visible={visible}
      onCancel={onCancel}
      centered
      width={420}
      onOk={onOkClick}
      okText='确认'
    >
      <Form
        preserve={false}
        layout={type == 2 ? 'horizontal' : 'inline'}
        {...layout}
        form={form}
        onFinish={onFinish}
        style={{ justifyContent: 'space-between' }}
      >
        {type === 1 && (
          <>
            <Form.Item
              name='idImages1'
              rules={[{ required: true, message: '请上传人像面照片' }]}
              style={{ marginLeft: 16 }}
              initialValue={idCardImages[0]}
            >
              <UploadImageButton
                buttonText='人像面照片'
                bucket='zhongzhi-dev-idcard-image'
                onUploaded={(url) => {
                  form.setFieldsValue({
                    idImages1: url
                  })
                }}
                dir={`/admin/certificateImages/${accountId || 'addDoctor'}`}
                accept='.gif,.jpg,.jpeg,.png,.GIF,.JPG,.JPEG,.PNG'
                noMessage
                defaultFileList={
                  idCardImages[0]
                    ? [
                        {
                          uid: 'idImages1',
                          name: 'idImages1.png',
                          status: 'done',
                          url: idCardImages[0],
                          size: 0,
                          type: ''
                        }
                      ]
                    : []
                }
              />
            </Form.Item>
            <Form.Item
              name='idImages2'
              rules={[{ required: true, message: '请上传国徽面照片' }]}
              initialValue={idCardImages[1]}
            >
              <UploadImageButton
                buttonText='国徽面照片'
                bucket='zhongzhi-dev-idcard-image'
                onUploaded={(url) => {
                  form.setFieldsValue({
                    idImages2: url
                  })
                }}
                dir={`/admin/certificateImages/${accountId || 'addDoctor'}`}
                accept='.gif,.jpg,.jpeg,.png,.GIF,.JPG,.JPEG,.PNG'
                noMessage
                defaultFileList={
                  idCardImages[1]
                    ? [
                        {
                          uid: 'idImages2',
                          name: 'idImages2.png',
                          status: 'done',
                          url: idCardImages[1],
                          size: 0,
                          type: ''
                        }
                      ]
                    : []
                }
              />
            </Form.Item>
          </>
        )}
        {type === 2 && (
          <>
            <Form.Item
              label='执业证件上传'
              name={`certificateType`}
              rules={[{ required: true, message: '请选择一项证件上传' }]}
              style={{ marginLeft: 16 }}
              initialValue={certificationImages?.cnName}
            >
              <Select placeholder='请选择一项证件上传'>
                {certificateOptions.map((option, i) => {
                  return (
                    <Select.Option key={i} value={option}>
                      {option}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>

            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.certificateType !== curValues.certificateType
              }
              noStyle
            >
              {({ getFieldValue }) => {
                const type = getFieldValue('certificateType')
                const currentType = credentialTypeMap.find(
                  (x) => x.type === type
                )
                if (!currentType) return null
                let itemHtml = null
                if (!['工作证', '卫健委资格证书'].includes(type)) {
                  itemHtml = currentType.tip?.map((tip, index) => {
                    const itemKey = `${type}${index}`
                    return (
                      <Row
                        key={itemKey}
                        style={{ marginLeft: 25, marginBottom: 20 }}
                      >
                        <Col span={12}>
                          <Form.Item
                            colon={false}
                            name={itemKey}
                            style={{ marginBottom: 0 }}
                            rules={[
                              { required: true, message: `请上传${type}` }
                            ]}
                            wrapperCol={{ span: 24 }}
                            initialValue={
                              certificationImages?.cnName === type
                                ? certificationImages?.certImages?.[index]
                                : undefined
                            }
                          >
                            <UploadImageButton
                              bucket='zhongzhi-dev-idcard-image'
                              onUploaded={(url) => {
                                form.setFieldsValue({
                                  [itemKey]: url
                                })
                              }}
                              dir={`/admin/certificateImages/${
                                accountId || 'addDoctor'
                              }`}
                              accept='.gif,.jpg,.jpeg,.png,.GIF,.JPG,.JPEG,.PNG'
                              noMessage
                              defaultFileList={
                                certificationImages?.cnName === type &&
                                certificationImages?.certImages?.[index]
                                  ? [
                                      {
                                        uid: itemKey,
                                        name: `${itemKey}.png`,
                                        status: 'done',
                                        url: certificationImages?.certImages?.[
                                          index
                                        ],
                                        size: 0,
                                        type: ''
                                      }
                                    ]
                                  : []
                              }
                            />
                          </Form.Item>
                          <div className='color666'>{tip}</div>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Image
                            height={112}
                            src={currentType.example[index]}
                          />
                          <div
                            className='colorCCC'
                            style={{ textAlign: 'center' }}
                          >
                            示例
                          </div>
                        </Col>
                      </Row>
                    )
                  })
                } else {
                  itemHtml = (
                    <Row style={{ marginLeft: 25 }}>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          name={type}
                          style={{ marginBottom: 0 }}
                          rules={[{ required: true, message: `请上传${type}` }]}
                          wrapperCol={{ span: 24 }}
                          initialValue={
                            certificationImages?.cnName === type
                              ? certificationImages?.certImages?.[0]
                              : undefined
                          }
                        >
                          <UploadImageButton
                            bucket='zhongzhi-dev-idcard-image'
                            onUploaded={(url) => {
                              form.setFieldsValue({
                                [`${type}`]: url
                              })
                            }}
                            dir={`/admin/certificateImages/${
                              accountId || 'addDoctor'
                            }`}
                            accept='.gif,.jpg,.jpeg,.png,.GIF,.JPG,.JPEG,.PNG'
                            noMessage
                            defaultFileList={
                              certificationImages?.cnName === type &&
                              certificationImages?.certImages?.[0]
                                ? [
                                    {
                                      uid: type,
                                      name: `${type}.png`,
                                      status: 'done',
                                      url: certificationImages?.certImages?.[0],
                                      size: 0,
                                      type: ''
                                    }
                                  ]
                                : []
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )
                }
                return (
                  <>
                    {itemHtml}
                    <div style={{ marginLeft: 25, marginTop: 15 }}>
                      材料要求
                      {currentType.message.map((message, index) => (
                        <div className='color999' key={index}>
                          {index + 1}.{message}
                        </div>
                      ))}
                    </div>
                  </>
                )
              }}
            </Form.Item>
          </>
        )}
        {type === 3 && (
          <Form.Item
            name='reviewImages'
            rules={[{ required: true, message: '请上传复核材料' }]}
            style={{ marginLeft: 16, width: 360 }}
            initialValue={reviewImages}
          >
            <UploadImageButton
              bucket='zhongzhi-dev-idcard-image'
              accept='.gif,.jpg,.jpeg,.png,.GIF,.JPG,.JPEG,.PNG,.mp4,.rmvb,.avi,.ts,.mov,.MP4,.RMVB,.AVI,.TS,.MOV'
              uploadImgLength={6}
              onUploaded={(url) => {
                form.setFieldsValue({
                  reviewImages: url
                })
              }}
              dir={`/admin/certificateImages/${accountId || 'addDoctor'}`}
              noMessage
              defaultFileList={reviewImages.map((img, index) => ({
                uid: `reviewImage${index}`,
                name: `${img.substring(img.lastIndexOf('/') + 1)}`,
                status: 'done',
                url: img,
                size: 0,
                type: isVideoUrl(img)
                  ? `video/${img.substring(img.lastIndexOf('.') + 1)}`
                  : ''
              }))}
            />
          </Form.Item>
        )}
        {type === 4 && (
          <Form.Item
            name='titleCertImages'
            rules={[{ required: true, message: '请上传职称证' }]}
            style={{ marginLeft: 16, width: 360 }}
            initialValue={titleCertImages}
          >
            <UploadImageButton
              bucket='zhongzhi-dev-idcard-image'
              onUploaded={(url) => {
                form.setFieldsValue({
                  titleCertImages: url
                })
              }}
              dir={`/admin/certificateImages/academicTitle/${
                accountId || 'addDoctor'
              }`}
              accept='.gif,.jpg,.jpeg,.png,.GIF,.JPG,.JPEG,.PNG'
              noMessage
              defaultFileList={
                titleCertImages[0]
                  ? [
                      {
                        uid: 'titleCertImages1',
                        name: 'titleCertImages1.png',
                        status: 'done',
                        url: titleCertImages[0],
                        size: 0,
                        type: ''
                      }
                    ]
                  : []
              }
            />
          </Form.Item>
        )}
        {type === 5 && (
          <>
            <Form.Item
              name='verifyResultImages'
              rules={[{ required: true, message: '请上传审核结果材料' }]}
              style={{ marginLeft: 16, width: 360 }}
              initialValue={verifyResultImages}
            >
              <UploadImageButton
                bucket='zhongzhi-dev-idcard-image'
                uploadImgLength={6}
                onUploaded={(url) => {
                  form.setFieldsValue({
                    verifyResultImages: url
                  })
                }}
                dir={`/admin/verifyImgs/${accountId || 'addDoctor'}`}
                accept='.gif,.jpg,.jpeg,.png,.GIF,.JPG,.JPEG,.PNG'
                noMessage
                defaultFileList={verifyResultImages.map((img, index) => ({
                  uid: `verifyResultImage${index}`,
                  name: `${img.substring(img.lastIndexOf('/') + 1)}`,
                  status: 'done',
                  url: img,
                  size: 0,
                  type: ''
                }))}
              />
            </Form.Item>
            <Form.Item
              label='验证结果'
              name='verifyResult'
              rules={[
                {
                  required: true,
                  message: '请选择验证结果'
                }
              ]}
              initialValue={
                verifyResult ? (verifyResult === '是' ? 1 : 0) : undefined
              }
            >
              <Radio.Group>
                <Radio value={1}>通过</Radio>
                <Radio value={0}>不通过</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default UploadImageModal
