import React, { useState, useEffect, FC } from 'react'
import omit from 'lodash.omit'
import { Modal, Spin, Input, notification, Progress } from 'antd'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import {
  ProcessBudgetListDocument,
  ProcessBudgetListQuery,
  ProcessBudgetListQueryVariables,
  useProcessBudgetListQuery
} from '../../graphql'
import { getData } from './data'
import ErrorView from '../../components/ErrorView'
import { generateExport } from '../../components/UploadBatch'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { useClient } from 'urql'
import PQueue from 'p-queue'
import { getExportPageSizeByDataCount } from '../../constant/user'

const tabKey = 'processBudget'
const BudgetList: FC = () => {
  const table = useSearchTable<{ number?: string }>(
    {},
    { pageSize: 15, currentPage: 1 }
  )
  const urqlClient = useClient()
  const [isExporting, setExporting] = useState(false)
  const [exportBudgetList, setExportBudgetList] = useState<any>([])
  const [progressPercent, setProgressPercent] = useState(0)
  const [progressStatus, setProgressStatus] = useState<
    'success' | 'normal' | 'exception' | 'active' | undefined
  >(undefined)

  const queryVariable = table.searchParams

  const [{ data, error, fetching }, refetchList] = useProcessBudgetListQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        ...queryVariable
      }
    }
  })

  const exportResult = (type: 'success' | 'error', message: string) => {
    notification[type]({
      message: message,
      duration: 0.5,
      onClose: () => {
        setExporting(false)
        setProgressPercent(0)
        setProgressStatus(undefined)
      }
    })
  }

  const onExportHandle = async () => {
    setExporting(true)

    const total = data?.budget?.pager?.total || 0
    if (total === 0) return
    const pageSize = getExportPageSizeByDataCount(total)
    const pageCount = _.ceil(total / pageSize)
    let budgetList: any[] = []
    const errors: any[] = []
    const pageList = []
    for (let index = 1; index <= pageCount; index++) {
      pageList.push({
        pageIndex: index,
        pageSize
      })
    }
    const queue = new PQueue({ concurrency: 2 })
    queue.addAll(
      pageList.map((page: any) => {
        return async () => {
          try {
            const { data, error: exportQueryErr } = await urqlClient
              .query<ProcessBudgetListQuery, ProcessBudgetListQueryVariables>(
                ProcessBudgetListDocument,
                {
                  pager: {
                    currentPage: page.pageIndex,
                    pageSize: page.pageSize
                  },
                  query: {
                    ...queryVariable
                  }
                }
              )
              .toPromise()
            if (exportQueryErr) {
              queue.pause()
              queue.clear()
              setProgressStatus('exception')
              errors.push(exportQueryErr)
            } else {
              budgetList = _.concat(budgetList, data?.budget)
              setProgressPercent(_.round((budgetList.length / pageCount) * 100))
            }
          } catch (e) {
            errors.push(e)
            queue.pause()
            queue.clear()
            setProgressStatus('exception')
          }
        }
      })
    )

    // 当队列成为空且所有任务都执行完时,返回promise,保证所有任务执行完
    await queue.onIdle()

    if (errors && errors.length > 0) {
      exportResult('error', '导出失败')
      return
    } else {
      budgetList = _.sortBy(budgetList, function (o) {
        return o.pager.currentPage
      })
      const budgetItemList = _.flatten(budgetList.map((x) => x.items))
      setExportBudgetList(budgetItemList)
    }
  }

  useEffect(() => {
    if (isExporting && exportBudgetList.length > 0) {
      const dataResult = getData(exportBudgetList, true)
      generateExport(
        dataResult,
        `流程预算查询_${new Date().getTime()}`,
        null,
        (err) => {
          exportResult(
            err ? 'error' : 'success',
            err ? '导出失败' : 'EXCEL下载成功，请查收'
          )
        }
      )
    }
  }, [exportBudgetList])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        delayFields={['number']}
        table={table}
        tabKey={tabKey}
        searchProps={{
          fields: [
            {
              name: 'number',
              label: '搜索',
              span: 10,
              render() {
                return <Input allowClear placeholder='请输入流程编号搜索' />
              }
            }
          ]
        }}
        bordered
        loading={fetching}
        pager={data?.budget?.pager}
        dataSource={data?.budget?.items || []}
        rowKey={'id'}
        columns={[
          {
            align: 'center',
            title: '流程编号',
            dataIndex: 'budgetNumber',
            render(v, item: any) {
              return (
                <Link to={`/processBudgetDetail/${item.id}`}>{v || '-'}</Link>
              )
            }
          },
          {
            align: 'center',
            title: '预算金额',
            dataIndex: 'budgetAmount',
            render(v) {
              return v || '-'
            }
          },
          {
            align: 'center',
            title: '已使用金额',
            dataIndex: 'usedFee',
            render(v) {
              return v || '-'
            }
          }
        ]}
        showExportBtn
        onExport={onExportHandle}
      />
      <Modal
        visible={!!isExporting}
        footer={null}
        maskClosable={false}
        closable={false}
        width={400}
      >
        <Spin tip='正在为您生成EXCEL文件，请不要关闭此网页…' />
        <Progress
          size='small'
          percent={progressPercent}
          status={progressStatus}
        />
      </Modal>
    </main>
  )
}

export default BudgetList
