import { SalesOrderStatus, SalesClaimStatus } from '../graphql'

export const formatAmountStr = (amountStr: string) => {
  if (!amountStr) return '-'

  return Number(amountStr)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getSalesOrderStatus = (status: string) => {
  let statusObj: { text?: string; color?: string } = {}

  switch (status) {
    case SalesOrderStatus.BookingProgress:
      statusObj = { text: '流程审批中', color: '#EE7700' }
      break
    case SalesOrderStatus.BookingReturn:
      statusObj = { text: '流程审批退回', color: '#F76060' }
      break
    case SalesOrderStatus.BookingSuccess:
      statusObj = { text: '预订成功', color: '#51A04C' }
      break
    case SalesOrderStatus.PrePaid:
      statusObj = { text: '待支付', color: '#EE7700' }
      break
    case SalesOrderStatus.PreVoucherUpload:
      statusObj = { text: '待票据上传', color: '#EE7700' }
      break
    case SalesOrderStatus.VoucherUploadCompleted:
      statusObj = { text: '票据上传完成', color: '#51A04C' }
      break
    case SalesOrderStatus.PreVoucherAudit:
      statusObj = { text: '待票据审核', color: '#EE7700' }
      break
    case SalesOrderStatus.AuditCompleted:
      statusObj = { text: '票据审核完成', color: '#51A04C' }
      break
    case SalesOrderStatus.AuditFailed:
      statusObj = { text: '票据审核退回', color: '#F76060' }
      break
    case SalesOrderStatus.FormCancel:
      statusObj = { text: '已取消', color: '#888' }
      break
    case SalesOrderStatus.FormReviewing:
      statusObj = { text: '待财务审核', color: '#EE7700' }
      break
    case SalesOrderStatus.ReviewedCompleted:
      statusObj = { text: '财务审核完成', color: '#51A04C' }
      break
    case SalesOrderStatus.FinancialReturn:
      statusObj = { text: '财务退回', color: '#F76060' }
      break
    default:
      statusObj = {}
      break
  }
  return statusObj
}

export const getClaimFormStatus = (claimFormStatus: string) => {
  let status: { text?: string; color?: string } = {}
  switch (claimFormStatus) {
    case SalesClaimStatus.Init:
      status = { text: '待提交', color: '#5581FA' }
      break
    case SalesClaimStatus.Approving:
      status = { text: '审批中', color: '#FF6F00' }
      break
    case SalesClaimStatus.ApproveReturn:
      status = { text: '审批退回', color: '#F76060' }
      break
    case SalesClaimStatus.Prereviewing:
      status = { text: '待初审', color: '#FF6F00' }
      break
    case SalesClaimStatus.Reviewing:
      status = { text: '待复核', color: '#FF6F00' }
      break
    case SalesClaimStatus.Reviewed:
      status = { text: '财务审核完成', color: '#51A04C' }
      break
    case SalesClaimStatus.Return:
      status = { text: '财务退回', color: '#F76060' }
      break
    default:
      status = {}
      break
  }
  return status
}

export const getFormCategoryText = (formCategory: string) => {
  let status = '-'
  switch (formCategory) {
    case '1':
      status = '餐饮消费'
      break
    case '2':
      status = '商超消费'
      break
    case '3':
      status = '网购消费'
      break
    case '4':
      status = '快递消费'
      break
    case '5':
      status = '交通消费'
      break
    default:
      break
  }
  return status
}

export const getFormPaymentTypeText = (paymentType: number) => {
  let paymentTypeText = '-'
  switch (paymentType) {
    case 0:
      paymentTypeText = '-'
      break
    case 1:
      paymentTypeText = '线下支付'
      break
    case 2:
      paymentTypeText = '线上支付'
      break
    default:
      break
  }

  return paymentTypeText
}

export const getSalesBudgetChannelText = (channel: number) => {
  let text = '-'
  switch (channel) {
    case 1:
      text = '项目预算'
      break
    case 2:
      text = '成本中心预算'
      break
    case 3:
      text = '日常预算'
      break
    case 4:
      text = '专项预算'
      break
    case 5:
      text = '学术预算'
      break
    default:
      text = channel.toString()
      break
  }

  return text
}
