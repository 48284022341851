import React, { FC, useState, useEffect } from 'react'
import { Map, Marker } from 'react-amap'
import { Row, Col, Input } from 'antd'
import { useMap } from 'react-use'
import { CitySelector, PoiSelector } from './SelectorCollection'

interface HotelInfo {
  amapId?: string
  position?: string
  city?: string
  address?: string
  name?: string
}

interface HotelSearchProps {
  value?: HotelInfo
  onChange?: (value: HotelInfo) => void
}

const HotelSearch: FC<HotelSearchProps> = ({ value = {}, onChange }) => {
  value = value || {}
  const [hotelObj, { set: setHotel, setAll: setHotelAll }] = useMap<HotelInfo>({
    city: '上海',
    ...value
  })

  const [currentPOI, setCurrentPOI] = useState<MaybeNull<AmapPOI>>(null)

  const triggerChange = (changedValue: any) => {
    onChange?.({
      ...hotelObj,
      ...value,
      ...changedValue
    })
  }

  const handleChange = (
    changed: {
      [field in keyof HotelInfo]: string | undefined
    }
  ) => {
    setHotelAll(changed)
    triggerChange(changed)
  }

  const transformPOItoMarker = (poi: MaybeNull<AmapPOI>) => {
    if (!poi) return null
    const [longitude, latitude] = poi.location?.split(',') || []
    return {
      clickable: true,
      label: {
        content: poi.name
      },
      amapId: poi.id,
      position: {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude)
      }
    }
  }

  useEffect(() => {
    if (value.amapId) {
      setCurrentPOI({
        id: value.amapId,
        address: value.address,
        location: value.position,
        name: value.name
      })
    }
  }, [value])

  return (
    <Row gutter={12}>
      <Col span={6}>
        <CitySelector
          value={value.city || hotelObj.city}
          onChange={(city) => handleChange({ city })}
          valueKey={'name'}
        />
      </Col>
      <Col span={18}>
        <PoiSelector
          city={value.city || hotelObj.city}
          value={value.name || hotelObj.name}
          onChange={(poi) => {
            handleChange({
              address: poi?.address,
              amapId: poi?.id,
              position: poi?.location,
              name: poi?.name
            })
            //setCurrentPOI(poi)
          }}
        />
      </Col>
      <Col span={24} style={{ marginTop: 20 }}>
        <Input value={value.address || hotelObj.address} readOnly />
      </Col>
      <Col span={24}>
        <div style={{ width: '100%', height: 300, marginTop: 20 }}>
          <Map
            zoom={15}
            center={transformPOItoMarker(currentPOI)?.position || undefined}
            amapkey={process.env.AMAP_KEY}
            events={{}}
          >
            {currentPOI && <Marker {...transformPOItoMarker(currentPOI)} />}
          </Map>
        </div>
      </Col>
    </Row>
  )
}

HotelSearch.defaultProps = { value: {} }
export default HotelSearch
