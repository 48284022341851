import React, { FC, useState } from 'react'
import omit from 'lodash.omit'
import { searchFields, useTableColumns, FieldTypes } from './data'
import { useUpadminFinancePageQuerySettlementsQuery } from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { useSearchStore } from '../../hooks/pageSearch'
// import { useUserStore } from '../../hooks/user'

const tabKey = 'paying'
const PayingTable: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[pageKey] }
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const date = table.searchParams?.date
  const [curRegionId, setCurRegionId] = useState(searchParams?.regionId)
  const [curCompanyId, setCurCompanyId] = useState(searchParams?.companyId)

  const queryVariable = {
    ...omit(table.searchParams, ['date', 'regionId']),
    // 省区为 '' 时，删除省区字段
    ...(!curRegionId ? {} : { region: curRegionId }),
    meetingStartTimestamp:
      (date?.[0] && JSON.stringify(date[0].startOf('day').unix() * 1000)) ||
      undefined,
    meetingEndTimestamp:
      (date?.[1] && JSON.stringify(date[1].endOf('day').unix() * 1000)) ||
      undefined
  }

  const [{ data, error, fetching }, refetch] =
    useUpadminFinancePageQuerySettlementsQuery({
      variables: {
        pager: omit(table.pager, 'total'),
        query: {
          settlementStatus: 1,
          ...queryVariable
        }
      }
    })

  const onConfirmOpen = (v: any, meeting: any) => {
    console.log(v)
    console.log(meeting)
  }

  const tableColumns = useTableColumns({
    onCancel: refetch,
    type: 'paying',
    openConfirmModal: onConfirmOpen
  })

  // 省区选择改变，手动触发状态更改
  const onRegionChange = (value: string) => {
    setCurRegionId(value)
  }
  // 企业选择改变，省区置空，待重新选择
  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
    setCurRegionId('')
  }

  const onTableReset = () => {
    setCurCompanyId('')
    setCurRegionId('')
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        tabKey={tabKey}
        searchProps={{
          fields: searchFields(
            // production,
            curCompanyId,
            onRegionChange,
            onCompanyChange
          )
        }}
        bordered
        loading={fetching}
        pager={data?.settlements?.pager}
        dataSource={
          data?.settlements?.items?.filter(
            (item) => item?.attenders?.meetingId
          ) || []
        }
        size='middle'
        rowKey='id'
        columns={tableColumns}
        onReset={onTableReset}
      />
    </main>
  )
}

export default PayingTable
