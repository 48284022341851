import React, { useState, useEffect, FC } from 'react'
import omit from 'lodash.omit'
import { message, Modal, notification, Progress, Spin } from 'antd'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import {
  HansohBudgetListDocument,
  HansohBudgetListQuery,
  HansohBudgetListQueryVariables,
  useHansohBudgetListQuery
} from '../../graphql'
import {
  searchFields,
  FieldTypes,
  useTableColumns,
  budgetFormat,
  getData
} from './data'
import ErrorView from '../../components/ErrorView'
import { generateExport } from '../../components/UploadBatch'
import { useSearchStore } from '../../hooks/pageSearch'
import _ from 'lodash'
import { useClient } from 'urql'
import PQueue from 'p-queue'
import { getExportPageSizeByDataCount } from '../../constant/user'

const tabKey = 'personalBudget'
const BudgetList: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[pageKey] }
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const urqlClient = useClient()
  const [isExporting, setExporting] = useState(false)
  const [curBusinessId, setBusinessId] = useState<string | undefined>(
    searchParams?.businessId
  )
  const [curRegionId, setRegionId] = useState<string | undefined>(
    searchParams?.regionId
  )
  const [curAreaId, setAreaId] = useState<string | undefined>(
    searchParams?.areaId
  )
  const [exportBudgetList, setExportBudgetList] = useState<any>([])
  const [progressPercent, setProgressPercent] = useState(0)
  const [progressStatus, setProgressStatus] = useState<
    'success' | 'normal' | 'exception' | 'active' | undefined
  >(undefined)

  const queryVariable = {
    ...omit(table.searchParams, ['businessId', 'areaId', 'regionId']),
    ...(table.searchParams?.businessId
      ? { businessIds: [table.searchParams.businessId] }
      : {}),
    ...(table.searchParams?.areaId
      ? { areaIds: [table.searchParams.areaId] }
      : {}),
    ...(table.searchParams?.regionId
      ? { regionIds: [table.searchParams.regionId] }
      : {})
  }

  const onBusinessChange = (value: string) => {
    setBusinessId(value)
    setRegionId(undefined)
    setAreaId(undefined)
  }

  const onRegionChange = (value: string) => {
    setRegionId(value)
    setAreaId(undefined)
  }

  const onTableReset = () => {
    setBusinessId(undefined)
    setRegionId(undefined)
    setAreaId(undefined)
  }

  const [{ data, error, fetching }, refetchList] = useHansohBudgetListQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        ...queryVariable
      }
    }
  })

  const tableColumns = useTableColumns()

  const exportResult = (type: 'success' | 'error', message: string) => {
    notification[type]({
      message: message,
      duration: 0.5,
      onClose: () => {
        setExporting(false)
        setProgressPercent(0)
        setProgressStatus(undefined)
      }
    })
  }

  const onExportHandle = async () => {
    setExporting(true)

    const total = data?.budget?.pager?.total || 0
    if (total === 0) return
    const pageSize = getExportPageSizeByDataCount(total)
    const pageCount = _.ceil(total / pageSize)
    let budgetList: any[] = []
    const errors: any[] = []
    const pageList = []
    for (let index = 1; index <= pageCount; index++) {
      pageList.push({
        pageIndex: index,
        pageSize
      })
    }
    const queue = new PQueue({ concurrency: 2 })
    queue.addAll(
      pageList.map((page: any) => {
        return async () => {
          try {
            const { data, error: exportQueryErr } = await urqlClient
              .query<HansohBudgetListQuery, HansohBudgetListQueryVariables>(
                HansohBudgetListDocument,
                {
                  pager: {
                    currentPage: page.pageIndex,
                    pageSize: page.pageSize
                  },
                  query: {
                    ...queryVariable
                  }
                }
              )
              .toPromise()
            if (exportQueryErr) {
              queue.pause()
              queue.clear()
              setProgressStatus('exception')
              errors.push(exportQueryErr)
            } else {
              budgetList = _.concat(budgetList, data?.budget)
              setProgressPercent(_.round((budgetList.length / pageCount) * 100))
            }
          } catch (e) {
            errors.push(e)
            queue.pause()
            queue.clear()
            setProgressStatus('exception')
          }
        }
      })
    )

    // 当队列成为空且所有任务都执行完时,返回promise,保证所有任务执行完
    await queue.onIdle()

    if (errors && errors.length > 0) {
      exportResult('error', '导出失败')
      return
    } else {
      budgetList = _.sortBy(budgetList, function (o) {
        return o.pager.currentPage
      })
      const budgetItemList = _.flatten(budgetList.map((x) => x.items))
      setExportBudgetList(budgetItemList)
    }
  }

  useEffect(() => {
    if (isExporting && exportBudgetList.length > 0) {
      const dataResult = getData(budgetFormat(exportBudgetList))
      generateExport(
        dataResult,
        `个人预算查询_${new Date().getTime()}`,
        null,
        (err) => {
          exportResult(
            err ? 'error' : 'success',
            err ? '导出失败' : 'EXCEL下载成功，请查收'
          )
        }
      )
    }
  }, [exportBudgetList])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        tabKey={tabKey}
        searchProps={{
          fields: searchFields(
            curBusinessId,
            onBusinessChange,
            curRegionId,
            onRegionChange
          )
        }}
        bordered
        loading={fetching}
        pager={data?.budget?.pager}
        dataSource={budgetFormat(data?.budget?.items || [])}
        rowKey={'accountId'}
        columns={tableColumns}
        onReset={onTableReset}
        showExportBtn
        onExport={onExportHandle}
      />
      <Modal
        visible={!!isExporting}
        footer={null}
        maskClosable={false}
        closable={false}
        width={400}
      >
        <Spin tip='正在为您生成EXCEL文件，请不要关闭此网页…' />
        <Progress
          size='small'
          percent={progressPercent}
          status={progressStatus}
        />
      </Modal>
    </main>
  )
}

export default BudgetList
