import React, { FC, useState } from 'react'
import {
  Modal,
  Input,
  Form,
  InputNumber,
  Button,
  Popconfirm,
  Select
} from 'antd'
import BudgetDetailCard from './budgetDetailCard'
import { Rule } from 'antd/lib/form'
import { BudgetFormItems } from './data'
import { LimitSwapPeopleSelector } from '../../components/SelectorCollection'

const BudgetTransferModal: FC<{
  budgetDetail: any
  onCancel: () => void
  onSubmit: (value: BudgetFormItems, callBack?: () => void) => Promise<void>
}> = ({ budgetDetail, onCancel, onSubmit }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [addSubtractAmount, setAddSubtractAmount] = useState(0)
  const [transferAccountName, setTransferAccountName] = useState('')
  const [popVisible, setPopVisible] = useState(false)

  const checkShowPopconfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setAddSubtractAmount(values.amount)
        setPopVisible(true)
      })
      .catch((errorInfo) => {
        setPopVisible(false)
      })
  }

  const checkValidate = (rule: Rule, value: any) => {
    if (value && value > 0) {
      return Promise.resolve()
    } else {
      return Promise.reject('请输入调整金额')
    }
  }

  const onFinish = (values: BudgetFormItems) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  const onFieldsChange = (changedFields: any, allFields: any) => {
    setPopVisible(false)
  }

  return (
    <Modal
      maskClosable={false}
      title={<div style={{ textAlign: 'center' }}>人员预算调整</div>}
      visible
      onCancel={onCancel}
      centered
      destroyOnClose
      width={700}
      footer={
        <>
          <Button onClick={onCancel}>取消</Button>
          <Popconfirm
            placement='topRight'
            visible={popVisible}
            title={
              <span>
                是否确认操作，从
                <span className='popTitleTag'>
                  员工{budgetDetail?.accountName}
                </span>
                的<span className='popTitleTag'>{budgetDetail?.month}</span>
                月预算中调整
                <span className='popTitleTag'>{addSubtractAmount}</span>到
                <span className='popTitleTag'>员工{transferAccountName}</span>的
                <span className='popTitleTag'>{budgetDetail?.month}</span>
                月预算中？
              </span>
            }
            onConfirm={() => {
              setPopVisible(false)
              form.submit()
            }}
            onCancel={() => {
              setPopVisible(false)
            }}
          >
            <Button
              type='primary'
              loading={loading}
              onClick={checkShowPopconfirm}
            >
              提交
            </Button>
          </Popconfirm>
        </>
      }
      confirmLoading={loading}
    >
      <BudgetDetailCard
        data={budgetDetail}
        budgetType='personal'
        bodyStyle={{ padding: 0, paddingLeft: 10 }}
        fetching={false}
        includeExtraOperation={false}
        hideLabel={false}
        hideBasicTitle={true}
      />
      <Form
        form={form}
        name='processBudgetAddSubtractForm'
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          label='人员选择'
          name='transferAccount'
          rules={[{ required: true, message: '请选择目标人员' }]}
          required
          className='transferAccount'
        >
          <LimitSwapPeopleSelector
            onChange={(accountId, option: any) => {
              setTransferAccountName(option?.name)
              form.setFieldsValue({
                transferAccount: accountId
              })
            }}
            disableValue={budgetDetail?.accountId}
            style={{ width: 250 }}
          />
        </Form.Item>
        <Form.Item
          label='调整金额'
          name='amount'
          rules={[{ validator: checkValidate }]}
          required
          className='amount'
        >
          <InputNumber
            placeholder='请输入调整金额，只能输入正数值'
            min={0}
            max={100000000}
            precision={2}
            style={{ width: 250 }}
          />
        </Form.Item>
        <Form.Item
          label='操作说明'
          name='explain'
          className='explain'
          validateFirst={true}
          rules={[
            {
              max: 20,
              message: '超出最大长度，最多20个中文字'
            },
            {
              pattern: /^[\u4E00-\u9FA5]+$/,
              message: '只能输入中文'
            }
          ]}
        >
          <Input.TextArea
            rows={2}
            placeholder='请输入操作说明，限制20个中文字'
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default BudgetTransferModal
