import React, { FC, Fragment, useState } from 'react'
import { Card, Tag, Space, Image, Descriptions } from 'antd'
import faultSvg from '../../assets/fault.svg'

const BillInfo: FC<{
  data: any
  invoices?: any[]
  loading: boolean
}> = ({ data, invoices = [], loading }) => {
  const bill = data?.find((x: any) => x.key === 'bill')
  const billInfo = JSON.parse(bill?.value || '[]')
  const invoiceCertification = data?.find(
    (x: any) => x.key === 'invoiceCertification'
  )
  const invoiceCertificationInfo = JSON.parse(
    invoiceCertification?.value || '[]'
  )
  const ticket = data?.find((x: any) => x.key === 'ticket')
  const ticketInfo = JSON.parse(ticket?.value || '[]')
  return (
    <Card
      loading={loading}
      title='票据信息'
      bordered={false}
      headStyle={{ padding: 0, border: 'none', color: '#888' }}
      bodyStyle={{ padding: 0 }}
    >
      <Descriptions
        column={2}
        labelStyle={{ fontWeight: 'bold', color: '#181818' }}
      >
        <Descriptions.Item key='billInfo' label='支付凭证'>
          <Image.PreviewGroup>
            <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
              {billInfo?.length > 0 ? (
                billInfo?.map((x: any, index: number) => {
                  return (
                    <Image
                      key={index}
                      width={100}
                      height={100}
                      src={x.url || faultSvg}
                    />
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        <Descriptions.Item key='ticketInfo' label='消费清单'>
          <Image.PreviewGroup>
            <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
              {ticketInfo?.length > 0 ? (
                ticketInfo?.map((x: any, index: number) => {
                  return (
                    <Image
                      key={index}
                      width={100}
                      height={100}
                      src={x.url || faultSvg}
                    />
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        <Descriptions.Item key='invoiceInfo' label='发票信息'>
          <Image.PreviewGroup>
            <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
              {invoices?.length > 0 ? (
                invoices?.map((x: any, index: number) => {
                  return (
                    <div key={index}>
                      <Image
                        width={100}
                        height={100}
                        src={x.invoiceUrl || faultSvg}
                      />
                      <div className='checkContainer'>
                        <Tag
                          color={x.checkOcr ? 'success' : 'error'}
                          className='checkTag'
                        >
                          {x.checkOcr ? '识别验证通过' : '识别验证不通过'}
                        </Tag>
                        <Tag
                          color={x.checkPassed ? 'success' : 'error'}
                          className='checkTag'
                        >
                          {x.checkPassed ? '验真验证通过' : '验真验证不通过'}
                        </Tag>
                        <Tag
                          color={x.checkTitle ? 'success' : 'error'}
                          className='checkTag'
                        >
                          {x.checkTitle ? '抬头验证通过' : '抬头验证不通过'}
                        </Tag>
                        <Tag
                          color={x.checkRepeat ? 'success' : 'error'}
                          className='checkTag'
                        >
                          {x.checkRepeat ? '重复验证通过' : '重复验证不通过'}
                        </Tag>
                      </div>
                    </div>
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        <Descriptions.Item key='invoiceCertificationInfo' label='发票查验'>
          <Image.PreviewGroup>
            <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
              {invoiceCertificationInfo?.length > 0 ? (
                invoiceCertificationInfo?.map((x: any, index: number) => {
                  return (
                    <Image
                      key={index}
                      width={100}
                      height={100}
                      src={x.url || faultSvg}
                    />
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        <Descriptions.Item span={2} key='approveInfo' label='审核信息'>
          <ul
            style={{ listStyleType: 'decimal', color: '#888', paddingLeft: 20 }}
          >
            <li>审核商户名称，一致</li>
            <li>审核发票和支付金额，一致</li>
            <li>审核发票日期与消费日期，一致</li>
          </ul>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default BillInfo
