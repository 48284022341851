import React, { FC, useState, useEffect } from 'react'
import { Table, Card } from 'antd'
import { TableProps } from 'antd/lib/table'
import SearchPanel, { SearchPanelProps } from './SearchPanel'
import { useSearchStore } from '../hooks/pageSearch'
import { FormInstance } from 'antd/lib/form'

export const useSearchTable = <T extends {}>(
  initialSearchParams: T,
  initialPager?: Pager
) => {
  const [searchParams, updateSearchParams] = useState<T>(initialSearchParams)
  const [pager, updatePager] = useState<Pager>(initialPager || {})

  const { setPageParams } = useSearchStore()

  return {
    searchParams,
    updateSearchParams: (
      updated: T,
      delayFields?: Array<string>,
      pageKey?: string
    ) => {
      const pageParamsKey = pageKey || window.location.pathname
      if (JSON.stringify(updated) === '{}') {
        setPageParams(pageParamsKey, updated)
        updateSearchParams(updated)
      } else {
        updateSearchParams((prev) => {
          const delayValues: { [key: string]: any } = {}
          delayFields?.forEach((i) => {
            //兼容审批管理待审批会议类型查询
            if (
              pageKey === '/approveManageapprovePending' &&
              i === 'meetingTypes'
            )
              return

            delayValues[i as keyof typeof delayValues] = undefined
          })

          setPageParams(pageParamsKey, {
            ...prev,
            ...updated
          })
          return { ...prev, ...updated, ...delayValues }
        })
      }
      updatePager((pager) => ({
        ...pager,
        currentPage: 1
      }))
    },
    pager,
    updatePager
  }
}

export interface SearchTableProps extends TableProps<any> {
  table?: ReturnType<typeof useSearchTable>
  pager?: Pager
  searchProps?: SearchPanelProps
  extraPanelContent?: JSX.Element
  extraOpera?: JSX.Element
  showExportBtn?: boolean
  onExport?: () => void
  showOperaBtn?: boolean
  showPanel?: boolean
  showPagination?: boolean
  onReset?: () => void
  needSearchStore?: boolean
  delayFields?: Array<string>
  tabKey?: string
  setTableFormRef?: (form: FormInstance<any>) => void
  // selectedRowKeys?: Array<any>
  // onSelectChange?: (selectedRowKeys: any, selectedRows: any) => void
}

const SearchTable: FC<SearchTableProps> = ({
  table,
  loading,
  pager = { currentPage: 1, pageSize: 15 },
  dataSource,
  columns,
  size,
  bordered,
  rowKey,
  extraPanelContent,
  extraOpera,
  searchProps,
  showExportBtn,
  onExport,
  showOperaBtn = true,
  showPanel = true,
  showPagination = true,
  scroll = {},
  onReset, // 自定义重置事件，用于重置时管理页面自定义状态
  // selectedRowKeys, // 当前被选择的行的key数组
  // onSelectChange, // 行选择事件
  needSearchStore = true, // 是否需要记忆搜索
  delayFields = ['keyword', 'keywords'], //禁止实时查询的字段
  tabKey = '',
  setTableFormRef
}) => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams, setPageParams } = useSearchStore()
  const localTable = table || useSearchTable<any>({ pager })

  useEffect(() => {
    if (!pager) return

    const { currentPage, total } = pager

    localTable.updatePager((currentPager) => ({
      ...currentPager,
      currentPage,
      total
    }))
  }, [JSON.stringify(pager)])

  useEffect(() => {
    if (needSearchStore) {
      if (pageParams[pageKey]) {
        localTable.updateSearchParams({
          ...pageParams[pageKey]
        })
      }
    } else {
      localTable.updateSearchParams({})
    }
  }, [])

  return (
    <>
      {showPanel ? (
        <Card>
          <SearchPanel
            {...searchProps}
            needSearchStore={needSearchStore}
            isExportAvailable={!!dataSource?.length}
            showExportBtn={showExportBtn}
            delayFields={delayFields}
            onChange={(value, isDelayField) => {
              // 禁止关键词跟随输入实时查询
              if (isDelayField) {
                setPageParams(pageKey, {
                  ...pageParams[pageKey],
                  ...value
                })
              } else {
                localTable.updateSearchParams(value, delayFields, pageKey)
              }
            }}
            onExport={onExport}
            showOperaBtn={showOperaBtn}
            onReset={onReset}
            extraOpera={extraOpera}
            onSearch={() => {
              // 点击搜索再进行关键词查询
              localTable.updateSearchParams(pageParams?.[pageKey])
            }}
            initialFormValues={pageParams?.[pageKey]}
            setTableFormRef={setTableFormRef}
          />
          {extraPanelContent && React.cloneElement(extraPanelContent, {})}
        </Card>
      ) : null}

      <Table
        // rowSelection={{
        //   selectedRowKeys: selectedRowKeys,
        //   onChange: onSelectChange
        // }}
        bordered={bordered}
        loading={loading}
        rowKey={rowKey}
        pagination={
          !showPagination
            ? false
            : {
                showSizeChanger: true,
                current: localTable.pager?.currentPage,
                pageSize: localTable.pager?.pageSize,
                total: localTable.pager?.total,
                onShowSizeChange(_, pageSize) {
                  localTable.updatePager((currentPager) => ({
                    ...currentPager,
                    currentPage: 1,
                    pageSize
                  }))
                },
                onChange(page) {
                  localTable.updatePager((currentPager) => ({
                    ...currentPager,
                    currentPage: page
                  }))
                }
              }
        }
        dataSource={dataSource}
        size={size}
        columns={columns}
        scroll={scroll}
        sticky
      />
    </>
  )
}

export default SearchTable
