import React, { FC, useState, useRef } from 'react'
import { Upload, Button, message, Row, Modal } from 'antd'
import {
  EyeOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import useAxios from 'axios-hooks'
import { UploadProps } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import OSS from 'ali-oss'
import { useUserStore } from '../hooks/user'
import { RcFile } from 'antd/es/upload/interface'
import { getBase64, isVideo, isVideoElement } from '../constant/user'

export interface UploadButtonProps {
  buttonText?: string
  bucket: string
  dir?: string
  onChange?: (fileList: UploadFile<any>[]) => void
  onUploaded?: (url: string | string[]) => void | Promise<void>
  accept?: string
  noMessage?: boolean
  defaultFileList?: UploadFile[]
  uploadImgLength?: number
}

interface STSServerResponse {
  SecurityToken: string
  AccessKeyId: string
  AccessKeySecret: string
  Expiration: string
}

const useOssClient = (bucket: string) => {
  const { token } = useUserStore()
  const [credentials, setCredentials] = useState<STSServerResponse | null>(null)
  const [, fetchSTS] = useAxios<STSServerResponse>(
    {
      url: process.env.STS_SERVICE,
      headers: {
        token
      }
    },
    { manual: true }
  )

  const ossClient = useRef<OSS | null>(null)

  const createOSSClient = (creds: STSServerResponse) => {
    const client = new OSS({
      region: 'oss-cn-shanghai',
      bucket,
      accessKeyId: creds.AccessKeyId,
      accessKeySecret: creds.AccessKeySecret,
      stsToken: creds.SecurityToken,
      timeout: 10 * 60 * 1000
    })
    ossClient.current = client
    return client
  }

  return async () => {
    const expired = credentials?.Expiration
      ? new Date().getTime() - new Date(credentials.Expiration).getTime() >= 0
      : true

    if (expired) {
      const result = await fetchSTS()
      const data = result?.data || result
      setCredentials(data)
      return createOSSClient(data)
    }
    return ossClient.current
  }
}

const UploadImageButton: FC<UploadButtonProps> = ({
  buttonText,
  dir,
  onChange,
  onUploaded,
  bucket,
  accept,
  noMessage,
  defaultFileList = [],
  uploadImgLength = 1
}) => {
  const [running, setRunning] = useState<boolean | null>(null)
  const [fileList, setFileList] = useState<UploadFile[]>(defaultFileList)
  const [requestFileList, setRequestFileList] = useState<any[]>(defaultFileList)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [previewType, setPreviewType] = useState('')
  const getOssClient = useOssClient(bucket)

  const onFileChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    console.log('Aliyun OSS:', fileList)
    setFileList(newFileList)
    // if (onChange) {
    //   onChange([...newFileList])
    // }
  }

  // 覆盖 antd 默认的上传行为
  // options: {
  //   file: File
  //   onSuccess: (body: any, xhr: any) => void
  //   onError: (e: any) => void
  // }
  const onRequest = async (
    options:
      | UploadRequestOption
      | {
          file: any // 为了解决上面 TODO 里提到的问题
          onSuccess: any // 为了解决上面 TODO 里提到的问题
        }
  ) => {
    console.log(options)
    const { file, onSuccess } = options
    setRunning(true)
    const client = await getOssClient()

    try {
      const res = await client?.put(
        `${dir}/${file.name.replace('.', `-${Date.now()}.`)}`,
        file
      )
      onSuccess(res || {}, file)
      if (res?.url) {
        const reqFileList = requestFileList.concat({
          uid: file.uid,
          url: res?.url
        })
        setRequestFileList(reqFileList)
        if (onUploaded) {
          await onUploaded(
            uploadImgLength > 1 ? reqFileList.map((x) => x.url) : res.url
          )
        }
        !noMessage && message.success('上传成功')
      }
    } catch (e) {
      // onError(e)
      message.error('上传失败')
    } finally {
      setRunning(false)
    }
    return {
      abort() {
        message.info('image uploader aborted')
      }
    }
  }

  const handleCancel = () => {
    if (isVideo(previewType)) {
      const video = document.getElementById('video')
      if (isVideoElement(video)) {
        video && video.pause()
      }
    }
    setPreviewVisible(false)
  }

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewType(file.type)
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    )
  }

  const handleRemove = (file: any) => {
    const index = requestFileList.findIndex((x) => x.uid === file.uid)
    if (index >= 0) {
      const reqFileList = Object.assign([], requestFileList)
      reqFileList.splice(index, 1)
      setRequestFileList(reqFileList)
      onUploaded &&
        onUploaded(
          uploadImgLength > 1 ? reqFileList.map((x: any) => x.url) : ''
        )
    } else {
      message.error('数据异常，请重试')
    }
  }

  const uploadButton = (
    <div>
      {running ? <LoadingOutlined /> : <PlusOutlined />}
      {buttonText && <div style={{ marginTop: 8 }}>{buttonText}</div>}
    </div>
  )

  return (
    <>
      <Upload
        accept={accept}
        onChange={onFileChange}
        customRequest={onRequest}
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={handleRemove}
        listType='picture-card'
        className='avatar-uploader'
      >
        {fileList.length >= uploadImgLength ? null : uploadButton}
      </Upload>
      {buttonText && fileList.length >= 1 && (
        <div style={{ marginLeft: 15 }}>{buttonText}</div>
      )}
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {isVideo(previewType) ? (
          <video id='video' width='460' controls>
            <source src={previewImage} type={previewType} />
            Your browser does not support HTML5 video.
          </video>
        ) : (
          <img alt='example' style={{ width: '100%' }} src={previewImage} />
        )}
      </Modal>
    </>
  )
}

export default UploadImageButton
