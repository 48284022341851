import React, { FC, useEffect } from 'react'
import { Form, Row, Col, Button, Input } from 'antd'
import { FormInstance, FormProps } from 'antd/lib/form'
import { Store } from 'antd/lib/form/interface'
import { ButtonProps } from 'antd/lib/button'
import _ from 'lodash'
import { isMobileClient } from '../constant/user'

interface SearchField {
  name: string
  label?: string | null
  defaultValue?: string
  span?: number
  render: (form: Store) => React.ReactNode
  grow?: boolean
  screenColumn?: any
}

export interface SearchPanelProps {
  fields?: SearchField[]
  delayFields?: Array<string>
  onChange?: (values: any, isDelayField?: boolean) => void
  onFinish?: FormProps['onFinish']
  onExport?: () => void
  showExportBtn?: boolean
  isExportAvailable?: boolean
  showOperaBtn?: boolean
  onReset?: () => void
  extraOpera?: JSX.Element
  onSearch?: () => void
  needSearchStore?: boolean
  initialFormValues?: any
  setTableFormRef?: (form: FormInstance<any>) => void
}

const SearchPanel: FC<SearchPanelProps> = ({
  fields = [],
  delayFields,
  onChange,
  onFinish,
  onExport,
  showExportBtn,
  isExportAvailable,
  showOperaBtn,
  onReset, // 自定义重置事件，用于重置时管理页面自定义状态
  onSearch,
  extraOpera,
  needSearchStore = false,
  initialFormValues = undefined,
  setTableFormRef
}) => {
  const [form] = Form.useForm()
  const isMobile = isMobileClient()
  const __setFormRef = (instance: FormInstance<any>) => {
    setTableFormRef && setTableFormRef(instance)
  }
  const __onFinish: FormProps['onFinish'] = (values) => {
    onFinish && onFinish(values)
  }

  const __onChange: FormProps['onValuesChange'] = (value, values) => {
    let isDelayField = false
    const changeField = Object.keys(value)[0]
    if (delayFields?.includes(changeField)) {
      isDelayField = true
    }
    switch (changeField) {
      case 'companyId':
        form.setFieldsValue({
          region: undefined,
          regionId: undefined,
          regionIds: undefined,
          meetingType: undefined,
          activityType: undefined,
          documentCategory: undefined,
          productCodes: undefined,
          documentCategoryIds: undefined,
          sphere: undefined
        })
        values.region = undefined
        values.regionId = undefined
        values.regionIds = undefined
        values.meetingType = undefined
        values.activityType = undefined
        values.documentCategory = undefined
        values.productCodes = undefined
        values.documentCategoryIds = undefined
        values.sphere = undefined
        break
      case 'meetingType':
        form.setFieldsValue({
          activityType: undefined,
          activityTypes: undefined
        })
        values.activityType = undefined
        break
      case 'businessId':
        form.setFieldsValue({
          regionId: undefined,
          areaId: undefined
        })
        values.regionId = undefined
        values.areaId = undefined
        break
      case 'regionId':
        form.setFieldsValue({
          areaId: undefined
        })
        values.areaId = undefined
        break
      default:
        break
    }
    onChange && onChange(values, isDelayField)
  }

  const __onReset: ButtonProps['onClick'] = () => {
    // form.resetFields()
    const fieldNames = fields.map((field) => [field.name, field.defaultValue])
    const initialFieldValues = _.fromPairs(fieldNames)
    form.setFieldsValue(initialFieldValues)
    onChange && onChange({})
    onReset && onReset()
  }

  const __onExport = () => {
    onExport && onExport()
  }

  const __onSearch = () => {
    onSearch && onSearch()
  }

  return (
    <Form
      form={form}
      name='advanced_search'
      className='ant-advanced-search-form'
      onFinish={__onFinish}
      onValuesChange={__onChange}
      initialValues={needSearchStore ? initialFormValues : {}}
      ref={(instance) => {
        if (instance) {
          __setFormRef(instance)
        }
      }}
    >
      <Row gutter={22}>
        {fields.map((field) => {
          return (
            <Col
              span={
                field.grow && !field.span
                  ? undefined
                  : field.span || 24 / fields.length
              }
              {...(isMobile ? field.screenColumn : {})}
              key={field.name}
            >
              <Form.Item name={field.name} label={field.label}>
                {field.render(form)}
              </Form.Item>
            </Col>
          )
        })}
      </Row>
      {showOperaBtn ? (
        <Row style={{ textAlign: 'right' }}>
          <Col span='24'>
            {extraOpera && React.cloneElement(extraOpera, {})}
            <Button type='primary' htmlType='submit' onClick={__onSearch}>
              搜索
            </Button>
            <Button onClick={__onReset}>重置</Button>
            {showExportBtn ? (
              <Button disabled={!isExportAvailable} onClick={__onExport}>
                导出
              </Button>
            ) : null}
          </Col>
        </Row>
      ) : null}
    </Form>
  )
}

export default SearchPanel
