import React, { useState, useEffect, FC } from 'react'
import omit from 'lodash.omit'
import { Button } from 'antd'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import {
  SalesClaimFormDocument,
  SalesClaimFormQuery,
  SalesClaimFormQueryVariables,
  useSalesClaimFormQuery
} from '../../graphql'
import {
  searchFields,
  FieldTypes,
  useTableColumns,
  getData,
  excelHead
} from './data'
import ErrorView from '../../components/ErrorView'
import { useSearchStore } from '../../hooks/pageSearch'
import { useClient } from 'urql'
import OpeartionScanningCodeGun from '../../components/OpeartionScanningCodeGun'
import ExportQueueModal from '../../components/ExportQueueModal'
import { isMobileClient } from '../../constant/user'
import './index.less'

const ConsumptionReimburseManage: QCPage = () => {
  const pageKey = window.location.pathname
  const { pageParams } = useSearchStore()
  const isMobile = isMobileClient()
  const searchParams = { ...pageParams[pageKey] }
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const urqlClient = useClient()
  const [isExporting, setExporting] = useState(false)
  const [curBusinessId, setBusinessId] = useState<string | undefined>(
    searchParams?.businessId
  )
  const [curRegionId, setRegionId] = useState<string | undefined>(
    searchParams?.regionId
  )
  const [curAreaId, setAreaId] = useState<string | undefined>(
    searchParams?.areaId
  )

  const queryVariable = {
    ...omit(table.searchParams, [
      'submitTime',
      'preReviewedTime',
      'reviewedTime'
    ]),
    startTime:
      table.searchParams?.submitTime?.[0]
        ?.startOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    endTime:
      table.searchParams?.submitTime?.[1]
        ?.endOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    preReviewedStartTime:
      table.searchParams?.preReviewedTime?.[0]
        ?.startOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    preReviewedEndTime:
      table.searchParams?.preReviewedTime?.[1]
        ?.endOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    reviewedStartTime:
      table.searchParams?.reviewedTime?.[0]
        ?.startOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    reviewedEndTime:
      table.searchParams?.reviewedTime?.[1]
        ?.endOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined
  }

  const onBusinessChange = (value: string) => {
    setBusinessId(value)
    setRegionId(undefined)
    setAreaId(undefined)
  }

  const onRegionChange = (value: string) => {
    setRegionId(value)
    setAreaId(undefined)
  }

  const onTableReset = () => {
    setBusinessId(undefined)
    setRegionId(undefined)
    setAreaId(undefined)
  }

  const [{ data, error, fetching }, refetchList] = useSalesClaimFormQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        ...queryVariable
      }
    }
  })

  const tableColumns = useTableColumns({
    isMobile
  })

  const queryExportData = async (currentPage: number, pageSize: number) => {
    const { data, error } = await urqlClient
      .query<SalesClaimFormQuery, SalesClaimFormQueryVariables>(
        SalesClaimFormDocument,
        {
          pager: {
            currentPage,
            pageSize
          },
          query: {
            ...queryVariable
          }
        }
      )
      .toPromise()
    return { currentPage, exportData: data?.result?.items, error }
  }

  const onExportHandle = async () => {
    setExporting(true)
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        scroll={{ x: 2150 }}
        searchProps={{
          fields: searchFields()
        }}
        bordered
        loading={fetching}
        pager={data?.result?.pager}
        dataSource={data?.result?.items || []}
        rowKey={(record) => record.claimForm.id}
        columns={tableColumns}
        onReset={onTableReset}
        showExportBtn
        onExport={onExportHandle}
      />
      <ExportQueueModal
        queryExportDataFc={queryExportData}
        formatDataFc={getData}
        excelHead={excelHead()}
        fileName={`提报单管理_${new Date().getTime()}`}
        isExport={isExporting}
        total={data?.result?.pager?.total || 0}
        concurrency={5}
        callback={() => {
          setExporting(false)
        }}
      />
    </main>
  )
}

ConsumptionReimburseManage.pageTitle = '提报单管理'
export default ConsumptionReimburseManage
