import React, { FC, useState } from 'react'
import { Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

const ApprovalConfirmModal: FC<{
  title?: string
  onCancel: () => void
  onSubmit: (callBack?: () => void) => Promise<void>
}> = ({ title = '审核', onCancel, onSubmit }) => {
  const [loading, setLoading] = useState(false)

  const startSubmit = () => {
    setLoading(true)
    onSubmit(() => {
      setLoading(false)
    })
  }

  return (
    <Modal
      maskClosable={false}
      title={`${title}通过`}
      className='financialReviewModal'
      visible
      onCancel={onCancel}
      centered
      destroyOnClose
      onOk={startSubmit}
      okText={'确认'}
      confirmLoading={loading}
      closeIcon={
        <CloseCircleOutlined style={{ fontSize: 20, color: '#ccc' }} />
      }
      bodyStyle={{ padding: '24px 80px' }}
    >
      是否确认完成该订单完成财务{title}
      工作，请确认订单提报金额以及对应发票信息？
    </Modal>
  )
}

export default ApprovalConfirmModal
