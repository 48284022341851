import React, { FC } from 'react'
import { Space, Avatar } from 'antd'
import LogoutButton from './LogoutButton'
import { useUserStore } from '../hooks/user'
import { UserOutlined } from '@ant-design/icons'
import { HR_PRODUCTION } from '../constant/user'
import RealNameAuthButton from './RealNameAuthButton'

const UserPanel: FC = () => {
  const userStore = useUserStore()
  const production = userStore.profile?.hrProduction || ''
  const isQC =
    production === HR_PRODUCTION.qc || production === HR_PRODUCTION.qcDemo

  return (
    <Space>
      {isQC && <RealNameAuthButton />}
      <Avatar icon={<UserOutlined />} />
      {userStore.profile?.name}
      <LogoutButton />
    </Space>
  )
}

export default UserPanel
