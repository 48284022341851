import React, { useState } from 'react'
import omit from 'lodash.omit'
import { ColumnsType } from 'antd/lib/table'
import { Modal, Radio, Input, message } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'

import { useHrDoctorListQuery, useChangeChannelMutation } from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { useUserStore } from '../../hooks/user'

type FieldTypes = {
  search?: string
}

const DoctorManage: QCPage = () => {
  const [open, setIsOpen] = useState(false)
  const [signatory, setSignatory] = useState('')
  const [editIndex, setEditIndex] = useState(0)

  const userStore = useUserStore()
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const searchFields = [
    {
      name: 'search',
      label: null,
      span: 12,
      render() {
        return <Input allowClear placeholder='请输入老师名字、手机号搜索' />
      }
    }
  ]
  const [, changeAccountDefaultChannel] = useChangeChannelMutation()

  // 拿到接口数据
  const [{ data, error, fetching }, refetchDoctorList] = useHrDoctorListQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      search: table.searchParams?.search
    }
  })

  // 接口报错
  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDoctorList}
        title={error.name}
        message={error.message}
      />
    )
  }

  // 打开弹框
  const onOpenModalClick = (index: number, record: any) => {
    setSignatory(record.defaultChannel)
    setIsOpen(true)
    setEditIndex(index)
  }

  // 点击 radio 选择签约渠道
  const onRadioChange = (e: RadioChangeEvent) => {
    setSignatory(e.target.value)
  }

  // 点击确定按钮，确定修改签约渠道
  const onSignatoryChange = async (data: any) => {
    const result = await changeAccountDefaultChannel({
      channel: signatory,
      accountId: data.doctors.items[editIndex].accountId
    })

    if (result.error || !result.data?.result) {
      message.error(result.error || '修改失败')
      return
    }

    if (result.data?.result) {
      // 更新列表
      setIsOpen(false)
      refetchDoctorList()
    }
  }

  const tableColumns = (channels: any[]): any => [
    {
      title: '老师名字',
      dataIndex: 'cnName',
      align: 'center',
      key: 'cnName'
    },
    {
      title: '老师手机号',
      dataIndex: 'companyMobile',
      align: 'center',
      key: 'companyMobile'
    },
    {
      title: '单位',
      dataIndex: 'hospital',
      key: 'hospitalName',
      align: 'center',
      render(v: any) {
        return v
      }
    },
    {
      title: '部门',
      dataIndex: 'department',
      align: 'center',
      key: 'departmentName',
      render(v: { name: string }) {
        return v?.name
      }
    },
    {
      title: '职称',
      align: 'center',
      dataIndex: 'groupInfo',
      key: 'name',
      render(v: { name: string }) {
        return v?.name
      }
    },
    {
      title: '银行卡号',
      align: 'center',
      dataIndex: 'bankInfo',
      key: 'certNo',
      render(v: { certNo: any }) {
        return v?.certNo
      }
    },
    {
      title: ' 银行',
      align: 'center',
      dataIndex: 'bankInfo',
      key: 'qkkBankName',
      render(v: { qkkBankName: any }) {
        return v?.qkkBankName
      }
    },
    {
      title: '开户网点',
      align: 'center',
      dataIndex: 'bankInfo',
      key: 'ssqBankName',
      render(v: { ssqBankName: any }) {
        return v?.ssqBankName
      }
    }
    // {
    //   title: '可签约渠道',
    //   align: 'center',
    //   dataIndex: 'defaultChannel',
    //   key: 'defaultChannel',
    //   render(v: string) {
    //     return v
    //       ? channels.find((i: { value: string }) => i.value === v)?.text || '-'
    //       : '-'
    //   }
    // },
    // {
    //   title: '操作',
    //   align: 'center',
    //   dataIndex: 'opera',
    //   key: 'opera',
    //   render(text: string, record: any, index: number) {
    //     return (
    //       <React.Fragment>
    //         <a
    //           onClick={() => onOpenModalClick(index, record)}
    //           style={{ paddingRight: '15px' }}
    //         >
    //           修改签约渠道
    //         </a>
    //       </React.Fragment>
    //     )
    //   }
    // }
  ]

  return (
    <main>
      <SearchTable
        delayFields={['search']}
        table={table}
        searchProps={{ fields: searchFields }}
        bordered
        loading={fetching}
        pager={data?.doctors?.pager}
        dataSource={data?.doctors?.items || []}
        size='middle'
        rowKey={(record) => String(record.accountId)}
        columns={tableColumns(userStore.channels)}
      />
      <Modal
        maskClosable={false}
        destroyOnClose
        title={'修改签约渠道'}
        visible={open}
        onCancel={() => setIsOpen(false)}
        centered
        // width={400}
        onOk={(): any => onSignatoryChange(data)}
      >
        <Radio.Group
          onChange={(e: RadioChangeEvent) => onRadioChange(e)}
          value={signatory}
        >
          {userStore.channels?.map((item) => (
            <Radio key={item.value} value={item.value}>
              {item.text}
            </Radio>
          ))}
        </Radio.Group>
      </Modal>
    </main>
  )
}

DoctorManage.pageTitle = '老师管理'

export default DoctorManage
