import React, { FC } from 'react'
import { Button, Input, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import dayjs, { Dayjs } from 'dayjs'
import {
  HRRegionSelector,
  HRMeetingStatusSelector,
  HRVendorsSelector,
  HRCompanySelector,
  ActivitiesTypeSelector,
  MeetingTypeSelector,
  HRBillStatusSelector,
  SettlementStatusSelector
} from '../../components/SelectorCollection'
import DatePicker from '../../components/CustomDatePicker'
import {
  StandardMeetingServiceBizStatus,
  useHrUploadAttenderPaymentVoucherMutation,
  HadesPaymentChannel,
  HadesReceiptType,
  useHrQueryAttenderPaymentDetailQuery,
  useHrUploadAttenderTaxReceiptsMutation,
  UpadminSearchAttendersQuery,
  OrderOperationCategory
} from '../../graphql'
import PreviewModalButton from '../../components/PreviewModalButton'
import { get } from 'lodash'
import {
  ATTENDER_BILL_STATUS,
  SETTLEMENT_STATUS_MAP,
  INVITE_STATUS_MAP
} from '../../constant/hrradiation'

const PaymentDetail: FC<{
  paymentId: string | null | undefined
  channel?: HadesPaymentChannel
  type?: HadesReceiptType
}> = ({ paymentId, channel, type }) => {
  const [{ data }] = useHrQueryAttenderPaymentDetailQuery({
    variables: {
      paymentId: paymentId || '',
      channel,
      type
    },
    pause: !paymentId
  })
  return data?.detail && data.detail.length > 0 ? (
    <>
      <h3>费用明细</h3>
      <Table dataSource={(data.detail as any[]) || []}>
        <Table.Column title='会议名称' dataIndex='meetingName'></Table.Column>
        <Table.Column title='申请人' dataIndex='applicant'></Table.Column>
        <Table.Column title='会议时间' dataIndex='start'></Table.Column>
        <Table.Column title='金额' dataIndex='fee'></Table.Column>
      </Table>
    </>
  ) : null
}

//搜索字段声明
export const searchFields = (
  companyId: string | undefined,
  meetingType: string | undefined,
  onRegionChange: any,
  onCompanyChange: any,
  onActivityTypesChange: any,
  onMeetingTypeChange: any
) => [
  {
    name: 'companyId',
    label: '企业',
    grow: true,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return (
        <HRCompanySelector
          onChange={onCompanyChange}
          style={{ minWidth: 120 }}
        />
      )
    }
  },
  {
    name: 'meetingType',
    label: '会议类型',
    grow: true,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return (
        <MeetingTypeSelector
          auto={true}
          filterByCompanyId
          companyId={companyId}
          disabled={!companyId}
          onChange={onMeetingTypeChange}
          style={{ minWidth: 120 }}
        />
      )
    }
  },

  {
    name: 'activityType',
    label: '活动类型',
    grow: true,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return (
        <ActivitiesTypeSelector
          companyId={companyId}
          filterByMeetingType
          disabled={!meetingType}
          auto={true}
          meetingType={meetingType}
          onChange={onActivityTypesChange}
          style={{ minWidth: 160 }}
        />
      )
    }
  },
  {
    name: 'regionId',
    label: '省区',
    grow: true,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return (
        <HRRegionSelector
          disabled={!companyId}
          auto={true}
          companyId={companyId}
          onChange={onRegionChange}
          style={{ minWidth: 120 }}
        />
      )
    }
  },
  {
    name: 'meetingStatus',
    label: '会议状态',
    grow: true,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <HRMeetingStatusSelector style={{ minWidth: 120 }} />
    }
  },
  {
    name: 'date',
    label: '会议日期',
    grow: true,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <DatePicker.RangePicker locale={locale} format='YYYY-MM-DD' />
    }
  },
  {
    name: 'settlementStatus',
    label: '结算状态',
    grow: true,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <SettlementStatusSelector style={{ minWidth: 120 }} />
    }
  },
  {
    name: 'providerId',
    label: '项目服务商',
    grow: true,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <HRVendorsSelector style={{ minWidth: 120 }} />
    }
  },
  {
    name: 'billStatus',
    label: '开票状态',
    grow: true,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <HRBillStatusSelector style={{ minWidth: 120 }} />
    }
  },
  {
    name: 'keyword',
    label: null,
    span: 12,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return (
        <Input
          allowClear
          placeholder='请输入会议ID/名称、申请人名字、讲师名字搜索'
        />
      )
    }
  }
]

export const useTableColumns = (props: {
  onRefreshed: () => void
  onOperationOrder: (type: OrderOperationCategory, meeting: any) => void
  isMobile?: boolean
}): ColumnsType<UpadminSearchAttendersQuery['orders']['items'][0]> => {
  const [, uploadPaymentVoucher] = useHrUploadAttenderPaymentVoucherMutation()
  const [, uploadTaxReceipts] = useHrUploadAttenderTaxReceiptsMutation()

  return [
    {
      title: '讲师姓名',
      dataIndex: 'attenderData',
      key: 'attenderName',
      width: 80,
      fixed: 'left',
      align: 'center',
      render(v: any) {
        return v?.name
      }
    },
    {
      title: '会议ID',
      width: 200,
      fixed: props.isMobile ? undefined : 'left',
      dataIndex: 'meetingId',
      align: 'center',
      key: 'meetingId',
      render(v: string) {
        return <Link to={`/meeting/${v}`}>{v}</Link>
      }
    },
    {
      title: '讲师ID',
      dataIndex: 'attenderData',
      key: 'accountId',
      width: 200,
      align: 'center',
      render(v: any) {
        return v?.accountId
      }
    },
    {
      title: '单位',
      dataIndex: 'attenderInfo',
      key: 'hospital',
      width: 120,
      align: 'center',
      render(v: any) {
        return v?.custom1
      }
    },
    {
      title: '手机',
      dataIndex: 'attenderData',
      key: 'mobile',
      width: 120,
      align: 'center',
      render(v: any) {
        return v?.mobile
      }
    },
    {
      title: '邀请状态',
      dataIndex: 'attenderStatus',
      key: 'attenderStatus',
      width: 80,
      align: 'center',
      render(v: any) {
        return (v && INVITE_STATUS_MAP[v]) || '待邀请'
      }
    },
    {
      title: '费用',
      width: 100,
      dataIndex: 'attenderFee',
      key: 'attenderFee',
      align: 'center',
      render(v: any) {
        return (!isNaN(Number(v)) && Number(v).toFixed(2)) || ''
      }
    },
    {
      title: '服务费',
      width: 100,
      dataIndex: 'serviceFee',
      key: 'serviceFee',
      align: 'center',
      render(v: any) {
        return (!isNaN(Number(v)) && Number(v).toFixed(2)) || ''
      }
    },
    {
      title: '总费用',
      width: 100,
      dataIndex: 'totalFee',
      key: 'totalFee',
      align: 'center',
      render(v: any) {
        return (!isNaN(Number(v)) && Number(v).toFixed(2)) || ''
      }
    },
    {
      title: '会议名称',
      dataIndex: 'meetingInfo',
      key: 'meetingName',
      align: 'center',
      render(v) {
        return v?.base?.name
      }
    },
    {
      title: '申请人',
      width: 80,
      dataIndex: 'meetingInfo',
      key: 'ownerName',
      align: 'center',
      render(v) {
        return v?.base?.owner?.cnName
      }
    },
    {
      title: '会议开始时间',
      dataIndex: 'meetingInfo',
      key: 'startTime',
      align: 'center',
      width: 120,
      render(v) {
        return !v?.base?.startTime
          ? ''
          : dayjs(v.base?.startTime).format('YYYY-MM-DD')
      }
    },
    {
      title: '会议结束时间',
      dataIndex: 'meetingInfo',
      key: 'endTime',
      align: 'center',
      width: 120,
      render(v) {
        return !v?.base?.endTime
          ? ''
          : dayjs(v.base?.endTime).format('YYYY-MM-DD')
      }
    },
    {
      title: '订单ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 120
    },
    {
      title: '会议状态',
      width: 80,
      dataIndex: 'meetingInfo',
      key: 'meetingStatus',
      align: 'center',
      render(v) {
        return v?.status === StandardMeetingServiceBizStatus.MeetingInApproving
          ? v?.statusExtra || v?.statusText
          : v?.statusText
      }
    },
    {
      title: '公司名称',
      width: 120,
      dataIndex: 'meetingInfo',
      key: 'companyName',
      align: 'center',
      render(v) {
        return v?.base?.owner?.company?.cnName
      }
    },
    {
      title: '省区',
      width: 120,
      dataIndex: 'meetingInfo',
      key: 'region',
      align: 'center',
      render(v) {
        return v?.metingRegion?.name
      }
    },
    {
      title: '成本中心',
      width: 150,
      dataIndex: 'meetingInfo',
      key: 'costCenter',
      align: 'center',
      render(v) {
        return v?.costCenter?.name
      }
    },
    {
      title: '项目服务商',
      width: 150,
      dataIndex: 'meetingInfo',
      key: 'vendorName',
      align: 'center',
      render(v) {
        return v?.vendor?.cnName
      }
    },
    {
      title: '结算状态',
      width: 80,
      dataIndex: 'settlementRecord',
      key: 'attenderSettlementStatus',
      align: 'center',
      render(v: any) {
        return (v && v?.status && SETTLEMENT_STATUS_MAP[v?.status]) || ''
      }
    },
    {
      title: '结算时间',
      width: 120,
      dataIndex: 'settlementRecord',
      key: 'attenderSettlementTime',
      align: 'center',
      render(v: any) {
        if (!v?.settlementTime || v?.status !== 'succeed') {
          return ''
        }
        const time = Number(v.settlementTime)
        return (!isNaN(Number(time)) && dayjs(time).format('YYYY-MM-DD')) || ''
      }
    },
    {
      title: '会议类型',
      width: 100,
      dataIndex: 'meetingInfo',
      key: 'meetingType',
      align: 'center',
      render(v: any) {
        return v?.meetingType || ''
      }
    },
    {
      title: '开票状态',
      width: 120,
      dataIndex: 'billStatus',
      key: 'billStatus',
      align: 'center',
      render(v: string) {
        return ATTENDER_BILL_STATUS[v] || ''
      }
    },
    {
      title: '开票日期',
      width: 120,
      dataIndex: 'billTime',
      key: 'billTime',
      align: 'center',
      render(v: string) {
        return !v ? '' : dayjs(v).format('YYYY-MM-DD')
      }
    },
    {
      title: '活动类型',
      width: 100,
      dataIndex: 'meetingInfo',
      key: 'activityType',
      align: 'center',
      render(v: any) {
        return v?.activityType || ''
      }
    },
    {
      title: '打款凭证',
      width: 100,
      dataIndex: 'attenderData',
      fixed: props.isMobile ? undefined : 'right',
      align: 'center',
      render(_: any, attender: any) {
        return (
          <PreviewModalButton
            file={get(attender.attenderData, 'paymentVouchers')?.[0]}
            files={get(attender.attenderData, 'paymentVouchers')}
            type='payment-vouchers'
            attenderId={attender.attenderId}
            status={attender?.meetingInfo?.status}
            meetingId={attender?.meetingId}
            onUploaded={async (url) => {
              await uploadPaymentVoucher({
                urls: [url],
                meetingId: attender?.meetingId,
                attenderId: attender.attenderId
              })
              props.onRefreshed()
            }}
          >
            <PaymentDetail
              paymentId={(attender.attenderData as any)?.paymentId}
              channel={attender.channel as HadesPaymentChannel | undefined}
              type={HadesReceiptType.Vouchaer}
            />
          </PreviewModalButton>
        )
      }
    },
    {
      title: '完税证明',
      width: 100,
      dataIndex: 'attenderData',
      fixed: props.isMobile ? undefined : 'right',
      align: 'center',
      render(_: any, attender: any) {
        return (
          <PreviewModalButton
            file={get(attender.attenderData, 'taxReceipts')?.[0]}
            type='tax-receipts'
            attenderId={attender.attenderId}
            status={attender?.meetingInfo?.status}
            meetingId={attender?.meetingId}
            onUploaded={async (url) => {
              await uploadTaxReceipts({
                urls: [url],
                meetingId: attender?.meetingId,
                attenderId: attender.attenderId
              })
              props.onRefreshed()
            }}
          >
            <PaymentDetail
              paymentId={(attender.attenderData as any)?.paymentId}
              channel={attender.channel as HadesPaymentChannel | undefined}
              type={HadesReceiptType.Tax}
            />
          </PreviewModalButton>
        )
      }
    },
    {
      title: '操作',
      width: props.isMobile ? 60 : 150,
      dataIndex: 'meetingInfo',
      fixed: 'right',
      align: 'center',
      render(v: any, data: any) {
        return (
          <Space wrap={true}>
            {([
              StandardMeetingServiceBizStatus.MeetingInApproving,
              StandardMeetingServiceBizStatus.MeetingSettlementConfirming
            ].includes(v?.status) ||
              (v?.status ===
                StandardMeetingServiceBizStatus.MeetingInSettlement &&
                ['failed', 'delay'].includes(
                  data?.settlementRecord?.status
                ))) && (
              <Button
                type='link'
                style={{ padding: 4 }}
                onClick={() =>
                  props.onOperationOrder(
                    OrderOperationCategory.DeleteUser,
                    data
                  )
                }
              >
                删除
              </Button>
            )}
            {([
              StandardMeetingServiceBizStatus.MeetingInApproving,
              StandardMeetingServiceBizStatus.MeetingSettlementConfirming
            ].includes(v?.status) ||
              (v?.status ===
                StandardMeetingServiceBizStatus.MeetingInSettlement &&
                ['failed', 'delay'].includes(
                  data?.settlementRecord?.status
                ))) &&
              v?.speakers?.length > 1 && (
                <Button
                  type='link'
                  style={{ padding: 4 }}
                  onClick={() =>
                    props.onOperationOrder(
                      OrderOperationCategory.SplitUser,
                      data
                    )
                  }
                >
                  拆分
                </Button>
              )}
            {([
              StandardMeetingServiceBizStatus.MeetingInApproving,
              StandardMeetingServiceBizStatus.MeetingSettlementConfirming
            ].includes(v?.status) ||
              (v?.status ===
                StandardMeetingServiceBizStatus.MeetingInSettlement &&
                ['failed', 'delay'].includes(data?.settlementRecord?.status) &&
                v?.speakers?.length === 1)) && (
              <Button
                type='link'
                style={{ padding: 4 }}
                onClick={() =>
                  props.onOperationOrder(
                    OrderOperationCategory.CancelOrder,
                    data
                  )
                }
              >
                退回
              </Button>
            )}
          </Space>
        )
      }
    }
  ]
}

// 搜索字段
export type FieldTypes = {
  companyId?: string
  meetingType?: string | undefined
  activityType?: string[]
  regionId?: string
  meetingStatus?: string[]
  date?: [Dayjs | undefined, Dayjs | undefined]
  providerId?: string
  settlementStatus?: string
  keyword?: string
}

// 表格导出字段
export const excelHead = () => [
  {
    key: 'accountId',
    path: 'attenderData.accountId',
    name: '讲师ID'
  },
  {
    key: 'attenderName',
    path: 'attenderData.name',
    name: '讲师姓名'
  }, // 讲师姓名
  { key: 'hospital', path: 'attenderInfo.custom1', name: '单位' }, //单位
  {
    key: 'companyMobile',
    path: 'attenderData.mobile',
    name: '手机'
  }, //手机
  {
    key: 'attenderStatus',
    path: 'attenderStatus',
    name: '邀请状态'
  }, //签约状态
  { key: 'attenderFee', path: 'attenderFee', name: '费用' }, //费用
  { key: 'serviceFee', path: 'serviceFee', name: '服务费' }, // 服务费
  { key: 'totalFee', path: 'totalFee', name: '总费用' }, // 总费用
  { key: 'meetingId', path: 'meetingId', name: '会议ID' }, //会议ID
  { key: 'meetingName', path: 'meetingInfo.base.name', name: '会议名称' }, // 会议名称
  { key: 'ownerName', path: 'meetingInfo.base.owner.cnName', name: '申请人' }, //申请人
  {
    key: 'startTime',
    path: 'meetingInfo.base.startTime',
    name: '会议开始日期'
  }, //开始日期
  { key: 'endTime', path: 'meetingInfo.base.endTime', name: '会议结束日期' }, //结束日期
  { key: 'id', path: 'id', name: '订单ID' }, // 订单id
  { key: 'statusText', path: 'meetingInfo.statusText', name: '会议状态' }, //会议状态
  {
    key: 'companyName',
    path: 'meetingInfo.base.owner.company.cnName',
    name: '公司名称'
  },
  { key: 'regionName', path: 'meetingInfo.metingRegion.name', name: '省区' }, //省区
  {
    key: 'costCenter',
    path: 'meetingInfo.costCenter.name',
    name: '成本中心'
  }, //成本中心
  {
    key: 'attenderSettlementStatus',
    path: 'settlementRecord.status',
    name: '结算状态'
  },
  {
    key: 'attenderSettlementTime',
    path: 'settlementRecord.settlementTime',
    name: '结算时间'
  }, //结算时间
  { key: 'type', path: 'meetingInfo.meetingType', name: '会议类型' }, //会议类型
  { key: 'billStatus', path: 'billStatus', name: '开票状态' }, //开票状态
  { key: 'billTime', path: 'billTime', name: '开票日期' }, //开票日期
  { key: 'activityType', path: 'meetingInfo.activityType', name: '活动类型' }
  // { key: 'vendor', path: 'meetingInfo.vendor.cnName', name: '项目服务商' } // 项目服务商
]
