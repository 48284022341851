import { Button } from 'antd'
import React, { FC, useEffect } from 'react'
import {
  useArchiveSignedUrlQuery,
  useDisableArchiveMutation,
  useEnableArchiveMutation
} from '../../graphql'

export const DownloadButton: FC<{ archiveId: string }> = ({ archiveId }) => {
  const [{ data, fetching, error }, fetchUrl] = useArchiveSignedUrlQuery({
    variables: {
      archiveId
    },
    pause: true
  })
  useEffect(() => {
    if (data?.getArchiveSignedUrl) {
      const tagA = document.createElement('a')
      tagA.href = data.getArchiveSignedUrl
      tagA.setAttribute('target', '_blank')
      const id = 'dl-archive-' + new Date().getTime()
      tagA.setAttribute('id', id)
      if (!document.getElementById(id)) {
        document.body.appendChild(tagA)
      }

      tagA.click()
      document.body.removeChild(tagA)
    }
  }, [data?.getArchiveSignedUrl])
  return (
    <Button
      type='link'
      onClick={() => {
        fetchUrl()
      }}
    >
      下载
    </Button>
  )
}

export const ToggleStatusButton: FC<{
  archiveId: string
  isEnable?: boolean | null
  onChange?: () => void
}> = ({ archiveId, isEnable, onChange }) => {
  const [{ fetching: disableFetching }, disable] = useDisableArchiveMutation()
  const [{ fetching: enableFetching }, enable] = useEnableArchiveMutation()
  return (
    <Button
      type='link'
      danger={!!isEnable}
      loading={disableFetching || enableFetching}
      onClick={async () => {
        if (isEnable) {
          await disable({ archiveId })
        } else {
          await enable({ archiveId })
        }
        onChange?.()
      }}
    >
      {isEnable ? '禁用' : '启用'}
    </Button>
  )
}
