import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, message, Space } from 'antd'
import {
  useAdjustProcessBudgetMutation,
  useProcessBudgetDetailQuery,
  useProcessBudgetLogQuery,
  useSearchBudgetUsedDetailQuery
} from '../../graphql'
import BudgetDetailCard from './budgetDetailCard'
import ErrorView from '../../components/ErrorView'
import BudgetAddSubtractModal from './budgetAddSubtractModal'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import { BudgetFormItems, useDetailTableColumns, useTableColumns } from './data'
import './index.less'
import { BudgetType } from '../../constant/hrradiation'
import omit from 'lodash.omit'

const ProcessBudgetDetail: QCPage = () => {
  const { id } = useParams<{ id: string }>()
  const table = useSearchTable({}, { pageSize: 10, currentPage: 1 })
  const detailTable = useSearchTable({}, { pageSize: 10, currentPage: 1 })
  const [isShowAddSubtractModal, updateAddSubtractModalShow] = useState(false)

  const [{ data, error, fetching }, refetchDetail] =
    useProcessBudgetDetailQuery({
      variables: {
        id: Number(id)
      },
      pause: !id
    })

  const [
    { data: logData, error: logDataError, fetching: logDataFetching },
    refetchBudgetLog
  ] = useProcessBudgetLogQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        number: data?.result?.budgetNumber
      }
    },
    pause: !data?.result?.budgetNumber
  })

  const [
    { data: detailData, error: detailDataError, fetching: detailDataFetching }
  ] = useSearchBudgetUsedDetailQuery({
    variables: {
      param: {
        pager: omit(detailTable.pager, 'total'),
        query: {
          type: BudgetType.PROCESS,
          budgetNumber: data?.result?.budgetNumber
        }
      }
    },
    pause: !data?.result?.budgetNumber
  })

  const [, adjustProcessBudget] = useAdjustProcessBudgetMutation()

  const budgetAddSubtract = () => {
    updateAddSubtractModalShow(true)
  }

  const onCancelAddSubtract = () => {
    updateAddSubtractModalShow(false)
  }

  const onSubmitAddSubtract = async (
    values: BudgetFormItems,
    callBack?: () => void
  ) => {
    const result = await adjustProcessBudget({
      id: Number(data?.result?.id),
      changeValue: values?.amount!.toString(),
      description: values?.explain
    })
    if (result?.error) {
      throw new Error(result.error?.message)
    } else if (result?.data) {
      if (result.data?.result?.success) {
        message.success('流程预算增减成功')
        updateAddSubtractModalShow(false)
        refetchDetail()
        refetchBudgetLog()
      } else {
        message.error(result.data?.result?.message || '流程预算增减失败')
        callBack && callBack()
      }
    }
  }

  const tableColumns = useTableColumns('process')
  const detailTableColumns = useDetailTableColumns()

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <Space direction='vertical' className='processBudgetDetail'>
      <BudgetDetailCard
        fetching={fetching}
        data={data?.result}
        budgetType='process'
        budgetAddSubtract={budgetAddSubtract}
        bodyStyle={{ paddingBottom: 0 }}
        operationDisable={!data?.result?.id}
      />
      {data?.result?.budgetNumber && (
        <Card
          title='操作历史记录'
          bordered={false}
          headStyle={{ border: 'none' }}
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <SearchTable
            table={table}
            bordered
            loading={logDataFetching}
            showPanel={false}
            pager={logData?.result?.pager}
            dataSource={logData?.result?.items || []}
            columns={tableColumns}
            scroll={{ x: 1000 }}
            rowKey={'id'}
          />
        </Card>
      )}
      {data?.result?.budgetNumber && (
        <Card
          title='预算使用明细'
          bordered={false}
          headStyle={{ border: 'none' }}
          bodyStyle={{ paddingTop: 0 }}
        >
          <SearchTable
            table={detailTable}
            bordered
            loading={detailDataFetching}
            showPanel={false}
            pager={detailData?.result?.pager}
            dataSource={detailData?.result?.items || []}
            columns={detailTableColumns}
            scroll={{ x: true }}
          />
        </Card>
      )}
      {isShowAddSubtractModal && (
        <BudgetAddSubtractModal
          title='流程预算增减'
          budgetDetail={data?.result}
          budgetType='process'
          onCancel={onCancelAddSubtract}
          onSubmit={onSubmitAddSubtract}
        />
      )}
    </Space>
  )
}

export default ProcessBudgetDetail
