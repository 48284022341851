import React, { useState, FC } from 'react'
import {
  Card,
  Tag,
  Row,
  Col,
  Button,
  Skeleton,
  Input,
  message,
  Modal,
  Result,
  Image
} from 'antd'
import { useParams } from 'react-router-dom'
import {
  useAttenderTasksQuery,
  AttenderTasksQuery,
  useTaskDocumentReviewContentQuery,
  useRetreatAttenderTaskMutation
} from '../../graphql'
import ErrorView from '../../components/ErrorView'
import { DocumentCommentResult } from './commentResult'
import { DocumentComposeResult } from './composeResult'
import { renderComponent } from './widgetCollections'
import { useUserStore } from '../../hooks/user'
import './index.less'
import { CommentContentAuditRecord } from './commentContentAuditRecord'
import { CaseContentAuditRecord } from './caseContentAuditRecord'
import { ContentAuditRecord } from './contentAuditRecord'
import { OperationRecord } from './operationRecord'

const configJsonMatchValue = (item: any, valueJson: { [key: string]: any }) => {
  const { parentField, field, children } = item
  const newField = parentField ? [parentField, field].join('_') : field

  if (children) {
    return {
      ...item,
      children: children?.map((v: any) =>
        configJsonMatchValue({ ...v, parentField: newField }, valueJson)
      )
    }
  } else {
    return { ...item, defaultValue: valueJson?.[newField] }
  }
}

const DetailOptions: FC<{
  total: number | undefined
  currentPage: number
  showRetreat: boolean
  onPrevOrNext: (currentPage: number, step: number) => void
  onRejectClick: () => void
}> = ({ total, currentPage, showRetreat, onPrevOrNext, onRejectClick }) => {
  return (
    <>
      {showRetreat && (
        <Row key='return' align='middle' justify='center'>
          <Button
            type='primary'
            size='large'
            style={{ width: '80%', marginBottom: 20 }}
            onClick={onRejectClick}
          >
            任务退回
          </Button>
        </Row>
      )}
      <Row key='actions' align='middle' justify='space-around'>
        {[-1, 1].map((step) => (
          <Col key={step} flex='auto'>
            <Button
              shape='round'
              type='primary'
              disabled={
                !total ||
                (step === 1 && currentPage >= total) ||
                (step === -1 && currentPage === 1)
              }
              style={{ width: 100 }}
              onClick={() => onPrevOrNext(currentPage, step)}
            >
              {`${step === 1 ? '下' : '上'}一份`}
            </Button>
          </Col>
        ))}
        <Col flex='60px'>
          <div style={{ color: '#0091fa' }}>{`${
            total || 0
          }/${currentPage}`}</div>
        </Col>
      </Row>
    </>
  )
}

const DetailBody: FC<{ detail: AttenderTasksQuery['result'] }> = ({
  detail
}) => {
  const data = detail?.items?.[0]
  const isCompose =
    data?.meetingInfo?.meetingBaseData?.type === 'CATE_DOCUMENT_COMPOSE'
  const title = isCompose
    ? '活动'
    : data?.meetingInfo?.meetingType?.slice(0, 2) || ''
  return (
    <>
      <Row wrap={false} gutter={[0, 12]}>
        <Col flex='80px'>老师姓名：</Col>
        <Col flex='auto'>{data?.accountInfo?.cnName}</Col>
      </Row>
      <Row wrap={false} gutter={[0, 12]}>
        <Col flex='80px'>{`${title}名称：`}</Col>
        <Col flex='auto'>
          {data?.meetingInfo?.meetingBaseData?.type ===
          'CATE_DOCUMENT_COMMENT' ? (
            data?.documentInfo?.archive &&
            data.documentInfo.archive[0]?.signedUrl ? (
              <a target='__blank' href={data.documentInfo.archive[0].signedUrl}>
                {data.documentInfo?.document?.title}
              </a>
            ) : (
              <span> {data?.documentInfo?.document?.title}</span>
            )
          ) : (
            data?.templateInfo?.templateName
          )}
        </Col>
      </Row>
      <Row wrap={false} gutter={[0, 12]}>
        <Col flex='80px'>所属产品：</Col>
        <Col flex='auto'>
          {data?.meetingInfo?.meetingBaseData?.belongProduct?.productName}
        </Col>
      </Row>
      <Row wrap={false} gutter={[0, 12]}>
        <Col flex='80px'>{`${title}积分：`}</Col>
        <Col flex='auto'>
          {isCompose ? data?.attenderInfo?.attenderFee : data?.taskPrice || ''}
        </Col>
      </Row>
    </>
  )
}

const TaskDetail: QCPage = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [modalShow, setModalShow] = useState(false)
  const [isReject, setIsReject] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const { attenderId }: { attenderId: string } = useParams()
  const userStore = useUserStore()

  const [, retreatAttenderTask] = useRetreatAttenderTaskMutation()

  const [{ data, error, fetching }, refetchTask] = useAttenderTasksQuery({
    variables: {
      pager: {
        currentPage: currentPage,
        pageSize: 1
      },
      attenderId: [attenderId]
    }
  })

  const [{ data: reviewContent, fetching: reviewFetching }, refetchDocReview] =
    useTaskDocumentReviewContentQuery({
      variables: {
        taskId: data?.result?.items?.[0]?.id || ''
      },
      pause:
        !data?.result?.items?.[0]?.id ||
        data.result.items?.[0]?.meetingInfo?.meetingBaseData?.type !==
          'CATE_DOCUMENT_COMMENT'
    })

  const onPrevOrNext = (page: number, step: number) => {
    setCurrentPage(page + step)
  }

  const onReject = async (value: string) => {
    try {
      setIsReject(true)
      if (data?.result?.items?.[0]?.id) {
        const result = await retreatAttenderTask({
          reason: value,
          taskId: data.result.items[0].id
        })

        if (result.data?.result?.success) {
          setTimeout(() => {
            message.success('任务退回成功')
            setModalShow(false)
            setInputValue('')
            refetchTask()
            refetchDocReview()
          }, 300)
        } else {
          message.error(result.error?.message || '退回失败，请稍后重试')
        }
      }
    } catch (error) {
      message.error('退回失败，请稍后重试')
    } finally {
      setIsReject(false)
    }
  }

  if (fetching || reviewFetching) {
    return <Skeleton active />
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={() => {
          refetchTask()
          refetchDocReview()
        }}
        title={error.name}
        message={error.message}
      />
    )
  }

  const taskDetail = data?.result?.items?.[0]
  const configJson = JSON.parse(
    taskDetail?.templateInfo?.templateAttribute || '[]'
  )
  const valueJson = JSON.parse(taskDetail?.taskAttribute || '{}')
  const signImgUrl = valueJson?.signImgUrl

  return !data?.result?.items?.length ? (
    <Result subTitle='该老师无任务数据' />
  ) : (
    <>
      <Row style={{ height: '100%' }} gutter={12}>
        <Col flex='300px'>
          <Card
            title={`${
              taskDetail?.meetingInfo?.meetingBaseData?.type ===
              'CATE_DOCUMENT_COMPOSE'
                ? taskDetail?.meetingInfo?.meetingType
                : taskDetail?.meetingInfo?.meetingType?.slice(0, 2) || ''
            }详情`}
            bodyStyle={{
              flex: 1
            }}
            style={{ display: 'flex', flexDirection: 'column' }}
            extra={
              taskDetail?.statusText && (
                <Tag style={{ fontSize: 14 }} color='orange'>
                  {taskDetail?.statusText}
                </Tag>
              )
            }
            actions={[
              <DetailOptions
                key='options'
                total={data?.result?.pager.total}
                currentPage={currentPage}
                showRetreat={
                  taskDetail?.status === 2 &&
                  userStore.hasAuth('uplatform_attender_task_reject')
                }
                onPrevOrNext={onPrevOrNext}
                onRejectClick={() => setModalShow(true)}
              />
            ]}
          >
            <DetailBody detail={data?.result} />
          </Card>
          {taskDetail?.meetingInfo?.meetingBaseData?.type ===
            'CATE_DOCUMENT_COMMENT' && !!reviewContent?.result?.length ? (
            <CommentContentAuditRecord
              taskId={data?.result?.items?.[0]?.id}
              style={{ marginTop: 10 }}
            />
          ) : taskDetail?.meetingInfo?.meetingBaseData?.type ===
              'CATE_CASE_COLLECTION' && Object.keys(valueJson).length > 0 ? (
            <CaseContentAuditRecord
              taskId={data?.result?.items?.[0]?.id}
              style={{ marginTop: 10 }}
            />
          ) : (
            <ContentAuditRecord
              taskId={data?.result?.items?.[0]?.id}
              title={
                taskDetail?.meetingInfo?.meetingBaseData?.type ===
                'CATE_DOCUMENT_COMMENT'
                  ? '点评内容验证'
                  : taskDetail?.meetingInfo?.meetingBaseData?.type ===
                    'CATE_CASE_COLLECTION'
                  ? '病例内容验证'
                  : `${taskDetail?.meetingInfo?.meetingType}验证`
              }
              style={{ marginTop: 10 }}
            />
          )}
          <OperationRecord
            taskId={data?.result?.items?.[0]?.id}
            style={{ marginTop: 10 }}
          />
        </Col>

        <Col flex='calc(100% - 300px)'>
          <Card>
            {taskDetail?.meetingInfo?.meetingBaseData?.type ===
            'CATE_DOCUMENT_COMMENT' ? (
              <DocumentCommentResult
                reviewContent={reviewContent?.result}
                properties={taskDetail?.documentInfo?.properties}
              />
            ) : taskDetail?.meetingInfo?.meetingBaseData?.type ===
              'CATE_DOCUMENT_COMPOSE' ? (
              <DocumentComposeResult
                documentComposeInfo={
                  taskDetail?.meetingInfo?.documentComposeInfo
                }
                taskAttribute={valueJson}
                meetingBaseInfo={taskDetail?.meetingInfo?.meetingBaseData}
                attenderAccountId={taskDetail?.accountId}
                taskId={taskDetail?.id}
              />
            ) : (
              <>
                {configJson
                  ?.map((item: any) => configJsonMatchValue(item, valueJson))
                  .map((conf: any) =>
                    renderComponent(conf, {
                      taskId: taskDetail?.id
                    })
                  )}
                {signImgUrl && (
                  <Card
                    title='老师手写签名'
                    bordered={false}
                    headStyle={{
                      height: 40,
                      paddingLeft: 8,
                      marginTop: '-20px'
                    }}
                    bodyStyle={{ paddingTop: 16 }}
                  >
                    <Image
                      alt='签名'
                      preview={false}
                      src={signImgUrl}
                      style={{ background: 'rgb(245, 246, 250)' }}
                    />
                  </Card>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
      {modalShow ? (
        <Modal
          maskClosable={false}
          title='任务退回理由'
          visible
          onCancel={() => {
            setModalShow(false)
            setInputValue('')
          }}
          centered
          closable={false}
          destroyOnClose
          width={450}
          onOk={() => {
            if (!inputValue) {
              message.warn('退回理由不能为空')
              return
            }
            onReject(inputValue)
          }}
          okText={'确定'}
          confirmLoading={isReject}
        >
          <Input.TextArea
            value={inputValue}
            placeholder='请输入任务退回理由'
            autoSize={{ minRows: 4, maxRows: 6 }}
            onChange={(e: any) => {
              setInputValue(e?.target?.value)
            }}
            style={{ marginBottom: '10px' }}
          />
        </Modal>
      ) : null}
    </>
  )
}

TaskDetail.pageTitle = '任务详情'

export default TaskDetail
