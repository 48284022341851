import React, { FC, useState } from 'react'
import { Modal, Form, Select } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import './index.less'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

const ApprovalConfirmModal: FC<{
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
}> = ({ onCancel, onSubmit }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = (values: any) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  const onConfirmSubmit = () => {
    form.submit()
  }

  return (
    <Modal
      maskClosable={false}
      title='审核通过'
      className='orderBillApprovalModal'
      visible
      onCancel={onCancel}
      centered
      destroyOnClose
      onOk={onConfirmSubmit}
      okText={'确定'}
      confirmLoading={loading}
      closeIcon={
        <CloseCircleOutlined style={{ fontSize: 20, color: '#ccc' }} />
      }
    >
      <Form form={form} colon={false} {...layout} onFinish={onFinish}>
        <Form.Item
          name='ticket'
          label='小票一致性说明'
          rules={[{ required: true, message: '请选择小票一致性说明' }]}
          initialValue='确认小票一致'
        >
          <Select placeholder='请选择'>
            <Select.Option value='确认小票一致'>确认小票一致</Select.Option>
            <Select.Option value='确认属于同一商户'>
              确认属于同一商户
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='invoice'
          label='发票一致性说明'
          rules={[{ required: true, message: '请选择发票一致性说明' }]}
          initialValue='确认发票一致'
        >
          <Select placeholder='请选择'>
            <Select.Option value='确认发票一致'>确认发票一致</Select.Option>
            <Select.Option value='确认商宴通发票有误已修改'>
              确认商宴通发票有误已修改
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ApprovalConfirmModal
