import { Button, Input, Table } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { omit } from 'lodash'
import React, { useState } from 'react'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import {
  useArchiveListQuery,
  ArchiveListQuery,
  ArchiveListQueryVariables
} from '../../graphql'
import { DownloadButton, ToggleStatusButton } from './actionBtns'
import SaveForm from './saveForm'

const ArchiveList: QCPage = () => {
  const table = useSearchTable<NonNullable<ArchiveListQueryVariables['query']>>(
    { keyword: '', needDisabled: true },
    { pageSize: 15, currentPage: 1 }
  )

  const [{ data, fetching, error }, refetch] = useArchiveListQuery({
    requestPolicy: 'network-only',
    variables: {
      pager: omit(table.pager, 'total'),
      query: table.searchParams
    }
  })

  const [isVisible, setVisible] = useState<boolean>(false)

  const columns: ColumnsType<
    ArchiveListQuery['queryArchiveList']['items'][0]
  > = [
    {
      title: '名称',
      dataIndex: 'title',
      align: 'center',
      width: 240
    },
    {
      title: '类型',
      dataIndex: 'type',
      align: 'center'
    },
    {
      title: '添加者',
      dataIndex: 'creator',
      align: 'center',
      render: (creator) => creator?.cnName
    },
    {
      title: '产品线',
      dataIndex: 'products',
      align: 'center',
      render: (_, a) => a.products?.map((p) => p.tag).join(',')
    },
    {
      title: '简介',
      dataIndex: 'describe',
      align: 'center',
      width: 300
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      render: (_, v) => (
        <ButtonGroup>
          <DownloadButton archiveId={v.id!} />
          <ToggleStatusButton
            archiveId={v.id!}
            isEnable={v.isEnable}
            onChange={refetch}
          />
        </ButtonGroup>
      )
    }
  ]

  return (
    <div>
      <Button type='primary' onClick={() => setVisible(true)}>
        上传
      </Button>

      <SearchTable
        table={table}
        searchProps={{
          fields: [
            {
              name: 'keyword',
              label: null,
              span: 12,
              render() {
                return (
                  <Input
                    allowClear
                    placeholder='请输入资料名称、添加者名字搜索'
                  />
                )
              }
            }
          ]
        }}
        bordered
        loading={fetching}
        pager={data?.queryArchiveList.pager}
        dataSource={data?.queryArchiveList.items || []}
        size='middle'
        rowKey='id'
        columns={columns}
      />
      <Modal
        visible={isVisible}
        footer={null}
        destroyOnClose
        closable
        onCancel={() => setVisible(false)}
      >
        <SaveForm
          onSaved={() => {
            setVisible(false)
            refetch()
          }}
        />
      </Modal>
    </div>
  )
}

ArchiveList.pageTitle = '学术资料'

export default ArchiveList
