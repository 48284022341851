import React, { FC, useCallback, useState } from 'react'
import { Alert, Form, Input, Modal, Select } from 'antd'
import {
  useVerifyDoctorBankInfoMutation,
  useVerifyDoctorCardInfoMutation
} from '../graphql'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

type RealNameAuthModalProps = {
  visible?: boolean
  handleOkCallBack?: () => void
  handleCancelCallBack?: () => void
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const authTypeOptions = [
  {
    label: '三要素验证（姓名+身份证号+手机号）',
    value: 1
  },
  {
    label: '四要素验证（姓名+身份证号+手机号+银行卡号）',
    value: 2
  }
]

const RealNameAuthModal: FC<RealNameAuthModalProps> = ({
  visible = false,
  handleOkCallBack,
  handleCancelCallBack
}) => {
  const [resultMsg, setResultMsg] = useState('')
  const [errorDescription, setErrorDescription] = useState('')
  const [form] = Form.useForm()

  const [{ fetching: verifyCardInfoFetching }, verifyCardInfo] =
    useVerifyDoctorCardInfoMutation()

  const [{ fetching: verifyBankInfoFetching }, verifyBankInfo] =
    useVerifyDoctorBankInfoMutation()

  // 三要素验证接口
  const validateCardInfo = useCallback(
    async (values) => {
      const { data, error } = await verifyCardInfo({
        name: values?.name,
        idCardNo: values?.idCardNo,
        mobile: values?.mobile
      })
      if (data && data?.result && data.result.pass) {
        setResultMsg(`三要素验证（姓名+身份证号+手机号）认证成功`)
        setErrorDescription('')
      } else {
        setResultMsg(`三要素验证（姓名+身份证号+手机号）认证失败`)
        setErrorDescription(
          '错误信息：' +
            (data?.result?.resultMsg ||
              error?.graphQLErrors?.[0]?.message ||
              '')
        )
      }
    },
    [useVerifyDoctorCardInfoMutation]
  )

  // 四要素验证接口
  const validateBankInfo = useCallback(
    async (values) => {
      const { data, error } = await verifyBankInfo({
        name: values?.name,
        idCardNo: values?.idCardNo,
        mobile: values?.mobile,
        bankCardNo: values?.bankCardNo
      })
      if (data) {
        setResultMsg(`四要素验证（姓名+身份证号+手机号+银行卡号）认证成功`)
        setErrorDescription('')
      } else {
        setResultMsg(`四要素验证（姓名+身份证号+手机号+银行卡号）认证失败`)
        setErrorDescription(
          '错误信息：' + error?.graphQLErrors?.[0]?.message || ''
        )
      }
    },
    [useVerifyDoctorBankInfoMutation]
  )

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = () => {
    setResultMsg('')
    setErrorDescription('')
    form.resetFields()
    handleCancelCallBack && handleCancelCallBack()
  }

  const onValuesChange = () => {
    setResultMsg('')
    setErrorDescription('')
  }

  const onFinish = (values: any) => {
    console.log(values)
    if (values?.authType === 1) {
      validateCardInfo(values)
    } else {
      validateBankInfo(values)
    }
  }

  return (
    <Modal
      title='实名认证工具'
      visible={visible}
      okText='验证'
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={verifyCardInfoFetching || verifyBankInfoFetching}
    >
      {resultMsg && (
        <Alert
          message={
            <span style={errorDescription ? {} : { marginBottom: 0 }}>
              {errorDescription ? (
                <CloseCircleOutlined style={{ color: 'red' }} />
              ) : (
                <CheckCircleOutlined style={{ color: 'green' }} />
              )}
              <span
                style={{
                  color: errorDescription ? 'red' : '#faad14',
                  marginLeft: 5
                }}
              >
                {resultMsg}
              </span>
            </span>
          }
          description={
            <span style={{ color: '#faad14' }}>{errorDescription}</span>
          }
          type='warning'
          style={{ textAlign: 'center', marginBottom: 20 }}
        />
      )}
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name='authType'
          label='认证类型'
          rules={[
            {
              required: true,
              message: '请选择认证类型'
            }
          ]}
        >
          <Select
            options={authTypeOptions}
            placeholder='请选择认证类型'
            allowClear
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.authType !== curValues.authType
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('authType') ? (
              <>
                <Form.Item
                  name='name'
                  label='姓名'
                  rules={[
                    {
                      required: true,
                      message: '请输入姓名'
                    }
                  ]}
                >
                  <Input placeholder='请输入姓名' allowClear />
                </Form.Item>
                <Form.Item
                  name='idCardNo'
                  label='身份证号'
                  rules={[
                    {
                      required: true,
                      message: '请输入身份证号'
                    }
                  ]}
                >
                  <Input placeholder='请输入身份证号' allowClear />
                </Form.Item>
                <Form.Item
                  name='mobile'
                  label='手机号'
                  rules={[
                    {
                      required: getFieldValue('authType') === 1,
                      message: '请输入手机号'
                    }
                  ]}
                >
                  <Input placeholder='请输入手机号' allowClear />
                </Form.Item>
                {getFieldValue('authType') === 2 && (
                  <Form.Item
                    name='bankCardNo'
                    label='银行卡号'
                    rules={[
                      {
                        required: true,
                        message: '请输入银行卡号'
                      }
                    ]}
                  >
                    <Input placeholder='请输入银行卡号' allowClear />
                  </Form.Item>
                )}
              </>
            ) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RealNameAuthModal
