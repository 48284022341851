import React from 'react'
import { Button, Input, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import dayjs, { Dayjs } from 'dayjs'

import DatePicker from '../../components/CustomDatePicker'
import { cloneDeep, map, get } from 'lodash'
import {
  ConsumptionOrderStatusSelector,
  ConsumptionTypesSelector,
  HSConsumptionOrderBudgetChannelSelector
} from '../../components/SelectorCollection'
import {
  getSalesBudgetChannelText,
  getSalesOrderStatus
} from '../../constant/sales'
import { SalesOrderStatus } from '../../graphql'

//搜索字段声明
export const searchFields = () => [
  {
    name: 'budgetChannel',
    label: '预算渠道',
    span: 8,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <HSConsumptionOrderBudgetChannelSelector />
    }
  },
  {
    name: 'formCategory',
    label: '消费类型',
    span: 8,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <ConsumptionTypesSelector />
    }
  },
  {
    name: 'status',
    label: '消费订单状态',
    span: 8,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <ConsumptionOrderStatusSelector />
    }
  },
  {
    name: 'submitTime',
    label: '提交时间',
    span: 8,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return (
        <DatePicker.RangePicker
          locale={locale}
          format='YYYY-MM-DD'
          style={{ width: '100%' }}
        />
      )
    }
  },
  {
    name: 'keyword',
    label: '搜索',
    span: 8,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return (
        <Input
          allowClear
          placeholder='请输入姓名、手机号、消费订单ID/名称进行搜索'
        />
      )
    }
  }
]

export const useTableColumns = (props: {
  terminateAlipayRule: (orderId: string) => void
  voucherReject: (orderId: string) => void
  isMobile?: boolean
}): any => [
  {
    title: '消费订单ID',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    align: 'center',
    width: props.isMobile ? 100 : 200,
    render(v: any) {
      return <Link to={`/consumptionOrderBillDetail/${v}`}>{v}</Link>
    }
  },
  {
    title: '消费订单名称',
    dataIndex: 'targetName',
    key: 'targetName',
    align: 'center',
    width: props.isMobile ? 120 : 200,
    render(v: any) {
      return v || '消费订单_未选消费场所'
    }
  },
  {
    title: '申请人',
    dataIndex: 'accountName',
    key: 'accountName',
    align: 'center',
    width: props.isMobile ? 70 : 100
  },
  {
    title: '申请人手机',
    dataIndex: 'accountMobile',
    key: 'accountMobile',
    align: 'center',
    width: props.isMobile ? 100 : 150
  },
  {
    title: '预算渠道',
    dataIndex: 'budgetChannel',
    key: 'budgetChannel',
    align: 'center',
    width: props.isMobile ? 80 : 150,
    render(v: any) {
      return getSalesBudgetChannelText(v)
    }
  },
  {
    title: '预计',
    dataIndex: 'consumptionAmount',
    key: 'consumptionAmount',
    align: 'center',
    width: props.isMobile ? 50 : 100
  },
  {
    title: '实际',
    dataIndex: 'actualConsumptionAmount',
    key: 'actualConsumptionAmount',
    align: 'center',
    width: props.isMobile ? 50 : 100
  },
  {
    title: '提交时间',
    dataIndex: 'submitTime',
    key: 'submitTime',
    align: 'center',
    width: props.isMobile ? 80 : 150,
    render(v: any) {
      return v ? dayjs(v).format('YYYY-MM-DD') : '-'
    }
  },
  {
    title: '消费时间',
    dataIndex: 'targetOccurTime',
    key: 'targetOccurTime',
    align: 'center',
    width: props.isMobile ? 80 : 150,
    render(v: any) {
      return v ? dayjs(v).format('YYYY-MM-DD') : '-'
    }
  },
  {
    title: '订单状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    fixed: props.isMobile ? '' : 'right',
    width: props.isMobile ? 80 : 120,
    render(v: any) {
      const status = getSalesOrderStatus(v)
      return <span style={{ color: status.color }}>{status.text}</span>
    }
  },
  {
    title: '操作',
    dataIndex: 'status',
    align: 'center',
    fixed: 'right',
    width: props.isMobile ? 80 : 120,
    render(v: any, record: any) {
      if (
        [SalesOrderStatus.PrePaid, SalesOrderStatus.AuditCompleted].includes(v)
      ) {
        const isSameMonth =
          dayjs(record.submitTime).format('YYYY-MM') ===
          dayjs().format('YYYY-MM')
        return (
          <>
            {SalesOrderStatus.PrePaid === v && (
              <Popconfirm
                placement='topRight'
                title='确定作废支付规则吗？作废成功后，用户下次操作时，将会生成新的支付规则'
                onConfirm={() => props.terminateAlipayRule(record.id)}
                okText='确认'
                cancelText='取消'
              >
                <Button
                  type='link'
                  className={props.isMobile ? 'mobileBtn' : ''}
                >
                  支付规则作废
                </Button>
              </Popconfirm>
            )}
            {SalesOrderStatus.AuditCompleted === v &&
              (record.budgetChannel === 2 ||
                (record.budgetChannel === 3 && isSameMonth)) && (
                <Popconfirm
                  placement='topRight'
                  title='点击“确认”按钮后，消费单状态将变为“票据审核退回”'
                  onConfirm={() => props.voucherReject(record.id)}
                  okText='确认'
                  cancelText='取消'
                >
                  <Button
                    type='link'
                    className={props.isMobile ? 'mobileBtn' : ''}
                  >
                    票据退回
                  </Button>
                </Popconfirm>
              )}
          </>
        )
      }
      return '-'
    }
  }
]

export type FieldTypes = {
  keyword?: string
  formCategory?: string[] | undefined
  status?: number[] | undefined
  submitTime?: [Dayjs | undefined, Dayjs | undefined]
  budgetChannel?: number | undefined
}

// 表格导出字段
export const excelHead = () => [
  {
    key: 'id',
    path: 'id',
    name: '消费订单ID'
  },
  {
    key: 'targetName',
    path: 'targetName',
    name: '消费订单名称'
  },
  {
    key: 'accountName',
    path: 'accountName',
    name: '申请人'
  },
  {
    key: 'accountMobile',
    path: 'accountMobile',
    name: '申请人手机'
  },
  {
    key: 'budgetChannel',
    path: 'budgetChannel',
    name: '预算渠道'
  },
  { key: 'consumptionAmount', path: 'consumptionAmount', name: '预计' },
  {
    key: 'actualConsumptionAmount',
    path: 'actualConsumptionAmount',
    name: '实际'
  },
  { key: 'submitTime', path: 'submitTime', name: '提交时间' },
  { key: 'targetOccurTime', path: 'targetOccurTime', name: '消费时间' },
  {
    key: 'status',
    path: 'status',
    name: '订单状态'
  }
]

// 整理出表格数据
export const getData = (data: any, head: any) => {
  const result = data ? cloneDeep(data) : []
  return map(result, (item) => {
    const obj: any = {}

    head.forEach((cell: any) => {
      const value = get(item, cell.path, '-')

      switch (cell.key) {
        case 'targetName':
          obj[cell.name] = value || '消费订单_未选消费场所'
          break
        case 'budgetChannel':
          obj[cell.name] = getSalesBudgetChannelText(value)
          break
        case 'status':
          obj[cell.name] = getSalesOrderStatus(value).text
          break
        default:
          obj[cell.name] = value
          break
      }
    })

    return obj
  })
}
