import React, { FC } from 'react'
import { Button, Result } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { ResultProps } from 'antd/lib/result'

type ErrorViewProps = {
  code?: ResultProps['status']
  title?: string
  message?: string
  onRefresh?: ButtonProps['onClick']
}

const ErrorView: FC<ErrorViewProps> = ({
  code = '500',
  onRefresh,
  title,
  message
}) => {
  return (
    <Result
      status={code}
      title={title}
      subTitle={message}
      extra={
        <Button type='primary' onClick={onRefresh}>
          刷新
        </Button>
      }
    />
  )
}

export default ErrorView
