import React, { useState } from 'react'
import { Button, Input, message, Card, Result } from 'antd'
// import UploadButton from '../../components/UploadButton'
import ResolveExcel from '../../components/ResolveExcel'
import { useBatchUpload } from '../../components/UploadBatch'
import { useBatchMarkBillStatusMutation } from '../../graphql'
import _ from 'lodash'
import { maps, checkValidate, template } from './constant'
import { generateExport } from '../../components/UploadBatch'

export type fileProps = {
  name?: string
  list?: Array<any>
}
const MarkInvoice: QCPage = () => {
  const [fileName, setFileName] = useState<string>('')
  const [fileList, setFileList] = useState<object[]>([])

  // 批量上传的大小
  const options = { concurrency: 2, chunkSize: 500 }

  const [, markBillStatus] = useBatchMarkBillStatusMutation()

  // 表格中数据上传
  const [bulkEdit] = useBatchUpload({
    task: async (item) => {
      const result = await markBillStatus({
        data: item
      })
      // 抛出异常
      if (result?.error?.message) {
        throw new Error(result?.error?.message || '上传失败')
      } else if (result.data?.result) {
        let msg = result.data?.result
        if (msg?.indexOf(';') > -1) {
          msg = JSON.stringify(msg.split(';'))
        }
        throw new Error(msg || '上传失败')
      }
    },
    options: options
  })

  // 文件读取之后进行数据校验和处理
  const uploadFile = (file: fileProps) => {
    let lists = file.list

    const validateRes = checkValidate(lists || [])
    if (validateRes.success) {
      // 替换表头
      lists = lists?.map((fileItem) => {
        return _.mapKeys(fileItem, (value, key) => {
          return maps[key]
        })
      })

      lists = lists?.map((list) => {
        return _.mapValues(list, (v, k) => {
          // 会议ID，老师ID 转字符串
          if (k === 'meetingId' || k === 'attenderId') {
            return String(v)
          }
          // 时间转 ISO 格式
          if (k === 'billTime') {
            // 如果单元格是Date类型
            if (v instanceof Date) {
              return v?.toISOString()
            } else if (typeof v === 'string') {
              // 单元格是文本类型
              return new Date(v).toISOString()
            }
          }
        })
      })

      setFileName(file.name || '')
      setFileList(lists || [])
    } else {
      message.error(validateRes.message)
    }
  }
  return (
    <>
      {/* 展示文件名 */}
      <Input
        disabled
        placeholder={fileName || '上传excel，标记开票'}
        style={{ width: '200px', backgroundColor: '#fff' }}
      />

      {/* 选择文件按钮 */}
      <ResolveExcel onUploaded={uploadFile} />

      {/* 开票标记按钮 */}
      <Button
        disabled={!fileName}
        style={{ margin: '0 40px' }}
        onClick={() => bulkEdit(fileList)}
        type='primary'
        title={`${fileName || '请选择文件'}`}
      >
        标记开票
      </Button>

      <span
        style={{ marginLeft: '20px', color: '#40a9ff', cursor: 'pointer' }}
        onClick={async () => {
          generateExport(template, `标记开票状态模版`, null, (err) => {
            if (err) {
              message.error('模版下载失败')
            } else {
              message.success('模版下载成功')
            }
          })
        }}
      >
        下载模版
      </span>

      <Card
        bordered
        style={{
          marginTop: '20px'
        }}
      >
        <h3>必填字段</h3>
        <ol>
          {Object.keys(template[0]).map((name, index) => {
            return <li key={index}>{name}</li>
          })}
        </ol>
      </Card>
    </>
  )
}

MarkInvoice.pageTitle = '标记开票'

export default MarkInvoice
