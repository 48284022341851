/* eslint-disable @typescript-eslint/no-empty-function */
import React, { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import { useLocalStore } from 'mobx-react'
import { CurrentUserQuery } from '../graphql'
import { COMPANY_FINANCE, SUPERADMIN, COMPANY_ADMIN } from '../constant/user'

export interface UserProfile {
  accountId?: string | null
  companyId?: string | null
  name?: string | null
}

export interface UserContext {
  profile: CurrentUserQuery['user'] | null
  isLoggedIn: boolean
  token: string | null
  login: (token: string, profile?: CurrentUserQuery['user']) => void
  logout: () => void
  setUser: (profile: CurrentUserQuery['user']) => void
  // isCompanyFinance: boolean
  // isCompanyAdmin: boolean
  // isAdmin: boolean
  setToken: (token: string) => void
  hasAuth(authId: string): boolean
  channels: Array<{ [key: string]: string }>
  setChannels: (channels: Array<{ [key: string]: string }>) => void
}

const userContext = createContext<MaybeNull<UserContext>>(null)

export const createUserStore = (): UserContext => {
  const storedToken = localStorage.getItem('bms-login-token')
  return {
    profile: null,
    channels: [],
    isLoggedIn: !!storedToken,
    token: storedToken,
    setChannels(channels): void {
      this.channels = channels
    },
    setToken(token): void {
      localStorage.setItem('bms-login-token', token)
    },
    setUser(profile): void {
      this.profile = profile
      // profile?.roles.forEach((role) => {
      //   if (role.type === COMPANY_FINANCE) {
      //     this.isCompanyFinance = true
      //   } else if (role.type === COMPANY_ADMIN) {
      //     this.isCompanyAdmin = true
      //   } else if (role.type === SUPERADMIN) {
      //     this.isAdmin = true
      //   }
      // })
    },
    login(token, profile): void {
      if (!profile) return
      this.token = token
      localStorage.setItem('bms-login-token', token)
      this.setUser(profile)
      this.isLoggedIn = true
    },
    logout(): void {
      localStorage.removeItem('bms-login-token')
      this.isLoggedIn = false
      // this.isCompanyFinance = false
      // this.isCompanyAdmin = false
      // this.isAdmin = false
    },
    hasAuth(authId) {
      return !!this.profile?.roleAuthorities?.find(
        (auth) => auth.appId === authId
      )
    }
    // isCompanyAdmin: false,
    // isCompanyFinance: false,
    // isAdmin: false
  }
}

export const StoreProvider = ({
  children
}: PropsWithChildren<{}>): JSX.Element => {
  const store = useLocalStore(createUserStore)
  return <userContext.Provider value={store}>{children}</userContext.Provider>
}

export const useUserStore = (): UserContext => {
  const store = useContext(userContext)
  if (!store) {
    throw new Error('useUserStore must be used within a StoreProvider.')
  }
  return store
}
