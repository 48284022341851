import React from 'react'
import { Tabs } from 'antd'
import ComponyActivityType from './activityType'
import ComponyMeetingType from './meetingType'

import { useQueryParams } from '../../hooks/common'
import CompanyTemplateType from './templateType'
import CompanySphereType from './sphereType'

const { TabPane } = Tabs

const CompanyCategoriesManage: QCPage = () => {
  const params = useQueryParams()
  console.log(params)
  return (
    <main>
      <Tabs defaultActiveKey={params.get('tab') || '1'}>
        <TabPane tab='企业会议类型管理' key='1'>
          <ComponyMeetingType />
        </TabPane>

        <TabPane tab='企业活动类型管理' key='2'>
          <ComponyActivityType />
        </TabPane>
        <TabPane tab='模板类型管理' key='3'>
          <CompanyTemplateType />
        </TabPane>
        <TabPane tab='领域类型管理' key='4'>
          <CompanySphereType />
        </TabPane>
      </Tabs>
    </main>
  )
}

CompanyCategoriesManage.pageTitle = '企业会议/活动类型管理'

export default CompanyCategoriesManage
