import React, { FC, useState } from 'react'
import { Modal, Input, InputNumber, Form } from 'antd'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 }
}

const DeductionAmountModal: FC<{
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
  salesOrder?: any | null
}> = ({ onCancel, onSubmit, salesOrder }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = (values: any) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  const checkValidate = (rule: any, value: any) => {
    if (salesOrder?.claimAmount - value <= 0) {
      return Promise.reject('【报销金额】-【核减报销金额】不能小于等于0')
    }
    return Promise.resolve()
  }

  return (
    <Modal
      maskClosable={false}
      title='核减报销金额'
      visible
      onCancel={onCancel}
      centered
      closable={false}
      destroyOnClose
      onOk={() => {
        form.submit()
      }}
      okText={'确定'}
      confirmLoading={loading}
    >
      <Form preserve={false} form={form} {...layout} onFinish={onFinish}>
        <Form.Item label='报销金额'>{salesOrder?.claimAmount}</Form.Item>
        <Form.Item
          label='核减报销金额'
          name='financialDeductionAmount'
          initialValue={salesOrder?.financialDeductionAmount || 0}
          rules={[
            { required: true, message: '请填写核减报销金额' },
            { validator: checkValidate }
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            max={salesOrder?.claimAmount}
          />
        </Form.Item>
        <Form.Item
          name='reason'
          label='核减原因'
          rules={[{ required: true, message: '请填写核减原因' }]}
        >
          <Input.TextArea
            maxLength={300}
            placeholder='请填写核减原因'
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DeductionAmountModal
