import React, { FC } from 'react'
import { Card, Row, Skeleton, Space } from 'antd'
import { useSalesOrderOperationLogQuery } from '../graphql'
import { format } from '../lib/timeFormat'
import { isMobileClient } from '../constant/user'

const statusColor: Record<string, string> = {
  BOOKING_CONFIRM: '#EE7700',
  PRE_PAID: '#EE7700',
  PRE_VOUCHER_UPLOAD: '#EE7700',
  VOUCHER_UPLOAD_COMPLETED: '#EE7700',
  PRE_VOUCHER_AUDIT: '#EE7700',
  FORM_REVIEWING: '#EE7700',
  FORM_CANCEL: '#999999',
  BOOKING_SUCCESS: '#51A04C',
  AUDIT_COMPLETED: '#51A04C',
  REVIEWED_COMPLETED: '#51A04C',
  AUDIT_FAILED: '#F76060'
}

const OperationLog: FC<{
  businessId?: string | null | undefined
  logs?: any
}> = ({ businessId, logs }) => {
  const isMobile = isMobileClient()
  const [{ data, fetching }] = useSalesOrderOperationLogQuery({
    variables: {
      pager: {
        pageSize: 999,
        currentPage: 1
      },
      query: {
        businessId
      }
    },
    pause: !businessId || !logs?.length
  })

  if (fetching) {
    return <Skeleton active />
  }

  return (
    <Card
      loading={fetching}
      title='日志信息'
      className='dashedBorderHead'
      headStyle={{ color: '#181818' }}
    >
      {(data?.result?.items || logs)?.length
        ? (data?.result?.items || logs)
            ?.sort((a: any, b: any) => a.createTime - b.createTime)
            ?.map((log: any, index: number) => {
              return (
                <Space
                  key={index}
                  direction='vertical'
                  size={28}
                  style={{
                    padding: '0 0 10px 30px',
                    borderLeft: '1px solid #f0f0f0',
                    width: '100%'
                  }}
                >
                  <Row
                    justify='space-between'
                    align='middle'
                    style={{
                      position: 'relative',
                      backgroundColor: '#f5f6fa',
                      padding: '8px 16px',
                      width: isMobile ? '100%' : '70%',
                      flexWrap: 'nowrap'
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span
                          style={{
                            flex: 1,
                            paddingRight: 10,
                            wordBreak: 'break-all'
                          }}
                        >
                          {log.log}
                        </span>
                        <span
                          style={{
                            color: '#888888',
                            width: isMobile ? '90px' : 'auto',
                            textAlign: 'right'
                          }}
                        >
                          {format(Number(log.createTime))}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        top: 'calc(50% - 6px)',
                        left: '-36px',
                        width: '11px',
                        height: '11px',
                        borderRadius: '50%',
                        backgroundColor: '#CCC'
                      }}
                    />
                  </Row>
                </Space>
              )
            })
        : '暂无日志信息'}
    </Card>
  )
}

export default OperationLog
