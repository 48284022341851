import React, { FC, useState, useEffect } from 'react'
import {
  Modal,
  Form,
  message,
  Input,
  Spin,
  Table,
  Select,
  Button,
  Row,
  Image,
  Col,
  Tag
} from 'antd'
import { Rule } from 'antd/lib/form'
import omit from 'lodash.omit'

import {
  useBankCardInfoQuery,
  BankCardInfo,
  useFetchCnapsCodeQuery,
  useCheckDoctorIdCardImagesMutation,
  AccountStatus
} from '../../graphql'
import {
  HRCompanySelector,
  HRDepartmentSelector,
  HRDoctorTitleSelector
} from '../../components/SelectorCollection'
import { useUserStore } from '../../hooks/user'
import UploadImageModal from './uploadImageModal'
import { useWindowSize } from 'react-use'
import { isVideoUrl } from '../../constant/user'
import VideoButton from '../../components/VideoButton'

const reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/).*/

const DoctorInfoModal: FC<{
  isLoading: boolean
  visible: boolean
  onCancel: () => void
  onSubmit: (values: any) => Promise<void>
  isEdit: boolean
  initialInfo: any
}> = ({
  isLoading = false,
  visible = false,
  onCancel,
  onSubmit,
  isEdit = false,
  initialInfo = null
}) => {
  const [bankNum, setBankNum] = useState('')
  const [bankInfo, setBankInfo] = useState({} as BankCardInfo)
  const [codeModalShow, setCodeModalShow] = useState(false)
  const [bankBranchName, setBankBranchName] = useState('')
  const [getBankNameDate, updateBankNameDate] = useState('')
  const [bankCodeSelected, setBankCodeSelected] = useState('' as React.Key)
  const [bankCodeValue, setBankCodeValue] = useState() as any
  const [curCompanyId, setCurCompanyId] = useState('')
  const [loading, setLoading] = useState(true)
  const [uploadImageModalVisible, setUploadImageModalVisible] = useState(false)
  const [uploadImageModalTitle, setUploadImageModalTitle] = useState('')
  const [uploadImageModalType, setUploadImageModalType] = useState(0)
  const [doctorIdCardImages, setDoctorIdCardImages] = useState<string[]>(
    initialInfo?.idCardInfo?.certImages || []
  )
  const [doctorCertInfo, setDoctorCertInfo] = useState<{
    certImages: string[]
    cnName: string
  }>(initialInfo?.userCertificationImages || {})
  const [doctorReviewImages, setDoctorReviewImages] = useState<string[]>(
    initialInfo?.userComplexMaterialCertImages?.certImages || []
  )
  const [doctorTitleCertImages, setDoctorTitleCertImages] = useState<string[]>(
    initialInfo?.userTitleCertificationImages?.certImages || []
  )
  const verifyResult = initialInfo?.verifyResult
  const [doctorVerifyResult, setDoctorVerifyResult] =
    useState<string>(verifyResult)
  const verifyImages = initialInfo?.verifyImages
    ?.split(',')
    ?.filter((x: string) => {
      return reg.test(x)
    })
  const [doctorVerifyResultImages, setDoctorVerifyResultImages] = useState<
    string[]
  >(verifyImages || [])
  const [, CheckDoctorIdCardImages] = useCheckDoctorIdCardImagesMutation()
  const { height } = useWindowSize()

  const [form] = Form.useForm()
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
  }

  // 查询银行卡信息
  const [
    { data, error: bankInfoError, fetching: bankInfoFetching },
    refetchBankInfo
  ] = useBankCardInfoQuery({
    variables: {
      bankCardNo: bankNum
    },
    pause: true
  })

  // 查询连行号
  const [
    { data: bankCodeInfo, error: bankCodeError, fetching: bankCodeFetching }
  ] = useFetchCnapsCodeQuery({
    variables: {
      bankBranchName: bankBranchName,
      bankName: !getBankNameDate
        ? initialInfo?.bankInfo?.qkkBankName || ''
        : bankInfo?.bankName || ''
    },
    pause: !codeModalShow
  })

  // 查询开户行
  const getBankName = async () => {
    const values = await form.getFieldsValue()
    if (!values['cardNo']) {
      message.warn({
        content: '请先填写银行卡号',
        style: { marginTop: '20vh' }
      })
    } else {
      updateBankNameDate(JSON.stringify(new Date()))
      refetchBankInfo()
    }
  }

  // 点击连行号回填，打开连行号选择弹框
  const getCNAPSCode = async () => {
    const values = await form.getFieldsValue()
    if (!values['bankBranchName'] || !values['bankName']) {
      message.warn({
        content: '请先填写开户行、开户网点',
        style: { marginTop: '20vh' }
      })
    } else {
      setCodeModalShow(true)
    }
  }

  // 老师信息提交前校验
  const checkValidate = (rule: Rule, value: any) => {
    if (value) {
      return Promise.resolve()
    } else {
      return Promise.reject('不能为空')
    }
  }
  const checkDepartmentValidate = (rule: Rule, value: any) => {
    const element = document.getElementsByClassName(
      'select_department_value_blank_'
    )
    if (!value || element.length) {
      return Promise.reject('不能为空')
    } else {
      return Promise.resolve()
    }
  }
  const checkTitleValidate = (rule: Rule, value: any) => {
    const element = document.getElementsByClassName('select_title_value_blank_')
    if (!value || element.length) {
      return Promise.reject('不能为空')
    } else {
      return Promise.resolve()
    }
  }

  // 监听用户银行卡号的输入
  const onBankNumListen = (e: any) => {
    setBankNum(e.target.value)
  }

  // 监听开户网点输入
  const onBranchBankListen = (e: any) => {
    setBankBranchName(e.target.value)
  }

  const onBankCodeListen = (e: any) => {
    if (!e.target.value) {
      setBankCodeValue(null)
    } else {
      setBankCodeValue(e.target.value)
    }
  }

  // 自动填充表单里的银行名字段
  useEffect(() => {
    setBankInfo(data?.result || {})
    // Input 的 value 或 defaultValue 设置不了表单域的值，只能用 setFieldsValue 更新
    form.setFieldsValue({ bankName: data?.result?.bankName || '' })
  }, [`${JSON.stringify(data?.result)}_${getBankNameDate}`])

  // 一些手动初始化
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
    setCurCompanyId(initialInfo?.companyId)
    form.setFieldsValue({ bankName: initialInfo?.bankInfo?.qkkBankName || '' })
    form.setFieldsValue({
      bankCode: initialInfo?.bankInfo?.cnFirstPinyin || ''
    })
    setBankBranchName(initialInfo?.bankInfo?.ssqBankName)
    setBankNum(initialInfo?.bankInfo?.rawCertNo)
  }, [])

  // 点击提交
  const onOkClick = async () => {
    try {
      let values = await form.validateFields()

      if (!values['bankCode']) {
        values = omit(values, 'bankCode')
      }
      if (!values['bankName']) {
        values = omit(values, 'bankName')
      }

      if (initialInfo?.accountId) {
        values.accountId = initialInfo.accountId
      }

      onSubmit &&
        onSubmit({
          ...values,
          idCardImages: doctorIdCardImages,
          qualificationCertTypeName: doctorCertInfo.cnName,
          qualificationCertImages: doctorCertInfo.certImages,
          complexMaterialCertImages: doctorReviewImages,
          titleCertImages: doctorTitleCertImages,
          ...(isEdit
            ? {
                verifyImages:
                  doctorVerifyResultImages?.length > 0
                    ? doctorVerifyResultImages.join(',')
                    : initialInfo?.verifyImages,
                verifyResult: doctorVerifyResult
              }
            : {})
        })
    } catch (err) {
      console.log(err)
    }
  }

  const onTitleChange = (v: string) => {
    form.setFieldsValue({ titleId: v })
  }
  const onDepartmentChange = (v: string) => {
    form.setFieldsValue({ departmentId: v })
  }
  const onCompanyChange = (v: string) => {
    setCurCompanyId(v)
    form.setFieldsValue({ departmentId: '' })
    form.setFieldsValue({ titleId: '' })
  }
  // 联行号单选事件
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setBankCodeSelected(selectedRowKeys[0])
    }
  }

  // 联行号modal确认事件
  const onCodeModalConfirm = () => {
    if (!bankCodeInfo?.result?.length && !bankCodeSelected) {
      setCodeModalShow(false)
      return
    }
    if (!bankCodeSelected) {
      message.warn({
        content: '请选择一个联行号',
        style: { marginTop: '20vh' }
      })
      return
    }
    setCodeModalShow(false)
    setBankCodeValue(bankCodeSelected)
    form.setFieldsValue({ bankCode: bankCodeSelected })
  }

  const onUploadIdCardImageClick = () => {
    setUploadImageModalVisible(true)
    setUploadImageModalType(1)
    setUploadImageModalTitle('上传身份证照片')
  }

  const onUploadPracticeImageClick = () => {
    setUploadImageModalVisible(true)
    setUploadImageModalType(2)
    setUploadImageModalTitle('上传执业证件')
  }

  const onUploadReviewImageClick = () => {
    setUploadImageModalVisible(true)
    setUploadImageModalType(3)
    setUploadImageModalTitle('上传复核材料')
  }

  const onUploadTitleCertImageClick = () => {
    setUploadImageModalVisible(true)
    setUploadImageModalType(4)
    setUploadImageModalTitle('上传职称证')
  }

  const onUploadVerifyResultImageClick = () => {
    setUploadImageModalVisible(true)
    setUploadImageModalType(5)
    setUploadImageModalTitle('编辑审核结果材料')
  }

  const onUploadCancle = () => {
    setUploadImageModalVisible(false)
  }

  const onUploadSubmit = async (values: any, callBack?: () => void) => {
    console.log(values)
    if (uploadImageModalType === 1) {
      if (
        !doctorIdCardImages ||
        doctorIdCardImages?.length <= 0 ||
        doctorIdCardImages?.[0] !== values.idImages1 ||
        doctorIdCardImages?.[1] !== values.idImages2
      ) {
        const name = form.getFieldValue('name')
        const channel = form.getFieldValue('channel')
        if (!name || !channel) {
          callBack && callBack()
          return
        }
        const newDoctorIdCardImages = [values.idImages1, values.idImages2]
        const { data, error } = await CheckDoctorIdCardImages({
          name,
          doctorIdCardImages: newDoctorIdCardImages,
          channel
        })
        if (data?.result) {
          form.setFieldsValue({
            certNo: data?.result
          })
          setDoctorIdCardImages(newDoctorIdCardImages)
          onUploadCancle()
        } else {
          message.error(
            '上传身份证照片失败：' + error?.graphQLErrors[0].message
          )
        }
      } else {
        onUploadCancle()
      }
    } else if (uploadImageModalType === 2) {
      const certificateType = values.certificateType
      setDoctorCertInfo({
        cnName: certificateType,
        certImages: !['工作证', '卫健委资格证书'].includes(certificateType)
          ? [values[`${certificateType}0`], values[`${certificateType}1`]]
          : [values[`${certificateType}`]]
      })
      onUploadCancle()
    } else if (uploadImageModalType === 3) {
      setDoctorReviewImages(values.reviewImages)
      onUploadCancle()
    } else if (uploadImageModalType === 4) {
      setDoctorTitleCertImages([values.titleCertImages])
      onUploadCancle()
    } else if (uploadImageModalType === 5) {
      setDoctorVerifyResultImages(values.verifyResultImages)
      setDoctorVerifyResult(values.verifyResult === 0 ? '否' : '是')
      onUploadCancle()
    }
    callBack && callBack()
  }

  return (
    <>
      <Modal
        confirmLoading={isLoading}
        forceRender
        maskClosable={false}
        title={`${isEdit ? '编辑' : '添加'}老师信息`}
        visible={visible}
        onCancel={onCancel}
        centered
        width={650}
        onOk={onOkClick}
        bodyStyle={{ height: height - 200, overflow: 'auto' }}
      >
        <Spin spinning={isLoading || (loading && isEdit)}>
          <Form
            preserve={false}
            {...layout}
            form={form}
            initialValues={
              initialInfo
                ? {
                    // 初始化表单值
                    accountId: initialInfo?.accountId,
                    name: initialInfo?.cnName,
                    mobile: initialInfo?.companyMobile,
                    hospital: initialInfo?.hospital,
                    cardNo: initialInfo?.bankInfo?.rawCertNo,
                    companyId: initialInfo?.companyId,
                    departmentId: initialInfo?.department?.groupId,
                    titleId: initialInfo?.groupInfo?.groupId,
                    // bankName: initialInfo?.bankInfo?.qkkBankName,
                    bankBranchName: initialInfo?.bankInfo?.ssqBankName,
                    // bankCode: '',
                    certNo: initialInfo?.idCardInfo?.rawCertNo, // 生份证号
                    channel: initialInfo?.defaultChannel
                  }
                : {}
            }
          >
            <Form.Item
              label='老师姓名'
              name='name'
              rules={[
                { required: true, message: '老师姓名不能为空' }
                // {
                //   pattern:
                //     /^[\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5]{2,5}([\u25CF\u00B7][\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5]{2,5})*$/,
                //   message: '请填写正确的中文姓名'
                // }
              ]}
            >
              <Input
                maxLength={50}
                style={{ width: '100%' }}
                placeholder='请填写老师姓名'
              />
            </Form.Item>
            <Form.Item
              label='签约渠道'
              name='channel'
              rules={[{ required: true, message: '签约渠道不能为空' }]}
            >
              <Select
                showSearch
                allowClear
                placeholder='请选择签约渠道'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {useUserStore().channels?.map((k) => {
                  return (
                    <Select.Option value={k.value} key={k.value}>
                      {k.text}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label='所属企业'
              name='companyId'
              rules={[{ required: true, message: '所属企业不能为空' }]}
            >
              <HRCompanySelector isFromDoctorPage onChange={onCompanyChange} />
            </Form.Item>

            <Form.Item
              label='老师手机号'
              name='mobile'
              rules={[
                { required: true, message: '老师手机号不能为空' },
                { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号' }
              ]}
            >
              <Input
                maxLength={11}
                style={{ width: '100%' }}
                placeholder='请填写老师手机号'
              />
            </Form.Item>

            <Form.Item
              label='单位'
              name='hospital'
              rules={[{ required: true, message: '单位不能为空' }]}
            >
              <Input
                maxLength={50}
                style={{ width: '100%' }}
                placeholder='请填写单位'
              />
            </Form.Item>

            <Form.Item
              label='部门'
              name='departmentId'
              rules={[{ required: true, message: '部门不能为空' }]}
            >
              <HRDepartmentSelector
                disabled={!curCompanyId && !initialInfo?.companyId}
                auto={false}
                companyId={curCompanyId || initialInfo?.companyId}
                onChange={onDepartmentChange}
              />
            </Form.Item>

            <Form.Item
              label='职称'
              name='titleId'
              rules={[{ required: true, message: '职称不能为空' }]}
            >
              <HRDoctorTitleSelector
                disabled={!curCompanyId && !initialInfo?.companyId}
                auto={false}
                companyId={curCompanyId || initialInfo?.companyId}
                onChange={onTitleChange}
              />
            </Form.Item>

            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.name !== curValues.name ||
                prevValues.channel !== curValues.channel
              }
              noStyle
            >
              {({ getFieldValue }) => {
                const nameValue = getFieldValue('name')
                const channelValue = getFieldValue('channel')
                return (
                  <Form.Item
                    label='身份证照片'
                    tooltip='上传身份证照片需填写老师姓名和选择签约渠道'
                  >
                    {doctorIdCardImages.length > 0 ? (
                      <Row align='middle' justify='end'>
                        <Button type='link' onClick={onUploadIdCardImageClick}>
                          编辑
                        </Button>

                        {/* <Button
                          type='link'
                          onClick={() => setDoctorIdCardImages([])}
                        >
                          删除
                        </Button> */}
                      </Row>
                    ) : (
                      <Button
                        type='link'
                        onClick={onUploadIdCardImageClick}
                        disabled={!nameValue || !channelValue}
                      >
                        点击上传身份证照片
                      </Button>
                    )}
                    <Row align='middle' justify='space-between'>
                      {doctorIdCardImages.map((img: string, index: number) => {
                        return (
                          <Image
                            key={index}
                            src={img}
                            width={150}
                            height={150}
                          />
                        )
                      })}
                    </Row>
                  </Form.Item>
                )
              }}
            </Form.Item>
            <Form.Item
              label='老师身份证号'
              name='certNo'
              rules={[
                {
                  pattern:
                    /^[1-9]\d{5}((19|20)?)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                  message: '请填写正确的身份证号'
                }
              ]}
            >
              <Input
                maxLength={18}
                style={{ width: '100%' }}
                placeholder='请填写老师身份证号'
              />
            </Form.Item>
            <Form.Item label='银行卡号' name='cardNo'>
              <Input
                maxLength={19}
                onChange={onBankNumListen}
                style={{ width: '100%' }}
                placeholder='请填写银行卡号'
              />
            </Form.Item>

            <Form.Item label='开户行' name='bankName'>
              <Spin spinning={bankInfoFetching}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '100%', position: 'relative' }}>
                    <Input
                      value={
                        !getBankNameDate
                          ? initialInfo?.bankInfo?.qkkBankName || ''
                          : bankInfo?.bankName
                      }
                    />
                    {/* 遮罩层,使银行名不能手动填写 */}
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '32px'
                      }}
                    />
                  </div>

                  <a
                    onClick={getBankName}
                    style={{ marginLeft: '5px', flexShrink: 0, fontSize: 13 }}
                  >
                    点击自动回填
                  </a>
                </div>
              </Spin>
            </Form.Item>

            <Form.Item label='开户网点' name='bankBranchName'>
              <Input
                style={{ width: '100%' }}
                onChange={onBranchBankListen}
                placeholder='请填写开户网点'
              />
            </Form.Item>

            <Form.Item label='联行号' name='bankCode'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  value={
                    bankCodeValue === null
                      ? ''
                      : bankCodeValue ||
                        initialInfo?.bankInfo?.cnFirstPinyin ||
                        ''
                  }
                  onChange={onBankCodeListen}
                />
                <a
                  onClick={getCNAPSCode}
                  style={{ marginLeft: '5px', flexShrink: 0, fontSize: 13 }}
                >
                  点击自动回填
                </a>
              </div>
            </Form.Item>
            <Form.Item label='职称证'>
              {doctorTitleCertImages?.length > 0 ? (
                <Row align='middle' justify='end'>
                  <Button type='link' onClick={onUploadTitleCertImageClick}>
                    编辑
                  </Button>
                </Row>
              ) : (
                <Button type='link' onClick={onUploadTitleCertImageClick}>
                  点击上传职称证
                </Button>
              )}
              <Row align='middle' justify='space-between'>
                {doctorTitleCertImages?.map((img: string, index: number) => {
                  return (
                    <Image key={index} src={img} width={150} height={150} />
                  )
                })}
              </Row>
            </Form.Item>
            <Form.Item label='执业证件'>
              {doctorCertInfo.certImages?.length > 0 ? (
                <Row align='middle' justify='space-between'>
                  <span>{doctorCertInfo.cnName}</span>
                  <Button type='link' onClick={onUploadPracticeImageClick}>
                    编辑
                  </Button>
                </Row>
              ) : (
                <Button type='link' onClick={onUploadPracticeImageClick}>
                  点击上传执业证件
                </Button>
              )}
              <Row align='middle' justify='space-between'>
                {doctorCertInfo.certImages?.map(
                  (img: string, index: number) => {
                    return (
                      <Image key={index} src={img} width={150} height={150} />
                    )
                  }
                )}
              </Row>
            </Form.Item>
            <Form.Item
              label='复核材料'
              tooltip='支持图片和视频上传，文件格式.gif,.jpg,.jpeg,.png,.mp4,.rmvb,.avi,.ts,.mov，最大上传文件数量6'
            >
              {doctorReviewImages.length > 0 ? (
                <Row align='middle' justify='end'>
                  <Button type='link' onClick={onUploadReviewImageClick}>
                    编辑
                  </Button>
                </Row>
              ) : (
                <Button type='link' onClick={onUploadReviewImageClick}>
                  点击上传复核材料
                </Button>
              )}
              <Row align='middle' justify='space-between'>
                {doctorReviewImages.map((img: string, index: number) => {
                  return isVideoUrl(img) ? (
                    <VideoButton
                      key={index}
                      url={img}
                      style={{
                        width: 150,
                        height: 150,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    />
                  ) : (
                    <Image key={index} src={img} width={150} height={150} />
                  )
                })}
              </Row>
            </Form.Item>
            {isEdit && doctorVerifyResult && (
              <Form.Item label='全程审核结果材料'>
                {doctorVerifyResultImages.length > 0 ? (
                  <Row align='middle' justify='end'>
                    <Button
                      type='link'
                      onClick={onUploadVerifyResultImageClick}
                    >
                      编辑
                    </Button>
                  </Row>
                ) : (
                  <Button type='link' onClick={onUploadVerifyResultImageClick}>
                    点击上传全程审核结果材料
                  </Button>
                )}
                <Row gutter={16}>
                  <Image.PreviewGroup>
                    {doctorVerifyResultImages.map(
                      (pic: string, index: number) => {
                        return (
                          <Col key={index} className='gutter-row'>
                            <Image
                              width={150}
                              height={150}
                              key={index}
                              src={pic}
                            />
                          </Col>
                        )
                      }
                    )}
                  </Image.PreviewGroup>
                </Row>
                {doctorVerifyResult === '是' ? (
                  <Tag color='success'>审核通过</Tag>
                ) : (
                  <Tag color='error'>审核不通过</Tag>
                )}
              </Form.Item>
            )}
            {isEdit &&
              initialInfo?.status === AccountStatus.AccountDisabled && (
                <Form.Item label='禁用理由'>
                  {initialInfo?.disableReason || '-'}
                </Form.Item>
              )}
          </Form>
        </Spin>
      </Modal>

      {/* 联行号查询结果 */}
      <Modal
        maskClosable={false}
        title='联行号列表'
        visible={codeModalShow}
        onCancel={() => setCodeModalShow(false)}
        centered
        closable={false}
        destroyOnClose
        width={600}
        onOk={onCodeModalConfirm}
        okText={'确定'}
      >
        <Spin spinning={bankCodeFetching} tip='连行号查询中，请耐心等待...'>
          {bankCodeFetching ? null : bankCodeInfo?.result?.length ? (
            <Table
              rowKey='bankCode'
              rowSelection={{
                type: 'radio',
                ...rowSelection
              }}
              columns={[
                {
                  title: '联行号',
                  dataIndex: 'bankCode',
                  align: 'center'
                },
                {
                  title: '支行名称',
                  dataIndex: 'bankName',
                  align: 'center'
                }
              ]}
              dataSource={bankCodeInfo?.result || []}
            />
          ) : (
            <span>没有查询到符合条件的联行号，请手动填写</span>
          )}
        </Spin>
      </Modal>
      <UploadImageModal
        visible={uploadImageModalVisible}
        title={uploadImageModalTitle}
        type={uploadImageModalType}
        onCancel={onUploadCancle}
        onSubmit={onUploadSubmit}
        accountId={initialInfo?.accountId}
        idCardImages={doctorIdCardImages}
        certificationImages={doctorCertInfo}
        reviewImages={doctorReviewImages}
        titleCertImages={doctorTitleCertImages}
        verifyResultImages={doctorVerifyResultImages}
        verifyResult={doctorVerifyResult}
      />
    </>
  )
}

export default DoctorInfoModal
