import React, { useState } from 'react'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import { HRRegionSelector } from '../../components/SelectorCollection'
import { Rule } from 'antd/lib/form'
import {
  Button,
  Modal,
  Form,
  Select,
  InputNumber,
  DatePicker,
  message,
  Spin
} from 'antd'
import { tableColumns, formateDate } from './data'
import moment from 'moment'
import { useBalanceListQuery, useBalanceRecordMutation } from '../../graphql'
import ErrorView from '../../components/ErrorView'
import { useUserStore } from '../../hooks/user'

type FieldTypes = {
  regionIds?: string[] | null
}

const BalanceManage: QCPage = () => {
  const userStore = useUserStore()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const queryVariable = {
    ...table.searchParams
  }
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
  }
  //搜索字段声明
  const searchFields = [
    {
      name: 'regionIds',
      label: '省区',
      span: 8,
      render() {
        return <HRRegionSelector />
      }
    }
  ]

  const [, balanceRecord] = useBalanceRecordMutation()

  // 余额列表数据
  const [{ data, error, fetching }, refetch] = useBalanceListQuery({
    variables: {
      ...queryVariable
    }
  })

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  // 提交登记金额
  const onOkHandle = async () => {
    try {
      const values = await form.validateFields()
      const { moneyType, cityId, time, count } = values
      setIsLoading(true)

      const result = await balanceRecord({
        paymentTime: formateDate(time),
        type: moneyType,
        amount: parseFloat(count),
        regionId: cityId
      })

      if (!result.data?.result) {
        setIsLoading(false)

        message.error('登记失败')
      } else {
        setIsLoading(false)
        setVisible(false)
        message.success('登记成功')
        refetch()
      }
    } catch (err) {
      if (err?.errorFields?.length) {
        console.log(err)
        setIsLoading(false)
      } else {
        console.log(err)
        setIsLoading(false)
        message.success('登记失败')
      }
    }
  }

  const checkValidate = (rule: Rule, value: any) => {
    if (value) {
      return Promise.resolve()
    } else {
      return Promise.reject('不能为空')
    }
  }

  return (
    <main>
      <SearchTable
        table={table}
        searchProps={
          userStore.hasAuth('uplatform_balance_pool_view_all')
            ? { fields: searchFields }
            : undefined
        }
        bordered
        loading={fetching}
        showPagination={false}
        dataSource={data?.lists || []}
        size='middle'
        rowKey='regionId'
        columns={tableColumns}
        showOperaBtn={false}
        extraPanelContent={
          <>
            {userStore.hasAuth('uplatform_balance_pool_addnew') ? (
              <Button
                type='primary'
                style={{ position: 'absolute', right: '5%', bottom: '15px' }}
                onClick={() => setVisible(true)}
              >
                登记预付金额
              </Button>
            ) : null}
          </>
        }
      />
      <Modal
        maskClosable={false}
        title={'登记预付金额'}
        visible={visible}
        onCancel={() => setVisible(false)}
        centered
        width={500}
        onOk={onOkHandle}
        okText={'登记'}
        afterClose={() => form.resetFields()}
      >
        <Spin spinning={isLoading} tip='金额登记中，请耐心等待...'>
          <Form
            {...layout}
            form={form}
            initialValues={{
              time: moment(new Date(), 'YYYY-MM-DD'),
              moneyType: '1'
            }}
          >
            <Form.Item label='省区'>
              <Form.Item
                name='cityId'
                rules={[{ validator: checkValidate }]}
                noStyle
              >
                <HRRegionSelector />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label='预付方向'
              name='moneyType'
              rules={[{ validator: checkValidate }]}
            >
              <Select placeholder='请选择预付方向'>
                <Select.Option value='1'>登记</Select.Option>
                <Select.Option value='2'>退回</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label='预付时间'
              name='time'
              rules={[{ validator: checkValidate }]}
            >
              <DatePicker showTime />
            </Form.Item>
            <Form.Item
              label='金额'
              name='count'
              rules={[{ validator: checkValidate }]}
            >
              <InputNumber
                precision={2}
                style={{ width: '100%' }}
                placeholder='请填写金额'
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </main>
  )
}

BalanceManage.pageTitle = '余额管理'

export default BalanceManage
