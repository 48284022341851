import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Input, Select, Space, Tooltip } from 'antd'
import dayjs from 'dayjs'

import {
  LiteratureAuthSelector,
  DomainSelector
} from '../../components/SelectorCollection'
import './index.less'
import { CompanyUpProductsQuery, UpadminCompaniesQuery } from '../../graphql'

export const searchFields = (
  selectOptions?: UpadminCompaniesQuery,
  productOptions?: CompanyUpProductsQuery,
  isQC?: boolean,
  onCompanyChange?: any,
  curCompanyId?: string,
  userCompanyId?: string
): Array<any> => [
  ...(isQC
    ? [
        {
          name: 'companyId',
          label: '企业',
          span: 6,
          render() {
            return (
              <Select
                showSearch
                placeholder='请选择企业'
                optionFilterProp='children'
                key={selectOptions?.companies[0]?.id || ''}
                onChange={onCompanyChange}
              >
                {selectOptions?.companies.map((company, i) => (
                  <Select.Option
                    value={company?.id || ''}
                    key={company?.id || i}
                  >
                    {company.cnName || ''}
                  </Select.Option>
                ))}
              </Select>
            )
          }
        }
      ]
    : []),
  {
    name: 'productCodes',
    label: '产品类型',
    span: 6,
    render() {
      return (
        <Select
          showSearch
          placeholder='请选择产品'
          optionFilterProp='children'
          key={productOptions?.companyUpProducts?.[0]?.id || ''}
          allowClear
        >
          {productOptions?.companyUpProducts?.map((company, i) => (
            <Select.Option
              value={company?.productCode || ''}
              key={company?.productCode || i}
            >
              {company.productName || ''}
            </Select.Option>
          ))}
        </Select>
      )
    }
  },
  {
    name: 'documentCategoryIds',
    label: '文献类型',
    span: 6,
    render() {
      return (
        <LiteratureAuthSelector
          placeholder='请选择文献类型'
          companyId={isQC ? curCompanyId : userCompanyId}
          auto={true}
        />
      )
    }
  },
  {
    name: 'sphere',
    label: '领域',
    span: 6,
    render() {
      return (
        <DomainSelector
          mode='multiple'
          companyId={isQC ? curCompanyId : userCompanyId}
          placeholder='请选择领域'
          auto={true}
        />
      )
    }
  },
  {
    name: 'keywords',
    label: '搜索',
    colon: false,
    span: 10,
    render() {
      return <Input allowClear placeholder='请输入文献名称搜索' />
    }
  }
]

export const useTableColumns = (
  hasDeleteAuth: boolean,
  onDelete: (record: any) => void,
  onEdit: (record: any) => void,
  isQC?: boolean
): any => {
  return [
    {
      title: '文献ID',
      dataIndex: 'document',
      align: 'center',
      key: 'id',
      fixed: 'left',
      width: 80,
      render(v: { id: any }) {
        return v?.id
      }
    },
    {
      title: '文献名称',
      dataIndex: 'document',
      align: 'center',
      key: 'title',
      // width: 300,
      render(v: { title: any }) {
        return v?.title
      }
    },
    {
      title: '中英文',
      dataIndex: 'document',
      align: 'center',
      key: 'language',
      width: 80,
      render(v: { language: any }) {
        return v?.language
      }
    },
    ...(isQC
      ? [
          {
            title: '公司',
            dataIndex: 'document',
            align: 'center',
            key: 'companyId',
            width: 80,
            render(v: { company: any }) {
              return v?.company?.cnName
            }
          }
        ]
      : []),
    {
      title: '产品类型',
      dataIndex: 'document',
      align: 'center',
      key: 'product',
      width: 80,
      render(v: { productInfo: any }) {
        return v?.productInfo?.productName
      }
    },
    {
      title: '通讯作者',
      align: 'center',
      width: 100,
      dataIndex: 'document',
      key: 'author',
      render(v: { author: any }) {
        return v?.author
      }
    },
    {
      title: '文献来源',
      width: 120,
      align: 'center',
      dataIndex: 'document',
      key: 'source',
      render(v: { source: any }) {
        return v?.source
      }
    },
    {
      title: '文献发布日期',
      width: 120,
      align: 'center',
      dataIndex: 'document',
      key: 'releaseDate',
      render(v: any) {
        const value = v?.releaseTimestamp
        return (
          value &&
          !isNaN(Number(value)) &&
          dayjs(Number(value)).format('YYYY-MM-DD')
        )
      }
    },
    {
      title: '文献类型',
      width: 80,
      align: 'center',
      dataIndex: 'document',
      key: 'category',
      render(v: any) {
        return v?.category?.title
      }
    },
    {
      title: '领域',
      width: 80,
      align: 'center',
      dataIndex: 'document',
      key: 'documentSphere',
      render(v: any) {
        return v?.documentSphere?.sphereName || '-'
      }
    },
    {
      title: '正文',
      width: 80,
      ellipsis: true,
      align: 'center',
      dataIndex: 'archive',
      key: 'url',
      render(v: any) {
        return <Link to={`/literaturePreview/${v[0]?.id}`}>查看</Link>
      }
    },
    {
      title: '简介',
      align: 'center',
      width: 180,
      dataIndex: 'document',
      key: 'describe',
      ellipsis: {
        showTitle: false
      },
      render(v: any) {
        return !v?.describe ? (
          '-'
        ) : (
          <Tooltip
            placement='topLeft'
            title={v?.describe}
            overlayClassName='documentOverlayStyle'
          >
            {v?.describe}
          </Tooltip>
        )
      }
    },
    {
      title: '上传时间',
      width: 120,
      align: 'center',
      dataIndex: 'document',
      key: 'createdTimestamp',
      render(v: { createdTimestamp: any }) {
        const value = v?.createdTimestamp
        return (
          value &&
          !isNaN(Number(value)) &&
          dayjs(Number(value)).format('YYYY-MM-DD')
        )
      }
    },
    {
      title: '状态',
      width: 80,
      align: 'center',
      dataIndex: 'document',
      key: 'enabled',
      render(v: { enabled: boolean }) {
        return v?.enabled ? '启用' : '禁用'
      }
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      fixed: 'right',
      width: hasDeleteAuth ? 120 : 80,
      key: 'action',
      render(text: any, record: any) {
        return (
          <Space>
            <Button
              type='link'
              style={{ padding: 0 }}
              onClick={() => onEdit(record)}
            >
              编辑
            </Button>
            {hasDeleteAuth && (
              <Button
                type='link'
                danger
                style={{ padding: 0 }}
                onClick={() => onDelete(record)}
              >
                删除
              </Button>
            )}
          </Space>
        )
      }
    }
  ]
}
