import React, { FC, useState, useEffect } from 'react'
import omit from 'lodash.omit'
import {
  searchFields,
  useTableColumns,
  FieldTypes,
  excelHead,
  getData
} from './data'
import {
  SalesOrderPageQuery,
  SalesOrderPageQueryVariables,
  SalesOrderPageDocument,
  useSalesOrderPageQuery,
  useTerminateAlipayRuleMutation,
  useSalesOrderVoucherRejectMutation
} from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { useClient } from 'urql'
import ExportQueueModal from '../../components/ExportQueueModal'
import { message } from 'antd'
import { isMobileClient } from '../../constant/user'
import './index.less'
import { getMessage } from '../../lib/getMessage'

const ConsumptionOrderManage: QCPage = () => {
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const isMobile = isMobileClient()
  const urqlClient = useClient()
  const [isExporting, setExporting] = useState(false)

  const queryVariable = {
    ...omit(table.searchParams, ['submitTime', 'budgetChannel']),
    budgetChannel: table.searchParams?.budgetChannel
      ? [table.searchParams?.budgetChannel]
      : [],
    start:
      table.searchParams?.submitTime?.[0]
        ?.startOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    end:
      table.searchParams?.submitTime?.[1]
        ?.endOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined
  }

  const [{ data, error, fetching }, refetchList] = useSalesOrderPageQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        ...queryVariable
      }
    }
  })

  const [, terminateRule] = useTerminateAlipayRuleMutation()
  const [, orderVoucherReject] = useSalesOrderVoucherRejectMutation()

  const terminateAlipayRule = async (orderId: string) => {
    const data = await terminateRule({ orderId })
    if (data?.data?.result?.success) {
      message.success('支付规则作废成功')
    } else {
      message.error(data?.data?.result?.message || '支付规则作废失败')
    }
  }

  const voucherReject = async (orderId: string) => {
    const { data, error } = await orderVoucherReject({
      id: orderId
    })
    if (data?.result) {
      message.success('票据退回成功')
      refetchList()
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('票据退回失败:' + errorMsg)
    }
  }

  const tableColumns = useTableColumns({
    terminateAlipayRule,
    voucherReject,
    isMobile
  })

  const queryExportData = async (currentPage: number, pageSize: number) => {
    const { data, error } = await urqlClient
      .query<SalesOrderPageQuery, SalesOrderPageQueryVariables>(
        SalesOrderPageDocument,
        {
          pager: {
            currentPage,
            pageSize
          },
          query: {
            ...queryVariable
          }
        }
      )
      .toPromise()
    return { currentPage, exportData: data?.result?.items, error }
  }

  const onExportHandle = () => {
    setExporting(true)
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        scroll={{ x: isMobile ? 890 : 1540 }}
        searchProps={{
          fields: searchFields()
        }}
        bordered
        loading={fetching}
        pager={data?.result?.pager}
        dataSource={data?.result?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
        showExportBtn
        onExport={onExportHandle}
      />
      <ExportQueueModal
        queryExportDataFc={queryExportData}
        formatDataFc={getData}
        excelHead={excelHead()}
        fileName={`消费订单管理_${new Date().getTime()}`}
        isExport={isExporting}
        total={data?.result?.pager?.total || 0}
        concurrency={2}
        callback={() => {
          setExporting(false)
        }}
      />
    </main>
  )
}

ConsumptionOrderManage.pageTitle = '消费订单管理'
export default ConsumptionOrderManage
