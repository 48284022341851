import React, { FC, Fragment, useState } from 'react'
import { Card, Row, Space, Tabs } from 'antd'
import { format } from '../../lib/timeFormat'
import { ApproveInfo } from '../../graphql'

const { TabPane } = Tabs

const statusColor: Record<string, string> = {
  SUBMIT: '#5581FA',
  APPROVED: '#FF6F00',
  RETREAT: '#FF5159',
  APPROVING: '#51A04C'
}

const ApprovalCard = ({ approvalDetail }: { approvalDetail: any }) => {
  for (let index = 0; index < approvalDetail.length; index++) {
    const approval = approvalDetail[index]
    const beforeApprovals = approval.logDatas?.filter(
      (x: any) => x.logType === 'BEFORE'
    )
    if (beforeApprovals?.length) {
      for (let bindex = 0; bindex < beforeApprovals?.length; bindex++) {
        const beforeApproval = beforeApprovals[bindex]
        const preApproval = approvalDetail[index - bindex - 1]
        if (preApproval.approverInfo?.accountId === beforeApproval.assginer) {
          preApproval.isForwardSignature = true
        }
      }
    }

    const afterApproval = approval.logDatas?.find(
      (x: any) => x.logType === 'AFTER'
    )
    if (afterApproval) {
      const curAfterApproval = approvalDetail[index + 1]
      if (curAfterApproval.approverInfo?.accountId === afterApproval.assginer) {
        curAfterApproval.isForwardSignature = true
      }
    }
  }
  return approvalDetail?.map((approval: any, index: number) => {
    const beforeApprovals = approval.logDatas?.filter(
      (x: any) => x.logType === 'BEFORE'
    )
    const afterApproval = approval.logDatas?.find(
      (x: any) => x.logType === 'AFTER'
    )
    return (
      <Space
        key={index}
        direction='vertical'
        size={28}
        style={{
          paddingLeft: '30px',
          borderLeft: '1px solid #f0f0f0',
          width: '100%'
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{
            position: 'relative',
            backgroundColor: '#f5f6fa',
            padding: '8px 16px',
            width: '70%',
            flexWrap: 'nowrap'
          }}
        >
          <div style={{ width: '100%' }}>
            <div style={{ color: '#888888' }}>
              {approval.approverInfo?.cnName}
              {approval.isForwardSignature && (
                <span style={{ color: '#fa8c16', marginLeft: 15 }}>加签</span>
              )}
            </div>
            {beforeApprovals?.map((beforeApproval: any, index: number) => {
              return (
                <Fragment key={index}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>
                      向前加签给
                      <span style={{ color: '#888888' }}>
                        {` ${beforeApproval.assginerInfo?.cnName}`}
                      </span>
                    </span>
                    <span style={{ color: '#888888' }}>
                      {format(beforeApproval.createTime)}
                    </span>
                  </div>
                  <div>加签理由：{beforeApproval.reason || '无'}</div>
                </Fragment>
              )
            })}
            {afterApproval && (
              <Fragment key={index}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>
                    向后加签给
                    <span style={{ color: '#888888' }}>
                      {` ${afterApproval.assginerInfo?.cnName}`}
                    </span>
                  </span>
                  <span style={{ color: '#888888' }}>
                    {format(afterApproval.createTime)}
                  </span>
                </div>
                <div>加签理由：{afterApproval.reason || '无'}</div>
              </Fragment>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                {approval.status === 'SUBMIT'
                  ? approval.opinion
                  : approval.status === 'APPROVING'
                  ? '审批中'
                  : approval.status === 'WAITING'
                  ? '待审批'
                  : `审批结果：${
                      approval.status === 'APPROVED' ? '通过' : '退回'
                    }`}
              </span>
              {approval.status &&
              ['SUBMIT', 'APPROVED', 'RETREAT'].includes(approval.status) ? (
                <span style={{ color: '#888888' }}>
                  {format(approval.createTime)}
                </span>
              ) : null}
            </div>
            {approval.status === 'RETREAT' && (
              <div style={{ color: '#f76060' }}>
                退回理由：{approval.opinion}
              </div>
            )}
          </div>
          <div
            style={{
              position: 'absolute',
              top: 'calc(50% - 6px)',
              left: '-36px',
              width: '11px',
              height: '11px',
              borderRadius: '50%',
              backgroundColor: statusColor[approval.status] || '#CCC'
            }}
          />
        </Row>
      </Space>
    )
  })
}

const ApprovalProcessCard: FC<{
  loading: boolean
  approvalInfo: Array<ApproveInfo>
  isShow: boolean
}> = ({ loading, approvalInfo, isShow }) => {
  const approvals = approvalInfo?.map((item) => item.tasks)
  const currentApproval = approvals.pop()

  const [activeKey, setActiveKey] = useState('1')
  return isShow ? (
    <Card
      loading={loading}
      title={
        approvals?.length ? (
          <Tabs
            defaultActiveKey='1'
            size='large'
            onChange={(key) => setActiveKey(key)}
          >
            <TabPane tab='审批信息' key='1' />
            <TabPane tab='历史审批' key='2' />
          </Tabs>
        ) : (
          '审批信息'
        )
      }
    >
      {activeKey === '1' ? (
        !approvalInfo || approvalInfo?.length <= 0 ? (
          '暂无审批信息'
        ) : (
          <ApprovalCard approvalDetail={currentApproval} />
        )
      ) : (
        approvals?.map((group: any, i: number) => {
          return (
            <div key={i} style={{ marginBottom: 40 }}>
              <ApprovalCard approvalDetail={group} />
            </div>
          )
        })
      )}
    </Card>
  ) : null
}

export default ApprovalProcessCard
