import Button from 'antd/es/button'
import React, { useRef } from 'react'
import CodeEditor from '@uiw/react-textarea-code-editor'
import { InputProps, TextAreaProps } from 'antd/lib/input'

interface Props {
  value?: string
  onChange?: (value: string) => void
}

export const MirrorCodeArea: React.FC<TextAreaProps> = ({
  value = '',
  onChange
}) => {
  return (
    <>
      <CodeEditor
        value={value}
        padding={15}
        language='json'
        minHeight={800}
        style={{
          fontSize: 12,
          backgroundColor: '#f5f5f5',
          fontFamily:
            'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
        }}
        onChange={(ev) => {
          onChange?.(ev)
        }}
      ></CodeEditor>
    </>
  )
}
