import React, { useState, useEffect, FC, useRef } from 'react'
import omit from 'lodash.omit'
import { Button } from 'antd'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import {
  SalesClaimFormDocument,
  SalesClaimFormQuery,
  SalesClaimFormQueryVariables,
  useSalesClaimFormQuery
} from '../../graphql'
import {
  searchFields,
  FieldTypes,
  useTableColumns,
  getData,
  excelHead
} from './data'
import ErrorView from '../../components/ErrorView'
import { useSearchStore } from '../../hooks/pageSearch'
import { useClient } from 'urql'
import OpeartionScanningCodeGun, {
  event
} from '../../components/OpeartionScanningCodeGun'
import ExportQueueModal from '../../components/ExportQueueModal'
import './index.less'

const tabKey = 'approved'
const Approved: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[pageKey] }
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const urqlClient = useClient()
  const [isExporting, setExporting] = useState(false)
  const [isScanning, setIsScanning] = useState(false)
  const event = useRef({} as event)

  const queryVariable = {
    ...omit(table.searchParams, [
      'submitTime',
      'preReviewedTime',
      'reviewedTime'
    ]),
    startTime:
      table.searchParams?.submitTime?.[0]
        ?.startOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    endTime:
      table.searchParams?.submitTime?.[1]
        ?.endOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    preReviewedStartTime:
      table.searchParams?.preReviewedTime?.[0]
        ?.startOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    preReviewedEndTime:
      table.searchParams?.preReviewedTime?.[1]
        ?.endOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    reviewedStartTime:
      table.searchParams?.reviewedTime?.[0]
        ?.startOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    reviewedEndTime:
      table.searchParams?.reviewedTime?.[1]
        ?.endOf('day')
        .format('YYYY-MM-DD HH:mm:ss') || undefined,
    status: [3, 4, 5]
  }

  const [{ data, error, fetching }, refetchList] = useSalesClaimFormQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        ...queryVariable
      }
    }
  })

  const tableColumns = useTableColumns({ type: 'approved' })

  const queryExportData = async (currentPage: number, pageSize: number) => {
    const { data, error } = await urqlClient
      .query<SalesClaimFormQuery, SalesClaimFormQueryVariables>(
        SalesClaimFormDocument,
        {
          pager: {
            currentPage,
            pageSize
          },
          query: {
            ...queryVariable
          }
        }
      )
      .toPromise()
    return { currentPage, exportData: data?.result?.items, error }
  }

  const onExportHandle = async () => {
    setExporting(true)
  }

  const visibilityChangeNamePropName = (
    document: Document & { msHidden?: boolean; webkitHidden?: boolean }
  ) => {
    if (typeof document.hidden !== 'undefined') {
      return 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      return 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      return 'webkitvisibilitychange'
    }
    return 'visibilitychange'
  }

  const handleVisibilityChange = () => {
    const doc = document as Document & {
      msHidden?: boolean
      webkitHidden?: boolean
    }
    if (doc.hidden || doc.msHidden || doc.webkitHidden) {
      event?.current?.onCancel?.()
    } else {
      event?.current?.onStart?.()
    }
  }

  useEffect(() => {
    const visibilityChangeName = visibilityChangeNamePropName(document)
    // 处理页面可见属性的改变
    document.addEventListener(visibilityChangeName, handleVisibilityChange)
    return () => {
      document.removeEventListener(visibilityChangeName, handleVisibilityChange)
    }
  }, [])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        tabKey={tabKey}
        scroll={{ x: 2150 }}
        searchProps={{
          fields: searchFields({ type: 'approved' })
        }}
        bordered
        loading={fetching}
        pager={data?.result?.pager}
        dataSource={data?.result?.items || []}
        rowKey={(record) => record.claimForm.id}
        columns={tableColumns}
        showExportBtn
        onExport={onExportHandle}
        extraOpera={
          <>
            <Button
              type='primary'
              onClick={() => setIsScanning(true)}
              style={{ marginRight: 5 }}
              className='scanningBtn'
            >
              扫码搜索
            </Button>
          </>
        }
      />
      <ExportQueueModal
        queryExportDataFc={queryExportData}
        formatDataFc={getData}
        excelHead={excelHead({ type: 'approved' })}
        fileName={`财务初审已审核_${new Date().getTime()}`}
        isExport={isExporting}
        total={data?.result?.pager?.total || 0}
        concurrency={5}
        callback={() => {
          setExporting(false)
        }}
      />
      {isScanning && (
        <OpeartionScanningCodeGun
          event={event}
          visible={isScanning}
          callBack={(code) => {
            window.open(`/consumptionReimburseDetail/${code}`)
          }}
          onCancel={() => setIsScanning(false)}
        />
      )}
    </main>
  )
}

export default Approved
