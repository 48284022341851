import React, { FC, useState } from 'react'
import { Modal, Form } from 'antd'
import { ApproveUserSelector } from '../../components/SelectorCollection'
import './index.less'

const ApprovalConfirmModal: FC<{
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
}> = ({ onCancel, onSubmit }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = (values: any) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  return (
    <Modal
      maskClosable={false}
      title='审批通过'
      visible
      onCancel={onCancel}
      centered
      closable={false}
      destroyOnClose
      width={450}
      onOk={() => {
        form.submit()
      }}
      okText={'确定'}
      confirmLoading={loading}
    >
      <Form preserve={false} form={form} onFinish={onFinish}>
        <Form.Item
          label='审批人员'
          name='approveUser'
          rules={[{ required: true, message: '请选择审批人员' }]}
          initialValue={localStorage.getItem('meeting-approve-user')}
        >
          <ApproveUserSelector auto={true} isSave={true} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ApprovalConfirmModal
