import React, { useState, useEffect } from 'react'
import omit from 'lodash.omit'
import { searchFields, useTableColumns, FieldTypes, excelHead } from './data'
import {
  OrderOperationCategory,
  useOperateOrderMutation,
  useUpadminSearchAttendersQuery
} from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { generateExport } from '../../components/UploadBatch'
import Modal from 'antd/lib/modal/Modal'
import { message, Spin } from 'antd'
import { getData } from '../../lib/getExportList'
import { useSearchStore } from '../../hooks/pageSearch'
import OperationOrderModal from './operationOrderModal'
import { isMobileClient } from '../../constant/user'

const OrderManage: QCPage = () => {
  const { pageParams } = useSearchStore()
  const isMobile = isMobileClient()
  const searchParams = { ...pageParams[window.location.pathname] }
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const [isExporting, setExporting] = useState(false)
  const [curRegionId, setCurRegionId] = useState<string | undefined>(
    searchParams?.regionId
  )
  const [curCompanyId, setCurCompanyId] = useState<string | undefined>(
    searchParams?.companyId
  )
  const [curActivityTypes, setCurActivityTypes] = useState<
    Array<any> | undefined
  >(searchParams?.activityType)
  const [curMeetingType, setCurMeetingType] = useState<string | undefined>(
    searchParams?.meetingType
  )
  const [meetingInfo, setMeetingInfo] = useState<any>()
  const [operationType, setOperationType] = useState<
    OrderOperationCategory | undefined
  >()

  const queryVariable = {
    ...omit(table.searchParams, [
      'date',
      'activityType',
      'regionId',
      'meetingType'
    ]),
    ...(!curMeetingType ? {} : { meetingType: curMeetingType }),
    ...(!curActivityTypes?.length || curMeetingType === 'ACADEMIC_CONFERENCE'
      ? {}
      : { activityType: curActivityTypes }),
    ...(!curRegionId ? {} : { regionId: curRegionId }),
    meetingStartTime:
      (table.searchParams?.date?.[0]?.startOf('day').unix() || 0) * 1000 ||
      undefined,
    meetingEndTime:
      (table.searchParams?.date?.[1]?.endOf('day').unix() || 0) * 1000 ||
      undefined
  }

  const [{ data, error, fetching }, refetch] = useUpadminSearchAttendersQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: {
        ...queryVariable
      }
    }
  })

  const [, operateOrder] = useOperateOrderMutation()

  const [{ data: exportResult, error: exportErr }] =
    useUpadminSearchAttendersQuery({
      variables: {
        pager: {
          currentPage: 1,
          pageSize: 500
        },
        query: {
          ...queryVariable
        }
      },
      pause: !isExporting
    })

  const onExportHandle = () => {
    setExporting(true)

    if (exportErr) {
      setExporting(false)
      message.error('导出失败')
      return
    }
  }

  useEffect(() => {
    if (isExporting) {
      const dataResult = getData(
        exportResult?.orders?.items || [],
        excelHead(),
        false
      )
      generateExport(
        dataResult,
        `订单管理_${new Date().getTime()}`,
        5000,
        (err) => {
          setExporting(false)
          if (err) {
            message.error('导出失败')
          } else {
            message.success('EXCEL下载成功，请查收')
          }
        }
      )
    }
  }, [exportResult])

  const onOperationOrder = (type: OrderOperationCategory, data: any) => {
    //delete-split-return
    setOperationType(type)
    setMeetingInfo(data)
  }

  const onSubmitOperationOrder = async (values: any, callBack?: () => void) => {
    console.log(values)
    const { meetingId, attenderId } = meetingInfo
    const { data, error } = await operateOrder({
      input: {
        reason: values.reason,
        channel: values.channel,
        relUserId: attenderId,
        relMeetingId: meetingId,
        category: operationType
      }
    })
    const operationTypeText =
      operationType === OrderOperationCategory.DeleteUser
        ? '删除'
        : operationType === OrderOperationCategory.SplitUser
        ? '拆分'
        : operationType === OrderOperationCategory.CancelOrder
        ? '退回'
        : '操作'
    callBack && callBack()
    if (data?.result) {
      message.success(operationTypeText + '成功')
      setOperationType(undefined)
      refetch()
    } else {
      message.error(error?.message || operationTypeText + '失败')
    }
  }

  const tableColumns = useTableColumns({
    onRefreshed: refetch,
    onOperationOrder,
    isMobile
  })

  // 省区选项改变，手动触发状态更改
  const onRegionChange = (value: string) => {
    setCurRegionId(value)
  }
  // 企业选项改变，省区置空，待重新选择
  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
    setCurRegionId(undefined)
    setCurMeetingType(undefined)
    setCurActivityTypes(undefined)
  }
  // 活动类型选项改变
  const onActivityTypesChange = (value: Array<any>) => {
    setCurActivityTypes(value)
  }
  // 会议类型选项改变
  const onMeetingTypeChange = (value: string) => {
    setCurActivityTypes(undefined)
    setCurMeetingType(value)
  }

  const onTableReset = () => {
    setCurMeetingType(undefined)
    setCurCompanyId(undefined)
    setCurActivityTypes(undefined)
    setCurRegionId(undefined)
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        scroll={{ x: isMobile ? 3360 : 3450 }}
        table={table}
        searchProps={{
          fields: searchFields(
            curCompanyId,
            curMeetingType,
            onRegionChange,
            onCompanyChange,
            onActivityTypesChange,
            onMeetingTypeChange
          )
        }}
        bordered
        loading={fetching}
        pager={data?.orders?.pager}
        dataSource={data?.orders?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
        showExportBtn
        onExport={onExportHandle}
        onReset={onTableReset}
      />

      <Modal
        visible={!!isExporting}
        footer={null}
        maskClosable={false}
        closable={false}
        width={400}
      >
        <Spin tip='正在为您生成EXCEL文件，请不要关闭此网页…' />
      </Modal>
      {operationType && (
        <OperationOrderModal
          onCancel={() => {
            setOperationType(undefined)
          }}
          onSubmit={onSubmitOperationOrder}
          operationType={operationType}
        />
      )}
    </main>
  )
}

OrderManage.pageTitle = '订单管理'

export default OrderManage
