import React, { useState, FC, useEffect } from 'react'
import {
  notification,
  Modal,
  Form,
  Select,
  Input,
  message,
  Button,
  TimePicker
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import flatten from 'lodash/flatten'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import {
  HRCompanySelector,
  ActivitiesTypeSelector,
  MeetingTypeSelector,
  ProcessSelector,
  LiteratureProductSelector,
  ActivityTemplateSelector,
  DomainSelector
} from '../../components/SelectorCollection'
import { useActivityTypeColumns } from './data'
import {
  useUpadminCompaniesQuery,
  useMeetingTypesByCompanyQuery,
  MeetingActivity,
  MeetingTypesByCompanyQuery,
  useUpdateActivityEnabledMutation,
  useBindActivityToMeetingCategoryMutation
} from '../../graphql'
import DatePicker from '../../components/CustomDatePicker'
import { useSearchStore } from '../../hooks/pageSearch'

const getDataSource = (data: MeetingTypesByCompanyQuery['result']) => {
  if (!data?.length) {
    return []
  }
  const source = data
  source.forEach((item) => {
    const activitiyTypes = item.activitiyTypes
    item.activitiyTypes = activitiyTypes.map((i) => {
      return { ...i, meetingType: item?.config?.title }
    })
  })
  return flatten(source.map((i) => i.activitiyTypes))
}

const tabKey = 'componyActivityType'
const ComponyActivityType: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[pageKey] }
  const [form] = Form.useForm()
  const table = useSearchTable<any>({}, { pageSize: 15, currentPage: 1 })
  const [modalShow, updateModalShow] = useState(false)
  const [companyIdOfAdd, setCompanyIdOfAdd] = useState<string>('')

  const [{ data: selectOptions }] = useUpadminCompaniesQuery()
  const [{ fetching: updateLoading }, updateStatus] =
    useUpdateActivityEnabledMutation()
  const [{ fetching: bindLoading }, bindActivity] =
    useBindActivityToMeetingCategoryMutation()

  const [{ data, error, fetching }, refetch] = useMeetingTypesByCompanyQuery({
    variables: {
      companyId: table.searchParams.companyId
    },
    pause: !selectOptions?.companies?.length
  })

  const onStatusChange = (typeInfo: MeetingActivity) => {
    Modal.confirm({
      title: '确认',
      icon: <ExclamationCircleOutlined />,
      content: `确认${!typeInfo.enable ? '启用' : '禁用'}${
        typeInfo.config?.title
      }？`,
      okText: '确认',
      okButtonProps: { loading: updateLoading },
      onOk: async () => {
        const result = await updateStatus({
          enabled: !typeInfo.enable,
          activityId: typeInfo.id
        })

        if (!result.data?.result?.success) {
          notification.error({
            message: `${!typeInfo.enable ? '启用' : '禁用'}失败`,
            description: `【${typeInfo.config?.title}】${
              result.data?.result?.message || ''
            }`
          })
        } else {
          notification.success({
            message: `${!typeInfo.enable ? '启用' : '禁用'}成功`,
            description: `【${typeInfo.config?.title}】`
          })
          updateModalShow(false)
          refetch()
        }
      }
    })
  }

  const onConfirm = async () => {
    try {
      const values = await form.validateFields()
      const {
        companyId,
        categoryCode,
        activityCode,
        enable,
        processId,
        instanceMax,
        instanceMin,
        productType,
        start,
        end,
        templateCode,
        sphere
      } = values

      const result = await bindActivity({
        input: {
          companyId,
          categoryCode,
          activityCode,
          enable: !!enable,
          processId,
          instanceMax: parseInt(instanceMax),
          instanceMin: parseInt(instanceMin),
          productType,
          start,
          end,
          templateCode,
          sphere
        }
      })
      if (!result.data?.result?.success) {
        notification.error({
          message: '企业新增活动类型失败',
          description: result.data?.result?.message || '失败'
        })
      } else {
        notification.success({
          message: '企业新增活动类型成功',
          description: '新增成功'
        })
        updateModalShow(false)
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  // 自动查询第一个企业的数据
  useEffect(() => {
    if (!searchParams?.companyId && selectOptions?.companies[0]?.id) {
      table.updateSearchParams(
        {
          companyId: selectOptions?.companies[0]?.id
        },
        undefined,
        pageKey
      )
    }
  }, [JSON.stringify(selectOptions?.companies)])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        showOperaBtn={false}
        showPagination={false}
        table={table}
        tabKey={tabKey}
        bordered
        loading={fetching}
        dataSource={getDataSource(data?.result)}
        size='middle'
        rowKey='id'
        columns={useActivityTypeColumns({ onStatusChange })}
        scroll={{ x: 1520 }}
        searchProps={{
          fields: [
            {
              name: 'companyId',
              label: '企业',
              span: 6,
              defaultValue: selectOptions?.companies[0]?.id!,
              render() {
                return (
                  <Select
                    showSearch
                    placeholder='请选择企业'
                    optionFilterProp='children'
                    key={selectOptions?.companies[0]?.id || ''}
                    defaultValue={selectOptions?.companies[0]?.id || ''}
                  >
                    {selectOptions?.companies.map((company, i) => (
                      <Select.Option
                        value={company?.id || ''}
                        key={company?.id || i}
                      >
                        {company.cnName || ''}
                      </Select.Option>
                    ))}
                  </Select>
                )
              }
            }
          ]
        }}
        extraPanelContent={
          <Button
            type='primary'
            style={{ position: 'absolute', right: '5%', bottom: '15px' }}
            onClick={() => updateModalShow(true)}
          >
            企业新增活动类型
          </Button>
        }
      />

      <Modal
        maskClosable={false}
        title='企业新增活动类型'
        visible={modalShow}
        onOk={onConfirm}
        onCancel={() => updateModalShow(false)}
        okText='确认'
        cancelText='取消'
        confirmLoading={bindLoading}
        afterClose={() => {
          setCompanyIdOfAdd('')
          form.resetFields()
        }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} form={form}>
          <Form.Item
            label='企业'
            name='companyId'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <HRCompanySelector
              onChange={(value) => {
                // 公司切换时，清空一下会议类型、审批流
                const { companyId, ...other } = form.getFieldsValue()

                form.setFieldsValue({
                  companyId,
                  ...Object.keys(other).reduce<Record<string, undefined>>(
                    (prev, curent) => {
                      prev[curent] = undefined
                      return prev
                    },
                    {}
                  )
                })
                setCompanyIdOfAdd(value)
              }}
            />
          </Form.Item>
          <Form.Item
            label='审批流'
            name='processId'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <ProcessSelector
              companyId={companyIdOfAdd}
              disabled={!companyIdOfAdd}
            />
          </Form.Item>
          <Form.Item
            label='会议类型'
            name='categoryCode'
            rules={[{ required: true, message: '不能为空' }]}
          >
            {/* 新增活动类型，只能选择公司已绑定了的会议类型下的活动 */}
            <MeetingTypeSelector
              filterByCompanyId
              disabled={!companyIdOfAdd}
              companyId={companyIdOfAdd}
            />
          </Form.Item>
          <Form.Item
            label='活动类型'
            name='activityCode'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <ActivitiesTypeSelector isMulti={false} />
          </Form.Item>

          <Form.Item label='产品类型' name='productType'>
            <LiteratureProductSelector companyId={companyIdOfAdd} />
          </Form.Item>
          <Form.Item label='模板' name='templateCode'>
            <ActivityTemplateSelector companyId={companyIdOfAdd} />
          </Form.Item>
          <Form.Item label='领域' name='sphere'>
            <DomainSelector companyId={companyIdOfAdd} />
          </Form.Item>
          <Form.Item label='开始时间' name='start'>
            <DatePicker />
          </Form.Item>
          <Form.Item label='结束时间' name='end'>
            <DatePicker />
          </Form.Item>
          <Form.Item label='最大份数' name='instanceMax'>
            <Input type='number' />
          </Form.Item>
          <Form.Item label='最小份数' name='instanceMin' initialValue={1}>
            <Input type='number' />
          </Form.Item>
          <Form.Item
            label='状态'
            name='enable'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Select placeholder='请选择状态'>
              <Select.Option value={1}>启用</Select.Option>
              <Select.Option value={0}>禁用</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </main>
  )
}

export default ComponyActivityType
