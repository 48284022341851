import React, { useState, FC, useEffect } from 'react'
import { notification, Modal, Form, Select, Input, Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import {
  HRCompanySelector,
  DocumentCategories
} from '../../components/SelectorCollection'
import { useTemplateTypeColumns } from './data'
import {
  useUpadminCompaniesQuery,
  MeetingActivity,
  useUpdateActivityEnabledMutation,
  useQueryActivityTemplateQuery,
  QueryActivityTemplateQuery,
  useQueryDocumentCategoriesQuery,
  useCreateMeetingActivityTemplateMutation
} from '../../graphql'
import TextArea from 'antd/lib/input/TextArea'
import { MirrorCodeArea } from '../../components/MirrorCodeArea'
import { useSearchStore } from '../../hooks/pageSearch'

const getDataSource = (data: QueryActivityTemplateQuery['result']) => {
  if (!data?.length) {
    return []
  }
  return data
}

const tabKey = 'companyTemplateType'
const CompanyTemplateType: FC = () => {
  const pageKey = window.location.pathname + tabKey
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[pageKey] }
  const [form] = Form.useForm()
  const table = useSearchTable<any>({}, { pageSize: 15, currentPage: 1 })
  const [curCompanyId, setCurCompanyId] = useState(searchParams?.companyId)
  const [modalShow, updateModalShow] = useState(false)
  const [companyIdOfAdd, setCompanyIdOfAdd] = useState<string>('')

  const [{ data: selectOptions }] = useUpadminCompaniesQuery()
  const [{ data: documentCategories }] = useQueryDocumentCategoriesQuery({
    variables: { companyId: table.searchParams.companyId },
    pause: !table.searchParams.companyId
  })
  const [{ fetching: updateLoading }, updateStatus] =
    useUpdateActivityEnabledMutation()
  const [{ fetching: bindLoading }, createTemplate] =
    useCreateMeetingActivityTemplateMutation()

  const [{ data, error, fetching }, refetch] = useQueryActivityTemplateQuery({
    variables: {
      companyId: curCompanyId || selectOptions?.companies[0]?.id,
      documentCategory: table.searchParams.documentCategory
    },
    pause: !selectOptions?.companies?.length
  })

  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
  }

  const onStatusChange = (typeInfo: MeetingActivity) => {
    Modal.confirm({
      title: '确认',
      icon: <ExclamationCircleOutlined />,
      content: `确认${!typeInfo.enable ? '启用' : '禁用'}${
        typeInfo.config?.title
      }？`,
      okText: '确认',
      okButtonProps: { loading: updateLoading },
      onOk: async () => {
        const result = await updateStatus({
          enabled: !typeInfo.enable,
          activityId: typeInfo.id
        })

        if (!result.data?.result?.success) {
          notification.error({
            message: `${!typeInfo.enable ? '启用' : '禁用'}失败`,
            description: `【${typeInfo.config?.title}】${
              result.data?.result?.message || ''
            }`
          })
        } else {
          notification.success({
            message: `${!typeInfo.enable ? '启用' : '禁用'}成功`,
            description: `【${typeInfo.config?.title}】`
          })
          updateModalShow(false)
          refetch()
        }
      }
    })
  }

  const onConfirm = async () => {
    try {
      const values = await form.validateFields()
      const {
        documentCategory,
        enable,
        templateName,
        unitPrice,
        templateAttribute
      } = values

      const result = await createTemplate({
        template: {
          companyId: companyIdOfAdd,
          documentCategory,
          isEnable: !!enable,
          templateName,
          unitPrice,
          templateAttribute
        }
      })
      if (!result.data?.result?.success) {
        notification.error({
          message: '企业新增模板失败',
          description: result.data?.result?.message || '失败'
        })
      } else {
        notification.success({
          message: '企业新增模板成功',
          description: '新增成功'
        })
        updateModalShow(false)
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  // 自动查询第一个企业的数据
  useEffect(() => {
    if (!curCompanyId && selectOptions?.companies[0]?.id) {
      table.updateSearchParams(
        {
          companyId: selectOptions?.companies[0]?.id
        },
        undefined,
        pageKey
      )
      setCurCompanyId(selectOptions?.companies[0]?.id)
    }
  }, [JSON.stringify(selectOptions?.companies)])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        showOperaBtn={false}
        showPagination={false}
        table={table}
        tabKey={tabKey}
        bordered
        loading={fetching}
        dataSource={getDataSource(data?.result)}
        size='middle'
        rowKey='id'
        columns={useTemplateTypeColumns({
          onStatusChange
        })}
        searchProps={{
          fields: [
            {
              name: 'companyId',
              label: '企业',
              span: 6,
              defaultValue: selectOptions?.companies[0]?.id!,
              render() {
                return (
                  <Select
                    showSearch
                    placeholder='请选择企业'
                    optionFilterProp='children'
                    key={selectOptions?.companies[0]?.id || ''}
                    onChange={onCompanyChange}
                    defaultValue={selectOptions?.companies[0]?.id || ''}
                  >
                    {selectOptions?.companies.map((company, i) => (
                      <Select.Option
                        value={company?.id || ''}
                        key={company?.id || i}
                      >
                        {company.cnName || ''}
                      </Select.Option>
                    ))}
                  </Select>
                )
              }
            },
            {
              name: 'documentCategory',
              label: '文档类型',
              span: 6,
              render() {
                return (
                  <Select
                    showSearch
                    placeholder='请选择文档类型'
                    allowClear
                    optionFilterProp='children'
                    key={documentCategories?.categories[0]?.id || ''}
                  >
                    {documentCategories?.categories.map((category, i) => (
                      <Select.Option
                        value={category?.id || ''}
                        key={category?.id || i}
                      >
                        {category.title || ''}
                      </Select.Option>
                    ))}
                  </Select>
                )
              }
            }
          ]
        }}
        extraPanelContent={
          <Button
            type='primary'
            style={{ position: 'absolute', right: '5%', bottom: '15px' }}
            onClick={() => updateModalShow(true)}
          >
            企业新增模板
          </Button>
        }
      />

      <Modal
        maskClosable={false}
        title='企业新增模板'
        visible={modalShow}
        onOk={onConfirm}
        onCancel={() => updateModalShow(false)}
        okText='确认'
        cancelText='取消'
        confirmLoading={bindLoading}
        afterClose={() => {
          setCompanyIdOfAdd('')
          form.resetFields()
        }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} form={form}>
          <Form.Item
            label='企业'
            name='companyId'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <HRCompanySelector
              onChange={(value) => {
                // 公司切换时，清空一下会议类型、审批流
                const { companyId, ...other } = form.getFieldsValue()

                form.setFieldsValue({
                  companyId,
                  documentCategory: undefined
                })
                setCompanyIdOfAdd(value)
              }}
            />
          </Form.Item>

          <Form.Item
            label='模板名称'
            name='templateName'
            rules={[{ required: true, message: '不能为空' }]}
          >
            {/* 新增活动类型，只能选择公司已绑定了的会议类型下的活动 */}
            <Input />
          </Form.Item>
          <Form.Item
            label='单价'
            name='unitPrice'
            rules={[{ required: true, message: '不能为空' }]}
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item label='文档类型' name='documentCategory'>
            <DocumentCategories companyId={companyIdOfAdd} />
          </Form.Item>
          <Form.Item
            label='状态'
            name='enable'
            rules={[{ required: true, message: '不能为空' }]}
            initialValue={1}
          >
            <Select placeholder='请选择状态'>
              <Select.Option value={1}>启用</Select.Option>
              <Select.Option value={0}>禁用</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label='模板内容' name='templateAttribute'>
            <MirrorCodeArea />
          </Form.Item>
        </Form>
      </Modal>
    </main>
  )
}

export default CompanyTemplateType
