import React from 'react'
import { Tabs } from 'antd'
import ApproveWait from './approveWait'
import Approved from './approved'

const { TabPane } = Tabs

const ConsumptionReimbursePreApprove: QCPage = () => {
  const onTabKeyChange = (key: any) => {
    console.log(key)
  }

  return (
    <main>
      <Tabs defaultActiveKey='1' onChange={onTabKeyChange}>
        <TabPane tab='待审核' key='wait'>
          <ApproveWait />
        </TabPane>
        <TabPane tab='已审核' key='approved'>
          <Approved />
        </TabPane>
      </Tabs>
    </main>
  )
}

ConsumptionReimbursePreApprove.pageTitle = '提报单财务初审'
export default ConsumptionReimbursePreApprove
